import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DoGetByZoneIdParkingAnomaly,
  DoSetZoneParkingAnomalyDuration,
  DoSetZoneParkingAnomalyIgnore,
  DoEditZoneDurationParkingAnomaly,
  DoEditZoneIgnoreParkingAnomaly,
  DoGetPositionsParkinganomaly,
  DoGetGroupsParkinganomaly
} from 'actions/parking-monitoring';
import { RootState } from 'reducers';

// component
import {  ParkingAnomaly, State } from 'components/ParkingAnomaly/ParkingAnomalyComponent';
import { Dialog } from 'components/Dialogs';
import { SuccessButton } from 'components/Buttons';
import { DialogList } from './DialogList';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// styles
import { useStyles } from './ParkingAnomalyStyles';
import { Typography } from '@mui/material';

interface Props {
  zoneId: number;
}

interface DialogProps {
  isOpen: boolean;
  change: string;
}

export const ZoneParkingMonitoring = ({ zoneId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { byZoneId: monitoring, isFetching } = useSelector((state: RootState) => state.parkingMonitoring.zones);
  const positions = useSelector((state: RootState) => state.parkingMonitoring.positions.data.map(anomaly => anomaly.position_id));
  const groups = useSelector((state: RootState) => state.parkingMonitoring.groups.data.map(anomaly => anomaly.group_id));

  const classes = useStyles();

  const [state, setState] = useState<State>({
    occupancyDuration: undefined,
    availabilityDuration: undefined,
    ignored: false,
  });
  const [dialogState, setDialogState] = useState({
    groups: [],
    positions: []
  });

  const [dialog, setDialogProps] = useState<DialogProps>({
    isOpen: false,
    change: ''
  });

  useEffect(() => {
    if (zoneId) {
      dispatch(DoGetByZoneIdParkingAnomaly(zoneId));

      dispatch(DoGetPositionsParkinganomaly({ zones: [zoneId] }));
      dispatch(DoGetGroupsParkinganomaly({ zones: [zoneId] }));
    }
  }, [dispatch, zoneId]);

  useEffect(() => {
    setState({
      occupancyDuration: monitoring?.occupy_max_interval || 0,
      availabilityDuration: monitoring?.availability_max_interval || 0,
      ignored: monitoring?.ignored || false,
    });
  }, [monitoring, zoneId]);

  const handleSubmitDuration = () => setDialogProps({
    isOpen: true,
    change: 'duration'
  });

  const handleSubmitIgnore = () => setDialogProps({
    isOpen: true,
    change: 'ignore'
  });
  const handleChangeDuration = () => {
    if (!monitoring) {
      dispatch(DoSetZoneParkingAnomalyDuration({
        occupyMaxInterval: Number(state.occupancyDuration) || undefined,
        availabilityMaxInterval: Number(state.availabilityDuration)|| undefined,
        zoneId,
        includeGroups: dialogState.groups,
        includePositions: dialogState.positions,
      }));
    } else {
      dispatch(DoEditZoneDurationParkingAnomaly({
        occupyMaxInterval: Number(state.occupancyDuration) || undefined,
        availabilityMaxInterval: Number(state.availabilityDuration) || undefined,
        includeGroups: dialogState.groups,
        includePositions: dialogState.positions,
        zoneId,
      }));
    }
  };
  const handleChangeIgnore = () => {
    if(!monitoring) {
      dispatch(DoSetZoneParkingAnomalyIgnore({
        ignored: state.ignored,
        includeGroups: dialogState.groups,
        includePositions: dialogState.positions,
        zoneId,
      }));
    } else {
      dispatch(DoEditZoneIgnoreParkingAnomaly({
        ignored: state.ignored,
        includeGroups: dialogState.groups,
        includePositions: dialogState.positions,
        zoneId,
      }));
    }
  };

  const handleSubmit = () => {
    if (dialog.change === 'duration') {
      handleChangeDuration();
    } else {
      handleChangeIgnore();
    }
    setDialogProps(prevState => ({ ...prevState, isOpen: false }));
  };

  const handleSelect = (name: string, value: number[]) => setDialogState(prevState => ({ ...prevState, [name]: value }));

  return (
    <div>
      <ParkingAnomaly
        monitoring={ monitoring }
        isFetching={ isFetching }
        handleSubmitDuration={ handleSubmitDuration }
        handleSubmitIgnore={ handleSubmitIgnore }
        state={ state }
        setState={ setState }
      />

      <Dialog
        open={ dialog.isOpen }
        onOpen={ () => setDialogProps(prevState => ({ ...prevState, isOpen: true })) }
        onClose={ () => setDialogProps(prevState => ({ ...prevState, isOpen: false })) }
        fullWidth
        className={ classes.dialog }
      >
        {(!positions.length && !groups.length) ? (
          <DialogTitle>Are You sure?</DialogTitle>
        ):(
          <>
            <DialogTitle>Please, select positions and groups, which config of parking anomaly behaviour monitoring should be overwritten</DialogTitle>
            <DialogContent>
              {(groups?.length > 0) && (
                <Grid item={ true } xs={ 12 } sm={ 12 }>
                  <Typography>Groups:</Typography>
                  <DialogList list={ groups } name="groups" handleSelect={ handleSelect } />
                </Grid>
              )}
              {(positions?.length > 0) && (
                <Grid item={ true } xs={ 12 } sm={ 12 }>
                  <Typography>Positions:</Typography>
                  <DialogList list={ positions } name="positions" handleSelect={ handleSelect } />
                </Grid>
              )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <SuccessButton label="Save" onClick={ handleSubmit }/>
          <Button color="inherit" onClick={() => setDialogProps(prevState => ({ ...prevState, isOpen: false }))}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
