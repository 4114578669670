import React from 'react';
import { RouteConfig, RouteLabelRendererProps } from 'react-router-config';
import { match as Match } from 'react-router-dom';

export * from './renderRoutes';

export const RoutesContext = React.createContext<RouteConfig[] | null>(null);

type RouteParams = Record<string, string | undefined>;

export function getParam(name: string, match: Match<Record<string, string | undefined>> | null): string | undefined {
  return (match as Match<RouteParams> | null)?.params[name];
}

export function isImplementedRoute(route: RouteConfig): boolean {
  return !!(route.main || route.getRouteComponent || route.render);
}

export function renderRouteLabel(matchedRoute: RouteLabelRendererProps): React.ReactNode {
  if (typeof matchedRoute.route.label === 'function') {
    return matchedRoute.route.label(matchedRoute);
  }

  return matchedRoute.route.label;
}
