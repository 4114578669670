import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { GetTemplateSuitesParams } from 'models/caller';


export function parseUrl(urlState: UrlItems): GetTemplateSuitesParams {
  const parser = new Parser(urlState as GetTemplateSuitesParams);
  return {
    name: parser.asString('name'),
    owner: parser.asNumber('owner'),
  };
}


export const useTemplateSuitesParams = () => {
  const { items, setItems } = useLocationItems();
  const { limit, offset } = usePagination();

  return {
    params: { ...parseUrl(items), limit, offset },
    setParams: (params: GetTemplateSuitesParams) => {
      setItems({ ...params, limit, offset: 0 });
    }
  };
};