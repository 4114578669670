import React from 'react';
import { getDMLevelDetailPath } from 'routing/paths';

// components
import { Box, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { CommonButton } from 'components/Buttons';

interface Props {
  zoneId: string;
  levelId: string;
  groupId: string;
}

export const GroupNotFound = ({ zoneId, levelId, groupId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Group <b>{ `#${ groupId }` }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label={ `Back to level ${ levelId }` }
        LinkTo={ getDMLevelDetailPath(zoneId, levelId) }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};