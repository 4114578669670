import { DeviceStatesV2, IncidentsTypesEnumModel } from 'models/device-monitoring';

import redMarker from 'images/Map/MarkerIconRed.svg';
import greenMarker from 'images/Map/MarkerIconGreen.svg';
import orangeMarker from 'images/Map/MarkerIconOrange.svg';
import yellowMarker from 'images/Map/MarkerIconYellow.svg';
import transparentGreen from 'images/Map/MarkerIconTransparentGreen.svg';

import redMarkerDot from 'images/Map/red-ico.png';
import greenMarkerDot from 'images/Map/green-ico.png';
import yellowMarkerDot from 'images/Map/yellow-ico.png';
import orangeMarkerDot from 'images/Map/orange-ico.png';
import transparentGreenDot from 'images/Map/green-transparent-ico.png';
import greenRadioMarker from 'images/Map/MarkerRadioGreen.png';
import redRadioMarker from 'images/Map/MarkerRadioRed.png';
import { staticEnv } from 'env';


const KEY = staticEnv.GOOGLE_MAPS_KEY ? `key=${ staticEnv.GOOGLE_MAPS_KEY }&` : '';
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?${ KEY }v=3.exp&libraries=geometry&language=en-GB&region=GB`;
export const GOOGLE_STATIC_MAP_URL = `https://maps.googleapis.com/maps/api/staticmap?${ KEY }`;
export const GOOGLE_GEOLOCATION_URL = `https://www.googleapis.com/geolocation/v1/geolocate?${ KEY }`;

export const plainMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  ],
};

export function getMarkerIcon(isDamaged: boolean, isPositioned: boolean, state?: DeviceStatesV2) {
  switch (true) {
    case !isDamaged && isPositioned && !state:
      return greenMarker;
    case isPositioned && state && state.incident_type === IncidentsTypesEnumModel.LastMessageTimeError:
      return yellowMarker;
    case isPositioned && state && !state.reason:
      return orangeMarker;
    case isDamaged || (state && state.reason):
      return redMarker;
    case !isPositioned:
      return transparentGreen;
  }
}

export function getMarkerIconDot(isDamaged: boolean, isPositioned: boolean, state?: DeviceStatesV2): string {
  switch (true) {
    case !isDamaged && isPositioned && !state:
      return greenMarkerDot;
    case isPositioned && state && state.incident_type === IncidentsTypesEnumModel.LastMessageTimeError:
      return yellowMarkerDot;
    case isPositioned && state && !state.reason:
      return orangeMarkerDot;
    case isDamaged || (state && state.reason):
      return redMarkerDot;
    default:
      return transparentGreenDot;
  }
}

export interface ZoomArea {
  zoom: number;
  area: google.maps.LatLngBounds;
}

export enum MarkerColor {
  green,
  yellow,
  orange,
  red,
  transparent
}

export function getPositionMarkerLegend(color: MarkerColor) {
  switch (color) {
    case MarkerColor.green:
      return greenMarkerDot;
    case MarkerColor.yellow:
      return yellowMarkerDot;
    case MarkerColor.orange:
      return orangeMarkerDot;
    case MarkerColor.red:
      return redMarkerDot;
    case MarkerColor.transparent:
      return transparentGreenDot;
    default:
      return redMarkerDot;
  }
}

export function getStationMarkerIcon(color: MarkerColor) {
  switch (color) {
    case MarkerColor.green:
      return greenRadioMarker;
    case MarkerColor.red:
      return redRadioMarker;
    default:
      return redRadioMarker;
  }
}

export function getDeviceMarkerIcon(color: MarkerColor) {
  switch (color) {
    case MarkerColor.green:
      return greenMarkerDot;
    case MarkerColor.red:
      return redMarkerDot;
    case MarkerColor.yellow:
      return yellowMarkerDot;
    default:
      return redMarkerDot;
  }
}

export interface UserPosition  {
  lat: number;
  lon: number;
}

export const UserPositionDefault = {
  lat: 51.5073509, // center of London
  lon: -0.1277583, // center of London
};

export function fetchUserPosition(): Promise<UserPosition> {
  return new Promise((resolve) => {
    const resolveHTML5Geolocation = (onError: () => void) => {
      return () => {
        if (!navigator?.geolocation) {
          onError();
          return;
        }

        navigator.geolocation.getCurrentPosition(
          position => resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          }),
          () => onError()
        );
      };
    };

    const resolveGoogleGeolocation = (onError: () => void) => {
      return () => {
        fetch(GOOGLE_GEOLOCATION_URL, { method: 'POST' })
          .then(res => res.json())
          .then(position => {
            resolve({
              lat: position.location.lat,
              lon: position.location.lng,
            });
          })
          .catch(() => onError());
      };
    };

    const resolveDefault = () => {
      return () => {
        resolve(UserPositionDefault);
      };
    };

    resolveHTML5Geolocation(
      resolveGoogleGeolocation(
        resolveDefault()
      )
    )();
  });
}

export interface Marker {
  id?: unknown;
  lat: number;
  lon: number;
}

export interface MapState {
  map: google.maps.Map;
  google: typeof google;
}

export function setMapCenterByPoints(state: MapState, points: google.maps.LatLng[]) {
  if (!points.length) {
    return;
  }

  const bounds = new state.google.maps.LatLngBounds();
  points.forEach(p => bounds.extend(p));
  state.map.setCenter(bounds.getCenter());
}

export function fitMapByPoints(state: MapState, points: google.maps.LatLng[]) {
  if (!points.length) {
    return;
  }

  const bounds = new state.google.maps.LatLngBounds();
  points.forEach(p => bounds.extend(p));
  state.map.fitBounds(bounds);
}
