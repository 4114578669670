import { Device as ProvisioningDevice } from 'models/provisioning';
import {
  useProvisioningShipments,
  useReceivedShipmentsDevices,
  useShipmentById
} from 'hooks';

const shipmentStatuses = {
  produced: undefined,
  tested_successfully: undefined,
  rejected: undefined,
  added: 'added',
  dispatched: 'added',
  in_a_warehouse: 'received',
  received: 'received',
  installed: 'received',
  uninstalled: 'received',
  returned_to_nwave: 'received',
  disposed: 'received',
  restored: 'received',
};

export const useShipmentName = (provisioningDevices: ProvisioningDevice[]) => {
  const provisioningDevice = provisioningDevices[0];
  const status = shipmentStatuses[provisioningDevice.status];

  const shipmentsQuery = useProvisioningShipments({
    device_id: [provisioningDevice.device_id],
  }, Boolean(status) && status === 'added');

  const receivedDevice = useReceivedShipmentsDevices({
    deviceId: [provisioningDevice.device_id],
  }, Boolean(status) && status === 'received');

  const shipmentId = receivedDevice.data && receivedDevice.data[0] && receivedDevice.data[0].shipment_id;
  const receivedShipmentQuery = useShipmentById(shipmentId, !!shipmentId);

  const getName = () => {
    if (status) {
      if (status === 'added' && shipmentsQuery.data) { return shipmentsQuery.data[0].name; }
      if (status === 'received' && receivedShipmentQuery.data) { return receivedShipmentQuery.data.name; }
    }
    return '-';
  };

  return {
    sipmentName: getName(),
  };
};