import React from 'react';
import Link from '@mui/material/Link';
import { CustomLink } from 'components/Links';
import { dmPositionPath } from 'routing/paths';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface Props {
  positionId?: number;
  positionName?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

const PositionLink = ({ positionId, positionName, innerProps }: Props): JSX.Element => {
  const TypoProps = { ...defaultProps, ...innerProps };

  return positionId
    ? (
      <Link
        component={ CustomLink }
        color="secondary"
        to={ dmPositionPath(positionId) }
        withReturnUrl
      >
        { positionName ?? positionId }
      </Link>
    )
    : <Typography { ...TypoProps }>-</Typography>;
};

export default PositionLink;
