import { noop } from 'lodash';
import React from 'react';

import { Device } from 'models/device-management';
import { urlToFailuresTable } from 'routing/paths';

import { DEVICE_INCIDENTS_TO_SHOW, useDeviceIncidents } from './useDeviceIncidents';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Link,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import { IncidentsTable } from 'components/IncidentsTable/IncidentsTableComponent';
import { FieldSkeleton } from 'components/Skeleton';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';
import useDeviceIncidentsStyles from './style';

interface DeviceIncidentsProps {
  device?: Device;
}

const DeviceIncidents: React.FC<DeviceIncidentsProps> = ({ device }) => {
  const { recentIncidents, totalIncidents } = useDeviceIncidents({ deviceId: device?.device_id });

  const infoCss = useInfoBlockStyles();
  const deviceIncidentsCss = useDeviceIncidentsStyles();

  if (!device) {
    return <FieldSkeleton className={infoCss.field} />;
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Device Incidents
      </AccordionSummary>

      <AccordionDetails>
        <FormGroup className={infoCss.fields}>
          <IncidentsTable
            data={recentIncidents || []}
            isFetching={!recentIncidents}
            csvFetching={false}
            total={totalIncidents ?? DEVICE_INCIDENTS_TO_SHOW}
            fetchReport={noop}
            startSync={noop}
            stopSync={noop}
            className={deviceIncidentsCss.table}
            muiDataTableOptions={{
              elevation: 0,
              pagination: false,
              customToolbar: undefined,
              customFooter: undefined,
              viewColumns: false,
            }}
            visibleColumns={[
              'error_type',
              'resolving_status',
              'incident_appearing_time',
              'reason'
            ]}
          />

          {!!totalIncidents && totalIncidents > DEVICE_INCIDENTS_TO_SHOW && (
            <div className={infoCss.field}>
              <Link
                color="secondary"
                component={RouterLink}
                to={urlToFailuresTable({ devices: [device.device_id] })}
                target="_blank"
              >
                View all {totalIncidents} incidents...
              </Link>
            </div>
          )}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default DeviceIncidents;
