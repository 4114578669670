import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormActionNotifier } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { createLevel as createLevelApi, updateLevel as updateLevelApi, deleteLevel as deleteLevelApi } from 'clients/device-management';
import { DoFetchLevels } from 'actions/device-management/levels';
import { Level, LevelUpdateFields } from 'models/device-management';
import { LevelsFilters } from 'models/device-management/filters';
import { RootState } from 'reducers';

interface LevelsState<TWithFindById> {
  isLoading: boolean;
  levels: Level[];
  findLevelById: TWithFindById extends true
    ? (levelId: number) => Level | undefined
    : undefined;
  refetch: () => void;
}

interface LevelsSelectorHookOptions<TWithFindById> {
  params: LevelsFilters;
  withFindById?: TWithFindById;
  skipLoading?: boolean;
}

export function useLevelsSelector<TWithFindById extends boolean>({
  params,
  withFindById,
  skipLoading = false,
}: LevelsSelectorHookOptions<TWithFindById>): LevelsState<TWithFindById> {
  const dispatch = useDispatch();

  const { isFetched, levels, findLevelById } = useSelector((state: RootState) => ({
    isFetched: state.deviceManagement.levels.isFetched(params),
    levels: state.deviceManagement.levels.findByParams(params),
    findLevelById: withFindById ? state.deviceManagement.levels.findById : undefined,
  }), isEqual);

  useEffect(() => {
    if (skipLoading) {
      return;
    }

    if (isFetched === undefined) {
      dispatch(DoFetchLevels(params));
    }
  }, [dispatch, params, isFetched, skipLoading]);

  return {
    isLoading: isFetched !== true,
    levels,
    findLevelById: findLevelById as LevelsState<TWithFindById>['findLevelById'],
    refetch: () => dispatch(DoFetchLevels(params)),
  };
}

export const useCreateLevel = (onSuccess?: (level: Level) => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const createLevel = async (level: LevelUpdateFields) => {
    try {
      setIsFetching(true);
      const res = await createLevelApi(level);
      setIsFetching(false);
      notifySuccess('Level create successfully');
      onSuccess && onSuccess(res.data);
    } catch(e) {
      setIsFetching(false);
      notifyError(`Error while creating level`);
    }
  };

  return {
    isCreateLevelFetching: isFetching,
    createLevel
  };
};

export const useUpdateLevel = (onSuccess?: (level: Level) => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const updateLevel = async (levelId: number, level: LevelUpdateFields) => {
    try {
      setIsFetching(true);
      const res = await updateLevelApi(levelId, level);
      setIsFetching(false);
      notifySuccess('Level was updated successfully');
      onSuccess && onSuccess(res.data);
    } catch(e) {
      setIsFetching(false);
      notifyError('Error while updating Level');
    }
  };

  return {
    isUpdateLevelFetching: isFetching,
    updateLevel
  };
};


export const useDeleteLevel = (onSuccess?: () => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const deleteLevel = async (levelId: number) => {
    try {
      setIsFetching(true);
      await deleteLevelApi(levelId);
      setIsFetching(false);
      notifySuccess('Level was deleted successfully');
      onSuccess && onSuccess();
    } catch(e) {
      setIsFetching(false);
      notifyError('Error while deleting level');
    }
  };

  return {
    isDeleteLevelFetching: isFetching,
    deleteLevel
  };
};
