import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'form';
import { FormField, FormState } from '../types';
import { validate } from '../utils';
// components
import { Grid } from '@mui/material';
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';
// styles
import useStyles from '../style';


type Props = FormProps<FormState, FormField>
export const ManagementForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { state, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];
  const errors = props.errors ?? {};

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form className={ classes.form } noValidate autoComplete="off">
      <Grid container spacing={ 3 }>
        { !showFields.includes(FormField.name) ? '' :
          <Grid item xs={ 3 }>
            <TextControl
              disabled={ disabledFields.includes(FormField.name) }
              name="name"
              label="Endpoint name"
              value={ state.name }
              onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.name, event.target.value) }
              error={ showErrors.includes(FormField.name) ? errors[FormField.name] !== undefined : false }
              helperText={ showErrors.includes(FormField.name) ? errors[FormField.name] : undefined }
            />
          </Grid>
        }
        { !showFields.includes(FormField.ownerId) ? '' :
          <Grid item xs={ 3 }>
            <OwnersSingleSelectControl
              isDisabled={ disabledFields.includes(FormField.ownerId) }
              isClearable={ false }
              selected={ state.owner_id }
              error={ showErrors.includes(FormField.ownerId) ? errors[FormField.ownerId] : undefined }
              // `owner as number` is valid as long as `isClearable !== true`
              changeHandler={ (owner?: number) => handleChange(FormField.ownerId, owner as number) }
            />
          </Grid>
        }
      </Grid>
    </form>
  );
};