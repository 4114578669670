import { useState } from 'react';

type FormState = {
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
}

type PasswordErrors = {
  min: boolean;
  number: boolean;
  specialChar: boolean;
  uppercase: boolean;
  lowercase:  boolean;
}

const initialState: FormState = {
  firstName: '',
  lastName: '',
  password: '',
  repeatPassword: '',
};

const passwordErrorsInitState = {
  min: true,
  number: true,
  specialChar: true,
  uppercase: true,
  lowercase:  true,
};

const validate = (fields: FormState) => {
  const { firstName, lastName, password, repeatPassword } = fields;
  const errors = {
    firstName: firstName.trim().length ? '' : 'Field is required',
    lastName: lastName.trim().length ? '' : 'Field is required',
    password: password.trim().length ? '' : 'Field is required',
    repeatPassword: repeatPassword === password  ? '' : 'Passwords do not match',
    min: password.length < 8,
    number: !(/\d/g.test(password)),
    specialChar: !/[$&+,\\/:;=?@#"|'<>.^*()%![\]{}_~`-]/g.test(password),
    uppercase: !/[A-Z]/.test(password),
    lowercase:  !/[a-z]/.test(password)
  };
  return { errors, isValid: Object.values(errors).filter(e => e).length === 0 };
};

export const useInviteForm = () => {
  const [state, setState] = useState<FormState>(initialState);
  const [errors, setErrors] = useState<FormState & PasswordErrors>({
    ...initialState,
    ...passwordErrorsInitState,
  });

  const handleChange = (field: keyof FormState, value: string) => {
    if (field === 'password') {
      value = value.trim();
    }

    setState({ ...state, [field]: value });
  };

  const validateField = (field: keyof FormState) => {
    setErrors(prev => ({
      ...prev,
      [field]: validate(state).errors[field],
    }));
  };
  const validatePasswordField = (password: string) => {
    const validateResult = validate({ ...state, password });
    setErrors(prev => ({
      ...prev,
      password: validateResult.errors['password'],
      min: validateResult.errors['min'],
      number: validateResult.errors['number'],
      specialChar: validateResult.errors['specialChar'],
      uppercase: validateResult.errors['uppercase'],
      lowercase: validateResult.errors['lowercase']
    }));
  };
  const validateRepeatPasswordField = (password: string) => {
    const validateResult = validate({ ...state, repeatPassword: password });

    setErrors(prev => ({
      ...prev,
      repeatPassword: validateResult.errors['repeatPassword'],
    }));
  };

  return {
    handleChange,
    validateField,
    validatePasswordField,
    validateRepeatPasswordField,
    handleValidate: () => {
      const { errors, isValid } = validate(state);
      setErrors(errors);
      return isValid;
    },
    state,
    errors,
  };
};
