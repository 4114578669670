import { Shipment, ShipmentStatusEnum } from 'models/provisioning';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { ShipmentStatus } from '../Controls/ShipmentStatus';
import { OwnerName } from 'components/Labels';
import ShipmentName from '../Controls/ShipmentName';
import AcknowledgeButton from './widgets/AcknowledgeButton';

// styles
import { truncatedCellsMuiTableTheme } from 'styles/themes';
import { useShipmentDeviceCount } from './utils';

interface Props {
  shipments: Shipment[];
  isFetching: boolean;
  total: number;
  isAdmin: boolean;
}

const ShipmentTable = (props: Props): JSX.Element => {
  const { isFetching, shipments, total, isAdmin } = props;

  const { deviceCount } = useShipmentDeviceCount(shipments);
  const columns: MUIDataTableColumn[] = [
    {
      name: 'shipment',
      label: 'Shipment Name',
      options: {
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return shipment.id ? <ShipmentName id={ shipment.id }/> : '—';
        }
      }
    },
    {
      name: 'state',
      label: 'State',
      options: {
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return shipment.status ? <ShipmentStatus status={ shipment.status }/> : 'unknown';
        },
      }
    },
    {
      name: 'devices',
      label: 'Devices Count',
      options: {
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return deviceCount[shipment.id] ?? 0;
        },
      }
    },
    {
      name: 'sender',
      label: 'Sender',
      options: {
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ shipments[dataIndex].shipper_id }/>,
      }
    },
    {
      name: 'recipient',
      label: 'Recipient',
      options: {
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return shipment.recipient_id ? <OwnerName ownerId={ shipment.recipient_id }/> : '—';
        },
      }
    },
    {
      name: 'dispatch',
      label: 'Dispatch time',
      options: {
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return shipment.sending_date ? new Date(shipment.sending_date).toLocaleString() : '—';
        },
      }
    },
    {
      name: 'delivery',
      label: 'Delivery time',
      options: {
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return shipment.arrival_date ? new Date(shipment.arrival_date).toLocaleString() : '—';
        },
      },
    },
    {
      name: 'acknowledge',
      label: ' ',
      options: {
        customBodyRenderLite: dataIndex => {
          const shipment = shipments[dataIndex];
          return (
            shipment.status === ShipmentStatusEnum.Dispatched
              ? <AcknowledgeButton shipment={ shipment }/>
              : ''
          );
        },
      }
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    count: total,
    customFooter: count => <PaginationFooter count={count} />
  };

  return (
    <ThemeProvider theme={ truncatedCellsMuiTableTheme(shipments.length) }>
      <TableLoadingLayout isLoading={ isFetching }>
        <MUIDataTable
          title={ null }
          data={ shipments }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

export default ShipmentTable;
