import { useDMSDeviceProfilesDictionary } from 'hooks/dms';
import { DeviceProfile } from 'models/dms';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (profile: DeviceProfile) => boolean }

export const DeviceProfileSelect = (props: Props): JSX.Element => {
  const { isLoading, profiles } = useDMSDeviceProfilesDictionary();
  const filterCallback = props.filter ?? (() => true);
  const options: Option<string>[] =
    profiles
      .filter(filterCallback)
      .map(i => ({ value: i.id, label: i.name }));

  return (
    <SelectControl
      name="profile_id"
      label="Device profile"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ profiles.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
