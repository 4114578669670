import { put, takeEvery, call } from 'redux-saga/effects';

import {
  FETCH_LEVELS,
  FetchLevels,
  DoFetchLevelsSuccess,
  DoFetchLevelsFailure,
} from 'actions/device-management/levels';
import { NotifyError } from 'actions/notifier';

import {
  fetchLevels,
} from 'clients/device-management';

import { ResponseLevels, ResponseReason } from 'models/device-management';

function* fetchLevelsSaga(action: FetchLevels) {
  const response: ResponseLevels = yield call(fetchLevels, action.params);

  if (response.reason === ResponseReason.Ok && response.data) {
    yield put(DoFetchLevelsSuccess(action.params, response.data));
  } else {
    yield put(DoFetchLevelsFailure(action.params, response.message));
    yield put(NotifyError(`Error while fetching levels: ${response.message}`));
  }
}

export const deviceManagementLevelsSagas = [
  takeEvery(FETCH_LEVELS, fetchLevelsSaga),
];
