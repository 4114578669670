import { StationEntity } from 'models/base-station';
import { useLoraWANStationsDictionarySelector } from 'hooks/station';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number | string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (station: StationEntity) => boolean }

export const GatewayEUIControl = (props: Props): JSX.Element => {
  const { isLoading: isLoraStationsLoading, stations: loraStations } = useLoraWANStationsDictionarySelector();
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<number | string>[] = loraStations.filter(filterCallback)
    .map(station => ({
      value: station.eui,
      label: String(station.eui),
    }));

  return (
    <SelectControl
      name="GatewayEUI"
      label="Gateway EUI"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoraStationsLoading }
      checkSelected={ !isLoraStationsLoading }
      isDisabled={ !options.length || props.isDisabled }
    />
  );
};
