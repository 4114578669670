import React from 'react';

import { commonOptions as commonTableOptions } from 'utils/tables';

import { getMuiDataTableColumns } from './columns';
import { getTreeTableRows } from './rows';
import { TreeNode, TreeTableProps } from './types';

// components
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import { TableLoadingLayout } from 'components/Table';

import { TreeTableFooter } from './TreeTableFooter';

// styles
import { ThemeProvider } from 'styles/utils';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

export function TreeTable<TNode extends TreeNode<unknown>>({
  tree,
  defaultTotal,
  columns,
  pagination,
  onPaginationChange,
}: TreeTableProps<TNode>) {
  const rows = tree.children ? getTreeTableRows(tree.children) : [];

  const muiDataTableOptions: MUIDataTableOptions = {
    ...commonTableOptions,
    rowsPerPage: Infinity,
    customFooter: () => {
      return (
        <TreeTableFooter
          total={ tree.childrenTotal ?? defaultTotal }
          pagination={ pagination }
          onPaginationChange={ onPaginationChange }
        />
      );
    },
  };

  const muiDataTableColumns = getMuiDataTableColumns(columns, rows, {
    expand: tree.expand,
    collapse: tree.collapse,
    loadMoreChildren: tree.loadMoreChildren,
  });

  return (
    <ThemeProvider theme={ truncatedCellsMuiTableTheme(rows.length) }>
      <TableLoadingLayout isLoading={ tree.childrenLoadStatus === 'idle' || tree.childrenLoadStatus === 'loading' }>
        <MUIDataTable
          title={ null }
          options={ muiDataTableOptions }
          columns={ muiDataTableColumns }
          data={ rows }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
}
