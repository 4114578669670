import { API } from 'aws-amplify';
import { config } from './config';
import { errorHandler, formatQuery } from 'clients/utils';
import {
  Device,
  AwsDevice,
  ShipmentParams,
  ResponseShipments,
  ResponseShipment,
  ShipmentUpdateBody,
  ShipmentCreateBody,
  ShipmentStatusEnum,
  AwsDeviceProfile,
  AwsProvisioningDevice,
} from 'models/provisioning';
import { ApiResponse } from 'models';

const urls = {
  shipments: '/provisioning/shipments',
  shipmentById: (id: string) => `/provisioning/shipments/${id}`,
  updateShipmentStatusById: (id: string) => `/provisioning/shipments/status/${id}`,
  shipmentNonDeliveredDevices: '/provisioning/devices',
  shipmentDeliveredDevices: '/provisioning/received/devices',
  shipmentAwsDevices: '/provisioning/aws/devices',
  deviceProfiles: '/provisioning/aws/devices/profiles',
  deleteDeviceFromAws: (aws_id: string) => `/provisioning/aws/devices/${aws_id}`,
};

export const fetchProvisioningShipments = (params: Partial<ShipmentParams>): Promise<ResponseShipments> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      device_id: params.device_id,
      box_id: params.box_id,
      shipment_id: params.shipment_id,
      status: params.shipment_status,
      shipper_id: params.shipper_id,
      recipient_id: params.recipient_id,
      owner_id: params.owner_id
    }),
  };

  return API
    .get(config.name, urls.shipments, body)
    .catch(errorHandler);
};

export const getShipmentById = (id: string): Promise<ResponseShipment> => {
  return API
    .get(config.name, urls.shipmentById(id), {})
    .catch(errorHandler);
};

export const removeShipmentById = (id: string): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.shipmentById(id), {})
    .catch(errorHandler);
};

export const updateShipmentById = (id: string, data: ShipmentUpdateBody): Promise<ResponseShipment> => {
  return API
    .put(config.name, urls.shipmentById(id), { body: data })
    .catch(errorHandler);
};

export const createShipment = (shipment: ShipmentCreateBody): Promise<ResponseShipment> => {
  return API
    .post(config.name, urls.shipments, { body: shipment })
    .catch(errorHandler);
};

export const updateShipmentStatus = (id: string, status: ShipmentStatusEnum): Promise<ResponseShipment> => {
  return API
    .put(config.name, urls.updateShipmentStatusById(id), { body: { status } })
    .catch(errorHandler);
};

export type GetShipmentDevicesParams = {
  deviceId?: string[]
  shipmentId?: string
  limit?: number
  offset?: number
}

export const getNonDeliveredShipmentDevices = ({
  device_id,
  shipment_id,
  limit,
  offset
}: Partial<ShipmentParams>): Promise<ApiResponse<Device[]>> => {
  const query = {
    queryStringParameters: formatQuery({
      limit: limit,
      offset: offset,
      shipment_id,
      device_id,
    }),
  };

  return API
    .get(config.name, urls.shipmentNonDeliveredDevices, query)
    .catch(errorHandler);
};

export const getDeliveredShipmentDevices = ({
  shipmentId,
  deviceId,
  limit,
  offset
}: GetShipmentDevicesParams): Promise<ApiResponse<Device[]>> => {
  const query = {
    queryStringParameters: formatQuery({
      limit: limit,
      offset: offset,
      shipment_id: shipmentId,
      device_id: deviceId,
    }),
  };

  return API
    .get(config.name, urls.shipmentDeliveredDevices, query)
    .catch(errorHandler);
};

export const getAwsShipmentDevices = ({
  shipmentId,
  limit,
  offset
}: GetShipmentDevicesParams): Promise<ApiResponse<AwsDevice[]>> => {
  const query = {
    queryStringParameters: formatQuery({
      limit: limit,
      offset: offset,
      shipment_id: shipmentId,
    }),
  };

  return API
    .get(config.name, urls.shipmentAwsDevices, query)
    .catch(errorHandler);
};

export const getDeviceProfiles = (): Promise<ApiResponse<AwsDeviceProfile[]>> => {
  return API
    .get(config.name, urls.deviceProfiles, {})
    .catch(errorHandler);
};

export const deleteDeviceFromAws = (awsId: string) => {
  return API
    .del(config.name, urls.deleteDeviceFromAws(awsId), {})
    .catch(errorHandler);
};

export const provideDeviceToAws = (provisioningDevice: AwsProvisioningDevice) => {
  const body = {
    body: provisioningDevice
  };
  return API
    .post(config.name, urls.shipmentAwsDevices, body)
    .catch(errorHandler);
};
