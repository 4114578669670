import React from 'react';

import { DmTreeNode, ZoneNode } from 'hooks/device-management/useDmTree/types';
import { ProjectsState, useProjectsDictionarySelector } from 'hooks/user-managment';

// components
import { Box } from '@mui/material';
import { InlineLoader } from 'components/Loaders';
import { PositionLink, ProjectLink, ZoneLink } from 'components/Links';
import { LevelAndGroupLink } from 'components/Links/LevelAndGroupLink';

interface Props {
  node: DmTreeNode;
}

export const NameCell = ({ node }: Props): JSX.Element => {
  return (
    <Box display="inline-flex">
      { renderNode(node) }
    </Box>
  );
};

function renderNode(node: DmTreeNode) {
  switch (node.type) {
    case 'zone':
      return <ZoneNameCell node={ node }/>;
    case 'level': {
      if (node.isNoLevelNode) {
        return 'No level, no floor';
      }

      return `${ node.level?.name }, Floor ${ node.level?.floor_number }`;
    }

    case 'group': {
      return <LevelAndGroupLink groupId={ node.group.id } groupName={ node.group.name }/>;
    }

    case 'position': {
      return (
        <PositionLink
          positionId={ node.position.id }
          positionName={ node.position.network_id ?? `Position ID ${ node.position.id }` }
        />
      );
    }
  }
}

function getProjectName(projectId: number | undefined, projectsState: ProjectsState) {
  if (!projectId) {
    return 'Default project';
  }

  const project = projectsState.projects.find(p => p.id === projectId);

  if (project) {
    return <ProjectLink projectId={ project.id } name={ project.name }/>;
  }

  if (projectsState.isLoading) {
    return <InlineLoader/>;
  }

  return 'Project not found';
}

const ZoneNameCell = ({ node }: { node: ZoneNode }): JSX.Element => {
  const projectsState = useProjectsDictionarySelector();
  return (
    <>
      { getProjectName(node.zone.project_id, projectsState) }
      { ' / ' }
      <ZoneLink zoneId={ node.zone.id } defaultName={ node.zone.name }/>
    </>
  );
};