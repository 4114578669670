import React from 'react';
import { getRabbitEndpointDetailPath } from 'routing/paths';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  endpointId?: number;
  name?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

export const RabbitEndpointLink = ({ endpointId, name, innerProps }: Props): JSX.Element => {
  if (!endpointId) {
    const TypoProps = { ...defaultProps, ...innerProps };
    return <Typography { ...TypoProps }>{ name ?? '-' }</Typography>;
  }

  const label = name ?? `#${ endpointId }`;
  const path = getRabbitEndpointDetailPath(endpointId);

  return (
    <Link
      component={ RouterLink }
      color="secondary"
      to={ path }
    >
      { label }
    </Link>
  );
};


