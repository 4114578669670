import React from 'react';
import { useDialog } from 'hooks';
import { PositionNode } from 'hooks/device-management/useTree/types';
import { Position } from 'models/device-management';
// components
import { SwapButton } from 'components/Buttons';
import { CommonDialog } from 'components/Dialogs';
import { Button } from '@mui/material';

interface Props {
  node: PositionNode;
}

export const ReplaceDeviceAction = ({ node }: Props): JSX.Element => {
  const dialog = useDialog();
  return (
    <>
      <SwapButton label="Replace device" onClick={ dialog.open }/>
      { dialog.isMounted && (
        <ReplaceDeviceDialog
          position={ node.position }
          isOpen={ dialog.isOpen }
          onClose={ dialog.close }
          onCloseEnd={ dialog.unmount }
        />
      ) }
    </>
  );
};

interface DialogProps {
  isOpen: boolean;
  position: Position;
  onClose: () => void;
  onCloseEnd?: () => void;
}

const ReplaceDeviceDialog = (props: DialogProps): JSX.Element => {
  const { isOpen, onClose, onCloseEnd } = props;

  return (
    <CommonDialog
      isOpen={ isOpen }
      title="Bind device"
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      content={ <></> }
      actions={
        <>
          <Button onClick={ onClose }>Cancel</Button>
          <Button
            disabled
            color="primary"
            variant="contained"
            onClick={ () => true }
          >Bind</Button>
        </>
      }
    />
  );
};