import React from 'react';
import { Zone } from 'models/device-management';
import { Project } from 'models/user-management';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout } from 'components/Table';
import { ZoneLabel } from 'components/Labels';

// styles
import { ThemeProvider } from 'styles/utils';
import { getTableTheme } from 'styles/themes';


interface Props {
  zones: Zone[];
  isFetching: boolean;
  project: Project;
}
export const Zones = ({ zones, isFetching, project }: Props): JSX.Element => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'zoneId',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (zoneId?: number) => zoneId && <ZoneLabel zoneId={ zoneId } />,
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    responsive: 'simple',
  };

  return <>
    <ThemeProvider theme={ getTableTheme() }>
      <TableLoadingLayout isLoading={ isFetching }>
        <div data-testid="project-zones">
          <MUIDataTable
            title={ 'Zones' }
            data={ zones.filter(zone => zone.project_id === project.id).map(zone => ({
              zoneId: zone.id,
            })) }
            columns={ columns }
            options={ options }
          />
        </div>
      </TableLoadingLayout>
    </ThemeProvider>
  </>;
};
