// FIXME: remove `any` (BNIV-195)
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { staticEnv } from 'env';

import { AnyChangeHandler, InputProps, ErrorMessage } from 'components/Form/Field/utils';

// components
import { Controller, ControllerProps } from 'react-hook-form';

/**
 * Transforms react-hook-form's field errors to an array of error messages.
 */
function getErrors(fieldErrors?: FieldError): ErrorMessage[] {
  if (!fieldErrors) {
    return [];
  }

  // true if `validateCriteriaMode: 'all'` is passed to `useForm`
  if (fieldErrors.types) {
    // keys are error types, and values are error messages
    return Object.values(fieldErrors.types);
  }

  return [fieldErrors.message];
}

/**
 * Connects `Input` to the form and keeps `Input` in sync with it.
 *
 * It returns a wrapper, `Field`, which passes some props to `Input` automatically:
 * - it provides current value via `props.value`
 * - it tracks changes of value via `props.onChange`
 * - it provides error messages via `props.errors`
 * - TODO: it provides a flag to indicate whether field is required `props.required`
 * (based on form validators)
 *
 * TODO: mark the function as pure to leverage dead code elimination
 */
export const createField = <
  /**
   * Props of underlying input component. They are inferred from `Input` by default,
   * but sometimes that isn't enough, and we need to pass them manually.
   *
   * Useful when the underlying input component doesn't support props that
   * `Field` injects by default e.g. it uses `selected` and `changeHandler`
   * instead of `value` and `onChange`, so they need to be converted in a wrapper component.
   *
   * In order for a wrapper to inherit the typedef of the underlying input props, we set
   * `TInputProps` manually. See `components/Form/Field/fields/*` for examples.
   */
  TInputProps extends Record<string, unknown>,
  /**
   * Typedef of the underlying input's change handler to be used to provide typings for
   * the `Field.onChange` callback i.e. typedefs for its parameters.
   *
   * It cannot be inferred from `Input` since not all input components
   * support `onChange` (some use `changeHandler`).
   */
  TInputChangeHandler extends AnyChangeHandler | undefined = AnyChangeHandler
>(
  /* eslint-disable indent */
  Input: React.ComponentType<TInputProps & InputProps>,
) => {
  /* eslint-enable indent */

  /**
   * `Field` injects these props to `Input` automatically based on the context.
   * As a result, `Field` shouldn't require these props to be passed manually.
   * Otherwise, TS errors are thrown (i.e. props are required, but not supplied).
   */
  type InjectedProps = keyof InputProps;

  /**
   * These props are processed by `Controller`, so they probably won't reach `Input`
   * (it depends on implementation of `Controller`, which doesn't seem a great idea to rely on)
   * Customization of `Controller` is disallowed as well to prevent any cohesion with it.
   *
   * Similar to `Field`, `Controller` infers the wrapped component's props.
   * It does this via `ControllerProps`'s `As` type parameter. But since we need to filter
   * only `Controller`'s **own** props here, we pass `React.ComponentType<{}>` to it
   * (i.e. component without props).
   */
  type ControllerPropsOnly = keyof ControllerProps<React.ComponentType>;

  /**
   * This is a subset of `Input`'s original props, which resulting `Field` forwards to `Input`.
   * It is only a subset, because `Field` injects some props automatically (`InjectedProps`)
   * and simply cannot forward some others (`ControllerPropsOnly`; that's a temporary limitation).
   */
  type ForwardedInputProps = Omit<TInputProps, InjectedProps | ControllerPropsOnly>;

  type ControllerChangeHandlerArgs = TInputChangeHandler extends () => unknown
    ? Parameters<TInputChangeHandler>
    : any[];

  type FieldProps = {
    /** Name of the field. A string path, corresponding to a value in the form values. */
    name: string;
    /**
     * A callback that will be called whenever an onChange event is fired or
     * `props.onChange` is called from the underlying input, with the same arguments.
     */
    onChange?: TInputChangeHandler;
    mapChangedValue?: (...args: ControllerChangeHandlerArgs) => unknown;
  } & ForwardedInputProps;

  const Field = ({ name, onChange, mapChangedValue = (...args) => args[0], ...props }: FieldProps) => {
    const form = useFormContext();

    type ControllerChangeHandlerArgs = TInputChangeHandler extends () => unknown
      ? Parameters<TInputChangeHandler>
      : any[];

    /**
     * Controller calls its change handler with an array of original arguments
     * __in the first argument__.
     *
     * This behaviour will be changed in `react-hook-form@6`:
     * https://github.com/react-hook-form/react-hook-form/pull/1471
     *
     * In order to migrate to `react-hook-form@6`, we'll need to change `(args)` to `(...args)`.
     */
    const handleChange = (args: ControllerChangeHandlerArgs) => {

      // calling `Field.onChange` with the same arguments which the underlying input provides
      onChange?.(...args);

      // Controller expects that onChange returns the final value i.e. it acts as a value mapper
      // This seems counter-intuitive, so we provide a separate `props.mapChangedValue` for this
      return mapChangedValue(...args);
    };

    return (
      <Controller
        // these props are forwarded to `Input`:
        {...props as any}
        errors={getErrors(form.errors[name])}

        // these props are processed by `Controller`:
        as={Input}
        control={form.control}
        name={name}
        onChange={handleChange}
        onChangeName="onChange"
        // for some reason, `Controller` injected `checked` instead of `value`
        // to Material UI's Switch as a default value even if `valueName`
        // wasn't explicitly configured to do so
        valueName="value"
      />
    );
  };

  if (!staticEnv.IS_PRODUCTION) {
    Field.displayName = `Field.${Input.displayName || Input.name || 'Anonymous'}`;
  }

  return Field;
};