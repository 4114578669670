import React from 'react';

// components
import { Box, Grid, Paper } from '@mui/material';
import { FieldSkeleton } from 'components/Skeleton';
// styles
import useStyles from '../style';

export const PositionSkeleton = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 6 }>
        <Paper className={ classes.managementRoot }>
          <FieldSkeleton height="100%" width="100%" variant="rectangular"/>
        </Paper>
      </Grid>
      <Grid item xs={ 6 }>
        <Paper className={ classes.mapRoot }>
          <FieldSkeleton height="100%" width="100%" variant="rectangular"/>
        </Paper>
      </Grid>
      <Grid item xs={ 12 }>
        <Paper>
          <Box height="10vh" minHeight="100px">
            <FieldSkeleton height="100%" width="100%" variant="rectangular"/>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};