import React from 'react';
import { useAuthUserSelector, useFormActionLoader } from 'hooks';
import { useForm } from 'hooks/form';
import { isAdmin } from 'utils/permissions';
import { Device, DeviceUpdateParams, PositionedDeviceData } from 'models/device-management';
import { updateDevice } from 'actions/device-management/devices';

// components
import { Button } from '@mui/material';
import { CommonDialog } from 'components/Dialogs';
import { DeviceForm, DeviceFormField, DeviceFormState } from 'components/DeviceManagement/Forms';

interface Props {
  title?: string;
  device: PositionedDeviceData;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (device: Device) => void;
}

export const EditDeviceDialog = ({ title, device, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const user = useAuthUserSelector();
  const form = useForm<DeviceFormState, DeviceFormField>({
    initialState: {
      [DeviceFormField.owner]: device.owner_id,
      [DeviceFormField.active]: device.activation_status,
      [DeviceFormField.damaged]: device.damaged_status,
    },
    showFields: [
      ...(isAdmin(user) ? [DeviceFormField.owner] : []),
      DeviceFormField.active,
      DeviceFormField.damaged,
    ],
  });

  const handleSubmit = (): void => {
    if (!form.validate()) {
      return;
    }

    const deviceUpdate: DeviceUpdateParams = {
      id: device.device_id,
      props: {
        owner_id: Number(isAdmin(user) ? form.state[DeviceFormField.owner] ?? device.owner_id : device.owner_id),
        activation_status: form.state[DeviceFormField.active] ?? device.activation_status,
        damaged_status: form.state[DeviceFormField.damaged] ?? device.damaged_status,
        hardware_type: device.hardware_type ?? null,
      }
    };

    doAction({
      action: updateDevice(deviceUpdate),
      onError: form.catchError,
      onSuccess: (device: Device) => {
        onSuccess?.(device);
        onClose();
      }
    });
  };

  return (
    <CommonDialog
      testId="dm-device-edit-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ title ?? `Edit device > ${ device.device_id.toUpperCase() }` }
      content={ <DeviceForm { ...form } /> }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ () => handleSubmit() }>Save</Button>
        </>
      }
    />
  );
};
