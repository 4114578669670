import {
  Action,
  FETCH_LEVELS,
  FETCH_LEVELS_FAILURE,
  FETCH_LEVELS_SUCCESS,
  CHANGE_LEVEL,
} from 'actions/device-management/levels';
import { LevelState } from 'models/device-management/states';
import { Repository } from 'utils/repository';

const initState: LevelState = Repository.create('id');

export const reducer = (state: LevelState = initState, action: Action): LevelState => {
  switch (action.type) {
    case FETCH_LEVELS: {
      return state.setFetched(action.params, false);
    }

    case FETCH_LEVELS_FAILURE: {
      return state.setFetched(action.params, true);
    }

    case FETCH_LEVELS_SUCCESS: {
      return state
        .setFetched(action.params, true)
        .setData(action.params, action.levels);
    }

    case CHANGE_LEVEL: {
      return state.changeModel(
        action.level.id,
        action.options.delete ? undefined : action.level,
      );
    }

    default: {
      return state;
    }
  }
};
