import { Permissions, User, UserGroupServiceName } from 'models/user-management';
import { ApiGConfig } from 'models';
import { staticEnv } from 'env';

export const urls = {
  userBySub: (userSub: User['sub']) => `/users/${ userSub }`,
  users: '/users',
  userCreate: '/users/invite/?service=console',
  userGroups: '/groups',
  userGroupById: (id: number) => `/groups/${ id }`,
  servicesByGroupId: (id: number) => `/groups/${ id }/service`,
  deactivateUserGroupService: (id: number, serviceName: UserGroupServiceName) => `/groups/${id}/service/${serviceName}/deactivate`,
  activateUserGroupService: (id: number, serviceName: UserGroupServiceName) => `/groups/${id}/service/${serviceName}/activate`,
  userGroupAccessCodeRefresh: (id: number) => `/groups/${ id }/refresh_client_access_code`,
  permissions: '/permissions',
  permissionsById: (id: Permissions['id']) => `/permissions/${ id }`,
  projects: '/projects',
  projectById: (id: number) => `/projects/${ id }`,
  apiKeys: '/api_keys',
  apiKeyById: (id: string) => `/api_keys/${ id }`,
  changePassword: '/users/change_password',
  changePasswordForAnotherUser: (sub: User['sub']) => `/users/${sub}/admin_change_password`,
  reinvite: (id: string) => `/users/${id}/reinvite?service=console`,
};

export const config: ApiGConfig = {
  name: 'um',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://um.prod.api.nwave.io' : 'https://um.dev.api.nwave.io',
};
