import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';

// components
import Typography, { TypographyProps } from '@mui/material/Typography';

interface Props {
  endpointId: number;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

export const RabbitEndpointName = ({ endpointId, innerProps }: Props) => {
  const endpoint = useSelector((state: RootState) => state.rabbit.endpoint.findById(endpointId), shallowEqual);
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    <Typography { ...TypoProps }>
      { endpoint ? `${ endpoint.name }` : `#${ endpointId }` }
    </Typography>
  );
};