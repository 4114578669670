import { sortBy } from 'lodash';
import { put, takeEvery, takeLeading, call, all } from 'redux-saga/effects';
import { ReasonEnum, ApiResponse } from 'models';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { downloadFileFromRes, createCSVFromObject } from 'helpers';

import * as actions from 'actions/provisioning';
import * as models from 'models/provisioning';
import * as backend from 'clients/provisioning';

import { ResponseDevicesWithTotal } from 'models/device-management';
import { findDevices } from 'clients/device-management';
import { ActionWithPromise } from 'utils/store';

function* fetchShipmentByParams(action: actions.FetchShipmentByParams) {
  yield call(fetchShipmentCall, action.params);
}

// @TODO DEPRECATED MOVE TO useProvisioningShipments
function* fetchShipmentCall(params: models.ShipmentParams) {
  const response: models.ResponseShipments = yield call(backend.fetchProvisioningShipments, params);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoFetchShipmentSuccess(response.data, response.total || 0));
  } else {
    yield put(actions.DoFetchShipmentFailure());
    yield put(NotifyError(`Error while fetching shipment provisioning: ${ response.message }`));
  }
}

// @TODO DEPRECATED MOVE TO useShipmentById
function* fetchShipmentById(action: actions.FetchShipmentById) {
  const response: models.ResponseShipment = yield call(backend.getShipmentById, action.id);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoFetchShipmentByIdSuccess(response.data));
  } else {
    yield put(actions.DoFetchShipmentByIdFailure());
    yield put(NotifyError(`Error while fetching shipment provisioning: ${ response.message }`));
  }
}

type DeleteAction =
  | actions.DeleteShipment
  | ActionWithPromise<actions.DeleteShipment, models.Shipment>

function* deleteShipmentById(action: DeleteAction) {
  const response: ApiResponse = yield call(backend.removeShipmentById, action.shipmentId);

  if (response.reason === ReasonEnum.Ok) {
    yield put(actions.DoDeleteShipmentSuccess());
    yield put(NotifySuccess(`Shipment has been deleted`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(actions.DoDeleteShipmentFailure());
    yield put(NotifyError(`Error while deleting a shipment: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type UpdateAction =
  | actions.UpdateShipment
  | ActionWithPromise<actions.UpdateShipment, models.Shipment>

function* updateShipmentById(action: UpdateAction) {
  const response: models.ResponseShipment = yield call(backend.updateShipmentById, action.id, action.data);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoUpdateShipmentSuccess(response.data));
    yield put(NotifySuccess(`Shipment has been updated`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(actions.DoUpdateShipmentFailure());
    yield put(NotifyError(`Error while updating a shipment: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type CreateAction =
  | actions.CreateShipment
  | ActionWithPromise<actions.CreateShipment, models.Shipment>

function* createShipmentSaga(action: CreateAction) {
  const response: models.ResponseShipment = yield call(backend.createShipment, action.shipment);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoCreateShipmentSuccess(response.data));
    yield put(NotifySuccess(`Shipment has been created`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(actions.DoCreateShipmentFailure());
    yield put(NotifyError(`Error while creating a shipment: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type UpdateStatusAction =
  | actions.UpdateShipmentStatus
  | ActionWithPromise<actions.UpdateShipmentStatus, models.Shipment>

function* updateShipmentStatusById(action: UpdateStatusAction) {
  const response: models.ResponseShipment = yield call(backend.updateShipmentStatus, action.id, action.status);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoUpdateShipmentStatusSuccess(response.data));
    yield put(NotifySuccess(`Shipment status has been updated`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(actions.DoUpdateShipmentStatusFailure());
    yield put(NotifyError(`Error while updating shipment status: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

interface BoxDevice {
  box_id: number;
  device_id: string;
}

function* fetchShipmentReportCSV(action: actions.FetchShipmentCSVReport) {
  const { shipment } = action;
  try {
    let devices: BoxDevice[] = [];
    if (models.ShipmentStatusEnum.Received === shipment.status) {
      const receivedDevices: models.ResponseReceivedDevices = yield call(backend.fetchProvisioningReceivedDevices, {
        shipments: [shipment.id],
        limit: 10000
      });

      if (receivedDevices.reason !== ReasonEnum.Ok || !receivedDevices.data) {
        throw new Error();
      }

      devices = receivedDevices.data.map(b => ({
        box_id: b.box_id ?? 0,
        device_id: b.device_id ?? ''
      }));
    } else {
      const provisionDevices: models.ResponseDevices = yield call(backend.fetchProvisioningDevices, {
        shipment_id: [shipment.id],
        limit: 10000
      });
      if (provisionDevices.reason !== ReasonEnum.Ok || !provisionDevices.data) {
        throw new Error();
      }

      devices = provisionDevices.data.map(b => ({
        box_id: b.box_id ?? 0,
        device_id: b.device_id
      }));
    }

    const devicesIds = devices.map(b => b.device_id);

    // get by 500 devices cause get query may be too long
    const devicesIdsChunks = [];
    const CHUNK_SIZE = 500;
    for (let i = 0; i < devicesIds.length; i += CHUNK_SIZE) {
      devicesIdsChunks.push(devicesIds.slice(i, i + CHUNK_SIZE));
    }
    const DevicesResponses: ResponseDevicesWithTotal[] = yield all(
      devicesIdsChunks.map(devicesIdsChunk =>
        call(findDevices, {
          deviceIds: devicesIdsChunk,
          includeKeys: true
        }))
    );

    const normalizeDevicesResponse = [];
    for (const deviceResponse of DevicesResponses) {
      if (deviceResponse.reason !== ReasonEnum.Ok || !deviceResponse.data) {
        throw new Error();
      }
      normalizeDevicesResponse.push(...deviceResponse.data);
    }

    // https://nwaveio.atlassian.net/browse/BNIV-2003
    // AppKey is last of 16 secret_keys of device
    const deviceAppKeyMap = normalizeDevicesResponse
      .reduce((map, d) => map.set(d.device_id, d?.encryption_keys?.key16),
        new Map<string, string | undefined>());

    const DEV_EUI_PREFIX = '00E8BF3B00';
    const SHIPMENT_CVS_REPORT_KEYS = 'Box ID,Serial ID,DevEUI,AppKey';
    const SHIPMENT_DEVICE_KEYS = ['box_id', 'serial_id', 'devEUI', 'appKey'];
    const CSVResult = [SHIPMENT_CVS_REPORT_KEYS];
    sortBy(devices, pd => pd.box_id).forEach(pd => {
      const device = {
        box_id: pd.box_id,
        serial_id: pd.device_id,
        devEUI: DEV_EUI_PREFIX + pd.device_id,
        appKey: deviceAppKeyMap.get(pd.device_id.toUpperCase())
      };
      CSVResult.push(createCSVFromObject(device, SHIPMENT_DEVICE_KEYS));
    });
    downloadFileFromRes(CSVResult.join('\n'), `${ shipment.name } devices.csv`);
    yield put(actions.DoFetchShipmentCSVReportSuccess());
  } catch {
    yield put(actions.DoFetchShipmentCSVReportFailure());
    yield put(NotifyError(`Error while downloading CSV`));
  }
}

export const provisioningShipmentSagas = [
  takeEvery(actions.FETCH_SHIPMENT_BY_PARAMS, fetchShipmentByParams),
  takeEvery(actions.FETCH_SHIPMENT_BY_ID, fetchShipmentById),
  takeEvery(actions.SHIPMENT_UPDATE, updateShipmentById),
  takeEvery(actions.SHIPMENT_DELETE, deleteShipmentById),
  takeEvery(actions.SHIPMENT_CREATE, createShipmentSaga),
  takeEvery(actions.SHIPMENT_UPDATE_STATUS, updateShipmentStatusById),
  takeLeading(actions.FETCH_SHIPMENT_CSV_REPORT, fetchShipmentReportCSV)
];
