import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { v4 as uuid } from 'uuid';
import { FormProps } from 'form';
import { useDefaultProject } from 'hooks/user-managment';
import { LEVEL_NO_LEVEL_OPTION_VALUE } from 'models/device-management/dm';
import { FormField, FormState } from './types';
import { validate } from './utils';

// components
import { Grid, Box } from '@mui/material';
import {
  GroupSelectControl,
  LevelSelect,
  OwnersSingleSelectControl,
  ProjectSelectControl,
  TextControl,
  ZoneSelectControl
} from 'components/Controls';
import { RefreshButton } from 'components/Buttons';


type Props = FormProps<FormState, FormField> & {
  disabled?: boolean;
  showOwner?: boolean;
}

export const PositionManagementForm = (props: Props): JSX.Element => {
  const { state, showFields, showOwner, disabled, onChange, onValidate } = props;
  const { getDefaultProject } = useDefaultProject();
  const showErrors = props.showErrors ?? showFields;
  const errors = props.errors ?? {};

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={ 1 }>
        <Grid item xs={ 8 }>
          <ProjectSelectControl
            isDisabled={ disabled }
            label="Project"
            selected={ state[FormField.projectId] ?? getDefaultProject(state[FormField.ownerId])?.id }
            filter={ project => project.owner_id === state[FormField.ownerId] }
            onChange={ (projectId?: number) => handleChange(FormField.projectId, projectId) }
            error={ showErrors.includes(FormField.projectId) ? errors[FormField.projectId] : undefined }
          />
        </Grid>
        { showOwner && (
          <Grid item xs={ 4 }>
            <OwnersSingleSelectControl
              isDisabled={ disabled }
              isClearable={ false }
              label="Owner"
              selected={ state[FormField.ownerId] }
              changeHandler={ (ownerId?: number) => handleChange(FormField.ownerId, ownerId) }
              error={ showErrors.includes(FormField.ownerId) ? errors[FormField.ownerId] : undefined }
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={ 1 }>
        <Grid item xs={ 8 }>
          <ZoneSelectControl
            isDisabled={ disabled }
            label="Zone Name"
            selected={ state[FormField.zoneId] }
            filter={ zone => zone.project_id === state[FormField.projectId] }
            onChange={ (zoneId?: number) => handleChange(FormField.zoneId, zoneId) }
            error={ showErrors.includes(FormField.zoneId) ? errors[FormField.zoneId] : undefined }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <LevelSelect
            isDisabled={ disabled }
            withNoLevel
            label="Level Name"
            zoneId={ state[FormField.zoneId] }
            selected={ state[FormField.levelId] ?? LEVEL_NO_LEVEL_OPTION_VALUE }
            onChange={ (levelId?: number) => {
              const value = levelId === LEVEL_NO_LEVEL_OPTION_VALUE ? undefined : levelId;
              handleChange(FormField.levelId, value);
            } }
            error={ showErrors.includes(FormField.levelId) ? errors[FormField.levelId] : undefined }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 1 }>
        <Grid item xs={ 8 }>
          <GroupSelectControl
            isDisabled={ disabled }
            label="Group Name"
            zoneIds={ [state[FormField.zoneId] ?? 0 ] }
            filter={ group => group.level_id === (state[FormField.levelId] ?? null)}
            selected={ state[FormField.groupId] }
            onChange={ (groupId?: number) => handleChange(FormField.groupId, groupId) }
            error={ showErrors.includes(FormField.groupId) ? errors[FormField.groupId] : undefined }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <TextControl
            disabled={ disabled }
            label="Inner ID"
            type="number"
            value={ state[FormField.innerId] }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.innerId, Number(event.target.value)) }
            error={ showErrors.includes(FormField.innerId) ? errors[FormField.innerId] !== undefined : false }
            helperText={ showErrors.includes(FormField.innerId) ? errors[FormField.innerId] : undefined }
          />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="baseline">
        <TextControl
          disabled={ disabled }
          label="Custom ID"
          value={ state[FormField.customId] }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.customId, event.target.value) }
          error={ showErrors.includes(FormField.customId) ? errors[FormField.customId] !== undefined : false }
          helperText={ showErrors.includes(FormField.customId) ? errors[FormField.customId] : undefined }
        />
        { !disabled && (
          <RefreshButton
            type="icon"
            label="Generate"
            onClick={ () => handleChange(FormField.customId, uuid()) }
          />
        ) }
      </Box>
      <Grid container spacing={ 1 }>
        <Grid item xs={ 6 }>
          <TextControl
            disabled={ disabled }
            label="Latitude"
            type="number"
            value={ state[FormField.latitude] }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.latitude, event.target.value) }
            error={ showErrors.includes(FormField.latitude) ? errors[FormField.latitude] !== undefined : false }
            helperText={ showErrors.includes(FormField.latitude) ? errors[FormField.latitude] : undefined }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <TextControl
            disabled={ disabled }
            label="Longitude"
            type="number"
            value={ state[FormField.longitude] }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.longitude, event.target.value) }
            error={ showErrors.includes(FormField.longitude) ? errors[FormField.longitude] !== undefined : false }
            helperText={ showErrors.includes(FormField.longitude) ? errors[FormField.longitude] : undefined }
          />
        </Grid>
      </Grid>
    </form>
  );
};