import { GroupType, Group, Zone, Level, LabelCount } from 'models/device-management';
import { Project } from 'models/user-management';
import { formatPositionGroupType, isNil, Defined } from 'utils/models';
import { nameof } from 'utils/type-checking';
import {
  dmDevicesPath,
  dmPositionsPath,
  urlToFailuresInTheField,
  urlToSilentsInTheField,
} from 'routing/paths';
import { Link as RouterLink } from 'react-router-dom';
import { commonOptions, defaultSort } from 'utils/tables';

// components
import { Link } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { OwnerName, PositionGroupLabel, ZoneLabel } from 'components/Labels';
import { DmLabels } from 'components/DmLabels';
import { SPlaceLink } from 'components/Links';

// stylesPositionGroups
import { ThemeProvider } from 'styles/utils';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

interface Props {
  zones?: Zone[];
  findLevelById: (levelId: number) => Level | undefined;
  levelsLoading: boolean;
  projects?: Project[];
  positionGroups: Group[];
  connectivityByGroupId: {
    dictionary: Record<Defined<Group['id']>, number | undefined>;
    loading: boolean;
  };
  containsAllFields?: boolean;
  isLoading?: boolean;
  isAdmin?: boolean;
  limit?: number;
  page?: number;
}

interface RowData {
  name: string;
  zone?: number;
  project?: string;
  owner?: number;
  type: GroupType;
  custom_id?: string;
  positionCount: number[];
  boundDeviceCount: number[];
  damagedDeviceCount: number[];
  silentDeviceCount: number[];
  connectivity?: number | null;
  bind: number[];
  labels: LabelCount[];
}

const GroupsTable = ({
  positionGroups,
  connectivityByGroupId,
  containsAllFields,
  zones,
  projects,
  findLevelById,
  levelsLoading,
  isAdmin,
  isLoading,
  limit,
  page,
}: Props): JSX.Element => {
  const getRowData = (group: Group): RowData => {
    const groupId = group.id as number;

    const getConnectivity = (): RowData['connectivity'] => {
      if (!isNil(connectivityByGroupId?.dictionary[groupId])) {
        return connectivityByGroupId?.dictionary[groupId];
      }

      if (connectivityByGroupId.loading) {
        return undefined;
      }

      return null;
    };
    const zoneId = group.zone_id;
    const zone = zones ? zones.find(zone => zone.id === zoneId) : undefined;
    const project = (projects?.length && zone) ? projects.find(project => project.id === zone.project_id) : undefined;
    const rowData: RowData = {
      ...group,
      zone: zone?.id,
      project: project?.name || '-',
      owner: zone?.owner_id,
      positionCount: [zoneId, groupId],
      boundDeviceCount: [zoneId, groupId],
      damagedDeviceCount: [zoneId, groupId],
      silentDeviceCount: [zoneId, groupId],
      connectivity: getConnectivity(),
      bind: [zoneId, groupId, group.level_id ?? 0],
      labels: group.labels ?? [],
    };
    return rowData;
  };
  const columns: MUIDataTableColumn[] = [
    {
      name: nameof<RowData>('name'),
      label: 'Level / Group Name',
      options: {
        customBodyRenderLite: dataIndex => {
          const group = positionGroups[dataIndex];
          const groupId = group.id as number;
          const levelId = group.level_id;

          if (!levelId) {
            return <PositionGroupLabel groupId={ groupId } />;
          }

          const levelName = findLevelById(levelId)?.name;

          return (
            <>
              { levelName || (levelsLoading ? 'Level loading...' : 'n/a') }&nbsp;/&nbsp;
              <PositionGroupLabel groupId={ groupId } />
            </>
          );
        },
      },
    },
    {
      name: nameof<RowData>('zone'),
      label: 'Zone Name',
      options: {
        display: containsAllFields ? 'true' : 'excluded',
        customBodyRender: (id: number) => <ZoneLabel zoneId={ id } />
      }
    },
    {
      name: nameof<RowData>('project'),
      label: 'Project',
      options: {
        display: containsAllFields ? 'true' : 'excluded'
      }
    },
    {
      name: nameof<RowData>('owner'),
      label: 'Owner',
      options: {
        display: (containsAllFields && isAdmin) ? 'true' : 'excluded',
        customBodyRender: (ownerId: number) => <OwnerName ownerId={ ownerId } />
      }
    },
    {
      name: nameof<RowData>('type'),
      label: 'Group Type',
      options: {
        customBodyRender: (type: RowData['type']) => formatPositionGroupType(type),
      },
    },
    {
      name: nameof<RowData>('custom_id'),
      label: 'Group Custom ID',
      options: {
        customBodyRender: (customId: RowData['custom_id']) => customId || '-',
      },
    },
    {
      name: nameof<RowData>('positionCount'),
      label: 'Positions',
      options: {
        sort: false,
        customBodyRender: ([zoneId, groupId]: RowData['positionCount']) => (
          <Link
            color="secondary"
            component={ RouterLink }
            to={ dmPositionsPath({ zones: [zoneId], groupIds: [groupId] }) }
          >
            View
          </Link>
        ),
      },
    },
    {
      name: nameof<RowData>('boundDeviceCount'),
      label: 'Bound Devices',
      options: {
        sort: false,
        customBodyRender: ([zoneId, groupId]: RowData['boundDeviceCount']) => (
          <Link
            color="secondary"
            component={ RouterLink }
            to={ dmDevicesPath({ zones: [zoneId], groups: [groupId] }) }
          >
            View
          </Link>
        ),
      },
    },
    {
      name: nameof<RowData>('damagedDeviceCount'),
      label: 'Damaged Devices',
      options: {
        sort: false,
        customBodyRender: ([zoneId, groupId]: RowData['damagedDeviceCount']) => (
          <Link
            color="secondary"
            component={ RouterLink }
            to={ urlToFailuresInTheField({ zones: [zoneId], groups: [groupId], projects: [] }) }
          >
            Check
          </Link>
        ),
      },
    },
    {
      name: nameof<RowData>('silentDeviceCount'),
      label: 'Silent Devices',
      options: {
        sort: false,
        customBodyRender: ([zoneId, groupId]: RowData['silentDeviceCount']) => (
          <Link
            color="secondary"
            component={ RouterLink }
            to={ urlToSilentsInTheField({ zones: [zoneId], groups: [groupId], projects: [] }) }
          >
            Check
          </Link>
        ),
      },
    },
    {
      name: nameof<RowData>('connectivity'),
      label: 'Connectivity',
      options: {
        customBodyRender: (connectivity: RowData['connectivity']) => {
          if (typeof connectivity === 'number') {
            return `${connectivity} %`;
          }

          if (typeof connectivity === 'undefined') {
            return '';
          }

          return '-';
        },
      }
    },
    {
      name: nameof<RowData>('labels'),
      label: 'Labels',
      options: {
        customBodyRender: (labels: RowData['labels']) => <DmLabels labels={ labels } />,
      },
    },
    {
      name: nameof<RowData>('bind'),
      label: ' ',
      options: {
        viewColumns: false,
        customBodyRender: (data: RowData['bind']) => <SPlaceLink zoneId={ data[0] } groupId={ data[1] } levelId={ data[2] } />
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    customSort: defaultSort,
    page,
    rowsPerPage: limit,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  return (
    <ThemeProvider theme={ truncatedCellsMuiTableTheme(positionGroups.length) }>
      <TableLoadingLayout isLoading={ isLoading } >
        <MUIDataTable
          title={ null }
          columns={ columns }
          options={ options }
          data={ positionGroups.map(group => getRowData(group)) }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

export default GroupsTable;
