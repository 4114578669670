import { State } from 'reducers/pages/station-monitoring';
import { getDurationDays } from './utils';
import { useStatusChartData } from './hooks/useStatusChartData';

// components
import { Box } from '@mui/material';
import { BlockLoader } from 'components/Loaders';
import { Chart } from './components/Chart';

// styles
import { colors } from 'styles/themes/outer';

interface Props {
  isLorawan?: boolean;
  internet: State['internetHistory'];
}


export const InternetHistoryChart = ({ internet, isLorawan }: Props): JSX.Element => {
  const internetHistory = useStatusChartData({ statusHistory: internet, chartName: 'Internet' });

  return (
    <>
      <Box height={ 100 } mt={ isLorawan ? '0' : '-40px' } data-testid="chart">
        { !internet.fetched ? <BlockLoader size={ 20 } /> :
          <Chart
            data={ internetHistory.data }
            maxValue={ internetHistory.maxValue }
            title={ 'Internet' }
            color={ colors.lightBlue }
            valueFormat={ value => value ? 'Online' : 'Offline' }
            durationDays={ getDurationDays(internet.dateRange) }
            showAxisBottom={isLorawan}
          />
        }
      </Box>
    </>
  );
};
