import React from 'react';
import { styled } from 'styles/utils';
import { FlashStatus, StationFlash } from 'models/base-station';

// components
import { Box, Typography } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from '../../styles';

export const OutlinedBox = styled(Box)(({ theme }) => ({
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 0,
  width: '100%',
  height: '100%',
  color: theme.palette.primary.main,
}));

interface Props {
  stationFlash: StationFlash;
}

export const FlashLocation = ({ stationFlash }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <OutlinedBox display="flex" justifyContent="center" alignItems="center">
      { stationFlash.execution_status !== FlashStatus.CANCEL ? '' :
        <>
          <CircularProgress className={ classes.flashIcon } size={ 24 } color="primary"/>
          <Typography variant="h6">Cancelling creation...</Typography>
        </>
      }
      { stationFlash.execution_status !== FlashStatus.INSTALL && stationFlash.execution_status !== FlashStatus.PREPARE ? '' :
        <>
          <CircularProgress className={ classes.flashIcon } size={ 24 } color="primary"/>
          <Typography variant="h6">Creating software...</Typography>
        </>
      }
      { stationFlash.execution_status !== FlashStatus.SUCCESS ? '' :
        <>
          <CheckCircleOutline className={ classes.flashIcon } fontSize="large" htmlColor={ 'green' }/>
          <Typography variant="h6">Creation success</Typography>
        </>
      }
      { stationFlash.execution_status !== FlashStatus.FAILED ? '' :
        <>
          <ErrorOutline className={ classes.flashIcon } fontSize="large" htmlColor={ 'red' }/>
          <Typography variant="h6">Creation failed</Typography>
        </>
      }
    </OutlinedBox>
  );
};
