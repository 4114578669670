import React from 'react';

// components
import { Button } from 'components';
import Tooltip from '@mui/material/Tooltip';

// styles
import { ThemeProvider } from 'styles/utils';
import { successTheme } from 'styles/themes';

interface Props {
  disabled?: boolean;
  pending?: boolean;
  label?: string;
  titleForTooltip?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export const SuccessButton = ({ titleForTooltip, disabled, pending, label, onClick, ...props }: Props) => {
  return (
    <Tooltip title={titleForTooltip || ''}>
      <span>
        {/* disabled buttons don't fire events, so we need a wrapper like <span> */}
        <ThemeProvider theme={ successTheme }>
          <Button
            { ...props }
            color="primary"
            disabled={ disabled }
            pending={ pending }
            type="submit"
            onClick={ onClick }
          >
            { label || 'Save'}
          </Button>
        </ThemeProvider>
      </span>
    </Tooltip>
  );
};
