import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Address, Position } from '../../../types';

// components
import { Box, Input, InputAdornment } from '@mui/material';
// styles
import { useStyles } from '../styles';
import { Search } from '@mui/icons-material';
import { MapControl } from '../../../forms/LocationForm/widgets/MapControl';

interface Props {
  findUser?: Position;
  address?: Address;
  onApply: (address: Address) => void;
  found?: Address;
  setFound: (address?: Address) => void;
}

export const MapBlock = ({ found, setFound, findUser, address, onApply }: Props): JSX.Element => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const [findAddress, setFindAddress] = useState<string>('');
  // https://github.com/facebook/react/issues/19240
  const handleFindAddress = useMemo(() => debounce((value: string) => setFindAddress(value), 1000), []);
  const handleSearch = (value: string) => {
    setSearch(value);
    handleFindAddress(value);
  };

  return (
    <Box className={ classes.mapBlock }>
      <Input
        type="text"
        value={ search }
        onChange={ event => handleSearch(event.target.value || '') }
        className={ classes.mapSearch }
        endAdornment={
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        }
      />
      <MapControl
        className={ classes.mapRoot}
        findUser={ findUser }
        findAddress={ findAddress }
        found={ found }
        setFound={ setFound }
        address={ address }
        onChange={ onApply }
        onCancel={ () => setFindAddress('') }
      />
    </Box>
  );
};
