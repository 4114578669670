import React from 'react';

// components
import { Box } from '@mui/material';
import { LoaderSvg } from 'components/Loaders';

export const Loader = () => {
  return (
    <Box component="span" ml={ 0.5 } mr={ 0.5 }>
      <LoaderSvg />
    </Box>
  );
};
