import React from 'react';
import { Zone } from 'models/device-management';
import { formatDateTime } from 'utils/datetime';
import { Tree } from 'hooks/device-management/useTree/types';
import { calculateTotal, findZoneNode } from 'hooks/device-management/useTree/utils';

// components
import { Grid, Paper, Typography } from '@mui/material';
import { FieldSkeleton } from 'components/Skeleton';
import { ConnectivityCell, IssuesCell, LabelsCell } from 'components/DeviceManagement/TreeTable/cells';
// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  zone: Zone;
  tree: Tree;
}

export const ZoneStatistic = ({ zone, tree }: Props): JSX.Element => {
  const classes = useStyles();
  const zoneNode = findZoneNode(tree, zone.id);

  return (
    <Paper className={ classes.root } data-testid="dm-zone-statistic">
      <Grid container>
        <Grid item xs={ 1 }>
          <Typography>Zone ID</Typography>
          <Typography className={ classes.itemValue }>{ zone.id }</Typography>
        </Grid>
        <Grid item xs={ 2 }>
          <Typography>Creation time</Typography>
          <Typography className={ classes.itemValue }>
            { zone.creation_date ? formatDateTime(zone.creation_date) : '-' }
          </Typography>
        </Grid>
        <Grid item xs={ 1 }>
          <Typography>Devices</Typography>
          <Typography component="div" className={ classes.itemValue }>
            { tree.loaded.device && zoneNode ? calculateTotal(zoneNode, 'devices') : <FieldSkeleton height={ 24 }/> }
          </Typography>
        </Grid>
        <Grid item xs={ 1 }>
          <Typography>Positions</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.position && zoneNode
                ? calculateTotal(zoneNode, 'children')
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
        <Grid item xs={ 2 }>
          <Typography>Issues</Typography>
          <Typography component="div" className={ classes.itemValue }>
            { zoneNode ? <IssuesCell tree={ tree } node={ zoneNode } /> : <FieldSkeleton height={ 24 }/> }
          </Typography>
        </Grid>
        <Grid item xs={ 1 }>
          <Typography>Connectivity</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.deviceConnect && zoneNode
                ? <ConnectivityCell tree={ tree } node={ zoneNode } />
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
        <Grid item xs={ 4 }>
          <Typography>Labels</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.position && zoneNode
                ? <LabelsCell node={ zoneNode } />
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
