import clsx from 'clsx';
import React from 'react';
import { RouteConfigChildrenProps } from 'react-router-config';
import { getLocation } from 'utils/routing/query';

// components
import { ListItem, ListItemText, ListItemIcon, } from '@mui/material';
import { Link } from 'react-router-dom';

import { renderRouteLabel } from 'utils/routing';
import MenuIcon from './MenuIcon';

// styles
import useStyles from '../styles';

const MenuLink: React.FC<RouteConfigChildrenProps> = ({ match, route }) => {
  const classes = useStyles({});

  const { icon } = route;

  if (!route.path) {
    return null;
  }

  const location = getLocation(route.path, route.pathParams ?? {});
  return (
    <Link to={location} className={classes.noUnderline}>
      <ListItem
        button={true}
        className={clsx((match && match.isExact) ? classes.activeLink : '', classes.links)}
      >
        <ListItemIcon>
          <MenuIcon icon={icon} match={match} />
        </ListItemIcon>
        <ListItemText primary={renderRouteLabel({ match, route })} />
      </ListItem>
    </Link>
  );
};

export default MenuLink;
