// components
import { Button } from '@mui/material';
import { ZoneLabel, ConfirmationDialog } from 'components';

interface DeleteZoneProps {
  zoneId: number;
  pending: boolean;
  onDelete: () => void;
}

export const DeleteZone: React.FC<DeleteZoneProps> = ({ onDelete, zoneId, pending }) => {
  return (
    <ConfirmationDialog
      title={ (
        <>
          Are you sure you want to delete zone{ ' ' }
          <strong>
            <ZoneLabel zoneId={ zoneId } noLink/>
          </strong>?
        </>
      ) }
      pending={ pending }
      description={
        'The linked groups and positions will be deleted. ' +
        'The linked devices will be unbound.'
      }
      renderTrigger={ modal => (
        <Button color="danger" variant="contained" onClick={ modal.open }>Delete</Button>
      ) }
      onConfirm={ () => {
        onDelete();
      } }
    />
  );
};
