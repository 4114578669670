import React, { useState } from 'react';

// components
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { ConfirmationDialog, Dialog, DialogProps } from 'components';
import { useModalState } from 'components/Dialogs/Dialog/useModalState';

import Form, { PermissionsEditorFormProps } from './Form';

/* eslint-disable @typescript-eslint/indent */
type PermissionsEditorFormPropsSubset = Omit<
  PermissionsEditorFormProps,
  'actionsWrapper' | 'contentWrapper' | 'onSubmitBegin' | 'onSubmitEnd' | 'onCancel'
>;
/* eslint-enable @typescript-eslint/indent */

export type PermissionsEditorProps = PermissionsEditorFormPropsSubset & {
  renderTrigger: DialogProps['renderTrigger'];
}

const PermissionsEditor: React.FC<PermissionsEditorProps> = ({ renderTrigger, ...restProps }) => {
  const { open, openModal, closeModal } = useModalState();
  const closingConfirmationDialog = useModalState();
  const [pending, setPending] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const titleNodeId = 'permissions-editor-title';

  const handleModalClose = () => {
    if (formDirty) {
      closingConfirmationDialog.openModal();
    } else {
      closeModal();
    }
  };

  return (
    <>
      <Dialog
        disableBackdropClick={pending}
        disableEscapeKeyDown={pending}
        fullWidth
        maxWidth={false}
        open={open}
        renderTrigger={renderTrigger}
        scroll="body"
        onClose={handleModalClose}
        onOpen={openModal}
        aria-labelledby={titleNodeId}
      >
        <DialogTitle id={titleNodeId}>
          Permissions editor
        </DialogTitle>

        <Form
          {...restProps}
          actionsWrapper={DialogActions}
          contentWrapper={DialogContent}
          onDirtyChange={setFormDirty}
          onSubmitBegin={() => setPending(true)}
          onSubmitEnd={() => setPending(false)}
          onCancel={handleModalClose}
          onSuccess={(...args) => {
            closeModal();
            restProps.onSuccess?.(...args);
          }}
        />
      </Dialog>

      <ConfirmationDialog
        title="Are you willing to close the permissions editor?"
        description="Your unsaved changes will be lost."
        open={closingConfirmationDialog.open}
        onCancel={closingConfirmationDialog.closeModal}
        onConfirm={modal => {
          closeModal();
          closingConfirmationDialog.closeModal();
          modal.setPending(false);
        }}
      />
    </>
  );
};

export default PermissionsEditor;
