import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { CreateBoxField, CreateBoxState } from './types';

// components
import { Box } from '@mui/material';
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';

export function CreateBoxForm(props: FormProps<CreateBoxState, CreateBoxField>): JSX.Element {
  const { isView, state, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];
  const errors = props.errors ?? {};

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends keyof CreateBoxState>(field: CreateBoxField, value: CreateBoxState[TField]) {
    if (!onChange || state[field] === value) {
      return false;
    }

    onChange({ ...state, [field]: value }, field);
  }

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(CreateBoxField.ownerId) ? '' :
        <Box mt={ 1 }>
          <OwnersSingleSelectControl
            label="Owner"
            isRequired
            isClearable={ false }
            isDisabled={ isView || disabledFields.includes(CreateBoxField.ownerId) }
            name="box[owner]"
            selected={ state[CreateBoxField.ownerId] }
            error={ showErrors.includes(CreateBoxField.ownerId) && errors[CreateBoxField.ownerId] }
            changeHandler={ owner => handleChange(CreateBoxField.ownerId, owner ?? state[CreateBoxField.ownerId]) }
          />
        </Box>
      }
      { !showFields.includes(CreateBoxField.quantity) ? '' :
        <TextControl
          required
          disabled={ isView || disabledFields.includes(CreateBoxField.quantity) }
          type={'number'}
          inputProps={{ min: 0 }}
          label="Quantity"
          name="box[quantity]"
          value={ String(state[CreateBoxField.quantity] ?? 1) }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(CreateBoxField.quantity, Number(event.target.value))
          }
          error={ showErrors.includes(CreateBoxField.quantity) ? errors[CreateBoxField.quantity] !== undefined : false }
          helperText={ showErrors.includes(CreateBoxField.quantity) ? errors[CreateBoxField.quantity] : undefined }
        />
      }
    </form>
  );
}
