import React from 'react';
import { useDispatch } from 'react-redux';

import { deletePermissions } from 'actions/user-management/permissions';
import { Permissions } from 'models/user-management';
import { dispatchAsync } from 'utils/store';

// components
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { ConfirmationDialog } from 'components';

// styles
import { red } from '@mui/material/colors';

interface DeletePermissionsProps {
  permissions: Permissions;
  onSuccess?: () => void;
}

const DeletePermissions: React.FC<DeletePermissionsProps> = (props) => {
  const { permissions } = props;

  const dispatch = useDispatch();

  return (
    <ConfirmationDialog
      title="Are you sure?"
      description={(
        <>Do you really want to delete <strong>{permissions.name}</strong> permissions?</>
      )}
      renderTrigger={modal => (
        <IconButton aria-label="delete" onClick={modal.open}>
          <DeleteIcon style={{ color: red[700] }} />
        </IconButton>
      )}
      onConfirm={(modal) => {
        modal.setPending(true);

        dispatchAsync(dispatch, deletePermissions(permissions.id))
          .then(() => {
            modal.close();
            props.onSuccess?.();
          })
          .catch(() => modal.setPending(false));
      }}
    />
  );
};

export default DeletePermissions;
