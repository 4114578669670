import React from 'react';

import { useDialog } from 'hooks';
import { TemplatesState } from 'hooks/caller';

import { useTemplateFromUrl } from './useTemplateFromUrl';

// components
import { Paper } from '@mui/material';

import { CreateTemplateDialog, EditTemplateDialog } from './template-dialogs';
import { TemplateSuiteTemplatesTable } from './TemplateSuiteTemplatesTable';

interface CallerTemplateSuiteTemplatesProps {
  templateSuiteId: number;
  templatesState: TemplatesState;
  className?: string;
  tableClassName?: string;
  onTemplateDelete?: (deletedTemplateId: number) => void;
}

export const CallerTemplateSuiteTemplates = ({
  templateSuiteId,
  templatesState,
  className,
  tableClassName,
  onTemplateDelete,
}: CallerTemplateSuiteTemplatesProps) => {
  const createTemplateDialog = useDialog();
  const templateFromUrlDialog = useTemplateFromUrl();
  const templateFromUrl = templatesState.templates.find(template => {
    return template.template_id === templateFromUrlDialog.templateId;
  });

  return (
    <>
      <Paper className={ className } data-testid="templates-table">
        <TemplateSuiteTemplatesTable
          templateSuiteId={ templateSuiteId }
          templates={ templatesState.templates }
          isLoading={ templatesState.isLoading }
          className={ tableClassName }
          onTemplateCreateInitiated={ createTemplateDialog.open }
          onTemplateDelete={ onTemplateDelete }
        />
      </Paper>

      { createTemplateDialog.isMounted && (
        <CreateTemplateDialog
          templateSuiteId={ templateSuiteId }
          isOpen={ createTemplateDialog.isOpen }
          onClose={ createTemplateDialog.close }
          onCloseEnd={ createTemplateDialog.unmount }
        />
      ) }

      { templateFromUrlDialog.templateId !== undefined && (
        <EditTemplateDialog
          template={ templateFromUrl }
          templateLoading={ !templateFromUrl && templatesState.isLoading }
          templateSuiteId={ templateSuiteId }
          isOpen={ templateFromUrlDialog.isOpen }
          onClose={ templateFromUrlDialog.close }
          onCloseEnd={ templateFromUrlDialog.resetTemplateId }
        />
      ) }
    </>
  );
};
