import React from 'react';
import { Endpoint } from 'models/rabbit';
import { useDialog } from 'hooks';
import { commonOptions } from 'utils/tables';

// components
import { Box, Grid, Paper, Typography } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { EditButton } from 'components/Buttons';
import { EditEndpointSettingDialog } from 'components/Rabbit/Dialogs';
// styles
import { getMuiTheme } from 'styles/themes';
import { ThemeProvider } from 'styles/utils';
import useStyles from './style';

interface CellItem {
  name: string;
  value: string;
}

interface Props {
  endpoint: Endpoint;
}

export const EndpointSetting = ({ endpoint }: Props): JSX.Element => {
  const classes = useStyles();
  const editDialog = useDialog();

  const tableOptions: MUIDataTableOptions = {
    ...commonOptions,
    elevation: 0,
    pagination: false,
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'first',
      label: 'Endpoint settings',
      options: {
        customBodyRender: (value: Array<CellItem>) =>
          <Grid container>
            { value.map((item, key) =>
              <Grid key={ key } item xs={ value.length > 1 ? 6 : 12 } className={ classes.cellValue } >
                <Typography className={ classes.cellName } component="span">{ item.name }: </Typography>
                { item.value }
              </Grid>
            ) }
          </Grid>
      },
    },
  ];

  const data = [
    [
      [
        { name: 'Host', value: endpoint.host }
      ]
    ],
    [
      [
        { name: 'vHost', value: endpoint.vhost }
      ]
    ],
    [
      [
        { name: 'Exchange', value: endpoint.exchange },
        { name: 'Exchange type', value: endpoint.exchange_type },
      ]
    ],
    [
      [
        { name: 'Exchange is durable', value: endpoint.exchange_durable ? 'YES' : 'NO' },
        { name: 'Routing key', value: endpoint.queue },
      ]
    ],
    [
      [
        { name: 'Login', value: endpoint.login },
        { name: 'Use SSL', value: endpoint.ssl ? 'YES' : 'NO' },
      ]
    ],
  ];

  return (
    <Paper className={ classes.root } data-testid="rabbit-endpoint-setting">
      <ThemeProvider theme={ getMuiTheme({ embeddedTable: true }) }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ tableOptions }
          components={ {
            TableFooter: () => (
              <Box display="flex" justifyContent="flex-end" p={ 1 }>
                <EditButton type="text" onClick={ editDialog.open }/>
              </Box>
            )
          }}
        />
      </ThemeProvider>
      { editDialog.isMounted && (
        <EditEndpointSettingDialog
          endpoint={ endpoint }
          isOpen={ editDialog.isOpen }
          onClose={ editDialog.close }
          onCloseEnd={ editDialog.unmount }
        />
      ) }
    </Paper>
  );
};
