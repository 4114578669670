import { Level } from 'models/device-management';
import { LevelsFilters } from 'models/device-management/filters';

export const FETCH_LEVELS = 'FETCH_LEVELS';
export const FETCH_LEVELS_FAILURE = 'FETCH_LEVELS_FAILURE';
export const FETCH_LEVELS_SUCCESS = 'FETCH_LEVELS_SUCCESS';

export const CHANGE_LEVEL = 'CHANGE_LEVEL';

export interface FetchLevels {
  readonly type: typeof FETCH_LEVELS;
  readonly params: LevelsFilters;
}

export interface FetchLevelsFailure {
  readonly type: typeof FETCH_LEVELS_FAILURE;
  readonly params: LevelsFilters;
  readonly error: string;
}

export interface FetchLevelsSuccess {
  readonly type: typeof FETCH_LEVELS_SUCCESS;
  readonly params: LevelsFilters;
  readonly levels: Level[];
}

export interface ChangeLevel {
  readonly type: typeof CHANGE_LEVEL;
  readonly level: Level;
  readonly options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export const DoFetchLevels = (params: LevelsFilters): FetchLevels => ({
  type: FETCH_LEVELS,
  params,
});

export const DoFetchLevelsSuccess = (params: LevelsFilters, levels: Level[]): FetchLevelsSuccess => ({
  type: FETCH_LEVELS_SUCCESS,
  params,
  levels,
});

export const DoFetchLevelsFailure = (params: LevelsFilters, error: string): FetchLevelsFailure => ({
  type: FETCH_LEVELS_FAILURE,
  params,
  error,
});

export const DoChangeLevel = (level: Level, options: ChangeLevel['options']): ChangeLevel => ({
  type: CHANGE_LEVEL,
  level,
  options,
});

export type Action = (
  | FetchLevels
  | FetchLevelsSuccess
  | FetchLevelsFailure
  | ChangeLevel
);
