import React from 'react';
import { ApiKey } from 'models/user-management';
import { useAuthUserSelector, useDialog, useForm, useFormActionLoader, useFormEditToogleActions } from 'hooks';
import { canDeleteApiKey, canEditApiKey } from 'utils/permissions';
import { UpdateApiKey } from 'actions/user-management';
import { getClientDetailPath, getProfileClientPath } from 'routing/paths';

// components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { DeleteButton } from 'components/Buttons';
import { ApiKeyDeleteDialog, ApiKeyForm, ApiKeyFormField } from 'components/UserManagement';

interface Props {
  isProfile?: boolean;
  apiKey: ApiKey;
}

export const ApiKeyManagment = ({ isProfile, apiKey }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const form = useForm<ApiKey, ApiKeyFormField>({
    initialState: apiKey,
    showFields: [
      ApiKeyFormField.name,
      ApiKeyFormField.key,
      ApiKeyFormField.owner_id,
      ApiKeyFormField.project_ids,
    ],
  });
  const deleteDialog = useDialog();
  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: canEditApiKey(
      user),
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: UpdateApiKey(form.state),
        onError: form.catchError,
      });
    },
    onCancel: form.reset
  });

  return (
    <Card>
      <CardHeader title={ `API Auth Token ${ apiKey.name }` } action={
        <>
          { actions }
          { !canDeleteApiKey(user) || editMode ? '' :
            <DeleteButton type="icon" onClick={ deleteDialog.open }/>
          }
        </>
      }/>
      <CardContent>
        <ApiKeyForm
          isView={ !editMode }
          { ...form }
          disabledFields={ [ApiKeyFormField.key, ApiKeyFormField.owner_id] }
        />
      </CardContent>
      { deleteDialog.isMounted && <ApiKeyDeleteDialog
        apiKey={ apiKey }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
        onSucces={ () => ({ to: isProfile ? getProfileClientPath() : getClientDetailPath(apiKey.owner_id) }) }
      /> }
    </Card>
  );
};

