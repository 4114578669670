import { useEffect, useState } from 'react';
import { MapState, plainMapOptions, setMapCenterByPoints } from 'utils/map';
import { Address, Position } from '../../../types';
import { findAddressByAddress, findAddressByLocation, getDefaultPosition } from '../utils';

// components
import { CommonMap } from 'components/Map/CommonMap';
import { AddressMarker } from './AddressMarker';
import { FormControl } from '@mui/material';

// styles
import { styled } from 'styles/utils';

const LocationControl = styled(FormControl)(() => ({
  height: '270px',
  // hide close button in info window
  '& .gm-style button.gm-ui-hover-effect': {
    display: 'none!important'
  }
}));

interface Props {
  findUser?: Position;
  findAddress?: string;
  address?: Address;
  onChange: (address: Address) => void;
  onCancel?: () => void;
  className?: string;
  found?: Address;
  setFound: (address?: Address) => void;
}

export const MapControl = (props: Props): JSX.Element => {
  const { address, found, setFound,  onChange, onCancel, findAddress } = props;
  const [mapState, setMapState] = useState<MapState>();
  const [foundAddress, setFoundAddress] = useState<string | undefined>(undefined);

  /* update map center on change location */
  useEffect(() => mapState && setMapCenterByPoints(mapState, [
    new mapState.google.maps.LatLng(getDefaultPosition(address).lat, getDefaultPosition(address).lon)
  ]), [address, mapState]);

  /* update map center on change founded */
  useEffect(() => mapState && setMapCenterByPoints(mapState, [
    new mapState.google.maps.LatLng(getDefaultPosition(found ?? address).lat, getDefaultPosition(found ?? address).lon)
  ]), [found, address, mapState]);

  /* found position on map by find param */
  useEffect(() => {
    if (mapState && findAddress && foundAddress !== findAddress) {
      findAddressByAddress(mapState, findAddress, result => {
        setFound(
          result ?? {
            id: 'notFound',
            title: 'Not found',
            ...getDefaultPosition(address)
          }
        );
      });
    }
    setFoundAddress(findAddress);
  }, [foundAddress, findAddress, mapState, address, setFound]);

  /* load user position by default once on start */
  useEffect(() => {
    !props.findUser && found && setFound(undefined);
    if (mapState && props.findUser) {
      findAddressByLocation(mapState, props.findUser.lat, props.findUser.lon, result => {
        setFound(
          result ?? {
            id: 'notFound',
            title: 'Your geolocation',
            ...getDefaultPosition(props.findUser)
          }
        );
      });
    }
    // dont catch change of found, only for drop found on drop findUser
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapState, props.findUser]);

  const handleClick = (event: google.maps.MapMouseEvent) => {
    if (!mapState || null === event.latLng) {
      return;
    }

    findAddressByLocation(mapState, event.latLng.lat(), event.latLng.lng(), result => {
      setFound(
        result ?? {
          id: 'clickPoint',
          title: 'This point on the map',
          lat: event.latLng?.lat() ?? 0,
          lon: event.latLng?.lng() ?? 0,
        }
      );
    });
  };

  const handleSelectFound = (address?: Address) => {
    address && onChange(address);
    !address && onCancel && onCancel();
    setFound(undefined);
  };

  return (
    <LocationControl fullWidth margin="normal" className={ props.className }>
      <CommonMap
        zoom={ 16 }
        defaultCenter={ {
          lat: address?.lat || 0,
          lng: address?.lon || 0,
        } }
        onClick={ handleClick }
        onLoad={ (map, google) => setMapState({ map, google }) }
        options={ { ...plainMapOptions } }
      >
        {
          found
            ? <AddressMarker address={ found } onSelect={ handleSelectFound } />
            : (address ? <AddressMarker address={ address } /> : '')
        }
      </CommonMap>
    </LocationControl>
  );
};
