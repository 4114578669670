import React from 'react';
// components
import Add from '@mui/icons-material/Add';
import CommonButton, { CommonButtonProps } from './CommonButton';
// styles

type Props = Omit<CommonButtonProps, 'icon' | 'label' | 'type'> & {
  label?: CommonButtonProps['label'];
  type?: CommonButtonProps['type'];
};

const AddButton = (props: Props): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Add';
  const type = props.type !== undefined ? props.type : 'fab';
  return <CommonButton
    { ...props }
    type={ type }
    label={ label }
    icon={ type === 'text' ? undefined : <Add fontSize="large"/> }
    dataTestId={ 'create-common-button' }
  />;
};

export default AddButton;
