import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '75vh',
      maxHeight: '100%',
      padding: 0,
      ...margins(theme)
    },
    stationIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    bsIconContainer: {
      display: 'flex',
      height: theme.spacing(4)
    },
    bsDuration: {
      color: grey[500]
    }
  }),
);

export default useStyles;
