import stationSaga from './station';
import stationFlashSaga from './station-flash';
import stationLoraSaga from './station-lora';
import stationStatusSaga from './station-status';
import locationSaga from './location';
import stationMonitoring from './station-monitoring';

export default [
  ...stationSaga,
  ...stationFlashSaga,
  ...stationLoraSaga,
  ...stationStatusSaga,
  ...locationSaga,
  ...stationMonitoring,
];
