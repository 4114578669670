import React from 'react';
import { useMonitoringReasonsDictionary } from 'hooks/monitoring';
// components
import Typography, { TypographyProps } from '@mui/material/Typography';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  reason?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

export const IncidentReason = ({ reason, innerProps }: Props) => {
  const { isLoading, reasons } = useMonitoringReasonsDictionary();
  const incidentReason = reasons.find(x => x.id === reason);
  const name = incidentReason ? incidentReason.name : '-';
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    isLoading ? <LoaderSvg/> : <Typography { ...TypoProps }>{ name }</Typography>
  );
};

