import React from 'react';
import { UserGroup } from 'models/user-management';
import { useAuthUserSelector, useDialog, useForm, useFormActionLoader, useFormEditToogleActions } from 'hooks';
import { canEditUserGroup, canRefreshUserGroupAccessCode } from 'utils/permissions';
import { RefreshUserGroupAccessCode, UpdateUserGroup } from 'actions/user-management';
// components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { UserGroupForm, UserGroupFormField } from 'components/UserManagement';
import { RefreshAccessCodeDialog } from './RefreshAccessCodeDialog';

interface Props {
  userGroup: UserGroup;
}

export const UserGroupManagment = ({ userGroup }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const form = useForm<UserGroup, UserGroupFormField>({
    initialState: userGroup,
    showFields: [
      UserGroupFormField.name,
      UserGroupFormField.address,
      UserGroupFormField.phone_number,
    ],
  });
  const refreshAccessCodeDialog = useDialog();
  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: canEditUserGroup(user),
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: UpdateUserGroup(form.state),
        onError: form.catchError,
      });
    },
    onCancel: form.reset
  });

  const handleRefreshAccessCode = () => doAction({
    action: RefreshUserGroupAccessCode(userGroup),
  });

  return (
    <Card>
      <CardHeader title={ `Client ${ userGroup.name }` } action={
        <>
          { actions }
        </>
      }/>
      <CardContent>
        <UserGroupForm
          isView={ !editMode }
          { ...form }
          onRefreshAccessCode={ canRefreshUserGroupAccessCode(user) ? handleRefreshAccessCode : undefined }
        />
      </CardContent>
      { refreshAccessCodeDialog.isMounted && <RefreshAccessCodeDialog
        userGroup={ userGroup }
        isOpen={ refreshAccessCodeDialog.isOpen }
        onClose={ refreshAccessCodeDialog.close }
        onCloseEnd={ refreshAccessCodeDialog.unmount }
        onConfirm={ handleRefreshAccessCode }
      /> }
    </Card>
  );
};

