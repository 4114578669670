import React from 'react';
import { useLorawanStationStatusHistory } from 'hooks/station';
import { StationEntity } from 'models/base-station';
import { MonitoringDateRange } from 'models/base-station-status';
// components
import { Box, Typography } from '@mui/material';
import { InternetHistoryChart } from './charts/InternetHistoryChart';
// styles
import { useStyles } from '../../styles';

interface Props {
  station: Pick<StationEntity, 'id' | 'type'>;
  dateRange: MonitoringDateRange;
  dateRangeControlNode: React.ReactNode;
}

export const LorawanCharts = ({
  station,
  dateRange,
  dateRangeControlNode,
}: Props): JSX.Element => {
  const classes = useStyles();
  // LORAWAN
  const lorawanInternet = useLorawanStationStatusHistory({
    id: station.id,
    dateRange,
  });
  return (
    <>
      <Box
        className={ classes.blockCaption }
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={ classes.blockCaptionText }>Availability History</Typography>
        { dateRangeControlNode }
      </Box>
      <InternetHistoryChart isLorawan={true} internet={lorawanInternet} />
    </>
  );
};
