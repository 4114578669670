import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { CopyToClipboardButton } from 'components/Buttons';

type Props = { value?: string; label: string };

const Component = ({ value, label }: Props): JSX.Element => {
  return <Box my={ 2 }>
    <TextField
      fullWidth
      label={ label }
      value={ value ?? 'Not generated' }
      disabled={ !value }
      InputProps={ {
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            { value ? <CopyToClipboardButton
              help={ `Copy ${label}` }
              type="icon"
              value={ value }
            /> : '' }
          </InputAdornment>
        ),
      } }
    />
  </Box>;
};

export default Component;
