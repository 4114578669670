import { ReactNode } from 'react';
import { UserPayload } from 'actions/user-profile';
import { useAuthUserSelector, useDialog } from 'hooks';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

interface Props {
  button: (open: () => void) => ReactNode;
  dialog: (props: DialogProps) => ReactNode;
  permission?: (user: UserPayload) => boolean;
}

export function DialogButton(props: Props): JSX.Element {
  const { button, dialog, permission } = props;
  const state = useDialog();
  const authUser = useAuthUserSelector();
  if (permission && !permission(authUser.data)) {
    return (
      <></>
    );
  }

  return (
    <>
      { button(state.open) }
      { state.isMounted && dialog({
        isOpen: state.isOpen,
        onClose: state.close,
        onCloseEnd: state.unmount,
      })}
    </>
  );
}
