import React from 'react';
import { Helmet } from 'react-helmet-async';
import { TemplateSuiteId } from 'models/caller';
import { useTemplateSuiteSelector } from 'hooks/caller';
// components
import Navigation, { NavigationTab } from './Navigation';
import Skeleton from '@mui/lab/Skeleton';
import Typography from '@mui/material/Typography';

interface Props {
  templateSuiteId: TemplateSuiteId;
}

const Component = ({ templateSuiteId }: Props): JSX.Element => {
  const { templateSuite } = useTemplateSuiteSelector(templateSuiteId);
  return (
    <>
      <Helmet><title>{ `Http Template Suite #${ templateSuiteId } ${ templateSuite && templateSuite.name } Statistic` }</title></Helmet>
      <Navigation active={ NavigationTab.STATISTIC } templateSuite={ templateSuite }/>
      { !templateSuite
        ? <Skeleton variant="rectangular"/>
        : <Typography variant="h4" align="center">Page not implemented</Typography>
      }
    </>

  );
};

export default Component;
