import { API } from 'aws-amplify';
import { ApiResponse } from 'models';
import { errorHandler, formatQuery } from '../utils';
import { config }  from './config';

const urls = {
  last_message_time: '/raw_messages/last_message_time',
};

export const getLastRawMessagesTime = (deviceId?: string): Promise<ApiResponse<{ demodulation_time: string }[]>> => {
  const body = {
    queryStringParameters: formatQuery({ device_id: deviceId }),
  };

  return API
    .get(config.name, urls.last_message_time, body)
    .catch(errorHandler);
};
