import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { DevicesStatesFiltersFields } from 'models';
import { initialState } from './utils';

// components
import { CustomIdSelect, IncidentErrorTypeSelect, IncidentReasonSelect, SeverityTypeSelect } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/filters';

interface Props {
  isLoading: boolean;
  filter: DevicesStatesFiltersFields;
  onSave: (filters: DevicesStatesFiltersFields) => void;
  onReset: () => void;
}

export const DeviceStatesTableFilter = ({
  onSave,
  onReset,
  isLoading,
  filter
}: Props): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState(filter);

  useDeepCompareEffect(() => {
    setState(filter);
  }, [filter]);

  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner: Number(owner) || undefined });
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const handleSelectGroups = (groups: number[]) => setState({ ...state, groups });

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={filter}
      onSave={() => { onSave(state); }}
      onReset={() => { onReset(); setState(initialState); }}
    >
      <div className={ classes.gridRoot }>
        <GroupedFilters
          handleSelectOwner={ handleSelectOwner }
          owner={ state.owner }
          projects={ state.projects }
          handleSelectProjects={ handleSelectProjects }
          zones={ state.zones }
          handleSelectZones={ handleSelectZones }
          groups={ state.groups }
          handleSelectGroups={ handleSelectGroups }
          isProjectShow
          isZoneShow
          isGroupsShow
        />
        <CustomIdSelect
          selected={ state.devices }
          changeHandler={ devices => setState({ ...state, devices }) }
          name="Device"
          type="hex"
        />
        <IncidentErrorTypeSelect
          isMulti
          isClearable
          label="Error Types"
          schema="deviceState"
          selected={ state.incidentTypes }
          onChange={ incidentTypes => setState({ ...state, incidentTypes }) }
        />
        <IncidentReasonSelect
          isMulti
          isClearable
          label="Reasons"
          schema="deviceState"
          selected={ state.reason ?? [] }
          onChange={ reason => setState({ ...state, reason }) }
        />
        <SeverityTypeSelect
          isMulti
          isClearable
          selected={ state.severity }
          onChange={ severity => setState({ ...state, severity }) }
        />
      </div>
    </FilterButton>
  );
};
