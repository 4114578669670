import React from 'react';
import { ThemeProvider } from 'styles/utils';
import { isNil } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useEffectOnce } from 'react-use';
import { DoFetchProvisioningBoxesByFilters } from 'actions/provisioning';
import { initProvisioningBoxesFilters } from 'models/provisioning';
import { commonOptions } from 'utils/tables';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';

// components
import { SuccessButton } from 'components/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { TableLoadingLayout } from 'components/Table';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';
import { LoaderSvg } from 'components/Loaders';

// styles
import useStyles from 'components/Provisioning/styles';
import { getTableTheme } from 'styles/themes';

interface Props {
  isOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
  addBox: (boxes: number[]) => void;
}

type TableData = Array<{
  index: number;
  dataIndex: number;
}>;

type TableDisplayData = Array<{
  // TODO (BNIV-560): check whether we can at least replace it with `unknown`
  // it's defined the same way (`any[]`) in the mui-datatables' typedefs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  dataIndex: number;
}>;

const getDataArrFromTable = (data: TableData, displayData: TableDisplayData): number[] => {
  const accum: string[] = [];
  for (const { dataIndex } of data) {
    const data = displayData.find(data => data.dataIndex === dataIndex);
    accum.push(data?.data[0].replace(/^\D+/g, ''));
  }

  return accum.map(n => Number(n));
};

export const AddBoxModal = ({
  isOpen,
  setDialogOpen,
  addBox,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const provisioning = useSelector((state: RootState) => state.provisioning.boxes);
  const boxes = provisioning.boxes.filter(box => isNil(box.shipment_id) && Number(box.device_count) > 0);
  const { userGroupById: owners, isLoading: isOwnerLoading } = useUserGroupsDictionarySelector();
  let selected: number[] = [];

  useEffectOnce(() => {
    dispatch(DoFetchProvisioningBoxesByFilters({
      ...initProvisioningBoxesFilters,
      limit: 9999,
    }));
  });

  const handleSubmit = () => {
    addBox(selected);
    setDialogOpen(false);
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Box',
      options: {
        setCellProps: () => ({ style: { width: '150px' } }),
        customBodyRender: (id: number) => `Box ID ${ id }`
      }
    },
    {
      name: 'owner_id',
      label: 'Owner',
      options: {
        customBodyRenderLite: dataIndex => {
          if (isOwnerLoading) {
            return <LoaderSvg />;
          }
          const box = boxes[dataIndex];
          return owners[box.owner_id ?? 0]?.name ?? '-';
        }
      }
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    responsive: 'standard',
    elevation: 0,
    search: true,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customToolbarSelect: (selectedRows, displayData) => {
      selected = getDataArrFromTable(selectedRows.data, displayData);
      return '';
    },
  };

  return (
    <>
      <Dialog
        open={ isOpen }
        onClose={ () => setDialogOpen(false) }
        maxWidth={ 'sm' }
        fullWidth
      >
        <DialogContent className={ classes.dialogContentRootWithTable }>
          <ThemeProvider theme={ getTableTheme() }>
            <TableLoadingLayout isLoading={ provisioning.isFetching }>
              <MUIDataTable
                title={ null }
                data={ boxes }
                columns={ columns }
                options={ options }
              />
            </TableLoadingLayout>
          </ThemeProvider>
          <DialogActions>
            <Button color="inherit" onClick={ () => setDialogOpen(false) }>
              Cancel
            </Button>
            <SuccessButton
              label={ `Add` }
              onClick={ handleSubmit }
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
