import React from 'react';
// components
import Skeleton, { SkeletonProps } from '@mui/lab/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface Props {
  tabsCount: number;
  SkeletonProps?: Partial<SkeletonProps>;
}

export default (props: Props): JSX.Element => {
  const { tabsCount, SkeletonProps } = props;
  const tabs = Array.from({ length: tabsCount }, (_value, index) => (
    <Tab key={ index } value={ index } label={ <Skeleton variant="rectangular" width="80%"  { ...SkeletonProps }/> }/>
  ));

  return (
    <Tabs value={ false } indicatorColor="secondary" textColor="primary">{ tabs }</Tabs>
  );
};
