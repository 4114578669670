import React from 'react';
import { Permissions } from 'models/user-management';

// components
import { Box, Typography } from '@mui/material';
import PermissionsTable from './PermissionsTable';
import PermissionsIndicator from './PermissionsIndicator';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface PermissionsProps {
  permissions: Permissions;
}

const PermissionsBlock: React.FC<PermissionsProps> = (props) => {
  const { permissions } = props;
  const infoCss = useInfoBlockStyles();

  return (
    <Box data-testid="loaded-permissions">
      <Typography className={ infoCss.field }>
        { permissions.template
          ? <><strong>"{ permissions.name }" template</strong> is assigned to the user.</>
          : <><strong>Individual</strong> permissions are assigned to the user.</>
        }
      </Typography>

      <PermissionsTable
        renderSwitch={ ({ categoryFieldName, operation }) => (
          <PermissionsIndicator
            granted={ permissions[categoryFieldName]?.includes(operation.key) ?? false }
          />
        ) }
      />
    </Box>
  );
};

export default PermissionsBlock;
