import React, { useState, useEffect } from 'react';
import { Statuses } from 'models/lorawan';
import { useDialog, useAuthUserSelector, useFormActionLoader } from 'hooks';
import Cookies from 'js-cookie';
import { DoSendEmail } from 'actions/lorawan';
import { getCookieKeyForLorawan } from 'utils/cookies';
import { IntegrationData } from 'models/lorawan';

// components
import { Button, Box } from '@mui/material';
import { DeleteButton } from 'components/Buttons';
import { AddIntegrationDialog } from './dialogs/AddIntegration';
import { DisableIntegrationDialog } from './dialogs/DisableIntegrationDialog';

interface ButtonsProps {
  status: Statuses;
  registered: boolean;
  addIntegration: () => void;
  editIntegration: () => void;
  registerInterest: () => void;
  disableIntegration: () => void;
}

const ActionButtons = (props: ButtonsProps): JSX.Element => {
  const { status, registered, disableIntegration,
    addIntegration, editIntegration, registerInterest } = props;
  switch (status) {
    case Statuses.NotSet:
      return (
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={ addIntegration }>Add integration</Button>
        </Box>
      );
    case Statuses.UnderConstruction:
      return (
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={ registerInterest }
            disabled={ registered }
          >Register interest</Button>
        </Box>
      );
    case Statuses.Enabled:
      return (
        <Box display="flex" justifyContent="space-between" flexDirection="inherit">
          <DeleteButton onClick={ disableIntegration } type="text" label="Delete" />
          <Button color="primary" onClick={ editIntegration }>Details</Button>
        </Box>
      );

    default:
      return <></>;
  }
};

export const IntegrationAction = (props: IntegrationData & { reload: () => void }): JSX.Element => {
  const { status, title, reload, apiKey, url, documentation } = props;
  const { doAction } = useFormActionLoader();
  const [registeredInterest, setRegisteredInterest] = useState<boolean>(false);
  const addIntegration = useDialog();
  const editIntegration = useDialog();
  const user = useAuthUserSelector();
  const disableDialog = useDialog();

  useEffect(() => {
    const integration = Cookies.get(getCookieKeyForLorawan(user.data.email));
    if (integration && integration === title) {
      setRegisteredInterest(true);
    }
  }, [title, user.data.email]);

  function registerInterest() {
    doAction({
      action: DoSendEmail(user.data, title),
      onSuccess: () => setRegisteredInterest(true),
    });
  }

  return (
    <>
      <ActionButtons
        status={ status }
        addIntegration={ addIntegration.open }
        editIntegration={ editIntegration.open }
        registerInterest={ registerInterest }
        registered={ registeredInterest }
        disableIntegration={ disableDialog.open }
      />

      { addIntegration.isMounted && <AddIntegrationDialog
        integration={ title }
        documentation={ documentation }
        isOpen={ addIntegration.isOpen }
        onClose={ addIntegration.close }
        onCloseEnd={ addIntegration.unmount }
        user={ user.data }
        preSets={ { apiKey, url } }
        reload={ reload }
      /> }

      { editIntegration.isMounted && <AddIntegrationDialog
        integration={ title }
        documentation={ documentation }
        isOpen={ editIntegration.isOpen }
        onClose={ editIntegration.close }
        onCloseEnd={ editIntegration.unmount }
        user={ user.data }
        preSets={ { apiKey, url } }
        reload={ reload }
      /> }

      { disableDialog.isMounted && apiKey && <DisableIntegrationDialog
        integrationName={ title }
        isOpen={ disableDialog.isOpen }
        apiKey={ apiKey }
        onClose={ disableDialog.close }
        onCloseEnd={ disableDialog.unmount }
        onSuccess={ reload }
      /> }
    </>
  );

};
