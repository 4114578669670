import React from 'react';
import { connect } from 'react-redux';

import { Device } from 'models/device-management';
import { RawMessages } from 'models/raw-messages';
import { RootState as State } from 'reducers';
import { apiRawMessagesPath, apiLorawanRawMessagesPath } from 'routing/paths';
import { formatDateTime } from 'utils/datetime';
import { getDeviceConnectivity } from 'selectors/connectivity';
import { useRawMessagesLastMessageTimeApi } from 'hooks/useRawMessagesLastMessageTimeApi';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Link,
  TextField,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import { FieldSkeleton } from 'components/Skeleton';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface OwnProps {
  device?: Device;
}

interface StateProps {
  deviceConnectivity: ReturnType<typeof getDeviceConnectivity>;
  deviceIncidentsAmount?: number;
  rawMessage?: RawMessages;
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    deviceConnectivity: props.device && getDeviceConnectivity(props.device.device_id, state),
    rawMessage: props.device && state.rawMessages.messages.find(m => m.device_id.toUpperCase() === props.device?.device_id.toUpperCase()),
  };
}

const DeviceMonitoring: React.FC<OwnProps & StateProps> = ({
  device,
  deviceConnectivity,
}) => {
  const infoCss = useInfoBlockStyles();

  const { lastRawMessagesTime: lastMessagesTimeRaw } = useRawMessagesLastMessageTimeApi(device?.device_id);
  const lastMessageTime = lastMessagesTimeRaw && lastMessagesTimeRaw[0]?.demodulation_time;

  if (!device) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  const rawMessageslink = device.protocol_name === 'unb' ?
    apiRawMessagesPath({ devices: [device.device_id] }) :
    apiLorawanRawMessagesPath({ devices: [device.device_id] });

  return (
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
        Message Statistics
      </AccordionSummary>

      <AccordionDetails>
        <FormGroup className={ infoCss.fields }>
          <TextField
            className={ infoCss.field }
            disabled
            label="Last received message time"
            value={ lastMessageTime ? formatDateTime(lastMessageTime) : '' }
          />

          <TextField
            className={ infoCss.field }
            disabled
            label="Current connectivity"
            value={ deviceConnectivity ? `${deviceConnectivity.connectivity}%` : '0' }
          />

          {/* TODO: add connectivity history chart */}

          <div className={ infoCss.field }>
            <Link
              color="secondary"
              component={ RouterLink }
              to={ rawMessageslink }
              target="_blank"
            >
              View all...
            </Link>
          </div>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default connect<StateProps, Record<string, unknown>, OwnProps, State>(mapStateToProps)(DeviceMonitoring);
