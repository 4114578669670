import React from 'react';
import { StationLocation } from 'models/base-station';
import { useStationLocationsDictionary } from 'hooks/station';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (location: StationLocation) => boolean }

export const StationCitySelectControl = (props: Props): JSX.Element => {
  const { isLoading, locations } = useStationLocationsDictionary();
  const filterCallback = props.filter ?? (() => true);

  const options: Option<string>[] = [];
  locations
    .filter(filterCallback)
    .reduce((cities, location) => cities.add(location.city), new Set<string>())
    .forEach(city => options.push({
      value: city,
      label: city,
    }));

  return (
    <SelectControl
      name="city"
      label="City"
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ !isLoading }
    />
  );
};


