import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { PageQueryParam } from '../utils';

// components
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { FilterButton } from 'components/Buttons';

interface Props {
  isLoading: boolean;
  param: PageQueryParam;
  setParam: (param?: PageQueryParam) => void;
}

export const PageFilter = ({ isLoading, param, setParam }: Props): JSX.Element => {
  const [state, setState] = useState(param);

  useDeepCompareEffect(() => {
    setState(param);
  }, [param]);

  const handleSelectOwner = (owner?: number | undefined) => setState(prevState => ({
    ...prevState,
    owner_id: Number(owner) || undefined
  }));
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({
    ...prevState,
    project_id: projects
  }));

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ param }
      onSave={ () => setParam(state) }
      onReset={ () => setParam(undefined) }
    >
      <form autoComplete="off">
        <GroupedFilters
          owner={ state.owner_id }
          handleSelectOwner={ handleSelectOwner }
          projects={ state.project_id }
          handleSelectProjects={ handleSelectProjects }
          isProjectShow
          isZoneShow={ false }
          isGroupsShow={ false }
        />
      </form>
    </FilterButton>
  );
};
