import React from 'react';

import { Typography, Box } from '@mui/material';
import { ShipmentState, ShipmentStatusEnum } from 'models/provisioning';
import { createStyles, makeStyles, Theme } from 'styles/utils';
import { blue, green, yellow } from '@mui/material/colors';

interface ShipmentStatusProps {
  status: keyof typeof ShipmentState;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  [ShipmentStatusEnum.Created]: {
    background: theme.palette.grey[200],
  },
  [ShipmentStatusEnum.Received]: {
    background: green[500],
    color: theme.palette.common.white
  },
  [ShipmentStatusEnum.Dispatched]: {
    background: blue[500],
    color: theme.palette.common.white
  },
  [ShipmentStatusEnum.InAWarehouse]: {
    background: yellow[500],
  },
}));

export const ShipmentStatus: React.FC<ShipmentStatusProps> = ({ status }) => {
  const classes = useStyles();
  return (
    <Box className={ classes[status] }>
      <Typography variant="caption" align="center" component="p">
        { ShipmentState[status] }
      </Typography>
    </Box>
  );
};
