import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { SelectOption } from 'components/Controls/Select/types';
import { useUniqueId } from 'hooks';

// components
import { Menu, MenuItem } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

import { CommonButton } from 'components/Buttons';

// styles
import { useMuiControlStyles } from './styles';

const ALL_VALUE = '__SECRET_ALL_VALUE';

interface AllValue {
  type: 'all';
}

interface OptionsValue<T> {
  type: 'options';
  values: T[];
}

type Value<T> = AllValue | OptionsValue<T>;

interface AllSelectControlMuiProps<TValue> {
  selected: Value<TValue>;
  options: SelectOption<TValue>[];
  onChange: (value: Value<TValue>) => void;
  allOptionLabel?: string;
  name?: string;
}

export function AllSelectControlMui<TValue>({
  selected,
  options,
  onChange,
  allOptionLabel = 'All',
  ...selectProps
}: AllSelectControlMuiProps<TValue>) {
  const popupId = useUniqueId(selectProps.name);
  const popupState = usePopupState({ popupId, variant: 'popover' });
  const triggerProps = bindTrigger(popupState);
  const classes = useMuiControlStyles();

  const handleChange = (optionValue: typeof ALL_VALUE | TValue) => {
    let selectedValue: typeof ALL_VALUE | TValue[];

    if (optionValue === ALL_VALUE) {
      selectedValue = ALL_VALUE;
    } else if (selected.type === 'all') {
      selectedValue = [optionValue];
    } else if (selected.values.includes(optionValue)) {
      selectedValue = selected.values.filter(v => v !== optionValue);
    } else {
      selectedValue = [...selected.values, optionValue];
    }

    if (Array.isArray(selectedValue) && !selectedValue.length) {
      selectedValue = ALL_VALUE;
    }

    if (selectedValue === ALL_VALUE) {
      onChange({ type: 'all' });
    } else {
      onChange({ type: 'options', values: selectedValue });
    }
    popupState.close();
  };

  const allOptions: SelectOption<TValue | typeof ALL_VALUE>[] = [
    { label: allOptionLabel, value: ALL_VALUE },
    ...options,
  ];

  return (
    <>
      <CommonButton
        type="text"
        label={ selected.type === 'all'
          ? allOptionLabel
          : options
            .filter(option => selected.values.includes(option.value))
            .map(option => option.label)
            .join(', ')
        }
        classes={ { buttonLabel: classes.controlButtonLabel } }
        icon={ <ArrowDropDownIcon className={ classes.controlButtonIcon } /> }
        iconPlacement="end"
        ButtonProps={ { ...triggerProps, className: classes.controlButton } }
        { ...triggerProps }
        onClick={ (_currentTarget, e) => triggerProps.onClick(e) }
      />

      <Menu
        { ...bindMenu(popupState) }
        variant="menu"
        PaperProps={ { className: classes.menuPaper } }
      >
        { allOptions.map((option, i) => {
          let optionSelected = false;

          if (option.value === ALL_VALUE && selected.type === 'all') {
            optionSelected = true;
          }

          if (
            option.value !== ALL_VALUE &&
            selected.type === 'options' &&
            selected.values.includes(option.value)
          ) {
            optionSelected = true;
          }

          return (
            <MenuItem
              key={ i }
              selected={ optionSelected }
              onClick={ () => handleChange(option.value) }
            >
              { option.label }
            </MenuItem>
          );
        }) }
      </Menu>
    </>
  );
}
