import React from 'react';
// components
import { Typography, Chip } from '@mui/material';

interface ChipLabelProps {
  label: string;
  value: string | React.ReactNode;
}

export const SelectedFilterChip = ({ label, value }: ChipLabelProps): JSX.Element => {
  return (
    <Chip
      label={
        <Typography color="textSecondary" variant="caption">
          { `${label}:` } <Typography color="primary" component="span" variant="caption">{ value }</Typography>
        </Typography>
      }
      clickable
      variant="outlined"
      size="small"
      color="primary"
      sx={ theme => ({
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
        backgroundColor: 'transparent',
        height: theme.spacing(3),
        borderColor: theme.palette.primary.main,
        '& p.MuiTypography-root': {
          display: 'inline-block',
        },
      })}
    />
  );
};
