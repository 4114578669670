import React from 'react';
// components
import { Typography } from '@mui/material';
import Update from '@mui/icons-material/Update';
import CommonButton, { CommonButtonProps } from './CommonButton';

type Props =
  Omit<CommonButtonProps, 'icon' | 'label' | 'type'>
  & {
    label?: CommonButtonProps['label'];
    status: boolean;
  };

export const AutoUpdateButton = (props: Props): JSX.Element => {
  const { label = 'Auto Update', status, ...commonProps } = props;

  return (
    <CommonButton
      { ...commonProps }
      type="text"
      label={ <Typography color={ status ? 'primary' : 'textPrimary' }>{ label }</Typography> }
      icon={ <Update color={ status ? 'primary' : 'action' }/> }
    />
  );
};

export default AutoUpdateButton;
