import React from 'react';
import { ShipmentCreateBody, ShipmentFormField } from 'models/provisioning';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
import { DoCreateShipment } from 'actions/provisioning';

// components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ShipmentForm } from '../ShipmentManagment';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  isOpen: boolean;
  isAdmin: boolean;
  setDialogOpen: (isOpen: boolean) => void;
}

export const CreateShipmentModal = ({ isOpen, isAdmin, setDialogOpen }: Props): JSX.Element => {
  const classes = useStyles();
  const defaultValues: ShipmentCreateBody = {
    name: '',
  };
  const form = useForm<ShipmentCreateBody, ShipmentFormField>({
    initialState: defaultValues,
    showFields: [
      ShipmentFormField.name,
      ShipmentFormField.shipper_id,
      ShipmentFormField.recipient_id,
    ],
  });
  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: isAdmin,
    btnSave: 'Create',
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: DoCreateShipment(form.state),
        onError: form.catchError,
        onSuccess: () => setDialogOpen(false),
      });
    },
    onCancel: () => {
      form.reset();
      setDialogOpen(false);
    },
  });

  return (
    <Dialog
      open={ isOpen }
      onClose={ () => setDialogOpen(false) }
      maxWidth={ 'sm' }
      fullWidth
      PaperProps={ { className: classes.formDialogPaper } }
    >
      <DialogTitle>Create Shipment</DialogTitle>
      <DialogContent className={ classes.dialogContentRoot }>
        <ShipmentForm<ShipmentCreateBody> { ...form } />
      </DialogContent>
      <DialogActions> { actions } </DialogActions>
    </Dialog>
  );
};
