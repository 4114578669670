
// components
import { Dialog, DialogProps } from '@mui/material';


export type Props = DialogProps & {
  onExited?: () => void
  disableBackdropClick?: boolean
  onBackdropClick?: () => void
  onEscapeKeyDown?: () => void
}

export const MuiDialog = (props: Props): JSX.Element => {
  const handleClose: DialogProps['onClose'] = (event, reason) => {
    switch (reason) {
      case 'backdropClick':
        props.onBackdropClick?.();
        if (!props.disableBackdropClick) {
          props.onClose?.(event, reason);
        }
        break;
      case 'escapeKeyDown':
        props.onEscapeKeyDown?.();
        if (!props.disableEscapeKeyDown) {
          props.onClose?.(event, reason);
        }
        break;
      default:
        props.onClose?.(event, reason);
    }
  };

  return (
    <Dialog
      { ... props }
      TransitionProps={ {
        onExited: props.onExited
      }}
      onClose={ handleClose }
    >
      { props.children }
    </Dialog>
  );
};
