import { Action } from 'redux';
import { DeleteLocation } from 'actions/base-station';
import { useFormActionLoader } from 'hooks/form';
import { StationLocation } from 'models/base-station';
import { formatLocationTitle } from '../../utils';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  location: StationLocation;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onDelete?: Action; // call before handleDeleteLocation (ex: we need unbind station before delete location)
  onDeleteCallback?: () => void;
}

export const DeleteLocationDialog = ({ location, isOpen, onClose, onCloseEnd, onDelete, onDeleteCallback }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const handleDeleteLocation = () => {
    doAction({
      action: DeleteLocation(location),
      onSuccess: () => {
        if (onDeleteCallback) {
          onDeleteCallback();
        }
        onClose();
      },
    });
  };

  let handleConfirm = handleDeleteLocation;

  if (onDelete) {
    handleConfirm = () => {
      doAction({
        action: onDelete,
        onSuccess: handleDeleteLocation,
      });
    };
  }

  return (
    <EnsureDialog
      name="delete-station-location"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      Are you sure you want to delete the Location { formatLocationTitle(location) }?
    </EnsureDialog>
  );
};
