import React from 'react';
import clsx from 'clsx';
import { formatDuration } from './utils';

// components
import Typography from '@mui/material/Typography';
// styles
import { useStyles } from './styles';

export interface Props {
  label: string;
  status?: boolean;
  duration?: string | Date;
  hideDuration?: boolean;
  variant?: 'outlined' | 'text';
  color?: 'nomal' | 'ok' | 'error' | 'unknown';
}



export const CommonStatus = ({ label, duration, variant, color, hideDuration }: Props) => {
  const classes = useStyles();
  return (
    <Typography
      className={ clsx(
        variant !== 'text' ? classes.statusOutlined : {},
        color === 'nomal' ? classes.statusNormal : {},
        color === 'ok' ? classes.statusOk : {},
        color === 'error' ? classes.statusError : {},
        color === 'unknown' ? classes.statusUnknown : {},
      ) }
      component="span"
      color="inherit"
    >
      { label }&nbsp;{ hideDuration ? '' : duration ? formatDuration(duration) : '0m' }
    </Typography>
  );
};
