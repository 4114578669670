// https://github.com/facebook/create-react-app/tree/30eaab4ef26f6c72ef7e75866fb3e5353bb21dbd/packages/react-app-polyfill#polyfilling-other-language-features
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'react-hot-loader';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { App } from './App';
import { staticEnv } from 'env';

import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './utils/store';
import ReactQueryProvider from 'utils/react-query';

import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import outerTheme from 'styles/themes/outer';
import { HelmetProvider } from 'react-helmet-async';

if (!staticEnv.LOCAL_DEBUG) {
  Sentry.init({
    dsn: 'https://9d36787e4b7943d1abca1d7807f6f509@o328734.ingest.sentry.io/5731338',
    environment: staticEnv.IS_PRODUCTION ? 'production' : 'staging',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export const store = configureStore();

function render() {
  ReactDOM.render(
    (
      <ReactQueryProvider>
        <HelmetProvider>
          <ThemeProvider theme={ outerTheme }>
            <Container maxWidth={ false }>
              <Provider store={ store }>
                <App/>
              </Provider>
            </Container>
          </ThemeProvider>
        </HelmetProvider>
      </ReactQueryProvider>
    ),
    document.getElementById('root') as HTMLElement,
  );
}

render();

// store.subscribe(render);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
