import React, { ReactNode } from 'react';
import { provisioningPersonalShipmentPath } from 'routing/paths';
// components
import Link from '@mui/material/Link';
import { CustomLink } from 'components/Links';

interface Props {
  shipmentId: string;
  children: ReactNode;
  withReturnUrl?: boolean;
}

export const ProvisioningShipmentLink = (props: Props): JSX.Element => {
  const { shipmentId, withReturnUrl, children } = props;
  return (
    <Link
      component={ CustomLink }
      color="secondary"
      to={ provisioningPersonalShipmentPath(shipmentId) }
      withReturnUrl={ withReturnUrl }
    >
      { children }
    </Link>
  );
};
