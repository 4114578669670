/* eslint-disable no-console */
import React from 'react';
import { StationStatusFlat, StationStatusTitle } from 'models/base-station-status';
// components
import { CommonStatus, Props as CommonStatusProps } from 'components/CommonStatus';

interface Props {
  stationStatus?: StationStatusFlat;
  variant?: CommonStatusProps['variant'];
}

export const HealthyStatus = ({ stationStatus, variant }: Props) => {
  let statusConfig: { label: string; color: CommonStatusProps['color'] } = {
    label: StationStatusTitle.Unhealthy,
    color: 'unknown'
  };
  const fiveMinuteAgo = new Date(Date.now() - 1000 * (60 * 5));

  if (stationStatus?.hasIssues === false) {
    statusConfig = {
      label: StationStatusTitle.Healthy,
      color: 'ok'
    };
  }
  if (stationStatus?.hasIssues === true && stationStatus?.uptime && stationStatus.uptime > fiveMinuteAgo) {
    statusConfig = {
      label: StationStatusTitle.Unhealthy,
      color: 'error'
    };
  }

  return (
    <CommonStatus
      label={ statusConfig.label }
      duration={ stationStatus?.issueChanged}
      color={ statusConfig.color }
      variant={ variant }
    />
  );
};
