import { staticEnv } from 'env';
import { useSelector } from 'react-redux';
import { Zone, Group } from 'models/device-management';
import { RootState } from 'reducers';
import { isAllowedToUpdateZones, isAllowedToDeleteZones, isAllowedToCreateZones } from 'utils/permissions';
import { isShowParkingAnomaly } from 'selectors/device-management';

// components
import {
  Box,
  Grid,
  Paper,
} from '@mui/material';
import { FieldSkeleton } from 'components/Skeleton';
import { LevelsTable } from 'components/Levels';
import { ZoneParkingAnomaly } from 'components/ParkingAnomaly';
import { ZoneFormWidget } from 'components/Zones/ZoneForm/ZoneForm';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface GeneralTabProps {
  zone?: Zone;
  groups: Group[];
  idPrefix?: string;
  returnUrl?: string;
}

const GeneralTab: React.FC<GeneralTabProps> = ({
  zone,
  groups,
}) => {
  const allowedToDeleteZone = useSelector((state: RootState) => isAllowedToDeleteZones(state.user.data));
  const allowedToUpdateZone = useSelector((state: RootState) => isAllowedToUpdateZones(state.user.data));
  const allowedToCreateZone = useSelector((state: RootState) => isAllowedToCreateZones(state.user.data));
  const showParkingAnomaly = useSelector((state: RootState) => isShowParkingAnomaly(state));

  const infoCss = useInfoBlockStyles();

  if (!zone) {
    return (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
          <Paper>
            <FieldSkeleton className={ infoCss.field } />
          </Paper>
        </Grid>
        <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
          <Paper>
            <FieldSkeleton className={ infoCss.field } />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const zoneId = zone.id as number;

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
        <Paper>
          <ZoneFormWidget zone={zone} mode="EDIT" />
        </Paper>
      </Grid>
      <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
        <Box mb={1}>
          <Paper>
            <LevelsTable
              zone={ zone }
              groups={ groups }
              allowedToUpdateLevel={ allowedToUpdateZone }
              allowedToCreateLevel={ allowedToCreateZone }
              allowedToDeleteLevel={ allowedToDeleteZone }
            />
          </Paper>
        </Box>
        { staticEnv.IS_PARKING_ANOMALY_ON && (showParkingAnomaly && <ZoneParkingAnomaly zoneId={ zoneId } />) }
      </Grid>
    </Grid>
  );
};

export default GeneralTab;
