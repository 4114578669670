import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deletePosition } from 'actions/device-management/positions';
import { RootState } from 'reducers';
import { getPosition } from 'selectors/device-management/positions';
import { dispatchAsync } from 'utils/store';

// components
import { Button } from '@mui/material';
import { ConfirmationDialog } from 'components';

// styles
import { ThemeProvider } from 'styles/utils';
import { dangerTheme } from 'styles/themes';

interface DeletePositionProps {
  deviceId?: string;
  positionId: number;
  onSuccess?: () => void;
}

const DeletePosition: React.FC<DeletePositionProps> = ({ deviceId, positionId, onSuccess }) => {
  const position = useSelector((state: RootState) => getPosition(positionId, state));
  const dispatch = useDispatch();

  return (
    <ConfirmationDialog
      title={(
        <>
          Are you sure you want to delete position{' '}
          <strong>
            {/* TODO: use the project-wide `PositionName` component */}
            {position?.network_id || positionId}
          </strong>?
        </>
      )}
      description={deviceId && (
        <>Device <strong>{deviceId}</strong> will be unbound.</>
      )}
      renderTrigger={modal => (
        <ThemeProvider theme={dangerTheme}>
          <Button color="primary" variant="contained" onClick={modal.open}>
            Delete
          </Button>
        </ThemeProvider>
      )}
      onConfirm={(modal) => {
        modal.setPending(true);

        dispatchAsync(dispatch, deletePosition(positionId))
          .then(() => {
            modal.close();
            onSuccess?.();
          })
          .catch(() => modal.setPending(false));
      }}
    />
  );
};

export default DeletePosition;
