/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Theme as DefaultTheme } from '@mui/material/styles';
import { makeStyles as muiMakeStyles } from '@mui/styles'; // eslint-disable-line no-restricted-imports
import { ClassNameMap, Styles, WithStylesOptions } from '@mui/styles/withStyles';

/**
 * The same as Material UI's makeStyles() but with `index: 1` option passed by default
 */
export function makeStyles<
  Theme = DefaultTheme,
  Props extends Record<string, unknown> = Record<string, unknown>,
  ClassKey extends string = string,
>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
): keyof Props extends never
    ? // `makeStyles` where the passed `styles` do not depend on props
    () => ClassNameMap<ClassKey>
    : // `makeStyles` where the passed `styles` do depend on props
    (props: Props) => ClassNameMap<ClassKey> {
  return muiMakeStyles(styles, { index: 1, ...options });
}
