import { getUserDetailPath } from 'routing/paths';
import { getUserFullName } from 'utils/models';

// components
import Link from '@mui/material/Link';
import { CustomLink } from 'components/Links';
import Typography, { TypographyProps } from '@mui/material/Typography';


interface Props {
  firstName: string;
  lastName: string;
  sub: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

export const UserLink = ({ firstName, lastName, sub, innerProps }: Props): JSX.Element => {
  const TypoProps = { ...defaultProps, ...innerProps };
  const userFullName = getUserFullName({ first_name: firstName, last_name: lastName });

  return userFullName
    ? (
      <Link
        component={ CustomLink }
        color="secondary"
        to={ getUserDetailPath(sub) }
        withReturnUrl
      >
        { userFullName }
      </Link>
    )
    : <Typography { ...TypoProps }>-</Typography>;
};
