import { LEVEL_NO_LEVEL_OPTION_VALUE } from 'models/device-management/dm';
import { useLevelsSelector } from 'hooks/device-management/levels';

// components
import {
  CommonProps,
  SingleProps,
  MultiProps,
  Option,
  SelectControl,
} from 'components/Controls/Select';

const NoLevelOption: Option<number> = {
  value: LEVEL_NO_LEVEL_OPTION_VALUE,
  label: 'No level',
};

type Value = number | undefined;

type LevelSelectProps =
  Partial<Omit<CommonProps<Value>, 'options'>>
  & (SingleProps<Value> | MultiProps<Value>)
  & {
    withNoLevel?: boolean;
    zoneId?: number;
  };

export const LevelSelect = (props: LevelSelectProps): JSX.Element => {
  const { zoneId } = props;
  const { levels, isLoading } = useLevelsSelector({
    params: { zones: zoneId ? [zoneId] : [] },
    skipLoading: !zoneId,
  });
  const options: Option<Value>[] = [
    ...(props.withNoLevel ? [NoLevelOption] : [{ label: 'No level', value: undefined }]),
    ...levels.map(l => ({ label: l.name, value: l.id })),
  ];

  return (
    <SelectControl
      name="levelId"
      label={ props.isMulti ? 'Levels' : 'Level' }
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ !isLoading && levels.length > 0 }
      isDisabled={ props.isDisabled || !zoneId }
    />
  );
};
