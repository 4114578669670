import { combineReducers } from 'redux';

import * as stationFlash from './station-flash';
import * as stationStatus from './station-status';

export interface State {
  stationFlash: stationFlash.State;
  stationStatus: stationStatus.State;
}

export const reducer = combineReducers<State>({
  stationFlash: stationFlash.reducer,
  stationStatus: stationStatus.reducer,
});
