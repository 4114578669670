import React from 'react';
import ReactSelect from 'components/Controls/Select/ReactSelect';
import { ValueType, SelectOption } from 'components/Controls/Select/types';
import {
  ResolvedStatuses,
  ResolvedTypes,
  DamageReasons
} from 'models/incidents';
import { ActivationStatuses, DamagedStatuses } from 'models/common';

// components
import { FormControl } from '@mui/material';

// styles
import useStyles from 'styles';

type Values = (
  | typeof ResolvedStatuses
  | typeof ResolvedTypes
  | typeof DamagedStatuses
  | typeof ActivationStatuses
  | typeof DamageReasons
);

type ValueKey = keyof Values;

type SelectedValue = string;

interface Props {
  changeHandler: (type: string | undefined) => void;
  selected: SelectedValue | undefined;
  values: Values;
  label: string;
  name: string;
  disabled?: boolean;
}

const Component = (props: Props): JSX.Element => {
  const classes = useStyles();

  const handleChange = (type: ValueType<SelectOption<SelectedValue>, false>) => {
    props.changeHandler(type ? type.value : undefined);
  };

  const getTypeOption = (type: SelectedValue) => {
    return { value: type, label: props.values[type as ValueKey] };
  };

  const valueKeys = Object.keys(props.values) as ValueKey[];

  return (
    <FormControl
      className={ classes.formControl }
    >
      <ReactSelect
        name={ props.name }
        label={ props.label }
        isDisabled={ props.disabled }
        isClearable={ true }
        isMulti={ false }
        value={ props.selected ? getTypeOption(props.selected) : undefined }
        options={ valueKeys.map(type => (
          { value: type, label: props.values[type] }
        )) }
        onChange={ handleChange }
      />
    </FormControl>
  );
};

export default Component;
