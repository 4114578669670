import { useState } from 'react';
import { useAuthUserSelector } from 'hooks';
import { validateEmail } from 'utils/validator';
import * as is from 'utils/form/validation/validators';
import { UserCreateParams } from 'models/user-management';
import { useFormActionLoader } from 'hooks/form';
import { useCreateUser } from './useCreateUser';
import { getUserListPath } from 'routing/paths';
import { UserRoleEnum } from 'models/user-management';

// components
import { FormGroup, Checkbox, Paper, FormControlLabel } from '@mui/material';
import * as Fields from 'components/Form/Field';
import { Button, Form } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';

// styles
import { ThemeProvider } from 'styles/utils';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';
import { useStyles } from './styles';

export const CreateUserForm = () => {
  const { isAdmin, data: user } = useAuthUserSelector();
  const classes = useStyles();
  const infoCss = useInfoBlockStyles();
  const { doAction } = useFormActionLoader();
  const { createUser } = useCreateUser();

  // @TODO form.setValue() not workig in SelectProject onChange, need analize or banned use Form component
  // decided to use 2 fileds - isAllProjects and projects
  const [isAllProjects, setIsAllProjects] = useState(false);

  const defaultValues = {
    owner: !isAdmin ? user.user_group : undefined,
    role: !isAdmin ? UserRoleEnum.User : undefined,
    email: '',
    permission: undefined,
    projects: [],
  };

  const handleOnSubmit = (params: UserCreateParams) => {
    doAction({
      action: createUser({ ...params, allProjects: isAllProjects }),
      onSuccess: () => ({ to: getUserListPath() }),
    });
  };

  return (<Form<UserCreateParams>
    defaultValues={defaultValues}
    validators={ {
      owner: is.required(),
      role: is.required(),
      email: email => !validateEmail(email) && 'Invalid e-mail',
      permission: is.required(),
      projects: projects => {
        return (projects.length === 0 && !isAllProjects) && 'Projects must be set';
      }
    } }
    onSubmit={ handleOnSubmit }
  >
    {form => {
      const values = form.getValues() as UserCreateParams;
      return (<Paper className={classes.wrap}>
        <BlockToolbar>
          <BlockTitle>Invite user</BlockTitle>

          <ThemeProvider theme={successTheme}>
            <Button
              color="primary"
              disabled={!form.formState.dirty}
              pending={form.formState.isSubmitting}
              type="submit"
            >
              Invite
            </Button>
          </ThemeProvider>
        </BlockToolbar>
        <FormGroup className={infoCss.fields}>
          <Fields.SelectOwner
            ControlProps={ { className: isAdmin ? infoCss.field : classes.hide } }
            name="owner"
            onChange={v => {
              if (v !== 1) {
                form.setValue('role', UserRoleEnum.User);
              }
              form.setValue('permission', '');
              form.setValue('projects', []);
            }}
          />
          <Fields.Text
            className={infoCss.field}
            label="E-mail"
            name="email"
          />
          <div className={isAdmin ?  infoCss.field : classes.hide}>
            <Fields.SelectUserRole
              name="role"
              label="Role"
              filter={ el => {
                const v = form.getValues();
                const owner = v.owner;
                // hide admin option for non Nwave users
                if (owner !== 1 && el.value === UserRoleEnum.Admin) {
                  return false;
                }
                return true;
              }}
            />
          </div>
          <Fields.UserPermission
            ControlProps={ { className: infoCss.field } }
            label="Permission"
            name="permission"
            filter={ el =>  String(values.owner) === String(el.owner_id) }
          />
          <div className={ classes.allProjects }>
            {(isAdmin || user.projects === 'all') && <FormControlLabel
              control={<Checkbox
                disabled={!values.owner}
                name="allProjects"
                checked={isAllProjects}
                onChange={(_e, checked) => {
                  setIsAllProjects(checked);
                  form.setValue('projects', []);
                  form.clearError('projects');
                }}
              />}
              label="All projects"
            />}
          </div>
          <Fields.SelectProject
            ControlProps={ {
              className: infoCss.field,
              style: { margin: '0px 8px', width: '98%' },
            } }
            name="projects"
            isMulti
            isMultiLine
            onChange={() => {
              setIsAllProjects(false);
            }}
            filter={ el => String(values.owner) === String(el.owner_id) }
          />
        </FormGroup>
      </Paper>);
    }
    }
  </Form>);
};