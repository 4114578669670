import React from 'react';
import { ThemeProvider } from 'styles/utils';

// components
import Delete from '@mui/icons-material/Delete';
import CommonButton, { CommonButtonProps } from './CommonButton';
import { dangerTheme } from 'styles/themes';

type Props = Omit<CommonButtonProps, 'label' | 'type'> & {
  label?: CommonButtonProps['label'];
  type?: CommonButtonProps['type'];
};

const DeleteButton = (props: Props): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Delete';
  const type = props.type !== undefined ? props.type : 'icon';
  return (
    <ThemeProvider theme={ dangerTheme }>
      <CommonButton
        { ...props }
        type={ type }
        label={ label }
        icon={ props.icon ?? (type === 'text' ? undefined : <Delete />) }
      />
    </ThemeProvider>
  );
};

export default DeleteButton;
