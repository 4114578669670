import { useMutation } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { getResponseData } from 'utils/clients';
import { clearQueryCache } from 'utils/react-query';
import { Device } from 'models/dms';
import { updateDevice } from 'clients/dms';


export function useDMSUpdateDeviceMutation() {
  const notifier = useFormActionNotifier();

  const mutation = useMutation({
    mutationFn: async (device: Device) => {
      const res = await updateDevice(device.id, {
        owner_id: device.owner_id,
        project_id: device.project_id ?? 0,
        comment: device.comment ?? '',
      });

      return getResponseData(res);
    },
    onError: (e) => {
      notifier.notifyError(`Error while updating device: ${ (e as Error).message }`);
    },
    onSuccess: () => {
      notifier.notifySuccess('Device has been updated');
      return clearQueryCache('dms/devices/');
    },
  });

  return {
    isLoading: mutation.isLoading,
    mutate: mutation.mutate
  };
}