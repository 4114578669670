import React from 'react';
import { OnSubmit } from 'react-hook-form';

import { useForm, FormHookOptions, FormHookResult } from 'utils/form/useForm';

// components
import { FormContext } from './FormContext';

export interface FormApi<TValues> extends FormHookResult<TValues> {
  submit: () => Promise<void>;
}

type FormProps<TValues> = FormHookOptions<TValues> & {
  children: React.ReactNode | ((form: FormApi<TValues>) => React.ReactNode);
  onSubmit: OnSubmit<TValues>;

  /** Props forwarded to <form> */
  formProps?: Omit<React.ComponentProps<'form'>, 'onSubmit'> & {
    [possibleDataProps: string]: string;
  };
}

/**
 * A component that does for us the repetitive routine that we usually do manually
 * in order to initialize forms. It passes known form options to `useForm` behind the scenes.
 *
 * As an escape hatch, it provides access to the underlying form API
 * if we pass children as a function (a.k.a render prop).
 */
export function Form<TValues>({
  children,
  onSubmit,
  formProps,
  ...restProps
}: FormProps<TValues>): JSX.Element {
  const form = useForm(restProps);

  const handleSubmit = form.handleSubmit(onSubmit);
  const formApi = { ...form, submit: handleSubmit };

  return (
    <FormContext form={form}>
      <form {...formProps} onSubmit={handleSubmit}>
        {typeof children === 'function' ? children(formApi) : children}
      </form>
    </FormContext>
  );
}
