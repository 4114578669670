import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import Link from '@mui/material/Link';
// components
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button, { ButtonProps } from '@mui/material/Button';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Fab, { FabProps } from '@mui/material/Fab';

// styles
import clsx from 'clsx';
import { styled } from 'styles/utils';
import { useStyles } from './CommonButtonStyles';

const FabProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: -6,
  left: -6,
  zIndex: 1,
}));

const FabButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  pointerEvents: 'auto',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
}));

const FabType = (props: CommonButtonProps): JSX.Element => {
  const { label, icon, onClick, isDisabled, isLoading, ButtonProps } = props;

  return (
    <FabButton
      aria-label={ typeof label === 'string' ? label : undefined }
      color="primary"
      { ...ButtonProps as FabProps }
      onClick={ (event: React.MouseEvent<HTMLButtonElement>) => onClick && onClick(event.currentTarget, event) }
      disabled={ isDisabled || isLoading }
      data-testid={ props.dataTestId || null }
    >
      { icon }
      { isLoading && <FabProgress size={ 68 } /> }
    </FabButton>
  );
};

const IconType = (props: CommonButtonProps): JSX.Element => {
  const { label, icon, onClick, isDisabled, isLoading, ButtonProps } = props;
  const classes = useStyles();

  return (
    <IconButton
      aria-label={ typeof label === 'string' ? label : undefined }
      color="primary"
      { ...ButtonProps as IconButtonProps }
      className={ clsx(props.compact && classes.compact, ButtonProps?.className) }
      onClick={ (event: React.MouseEvent<HTMLButtonElement>) => onClick && onClick(event.currentTarget, event) }
      disabled={ isDisabled || isLoading }
      data-testid={ props.dataTestId || null }
    >
      { isLoading ? <CircularProgress size={ 24 } /> : icon }
    </IconButton>
  );
};

const ButtonType = (props: CommonButtonProps): JSX.Element => {
  const { label, icon, iconPlacement = 'start', onClick, isDisabled, isLoading, ButtonProps } = props;
  const finalIcon = isLoading ? <CircularProgress size={ 20 } /> : icon;

  return (
    <Button
      color="primary"
      variant="contained"
      { ...ButtonProps as ButtonProps }
      onClick={ (event: React.MouseEvent<HTMLButtonElement>) => onClick && onClick(event.currentTarget, event) }
      disabled={ isDisabled || isLoading }
      startIcon={ iconPlacement === 'start' && finalIcon }
      endIcon={ iconPlacement === 'end' && finalIcon }
      data-testid={ props.dataTestId || null }
    >
      <span className={ props.classes?.buttonLabel }>{ label }</span>
    </Button>
  );
};

const TextType = (props: CommonButtonProps): JSX.Element => {
  const { label, icon, iconPlacement = 'start', onClick, isDisabled, isLoading, ButtonProps } = props;
  const finalIcon = isLoading ? <CircularProgress size={ 20 } /> : icon;

  return (
    <Button
      color="primary"
      variant="text"
      { ...ButtonProps as ButtonProps }
      onClick={ (event: React.MouseEvent<HTMLButtonElement>) => onClick && onClick(event.currentTarget, event) }
      disabled={ isDisabled || isLoading }
      startIcon={ iconPlacement === 'start' && finalIcon }
      endIcon={ iconPlacement === 'end' && finalIcon }
      data-testid={ props.dataTestId || null }
    >
      <span className={ props.classes?.buttonLabel }>{ label }</span>
    </Button>
  );
};

interface MuiButtonProps extends ButtonProps {
  'data-selected'?: boolean;
  'data-date-from'?: string;
  'data-date-to'?: string;
}

export interface CommonButtonProps {
  type: 'icon' | 'button' | 'text' | 'fab';
  icon?: React.ReactElement;
  iconPlacement?: 'start' | 'end';
  label: React.ReactNode;
  help?: string;
  LinkTo?: LinkProps['to'];
  onClick?: (currentTarget: HTMLButtonElement, event: React.MouseEvent) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  ButtonProps?: Partial<MuiButtonProps>;
  dataTestId?: string;
  TooltipProps?: Partial<TooltipProps>;
  classes?: {
    buttonLabel?: string;
  };
  compact?: boolean;
}

const CommonButton = (props: CommonButtonProps): JSX.Element => {
  const { type, LinkTo, TooltipProps } = props;
  const classes = useStyles();

  const ButtonProps = {
    ...props.ButtonProps,
    className: clsx(classes.root, props.ButtonProps?.className),
  };

  let result: JSX.Element = <></>;
  if ('icon' === type) {
    result = <IconType { ...props } ButtonProps={ ButtonProps } />;
  } else if ('button' === type) {
    result = <ButtonType { ...props } ButtonProps={ ButtonProps } />;
  } else if ('text' === type) {
    result = <TextType { ...props } ButtonProps={ ButtonProps } />;
  } else if ('fab' === type) {
    result = <FabType { ...props } ButtonProps={ ButtonProps } />;
  }

  if (LinkTo) {
    result = (
      <Link
        color="inherit"
        underline="none"
        component={ RouterLink }
        to={ LinkTo }
      >
        { result }
      </Link>
    );
  }

  const help = props.help || (type === 'icon' && props.label);

  if (help) {
    result = (
      <Tooltip title={ help } { ...TooltipProps }><span>{ result }</span></Tooltip>
    );
  }

  return result;
};

export default CommonButton;
