import { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
// components
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { InputAdornment, IconButton, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import { DateRange } from '@mui/icons-material';
// styles
import contentStyles from 'styles';
import { getDatePickerPaperStyles } from 'styles/themes';

interface Props {
  label: string;
  value?: Date | null;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange: (date?: Date) => void;
  error?: React.ReactNode;
  helperText?: React.ReactNode;
  isClearable?: boolean;
}

export const DateControl = (props: Props): JSX.Element => {
  const classes = contentStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <MobileDatePicker
        renderInput={ textProps => (
          <TextField
            { ...textProps }
            fullWidth
            margin="normal"
            className={ clsx(classes.formControl, props.className) }
            error={ !!props.error }
            helperText={ props.error ?? props.helperText }
            disabled={ props.isDisabled }
            required={ props.isRequired }
            onClick={ () => setOpen(true) }
            InputProps={ {
              endAdornment: !props.isDisabled && (
                <InputAdornment position="end">
                  <IconButton onClick={ () => setOpen(true) }>
                    <DateRange />
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        ) }
        open={ open }
        onClose={ () => setOpen(false) }
        label={ props.label }
        maxDate={ props.maxDate ? moment(props.maxDate) : undefined }
        minDate={ props.minDate ? moment(props.minDate) : undefined }
        // autoOk={ false }
        disableFuture={ true }
        disabled={ props.isDisabled }
        value={ props.value ? props.value : null }
        inputFormat="YYYY/MM/DD"
        mask="____/__/__"
        onChange={ () => true }
        onAccept={ date => props.onChange(date ? date.toDate() : undefined) }
        clearable={ props.isClearable }
        DialogProps={ {
          PaperProps: {
            sx: theme => ({
              ...getDatePickerPaperStyles(theme),
              '& .PrivatePickersToolbar-root .MuiTypography-root.MuiTypography-h4': {
                color: 'rgba(255, 255, 255)',
              }
            })
          }
        } }
      />
    </LocalizationProvider>
  );
};
