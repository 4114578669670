import React from 'react';
import { Template } from 'models/caller';

// components
import { Typography, TypographyProps } from '@mui/material';
import LensIcon from '@mui/icons-material/Lens';

// styles
import clsx from 'clsx';
import useContentStyles from 'styles';
import { makeStyles, createStyles } from 'styles/utils';

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  clickable: {
    textDecoration: 'underline',
  },
  statusIcon: {
    fontSize: '0.5rem',
    marginRight: theme.spacing(.5),
  },
  label: {
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export interface CallerTemplateNameDumbProps {
  templateId: number;
  template?: Template;
  statusVisible?: boolean;
  rootProps?: TypographyProps;
}

export const CallerTemplateNameDumb = ({
  templateId,
  template,
  statusVisible,
  rootProps,
}: CallerTemplateNameDumbProps): JSX.Element => {
  const templateName = template ? `${ template.name }` : `#${ templateId }`;

  const classes = useStyles();
  const contentClasses = useContentStyles();

  return (
    <Typography
      component="span"
      variant="inherit"
      data-testid="template-name"
      { ...rootProps }
      className={ clsx(
        classes.root,
        rootProps?.onClick && contentClasses.link,
        rootProps?.className,
      ) }
    >
      { template && statusVisible && (
        <LensIcon
          className={ classes.statusIcon }
          color={ template.is_active ? 'secondary' : 'error' }
        />
      ) }
      <span className={ classes.label }>
        { templateName }
      </span>
    </Typography>
  );
};
