import { useState } from 'react';
import { PositionWithLevel } from 'models/device-management';

// components
import { BlockLoader } from 'components/Loaders';
import { CommonMap } from 'components/Map';
import MarkerMap from './MarkerMapComponent';

// styles
import useStyles from './MapStyle';
import { useAutoFitMap } from 'hooks/map';

interface Props {
  positions: PositionWithLevel[];
  isLoading: boolean;
}

export const PositionsMap = ({ positions, isLoading }: Props) => {
  const classes = useStyles();
  const [select, handleSelect] = useState<number | undefined>(undefined);

  const { onLoad } = useAutoFitMap(positions);

  return (
    <div className={ classes.root }>
      { isLoading ? <BlockLoader/> :
        <CommonMap
          zoom={ 17 }
          defaultCenter={ { lat: 53.723865424172, lng: -1.8550449998982 } }
          onLoad={ onLoad }
        >
          { !!positions.length && positions.map(position => {
            return (
              <div data-testid="map-marker" key={ position.id }>
                <MarkerMap
                  select={ select }
                  handleSelect={ handleSelect }
                  position={ position }
                />
              </div>
            );
          }) }
        </CommonMap>
      }
    </div>
  );
};