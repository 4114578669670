import React from 'react';
import { useAuthUserSelector } from 'hooks';
import { isAdmin, isAllowedToUpdateDevice, isAllowedToUpdatePosition } from 'utils/permissions';
import { PositionNode } from 'hooks/device-management/useTree/types';
import { useOwnerName } from 'components/DeviceManagement/utils';
import { PositionWithLevel } from 'models/device-management';


// components
import { Grid, Paper, Typography } from '@mui/material';
import { EditButton, DeleteButton, DialogButton } from 'components/Buttons';
import { TruncatedLabel } from 'components/Labels';
import { FieldSkeleton } from 'components/Skeleton';

// styles
import useStyles from 'components/DeviceManagement/style';
import { EditDeviceDialog } from 'components/DeviceManagement';

interface Props {
  node: PositionNode;
  onEdit: (position: PositionWithLevel) => void;
}

export const PositionDeviceInfo = ({ node, onEdit }: Props): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const canBindDevice = isAllowedToUpdatePosition(user.data);
  const ownerName = useOwnerName(node.device?.owner_id ?? 0);
  const device = node.device;

  return (
    <Paper className={ classes.root } data-testid="dm-position-device-info">
      <Grid container alignItems="center">
        <Grid item lg={ canBindDevice ? 10 : 12 }>
          <Grid container spacing={ 3 } alignItems="center">
            <Grid item xs={ 2 }>
              <Typography>Bound Device</Typography>
              <Typography className={ classes.itemValue }>
                { device?.device_id ?? 'No device' }
              </Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Hardware type</Typography>
              <Typography className={ classes.itemValue }>
                { device?.hardware_type ?? 'No hardware' }
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Firmware</Typography>
              <TruncatedLabel
                label={ device?.firmware_hash ?? 'No firmware' }
                maxLength={ 30 }
                innerProps={ { className: classes.itemValue } }
              />
            </Grid>
            <Grid item xs={ 1 }>
              <Typography>Protocol</Typography>
              <Typography className={ classes.itemValue }>
                { device?.high_level_protocol_version ?? 'No protocol' }
              </Typography>
            </Grid>
            { isAdmin(user) && (
              <Grid item xs={ 1 }>
                <Typography>Owner</Typography>
                <Typography component="div" className={ classes.itemValue }>
                  { !device ? '-' : (
                    ownerName ?? <FieldSkeleton height={ 24 }/>
                  ) }
                </Typography>
              </Grid>
            ) }
            <Grid item xs={ 1 }>
              <Typography>Active</Typography>
              <Typography className={ classes.itemValue }>
                { !device ? '-' : (
                  device.activation_status ? 'Yes' : 'No'
                ) }
              </Typography>
            </Grid>
            <Grid item xs={ 1 }>
              <Typography>Damaged</Typography>
              <Typography className={ classes.itemValue }>
                { !device ? '-' : (
                  device.damaged_status ? 'Yes' : 'No'
                ) }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        { canBindDevice && (
          <Grid item lg={ 2 } className={ classes.buttonBlock }>
            { device
              ? (
                <>
                  <DialogButton
                    permission={ isAllowedToUpdateDevice }
                    button={ open => <EditButton type="text" label="Edit" onClick={ open } /> }
                    dialog={ props => (
                      <EditDeviceDialog
                        { ...props }
                        title={ `Device ${device?.device_id.toUpperCase()}` }
                        device={ device }
                        onSuccess={ () => onEdit(node.position) }
                      />
                    )}
                  />
                  <EditButton
                    type="text"
                    label="Replace"
                    onClick={ () => true }
                  />
                  <DeleteButton
                    type="text"
                    label="Unbind"
                    onClick={ () => true }
                  />
                </>
              )
              : (
                <EditButton
                  type="text"
                  label="Bind"
                  onClick={ () => true }
                />
              )
            }
          </Grid>
        ) }
      </Grid>
    </Paper>
  );
};