import React from 'react';

import { Typography, Box } from '@mui/material';
import { Info, Cancel, Check } from '@mui/icons-material';
import { DeviceStateSeverityEnumModel } from 'models/device-monitoring';

interface SeverityStatusProps {
  status: DeviceStateSeverityEnumModel;
}

const statuses = {
  [DeviceStateSeverityEnumModel.Critical]: 'Critical',
  [DeviceStateSeverityEnumModel.Info]: 'Info',
  [DeviceStateSeverityEnumModel.Ok]: 'Ok',
  [DeviceStateSeverityEnumModel.Warning]: 'Warning'
};

// TODO: move colors to global
const Icon = ({ status }: SeverityStatusProps): JSX.Element => {
  const paddingRight = 5;

  switch (status) {
    case DeviceStateSeverityEnumModel.Critical:
      return <Cancel style={ { color: '#cf4539', paddingRight } } />;
    case DeviceStateSeverityEnumModel.Ok:
      return <Check style={ { color: '#3b9c58', paddingRight } } />;
    case DeviceStateSeverityEnumModel.Info:
      return <Info style={ { color: '#43afd5', paddingRight } } />;
    case DeviceStateSeverityEnumModel.Warning:
      return <Info style={ { color: '#f8c326', paddingRight } } />;
    default:
      return <></>;
  }
};

export const SeverityStatus: React.FC<SeverityStatusProps> = ({ status }) => {
  return (
    <Box display="flex" alignItems="center">
      <Icon status={ status } />
      <Typography variant="caption" component="span">
        { statuses[status] }
      </Typography>
    </Box>
  );
};
