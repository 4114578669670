import { Moment } from "moment";

export interface ConnectivityFilters {
  limit: number;
  offset?: number;
  owner?: number;
  devices?: string[];
  positionId?: number;
  groups?: number[];
  zones?: number[];
  positionInfo?: boolean;
}

export const DEFAULT_CONNECITIVITY_FILTERS = {
  limit: undefined,
  offset: undefined,
  owner: undefined,
  devices: [],
  positionId: undefined,
  zones: [],
  groups: [],
};

export interface ConnectivityHistoryFilters {
  project_id?: number[];
  zone_id?: number[];
  group_id?: number[];
  start_time?: string;
  end_time?: string;
}

export interface ConnectivityChartData {
  connectivity: number;
  saved_on: string | Date;
};

export interface LevelConnectivityParams {
  levels?: number[];
}

export interface PositionConnectivityParams {
  positions: number[];
}
