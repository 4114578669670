import { useAuthUserSelector } from 'hooks';
import { useForm } from 'hooks/form';
import { FormProps } from 'types/form';
import { Station, StationFlashCreate, StationFrequency, StationGeneration, StationLoRaWANCreate, StationReceiverType, StationType } from 'models/base-station';
import { StationFlashFormField, StationFormField } from '../../widgets';
import { CreateStation, CreateStationFlash, CreateStationLorawan } from 'actions/base-station';
import { getBaseStationDetailPath } from 'routing/paths';
import { ActionLoaderProps } from 'components/Loaders/ActionLoader';
import { LoraFormField } from '../StationLoRaWANForm/types';

interface Props {
  doAction: (props: ActionLoaderProps) => void;
  onSuccess: () => void;
}

interface FormState<StateType, FieldEnum extends string> {
  form: FormProps<StateType, FieldEnum>;
  onSubmit: () => boolean;
  onReset: () => void;
}

export const useAddForm = ({ doAction, onSuccess }: Props): FormState<Station, StationFormField> => {
  const user = useAuthUserSelector();
  const form = useForm<Station, StationFormField>({
    initialState: {
      id: 0,
      owner_id: Number(user.data.user_group) || 0,
      generation: StationGeneration.REGULAR,
      receiver_type: StationReceiverType.FUNCUBE,
      type: StationType.station
    },
    showFields: [
      ...(user.isAdmin ? [StationFormField.owner] : []),
      StationFormField.id,
      StationFormField.generation,
      StationFormField.frequency,
      StationFormField.receiverType,
    ],
  });

  const handleSubmit = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateStation(form.state),
      onSuccess: (station) => {
        onSuccess();
        return { push: true, to: getBaseStationDetailPath(station.id) };
      },
      onError: form.catchError
    });

    return true;
  };

  return {
    form,
    onReset: form.reset,
    onSubmit: handleSubmit,
  };
};

export const useInstallForm = ({ doAction, onSuccess }: Props): FormState<StationFlashCreate, StationFlashFormField> => {
  const user = useAuthUserSelector();
  const form = useForm<StationFlashCreate, StationFlashFormField>({
    initialState: {
      id: 0,
      pin: '',
      ownerId: Number(user.data.user_group) || 0,
      receiverType: StationReceiverType.FUNCUBE,
      frequency: StationFrequency.UK_B1,
    },
    showFields: [
      ...(user.isAdmin ? [StationFlashFormField.owner] : []),
      StationFlashFormField.id,
      StationFlashFormField.pin,
      StationFlashFormField.frequency,
      StationFlashFormField.receiverType,
    ],
  });

  const handleSubmit = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateStationFlash(form.state),
      onSuccess: onSuccess,
      onError: form.catchError
    });

    return true;
  };

  return {
    form,
    onReset: form.reset,
    onSubmit: handleSubmit,
  };
};

export const useLoRaWANForm = ({ doAction, onSuccess }: Props): FormState<StationLoRaWANCreate, LoraFormField> => {
  const user = useAuthUserSelector();
  const form = useForm<StationLoRaWANCreate, LoraFormField>({
    initialState: {
      eui: '',
      owner_id: Number(user.data.user_group) || 0,
    },
    showFields: [
      LoraFormField.eui,
      LoraFormField.owner_id,
    ],
  });

  const handleSubmit = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateStationLorawan(form.state),
      onSuccess: onSuccess,
      onError: form.catchError
    });

    return true;
  };

  return {
    form,
    onReset: form.reset,
    onSubmit: handleSubmit,
  };
};
