import { GetIncidentsStartFilters, DoGetIncidentsStartFilters } from 'actions/http-incidents';
import { fetchBoundDeviceId, findDevices, findPositions } from 'clients/device-management';
import { Device, Position, ResponseReason } from 'models/device-management';

import { MainEntity, RouteParams } from './types';

export function getDeviceIncidents(deviceId: Device['device_id']): GetIncidentsStartFilters {
  return DoGetIncidentsStartFilters({ devices: [deviceId] });
}

export function getPageTitle(mainEntity: MainEntity, deviceId: string, positionId: string): string {
  switch (mainEntity) {
    case MainEntity.DEVICE:
      return `Device ${ deviceId.toUpperCase() }`;
    case MainEntity.POSITION:
      return `Position ${ positionId }`;
    default:
      return 'Device Management';
  }
}

export async function getPageDeviceId(params: RouteParams): Promise<string | null> {
  if (params.deviceId) {
    return params.deviceId;
  }

  return fetchBoundDeviceId(params);
}

export async function getPagePositionId(params: RouteParams): Promise<number | null> {
  const { positionId } = params;

  if (positionId) {
    return positionId;
  }

  if (params.networkId) {
    return findPositions({ networkIds: [params.networkId] })
      .then(res => {
        if (res.reason === ResponseReason.Ok) {
          const positions: Position[] = res.data;

          return positions[0]?.id ?? null;
        }

        throw new Error(`Fetching the bound position's ID: ${res.message}`);
      });
  }

  if (params.deviceId) {
    return findDevices({ deviceIds: [params.deviceId] })
      .then(res => {
        if (res.reason === ResponseReason.Ok) {
          const devices: Device[] = res.data;

          return devices[0]?.position_id ?? null;
        }

        throw new Error(`Fetching the bound position's ID: ${res.message}`);
      });
  }

  throw new Error(
    '[getPagePositionId] Insufficient parameters: ' +
      'Expected params to contain non-empty deviceId, positionId, or networkId'
  );
}
