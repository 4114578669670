import { Device } from 'models/dms';
import { DialogProps, useAuthUserSelector } from 'hooks';
import { useDefaultProject } from 'hooks/user-managment';
import { useDMSUpdateDeviceMutation } from 'hooks/dms';
import { Controller, useForm } from 'react-hook-form-v6';
import { Box,  Button, TextField, FormGroup } from'@mui/material';
import { CommonDialog } from 'components';
import { OwnersSingleSelectControl, ProjectSelectControl } from 'components/Controls';

interface Props {
  device: Device;
  dialog: DialogProps;
}

export const EditDialog = ({ device, dialog }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();
  const { getDefaultProject } = useDefaultProject();
  const updateDevice = useDMSUpdateDeviceMutation();
  const form = useForm({
    defaultValues: {
      owner_id: device.owner_id,
      project_id: device.project_id ?? 0,
      comments: device.comment ?? '',
    },
    mode: 'onBlur',
  });

  return (
    <CommonDialog
      isOpen={ dialog.isOpen }
      onClose={ dialog.close }
      onCloseEnd={ dialog.unmount }
      title={ `Edit Digital Sign ${ device.id }` }
      content={
        <FormGroup>
          { isAdmin && <Controller
            control={ form.control }
            name="owner_id"
            rules={ { required: true } }
            defaultValue={ device.owner_id }
            render={ props =>
              <OwnersSingleSelectControl
                { ...props }
                selected={ props.value }
                changeHandler={ async v => {
                  props.onChange(v);
                  const defaultProjectId = getDefaultProject(v)?.id;
                  form.setValue('project_id', defaultProjectId);
                  await form.trigger('project_id');
                } }
                isClearable={ false }
                error={ form.errors.owner_id && 'Required' }
              />
            }
          /> }
          <Controller
            control={ form.control }
            name="project_id"
            rules={ { required: true } }
            defaultValue={ device.project_id }
            render={ props =>
              <ProjectSelectControl
                { ...props }
                selected={ props.value }
                onChange={ v => props.onChange(v) }
                filter={ project => {
                  const owner = form.watch('owner_id');
                  return project.owner_id === owner;
                } }
                error={ !form.errors.owner_id && form.errors.project_id && 'Required' }
                isDisabled={ !!form.errors.owner_id }
              />
            }
          />
          <Controller
            control={ form.control }
            name="comment"
            defaultValue={ device.comment ?? '' }
            render={ props => <Box pt={2}>
              <TextField
                { ...props }
                label="Comment"
                style={{ width: '100%' }}
                value={ props.value }
                onChange={ v => props.onChange(v) }
                multiline
                maxRows={4}
              />
            </Box>
            }
          />
        </FormGroup>
      }
      actions={
        <>
          <Button
            color="primary"
            onClick={ () => form.reset({
              owner_id: device.owner_id,
              project_id: device.project_id ?? 0,
              comments: device.comment ?? '',
            }) }
          > Reset </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ async () => {
              const isValid = await form.trigger();
              if (isValid) {
                updateDevice.mutate({ ...device, ...form.getValues() }, {
                  onSuccess: dialog.close
                });
              }
            } }
          > Save </Button>
        </>
      }
    />
  );
};