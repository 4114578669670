import React from 'react';
// components
import MoreVert from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
// styles
import { styled } from 'styles/utils';

const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(1),
}));

interface Props {
  label?: string;
  onClick: (event: HTMLButtonElement) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  menuId?: string;
  ButtonProps?: Partial<IconButtonProps>;
}

const ActionsButton = (props: Props): JSX.Element => {
  const { label, onClick, isDisabled, isLoading, menuId, ButtonProps } = props;
  return (
    <StyledButton
      aria-label={ label === undefined ? 'Actions' : label }
      aria-controls={ menuId }
      aria-haspopup={ menuId ? 'menu' : undefined }
      color="primary"
      { ...ButtonProps }
      onClick={ (event: React.MouseEvent<HTMLButtonElement>) => onClick(event.currentTarget) }
      disabled={ isDisabled || isLoading }
    >
      { isLoading ? <CircularProgress size={ 20 }/> : <MoreVert/> }
    </StyledButton>
  );
};

export default ActionsButton;
