import { useState } from 'react';

export interface DialogProps {
  isOpen: boolean;
  close: () => void;
  unmount: () => void;
}

export interface DialogState extends DialogProps {
  isMounted: boolean;
  open: () => void;
}

export function useDialog(): DialogState {
  const [isMounted, setMounted] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  return {
    isMounted,
    isOpen,
    open: () => {
      setMounted(true);
      setOpen(true);
    },
    close: () => setOpen(false),
    unmount: () => setMounted(false),
  };
}


interface DialogWithItemState<ItemType> extends Omit<DialogState, 'open'> {
  item?: ItemType;
  open: (item: ItemType) => void;
}

export function useDialogWithItem<ItemType>(): DialogWithItemState<ItemType> {
  const dialog = useDialog();
  const [item, setItem] = useState<ItemType | undefined>(undefined);

  return {
    ...dialog,
    item,
    unmount: () => {
      dialog.unmount();
      setItem(undefined);
    },
    open: (item: ItemType) => {
      setItem(item);
      dialog.open();
    },
  };
}
