import React from 'react';
import { provisioningShipmentsPath } from 'routing/paths';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CommonButton from 'components/Buttons/CommonButton';

export const ShipmentDetailPageNotFound = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Shipment not found
      </Typography>

      <CommonButton type="button" label="Back to list" LinkTo={ provisioningShipmentsPath } icon={ <ArrowBackIcon /> } />;
    </Box>
  );
};
