import React, { useState } from 'react';

// components
import {
  Button as MUIButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Button, Dialog, DialogProps } from 'components';
import { useModalState } from 'components/Dialogs/Dialog/useModalState';

interface ConfirmHandlerOptions {
  close: () => void;
  setPending: (pending: boolean) => void;
}

export interface ConfirmationDialogProps {
  renderTrigger?: DialogProps['renderTrigger'];

  title?: React.ReactNode;
  description?: React.ReactNode;

  cancelText?: string;
  confirmText?: string;

  open?: boolean;
  pending?: boolean;

  onCancel?: () => void;
  onConfirm: (options: ConfirmHandlerOptions) => void;
  onCloseEnd?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCancel = () => {},
  onCloseEnd,
  ...restProps
}) => {
  const { open: openState, openModal, closeModal } = useModalState();
  const [pendingState, setPendingState] = useState(false);

  const open = typeof restProps.open === 'undefined'
    ? openState
    : restProps.open;

  const pending = typeof restProps.pending === 'undefined'
    ? pendingState
    : restProps.pending;

  const handleCancel = () => {
    closeModal();
    onCancel();
  };

  const handleConfirm = () => {
    setPendingState(true);

    restProps.onConfirm({
      close: () => {
        setPendingState(false);
        closeModal();
      },
      setPending: setPendingState,
    });
  };

  const titleId = 'alert-dialog-title';
  const descriptionId = 'alert-dialog-description';

  return (
    <Dialog
      disableBackdropClick={pending}
      disableEscapeKeyDown={pending}
      open={open}
      renderTrigger={restProps.renderTrigger}
      onClose={handleCancel}
      onExited={ onCloseEnd }
      onOpen={openModal}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
    >
      <DialogTitle id={titleId}>
        {restProps.title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id={descriptionId}>
          {restProps.description}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MUIButton
          color="inherit"
          disabled={pending}
          onClick={handleCancel}
        >
          {cancelText}
        </MUIButton>
        <Button
          autoFocus
          color="primary"
          pending={pending}
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
