import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { OrdinalColorsInstruction } from '@nivo/colors';
import outerTheme from 'styles/themes/outer';
import { IncidentHistory } from '../../models/device-monitoring/incidentHistory';
import { props as BarChartsOptions } from './ChartsOptions/BarChartsOptions';
import { formatAxisBottom } from './ChartsOptions/CommonOptions';

interface Props {
  failures: IncidentHistory[];
  chartData: Map<string, number | null>;
}

export const FailureChart = (props: Props): JSX.Element => {
  const { chartData } = props;
  const colors: OrdinalColorsInstruction = outerTheme.palette.error.main;

  for (const stat of props.failures) {
    const day = moment(stat.date).format('YYYY-MM-DD');
    if (chartData.has(day)) {
      chartData.set(day, stat.total);
    }
  }

  const barData: BarDatum[] = [];

  for (const [date, value] of chartData) {
    barData.push({ date, Failures: value || 0 });
  }

  const maxValue = barData.some(e => e.Failures > 10) ? 'auto' : 10;

  return (
    <Box
      data-filled={ barData.some(bar => bar.Failures > 0) ? 'true' : 'false' }
      data-testid="chart"
      width="100%"
      height="100%"
    >
      <ResponsiveBar
        { ...BarChartsOptions }
        data={ barData }
        keys={ ['Failures'] }
        colors={ colors }
        maxValue={ maxValue }
        defs={ [
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: outerTheme.palette.error.light,
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ] }
        fill={ [
          {
            match: {
              id: 'Failures'
            },
            id: 'lines'
          }
        ] }
        axisTop={ {
          ...BarChartsOptions.axisTop,
          legend: 'Detected device failures',
        } }
        axisBottom={ {
          ...BarChartsOptions.axisBottom,
          format: formatAxisBottom(barData.length, 30)
        } }
      />
    </Box>
  );
};
