import { State as UserState, ADMIN_ROLE } from 'reducers/user-profile';
import { PermissionsConfig } from 'models/user-management';
import { UserPayload } from 'actions/user-profile';

export * from './stations';
export * from './userGroup';
export * from './apiKey';
export * from './rabbit';
export * from './provisioning';

export const isAdmin = (user: UserState): boolean => user.isAdmin;

// CALLER
export const isAllowedToReadCaller = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['http.sender']?.includes('r'));
export const isAllowedToCreateCaller = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['http.sender']?.includes('c'));
export const isAllowedToUpdateCaller = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['http.sender']?.includes('u'));
export const isAllowedToDeleteCaller = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['http.sender']?.includes('d'));

// MONITORING
export const isAllowedToReadMonitoring = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['monitoring']?.includes('r'));

// RAW MESSAGES
export const isAllowedToReadRawMessage = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['raw.messages']?.includes('r'));

// FIRMWARE
export const isAllowedToReadFirmware = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['fmw']?.includes('r'));

// DEVICE MANAGEMENT ZONES
export const isAllowedToReadZones = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('r'));
export const isAllowedToCreateZones = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('c'));
export const isAllowedToUpdateZones = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('u'));
export const isAllowedToDeleteZones = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('d'));

// DEVICE MANAGEMENT LEVELS
export const isAllowedToCreateLevel = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('c'));
export const isAllowedToUpdateLevel = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('u'));
export const isAllowedToDeleteLevel = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('d'));

// DEVICE MANAGEMENT GROUPS
export const isAllowedToCreateGroup = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('c'));
export const isAllowedToDeleteGroup = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('d'));
export const isAllowedToUpdateGroup = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('u'));

// DEVICE MANAGEMENT POSITIONS
export const isAllowedToCreatePosition = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('c'));
export const isAllowedToDeletePosition = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('d'));
export const isAllowedToUpdatePosition = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('u'));

// DEVICE MANAGEMENT LABELS
export const isAllowedToCreateLabels = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.zone']?.includes('c'));

// DEVICE MANAGEMENT DEVICES
export const isAllowedToReadDevice = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.device']?.includes('r'));
export const isAllowedToUpdateDevice = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.device']?.includes('u'));
export const isAllowedToDeleteDevice = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['dm.device']?.includes('d'));

// USER
export const isAllowedToReadUsers = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['um.user']?.includes('r'));
export const isAllowedToCreateUser = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['um.user']?.includes('c'));
export const isAllowedToUpdateUser = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['um.user']?.includes('u'));
export const isAllowedToDeleteUser = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user['um.user']?.includes('d'));

// DIGITAL SIGNS
export const isAllowedToUseDigitalSigns = (user: UserPayload): boolean => user.role === ADMIN_ROLE;

// PROVISIONING
export const isAllowedToReadProvisioning = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('r'));
export const isAllowedToCreateProvisioning = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('c'));
export const isAllowedToUpdateProvisioning = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('u'));
export const isAllowedToDeleteProvisioning = (user: UserPayload): boolean => user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('d'));
