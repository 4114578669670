import { all } from 'redux-saga/effects';

import dictionarySagas from './dictionary-saga';
import baseStaionSaga from './base-station';
import callerSaga from './caller';
import rabbitSage from './rabbit';
import deviceManagementSagas from './device-management';
import { messagesSagas } from './raw-messages';
import { incidentsSagas } from './incidents_sagas';
import { userProfileSagas } from './user-profile';
import { deviceConnectivitySagas } from './device-connectivity';
import { mainStatisticSagas } from './main-statistic';
import userManagementSagas from './user-management';
import parkingSagas from './parking';
import parkingMonitoringSaga from './parking-monitoring';
import provisioning from './provisioning';
import firmwareManagement from './firmware-management';
import monitoring from './monitoring';
import deviceEmulation from './device-emulation';
import { lorawanSagas } from './lorawan';

export default function* rootSaga() {
  yield all([
    ...baseStaionSaga,
    ...callerSaga,
    ...dictionarySagas,
    ...rabbitSage,
    ...deviceManagementSagas,
    ...messagesSagas,
    ...incidentsSagas,
    ...userProfileSagas,
    ...deviceConnectivitySagas,
    ...mainStatisticSagas,
    ...userManagementSagas,
    ...parkingSagas,
    ...parkingMonitoringSaga,
    ...provisioning,
    ...firmwareManagement,
    ...monitoring,
    ...deviceEmulation,
    ...lorawanSagas,
  ]);
}
