import { AnyAction, combineReducers } from 'redux';

import { reducer as dictionaryReducer, State as DictionaryState } from './dictionary';
import { reducer as BaseStationReducer, State as BaseStationState } from './base-station';
import { reducer as CallerReducer, State as CallerState } from './caller';
import { reducer as CallerHistoryReducer, State as CallerHistoryState } from './caller-history';
import { reducer as CallerRoutersReducer, State as CallerRoutersState } from './caller-routers';
import { reducer as rabbitReducer, State as RabbitState } from './rabbit';
import { reducer as rawMessagesReducer, State as rawMessagesState } from './raw-messages';
import { reducer as NotifierStateReducer, State as NotifierState } from './notifier';
import { reducer as devicesReduces, State as DevicesState } from './device-states';
import { reducer as incidentsReducer, State as IncidentsState } from './incidents';
import { reducer as deviceManagementReducer, State as deviceManagementState } from './device-management';
import { reducer as userManagementReducer, State as userManagementState } from './user-management/index';
import { reducer as userProfileReducer, State as userProfiletState } from './user-profile';
import { reducer as deviceConnectivityReducer, State as deviceConnectivityState } from './device-connectivity';
import { reducer as mainStatisticReducer, State as mainStatisticState } from './main-statistic';
import { reducer as parkingReducer, State as parkingState } from './parking';
import { reducer as parkingMonitoring, State as parkingMonitoringState } from './parking-monitoring';
import { reducer as projects, State as projectsState } from './projects';
import { reducer as provisioningReducer, State as provisioningState } from './provisioning';
import { reducer as fwmReducer, State as fwmState } from './firmware-management';
import { reducer as monitoringReducer, State as monitoringState } from './monitoring';
import { reducer as deviceEmulationReducer, State as deviceEmulationState } from './device-emulation';
import { reducer as lorawanReducer, State as lorawanState } from './lorawan';
import * as userManagement from './user-management';
import * as pages from './pages';
import { DESTROY_USER_SESSION } from 'actions/user-profile';

export interface RootState {
  baseStation: BaseStationState;
  dictionary: DictionaryState;
  caller: CallerState;
  callerHistory: CallerHistoryState;
  callerRouters: CallerRoutersState;
  rabbit: RabbitState;
  rawMessages: rawMessagesState;
  notifications: NotifierState;
  incidents: IncidentsState;
  deviceStates: DevicesState;
  deviceManagement: deviceManagementState;
  userManagement: userManagementState;
  user: userProfiletState;
  connectivity: deviceConnectivityState;
  mainStatistic: mainStatisticState;
  um: userManagement.State;
  pages: pages.State;
  parking: parkingState;
  parkingMonitoring: parkingMonitoringState;
  projects: projectsState;
  provisioning: provisioningState;
  fwm: fwmState;
  monitoring: monitoringState;
  deviceEmulation: deviceEmulationState;
  lorawan: lorawanState;
}

const appReducer = combineReducers({
  baseStation: BaseStationReducer,
  dictionary: dictionaryReducer,
  caller: CallerReducer,
  callerHistory: CallerHistoryReducer,
  callerRouters: CallerRoutersReducer,
  rabbit: rabbitReducer,
  rawMessages: rawMessagesReducer,
  notifications: NotifierStateReducer,
  incidents: incidentsReducer,
  deviceStates: devicesReduces,
  deviceManagement: deviceManagementReducer,
  userManagement: userManagementReducer,
  user: userProfileReducer,
  connectivity: deviceConnectivityReducer,
  mainStatistic: mainStatisticReducer,
  um: userManagement.reducer,
  pages: pages.reducer,
  parking: parkingReducer,
  parkingMonitoring,
  projects,
  provisioning: provisioningReducer,
  fwm: fwmReducer,
  monitoring: monitoringReducer,
  deviceEmulation: deviceEmulationReducer,
  lorawan: lorawanReducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === DESTROY_USER_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
