import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// component
import Skeleton from '@mui/lab/Skeleton';
import Grid from '@mui/material/Grid';
import Navigation, { NavigationTab } from './Navigation';
import { useTemplateSelector } from './useTemplateSelector';

import { Dialog } from 'components/Caller/TemplateDialogs/Dialog';
import { CallerTemplateManagment, CallerTemplateOptions, TemplateDialogs } from 'components/Caller';
// style
import { useStyles } from './styles';

interface Props {
  templateId: number;
}

const TemplateDetailPage = ({ templateId }: Props) => {
  const classes = useStyles();
  const { template } = useTemplateSelector(templateId);
  const [dialog, setDialog] = useState<Dialog>(Dialog.NONE);

  return (
    <>
      <Helmet><title>{ `Http Template #${ templateId } ${ template && template.name } Detail` }</title></Helmet>
      <Navigation active={ NavigationTab.DETAIL } template={ template }/>
      <Grid className={ classes.root } container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 12 } md={ 6 } className={ classes.block }>
          { !template
            ? <Skeleton variant="rectangular"/>
            : <CallerTemplateManagment template={ template } openDialog={ setDialog }/>
          }
        </Grid>
        <Grid item xs={ 12 } sm={ 12 } md={ 6 } className={ classes.block }>
          { !template
            ? <Skeleton variant="rectangular"/>
            : <CallerTemplateOptions template={ template }/>
          }
        </Grid>
      </Grid>
      <TemplateDialogs open={ dialog } onClose={ () => setDialog(Dialog.NONE) } template={ template }/>
    </>
  );
};

export default TemplateDetailPage;
