import React from 'react';
import { Template } from 'models/caller';
import { CreateTemplate } from 'actions/caller';
import { getCallerTemplateDetailPath } from 'routing/paths/caller';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
// components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { CallerTemplateForm, TemplateFormField } from 'components/Caller';

interface Props {
  template: Template;
}

export const CallerTemplateClone = (props: Props): JSX.Element => {
  const initialTemplate = {
    ...props.template,
    template_id: 0,
    name: `CLONE ${ props.template.name }`
  };

  const form = useForm<Template, TemplateFormField>({
    initialState: initialTemplate,
    showFields: [
      TemplateFormField.name,
      TemplateFormField.templateSute,
      TemplateFormField.messageType,
      TemplateFormField.url,
      TemplateFormField.method,
      TemplateFormField.headers,
      TemplateFormField.body,
    ]
  });

  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    canSave: true,
    btnSave: 'Clone',
    btnCancel: 'Reset',
    onSave: () => {
      if (form.validate()) {
        doAction({
          action: CreateTemplate(form.state),
          onSuccess: (result) => ({
            push: true,
            to: getCallerTemplateDetailPath(result.template_suite_id, result.template_id),
          }),
          onError: form.catchError
        });
      }
    },
    onCancel: form.reset
  });

  return (
    <Card>
      <CardHeader title="Clone Template" action={ actions }/>
      <CardContent>
        <CallerTemplateForm { ...form } />
      </CardContent>
    </Card>
  );
};
