import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { formatLongText } from 'utils/format';

interface Props {
  label: string;
  maxLength: number;
  innerProps?: TypographyProps;
}

export const TruncatedLabel = ({ label, maxLength, innerProps }: Props): JSX.Element => {
  return (
    <Typography
      title={ label }
      { ...innerProps }
    >
      { formatLongText(label, maxLength) }
    </Typography>
  );
};

