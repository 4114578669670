import { LevelUpdateFields } from 'models/device-management';
import { Controller, Control, FieldError } from 'react-hook-form-v6';

// components
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';

type Props = {
  defaultValues?: Partial<LevelUpdateFields>
  control: Control<Partial<LevelUpdateFields>>;
  errors: { [x in keyof Partial<LevelUpdateFields>]: FieldError | undefined };
}

export const LevelForm = ({ errors, control }: Props) => {
  return (
    <FormGroup>
      <Controller
        render={props => <TextField
          {...props}
          label="Level name"
          placeholder="Enter level name"
          error={!!errors.name}
          helperText={errors.name && 'Required'}
          style={{ padding: '8px 0xp' }}
        />}
        name="name"
        control={control}
        rules={{ required: true }}
      />
      <br />
      <Controller
        render={props => <TextField
          {...props}
          type="number"
          label="Floor number"
          placeholder="Enter floor number"
          error={!!errors.floor_number}
          helperText={errors.floor_number && 'Required'}
          style={{ padding: '8px 0xp' }}
        />}
        name="floor_number"
        control={control}
        rules={{ required: true, valueAsNumber: true }}
      />
      <br />
    </FormGroup>);
};
