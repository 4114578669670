import { useQuery } from 'react-query';
import { GetStatParams } from 'actions/device-statistic'; // @TODO
import { useFormActionNotifier } from 'hooks';
import { ConnectivityHistoryFilters } from 'models/connectivity/filters';
import { fetchZonesOverall, fetchZonesOverallHistory } from 'clients/connectivity';
import { fetchIncidents, fetchIncidentsLog } from 'clients/monitoring';
import * as dmClient from 'clients/device-management';

function setupFiltersForConnectivity(filters: GetStatParams): ConnectivityHistoryFilters {
  return {
    ...(filters.projects.length && { project_id: filters.projects }),
    ...(filters.groups?.length && { group_id: filters.groups }),
    ...(filters.zones.length && { zone_id: filters.zones }),
    ...(filters.timeFrom && { start_time: filters.timeFrom.toISOString() }),
    ...(filters.timeTo && { end_time: filters.timeTo.toISOString() })
  };
}

type TParams = {
  params: GetStatParams
  enabled: boolean
}

export const useDevicesHealthStat = ({ params, enabled = true }: TParams) => {
  const { notifyError } = useFormActionNotifier();

  const connectivityAvg = useQuery({
    queryKey: ['devicesHealth/connectivity', params],
    queryFn: () => fetchZonesOverall(setupFiltersForConnectivity(params)),
    onError: e => notifyError(`Fetching devicesHealth/connectivity error: ${ (e as Error).message }`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled
  });

  const connectivityLog = useQuery({
    queryKey: ['devicesHealth/connectivity/history', params],
    queryFn: () => fetchZonesOverallHistory(setupFiltersForConnectivity(params)),
    onError: e => notifyError(`Fetching devicesHealth/connectivity/history error: ${ (e as Error).message }`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled
  });

  const dmStat = useQuery({
    queryKey: ['devicesHealth/dm/statistics/all', params],
    queryFn: () => dmClient.fetchAllStatistics({ ...params, metrics: undefined }),
    onError: e => notifyError(`Fetching devicesHealth/dm/statistics/all error: ${ (e as Error).message }`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled
  });

  const monitoring = useQuery({
    queryKey: ['devicesHealth/monitoring', params],
    queryFn: () => fetchIncidents(params),
    onError: e => notifyError(`Fetching devicesHealth/monitoring error: ${ (e as Error).message }`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled
  });

  const monitoringLog = useQuery({
    queryKey: ['devicesHealth/monitoringLog', params],
    queryFn: () => fetchIncidentsLog(params),
    onError: e => notifyError(`Fetching devicesHealth/monitoringLog error: ${ (e as Error).message }`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled
  });

  return {
    replacement: {
      total: dmStat.data?.data.metrics?.devices_replacements || 0,
      log: dmStat.data?.data.metrics?.replacement_log_by_date || [],
    },
    damage: {
      log: dmStat.data?.data.metrics?.damage_log_by_date || [],
      total: dmStat.data?.data.metrics?.devices_damaged_installed || 0
    },
    silents: {
      total: monitoring.data?.data?.silent_ok || 0,
      log: monitoringLog.data?.data?.silent_history || []
    },
    incidents: {
      total: monitoring.data?.data?.total_failures || 0,
      log: monitoring.data?.data?.failures_history || [],
    },
    connectivity: {
      total: connectivityAvg.data?.data.connectivity || 0,
      log: connectivityLog.data?.data
    },
    installed: dmStat.data?.data.metrics?.devices_installed || 0,
    empty: dmStat.data?.data.metrics?.positions_empty || 0,
    isFetching: connectivityAvg.isFetching || connectivityLog.isFetching || dmStat.isFetching || monitoring.isFetching || monitoringLog.isFetching,
  };
};
