import { Helmet } from 'react-helmet-async';
import { useDeviceStatesRequestParams, useDeviceStatesByQuery, useDeviceStatesExport } from './utils';

// components
import { DevicesTable } from 'components';
import { DeviceStatesTableFilter } from './FilterComponents';

// styles
import useStyles from 'styles/page';

const DeviceStates = (): JSX.Element => {
  const classes = useStyles();

  const { params, filter, updateFilter, resetFilter } = useDeviceStatesRequestParams();
  const { isLoading, data, total } = useDeviceStatesByQuery(params);
  const { isExporting, onExport } = useDeviceStatesExport(params);

  return (
    <>
      <Helmet><title>Devices states</title></Helmet>
      <div className={ classes.toolbar } >
        <DeviceStatesTableFilter
          isLoading={isLoading}
          filter={filter}
          onSave={updateFilter}
          onReset={resetFilter}
        />
      </div>
      <div className={ classes.table } >
        <DevicesTable
          isLoading={ isLoading }
          data={ data }
          total={ total }
          fetchReport={ onExport }
          csvFetching={ isExporting }
        />
      </div>
    </>
  );
};

export default DeviceStates;
