import moment from 'moment';
import { Box } from '@mui/material';
import { ResponsiveBar, BarDatum, TooltipProp } from '@nivo/bar';
import { OrdinalColorsInstruction } from '@nivo/colors';
import { colors as themeColors } from 'styles/themes/outer';
import { props as BarChartsOptions } from './ChartsOptions/BarChartsOptions';
import { StatisticRawMessage } from '../../actions/device-statistic';
import {
  dateToMonthDay,
  patternLines,
  patternLines2,
  legends,
  PatternIds,
  stackedBarTooltip,
  kFormatter,
  layers
} from './ChartsOptions/CommonOptions';

interface Props {
  data: StatisticRawMessage[];
}

enum TooltipText {
  total = 'Duplicates',
  total_unique = 'Unique',
  total_sum = 'Total',
}

function isChartFilled(data: BarDatum[]): boolean {
  return data.some(bar => bar[TooltipText.total_unique] > 0 || bar[TooltipText.total] > 0);
}

export const RawMessagesChart = (props: Props): JSX.Element => {
  const chartDuration = 30; // visible days

  const filteredData = props.data.filter(d => {
    return moment(d.demodulation_date, 'YYYY-MM-D').isAfter(moment().subtract(chartDuration, 'days'));
  });

  const dates: Map<string, { total: number; total_unique: number }> = new Map();

  for (let i = chartDuration - 1; i >= 0; i--) {
    const day = moment().subtract(i, 'days').format('YYYY-MM-DD');
    dates.set(day, { total: 0, total_unique: 0 });
  }

  for (const stat of filteredData) {
    const day = moment(stat.demodulation_date, 'YYYY-MM-D').format('YYYY-MM-DD');
    dates.set(day, {
      total_unique: stat.total_unique,
      total: stat.total
    });
  }

  const data: BarDatum[] = [];

  for (const [key, value] of dates) {
    data.push({
      date: key,
      [TooltipText.total_unique]: value.total_unique,
      [TooltipText.total]: (value.total - value.total_unique)
    });
  }

  const tooltip: TooltipProp = ({ id, value, color, data }) => {
    const total = Number(data[TooltipText.total] as string) + Number(data[TooltipText.total_unique] as string);
    return stackedBarTooltip(total, data, color, value, TooltipText.total_sum, id);
  };

  const fill = [
    {
      match: {
        id: TooltipText.total_unique
      },
      id: PatternIds.lines2
    },
    {
      match: {
        id: TooltipText.total
      },
      id: PatternIds.lines1
    }
  ];

  const keys = [TooltipText.total_unique, TooltipText.total];
  const colors: OrdinalColorsInstruction = [themeColors.mainPrimary, themeColors.mainOrange];

  return (
    <Box
      data-filled={isChartFilled(data) ? 'true' : 'false'}
      data-testid="chart"
      width="100%"
      height="100%"
    >
      <ResponsiveBar
        { ...BarChartsOptions }
        data={ data }
        keys={ keys }
        colors={ colors }
        animate={ false }
        margin={ { top: 40, right: 10, bottom: 80, left: 50 } }
        defs={ [
          { ...patternLines, color: themeColors.lightOrange },
          { ...patternLines2, color: themeColors.lightPrimary },
        ] }
        axisLeft={ {
          format: kFormatter
        } }
        fill={ fill }
        axisTop={ {
          ...BarChartsOptions.axisTop,
          legend: 'Received Raw Messages',
        } }
        tooltip={ tooltip }
        axisBottom={ {
          ...BarChartsOptions.axisBottom,
          format: dateToMonthDay,
        } }
        layers={ layers }
        legends={ [
          {
            data: keys.map((id, index) => ({
              id,
              value: index,
              color: colors[index],
              label: id
            })),
            ...legends
          }
        ]}
      />
    </Box>
  );
};
