import { apiLorawanRawMessagesPath } from 'routing/paths';
import MessageIcon from '@mui/icons-material/Message';
import { LorawanStation } from 'models/base-station';

// components
import { CommonButton } from 'components/Buttons';
// styles
import { useStyles } from './styles';

interface Props {
  station: LorawanStation;
  type?: 'text' | 'icon';
}

export const LorawanMessagesButton = ({ station, type = 'text' }: Props): JSX.Element => {
  const classes = useStyles();
  const messagePath = apiLorawanRawMessagesPath({
    gatewayEUI: station.eui
  });

  return (
    <CommonButton
      type={type}
      label="MESSAGES"
      icon={ <MessageIcon/> }
      LinkTo={ messagePath }
      ButtonProps={ type === 'text' ? { className: classes.buttonMessage } : { size: 'small' } }
    />
  );
};
