import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { usePositionStates } from './usePositionStates';
import { usePositionStatesPageParams } from './usePositionStatesPageParams';

// components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import StateTable from './StateTable/Component';
import { LegendPopover } from 'components/Popover';
import { OccupancyPageFilter } from './StateFilter';
import { ParkingPositionStateMap, ParkingPositionStateMapMarker } from './StateMap/Component';
import { PositionStateLegend } from './StateMap/Legend';
// styles
import usePositionsStyles from './style';
import useStyles from 'styles/page';

enum NavigationTab {
  CURRENT_TABLE,
  OCCUPANCY_MAP,
  DURATION_MAP,
}

const StatePage = () => {
  const classes = { ...useStyles(), ...usePositionsStyles() };
  const [activeTab, setActiveTab] = React.useState<NavigationTab>(NavigationTab.CURRENT_TABLE);

  const { params, filters, setFilters } = usePositionStatesPageParams();

  const [isSync, setIsSync] = useState(false);
  const { positionStates, isLoading, total } = usePositionStates({
    params,
    refetchInterval: isSync ? 10 * 1000 : undefined
  });

  return (
    <>
      <Helmet><title>Occupancy</title></Helmet>
      <OccupancyPageFilter
        isFetching={isLoading}
        filter={filters}
        onApply={setFilters}
      />
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Tabs
            className={ classes.navigation }
            value={ activeTab }
            onChange={ (_, value) => setActiveTab(value as NavigationTab) }
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Table" value={ NavigationTab.CURRENT_TABLE } />
            <Tab label="Occupancy Map" value={ NavigationTab.OCCUPANCY_MAP } />
            <Tab label="Duration Map" value={ NavigationTab.DURATION_MAP } />
          </Tabs>
        </Box>
        <Box display="flex">
          { activeTab !== NavigationTab.CURRENT_TABLE ?
            <>
              <LegendPopover>
                <PositionStateLegend markerType={ activeTab === NavigationTab.OCCUPANCY_MAP ? 0 : 1 } />
              </LegendPopover>

            </>
            : ''
          }
        </Box>
      </Box>
      <Paper className={ classes.content }>
        { activeTab !== NavigationTab.CURRENT_TABLE ? '' :
          <StateTable
            isLoading={ isLoading }
            offset={ params.offset }
            data={ positionStates }
            total={ total }
            sync={ {
              syncStatus: isSync,
              handleChangeStatus: () => setIsSync(!isSync)
            } }
          />
        }
        { activeTab !== NavigationTab.OCCUPANCY_MAP ? '' :
          <ParkingPositionStateMap
            filter={filters}
            markerType={ ParkingPositionStateMapMarker.OCCUPANCY }
          />
        }
        { activeTab !== NavigationTab.DURATION_MAP ? '' :
          <ParkingPositionStateMap
            filter={filters}
            markerType={ ParkingPositionStateMapMarker.DURATION }
          />
        }
      </Paper>
    </>
  );
};

export default StatePage;
