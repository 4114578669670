import { RootState } from 'reducers';
import { isUserManagementLoading } from 'selectors/user-management';
import { useSelector } from 'react-redux';


export const useLoadingSelector = (): boolean => {
  return useSelector((state: RootState) => (
    state.baseStation.stationStatus.isFetching() ||
    state.baseStation.stationFlash.isFetching() ||
    state.caller.template.isFetching() ||
    state.caller.templateStatistic.isFetching() ||
    state.caller.templateSuite.isFetching() ||
    state.callerHistory.isFetching ||
    state.callerRouters.isFetching ||
    state.rabbit.rule.isFetching() ||
    state.rabbit.endpoint.isFetching() ||
    state.rabbit.history.repository.isFetching() ||
    state.rawMessages.isFetching ||
    state.deviceEmulation.deviceSettings.isFetching() ||
    state.deviceManagement.devices.isFetching ||
    state.deviceManagement.levels.isFetching() ||
    state.deviceManagement.zones.repository.isFetching() ||
    state.deviceManagement.positionsRepository.isFetching() ||
    state.deviceManagement.positions.isFetching ||
    state.deviceManagement.positions.positionsFetching ||
    state.incidents.isFetching ||
    state.parking.positionState.isLoading ||
    state.provisioning.devices.isFetching ||
    state.provisioning.boxes.isFetching ||
    state.provisioning.shipment.isFetching ||
    isUserManagementLoading(state)
  ));
};
