import React from 'react';
import { useForm, useFormActionLoader } from 'hooks';
import { PositionNode } from 'hooks/device-management/useTree/types';
import { PositionWithLevel } from 'models/device-management';
import { updatePosition } from 'actions/device-management/positions';
import { FormField, FormState } from './manage/types';
// components
import { Grid } from '@mui/material';
import { PositionMap } from './map/PositionMap';
import { PositionManagement } from './manage/PositionManagement';


interface Props {
  node: PositionNode;
  onEdit: (position: PositionWithLevel) => void;
  onDelete: (position: PositionWithLevel) => void;
}

export const PositionMainBlock = ({ node, onEdit, onDelete }: Props): JSX.Element => {
  const position = node.position;
  const zone = node.parent.parent.parent.zone;
  const { doAction } = useFormActionLoader();
  const form = useForm<FormState, FormField>({
    initialState: {
      [FormField.ownerId]: zone.owner_id,
      [FormField.projectId]: zone.project_id,
      [FormField.zoneId]: zone.id,
      [FormField.levelId]: position.level_id,
      [FormField.groupId]: position.group_id,
      [FormField.innerId]: position.group_inner_id,
      [FormField.customId]: position.custom_id ?? '',
      [FormField.latitude]: position.lat,
      [FormField.longitude]: position.lon,
    },
    showFields: Object.values(FormField)
  });

  const handleSave = () => {
    if (!form.validate()) {
      return;
    }

    const positionUpdate: PositionWithLevel = {
      ...position,

      // updatable fields in position
      group_id: Number(form.state[FormField.groupId]),
      group_inner_id: Number(form.state[FormField.innerId]),
      custom_id: form.state[FormField.customId],
      lat: Number(form.state[FormField.latitude]),
      lon: Number(form.state[FormField.longitude]),
    };

    doAction({
      action: updatePosition(positionUpdate),
      onError: form.catchError,
      onSuccess: onEdit,
    });
  };

  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 6 }>
        <PositionManagement
          node={ node }
          form={ form }
          onEdit={ () => onEdit(position) }
          onDelete={ () => onDelete(position) }
          onSave={ handleSave }
        />
      </Grid>
      <Grid item xs={ 6 }>
        <PositionMap
          position={ node.position }
          device={ node.device }
          coordinate={ {
            lat: form.state[FormField.latitude] ?? 0,
            lng: form.state[FormField.longitude] ?? 0,
          } }
          onChangePosition={ (lat, lon) =>
            form.onChange?.({
              ...form.state,
              [FormField.latitude]: lat,
              [FormField.longitude]: lon,
            }, FormField.latitude)
          }
        />
      </Grid>
    </Grid>
  );
};