import { useState, useEffect } from 'react';
import { DEFAULT_LIMIT_OPTIONS } from 'utils/paginator';
import { TableFooter, TablePagination, TableRow } from '@mui/material';
import { useStyles } from './styles';
import { usePagination } from 'hooks';

interface Props {
  count: number;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowPerPage: number) => void;
  page?: number;
  withoutTotal?: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
}

/**
 * By default PaginationFooter work together with usePagination which work with url params
 * usePagination hook provide `{ Paginator } from utils/paginator` API
 * PaginationFooter with usePagination provide all logic you need for pagination (see usage in src/pages/RawMessages/RawMessagesPage.tsx)
 * If you want to customize logic you can use component API(see type Props above)
 */
export const PaginationFooter = (props: Props): JSX.Element => {
  const {
    count,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPage,
    rowsPerPageOptions = DEFAULT_LIMIT_OPTIONS,
    withoutTotal = false
  } = props;
  const classes = useStyles();
  const pagination = usePagination();
  const { maxReachedCount, onChangeMaxReachedCount } = pagination;

  const _page = page || pagination.page;
  const _rowsPerPage = rowsPerPage || pagination.limit;
  const [_count, _setCount] = useState(count);
  const [_maxCountReached, _setmaxCountReached] = useState(false);

  // set correct total count
  useEffect(() => {
    if (!withoutTotal) {
      _setCount(count);
      return;
    }

    // correct count for last pages and first pages with count < rowsPerPage
    if (count < _rowsPerPage) {
      _setCount((_page * _rowsPerPage) + count);
      return;
    }

    _setCount(-1);
  }, [withoutTotal, count, _page, _rowsPerPage, onChangeMaxReachedCount]);

  // save max reached count for withoutTotal pagination
  useEffect(() => {
    if (withoutTotal) {
      const currentMaxAvalibleCount  = (_page + 1) * _rowsPerPage;
      const currentMaxCount  = (_page * _rowsPerPage) + count;

      // if it's new page with new MaxAvalibleCount
      if (maxReachedCount <= currentMaxAvalibleCount) {
        // save correct maxReachedCount if it's last page (like '20-22 from 22')
        if (currentMaxCount < currentMaxAvalibleCount) {
          onChangeMaxReachedCount(currentMaxCount);
          _setmaxCountReached(true);
        } else {
          onChangeMaxReachedCount(currentMaxAvalibleCount);
          _setCount(-1);
        }
      // if we return to previus page
      } else {
        _setCount(maxReachedCount);
      }
    }
  }, [_page, _rowsPerPage, withoutTotal, count, maxReachedCount, onChangeMaxReachedCount]);

  const _onChangePage = (page: number) => {
    onChangePage?.(page);
    pagination.onChangePage(page);
  };

  return (
    <TableFooter>
      <TableRow className={ classes.pagination }>
        <TablePagination
          count={ _count }
          page={ _page }
          rowsPerPage={ _rowsPerPage }
          labelDisplayedRows={ !_maxCountReached && withoutTotal && _count !== -1 && count === _rowsPerPage ? ({ from, to, count }) => `${from}-${to} of more than ${count}` : undefined }
          rowsPerPageOptions={ rowsPerPageOptions }
          onPageChange={ (_, page) => { _onChangePage(page); }}
          onRowsPerPageChange={ e => {
            if(onChangeRowsPerPage) { onChangeRowsPerPage(Number(e.target.value)); }
            pagination.onChangeRowCount(Number(e.target.value));

            // save correct page. ex. (limit=50 offset=150) => (limit=20 offset=140)
            const newPage = Math.floor(pagination.offset / Number(e.target.value));
            onChangePage?.(newPage);
          }}
        />
      </TableRow>
    </TableFooter>
  );
};
