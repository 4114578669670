import React, { ReactNode } from 'react';

// components
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  name: string;
  label?: string;
  children: ReactNode;
  btnLabel?: string;
  isOpen: boolean;
  onClick?: () => void;
  onClose: () => void;
  onCloseEnd?: () => void;
  DialogProps?: Partial<DialogProps>;
}

const Component = (props: Props): JSX.Element => {
  const handleOk = () => {
    props.onClick && props.onClick();
    props.onClose();
  };

  return (
    <Dialog
      open={ props.isOpen }
      onClose={ props.onClose }
      TransitionProps={ { onExited: props.onCloseEnd } }
      aria-labelledby={ `dialog-${ props.name }-label` }
      aria-describedby={ `dialog-${ props.name }-description` }
      { ...props.DialogProps }
    >
      <DialogTitle id={ `dialog-${ props.name }-label` }>
        { props.label || 'For your information' }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={ `dialog-${ props.name }-description` }>
          { props.children }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleOk } color="primary" autoFocus>{ props.btnLabel || 'Ok' }</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
