import React from 'react';

// components
import { MuiDialog, Props as MuiDialogProps } from '../MuiDialog';

interface TriggerRendererOptions {
  open: () => void;
}

export interface DialogProps extends MuiDialogProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;

  renderTrigger?: (options: TriggerRendererOptions) => React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({
  onOpen,
  renderTrigger,
  ...restProps
}) => {
  return (
    <>
      {renderTrigger?.({ open: onOpen })}

      <MuiDialog {...restProps} />
    </>
  );
};
