import { EnsureDialog } from 'components/Dialogs';
import { useDialog } from 'hooks';

type Props = {
  floor: number | null
  zoneName: string
  onYes: () => void
  dialog: ReturnType<typeof useDialog>
}

export const AddLevelEnsureDialog = ({ dialog, floor, zoneName, onYes }: Props) => {
  return (<>
    { dialog.isMounted && floor && (
      <EnsureDialog
        name="dm-add-level-duplicate-dialog"
        label="Floor number not uniq"
        btnNo="Cancel"
        btnYes="Add"
        isOpen={ dialog.isOpen }
        onClose={ dialog.close }
        onCloseEnd={ dialog.unmount }
        onYes={ onYes }
      >
          Level with floor number { floor } already exists in Zone { zoneName }<br/>
          Do you want to add another one?
      </EnsureDialog>
    ) }
  </>);
};
