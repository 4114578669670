import React, { useRef, useState } from 'react';
import { ThemeProvider } from 'styles/utils';
import { useDispatch } from 'react-redux';
import { useFormActionLoader, useFormActionNotifier } from 'hooks/form';
import { fetchUsers, addUsersToProject } from 'actions/user-management/users';
import { Project, User, UserRoleEnum } from 'models/user-management';
import { commonOptions } from 'utils/tables';

// components
import { SuccessButton } from 'components/Buttons';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

// styles
import useStyles from 'components/Provisioning/styles';
import { getTableTheme } from 'styles/themes';

interface Props {
  users: User[];
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  project: Project;
}

const filterUser = (user: User, project: Project) => {
  return (
    user.user_group === project.owner_id
    && user.role !== UserRoleEnum.Admin
    && user.projects !== 'all'
    && !user.projects.includes(project.id)
  );
};

export const AddUserModal = ({ isOpen, onClose, onCloseEnd, project, users }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { doAction } = useFormActionLoader();
  const { notifySuccess } = useFormActionNotifier();
  const [error, setError] = useState<string>();
  const selected = useRef<User[]>([]);

  const handleSubmit = () => {
    if (selected.current.length === 0) {
      return false;
    }

    doAction({
      action: addUsersToProject(project.id, selected.current),
      onError: error => setError(String(error)),
      onSuccess: () => {
        dispatch(fetchUsers({ offset: 0, limit: 1000 }));
        notifySuccess(selected.current.length > 1 ? 'Users have been added' : 'User has been added');
        onClose();
      },
    });
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'user',
      label: 'Name',
      options: {
        customBodyRender: (user: User) => `${user.first_name} ${user.last_name}`
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    sortOrder: {
      name: 'user',
      direction: 'asc'
    },
    elevation: 0,
    search: true,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    onRowSelectionChange: (_currentRowsSelected, _allRowsSelected, rowsSelected) => {
      selected.current = data
        .filter((_, i) => rowsSelected && rowsSelected.includes(i))
        .map(row => row.user)
      ;
    },
  };

  const data = users.filter(user => filterUser(user, project)).map(user => {
    return { user, email: user.email };
  });

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      TransitionProps={ { onExited: onCloseEnd } }
      maxWidth={ 'sm' }
      PaperProps={ { className: classes.formDialogPaper } }
      fullWidth
    >
      <DialogContent className={ classes.dialogContentRootWithTable }>
        { error &&
          <Box m={ 2 }>
            <Typography color="error">{ error }</Typography>
          </Box>
        }
        <ThemeProvider theme={ getTableTheme() }>
          <MUIDataTable
            title={ 'Users' }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </ThemeProvider>
        <DialogActions>
          <Button color="inherit" onClick={ onClose }>
            Cancel
          </Button>
          <SuccessButton
            label={ `Add` }
            onClick={ handleSubmit }
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
