import { isAllowedToDeleteGroup, isAllowedToUpdateGroup } from 'utils/permissions';
import { GroupNode } from 'hooks/device-management/useTree/types';
import { Group } from 'models/device-management';
import { groupTypes } from 'models/device-management/dm';

// components
import { Grid, Paper, Typography } from '@mui/material';
import { DeleteButton, DialogButton, EditButton } from 'components/Buttons';
import { DeleteGroupDialog, EditGroupDialog } from 'components/DeviceManagement';
// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  node: GroupNode;
  onEdit: (group: Group) => void;
  onDelete: () => void;
}

export const GroupManagement = ({ node, onEdit, onDelete }: Props): JSX.Element => {
  const classes = useStyles();
  const group = node.group;
  const zoneName = node.parent.parent.zone.name;
  const levelName = node.parent.level?.name ?? 'no level';

  return (
    <Paper className={ classes.root } data-testid="dm-group-management">
      <Grid container alignItems="center">
        <Grid item lg={ 10 }>
          <Grid container spacing={ 1 } alignItems="center">
            <Grid item xs={ 3 }>
              <Typography>Group Name</Typography>
              <Typography className={ classes.itemValue }>{ group.name }</Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Group Type</Typography>
              <Typography className={ classes.itemValue }>
                { groupTypes[group.type] ?? '-' }
              </Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Zone</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { zoneName }
              </Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Level</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { levelName }
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Custom ID</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { group.custom_id || '-' }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={ 2 } className={ classes.buttonBlock }>
          <DialogButton
            permission={ isAllowedToUpdateGroup }
            button={ open => <EditButton type="text" label="Edit" onClick={ open }/> }
            dialog={ props =>
              <EditGroupDialog
                { ...props }
                group={ group }
                onSuccess={ onEdit }
              />
            }
          />
          <DialogButton
            permission={ isAllowedToDeleteGroup }
            button={ open => <DeleteButton type="text" label="Delete" onClick={ open }/> }
            dialog={ props =>
              <DeleteGroupDialog
                { ...props }
                group={ group }
                onSuccess={ onDelete }
              />
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
