import React from 'react';
import { useMonitoringErrorTypesDictionary } from 'hooks/monitoring';
// components
import Typography, { TypographyProps } from '@mui/material/Typography';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  errorType?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

export const IncidentErrorType = ({ errorType, innerProps }: Props) => {
  const { isLoading, errorTypes } = useMonitoringErrorTypesDictionary();
  const incidentType = errorTypes.find(x => x.id === errorType);
  const name = incidentType ? incidentType.name : '-';
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    isLoading ? <LoaderSvg/> : <Typography { ...TypoProps }>{ name }</Typography>
  );
};

