import { createStyles, makeStyles, Theme } from 'styles/utils';

const smallScale = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginRight: 0,
  }
});

export const margins = (theme: Theme) => ({
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  ...smallScale(theme)
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 350,
      paddingTop: theme.spacing(2),
    },
    toolbar: {
      marginLeft: '32px',
      marginRight: '32px',
      marginBottom: 0,
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    filter: {
      zIndex: 1000,
      marginTop: theme.spacing(1),
      marginBottom: 0,
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(-4),
      '&[class*="Mui-expanded"]': {
        marginLeft: theme.spacing(-4),
        marginRight: theme.spacing(-4),
        marginTop: theme.spacing(1),
        marginBottom: 0,
        ...smallScale(theme)
      },
      '& [class*="MuiExpansionPanelDetails-root"]': {
        paddingTop: 0
      },
      ...smallScale(theme)
    },
    table: {
      minWidth: 350,
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(-4),
      ...smallScale(theme)
    },
    nowrap: {
      whiteSpace: 'nowrap'
    },
  }),
);

export default useStyles;
