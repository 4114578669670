import React from 'react';
import { Endpoint } from 'models/rabbit';
import { useForm, useFormActionLoader } from 'hooks';
import { CreateEndpoint, TestEndpoint } from 'actions/rabbit';
// components
import { Box, Button } from '@mui/material';
import { CommonDialog } from 'components/Dialogs';
import { EndpointForm, EndpointFormField, EndpointFormState } from 'components/Rabbit';
// styles
import useStyles from '../style';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const CreateEndpointDialog = ({ isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const classes = useStyles();
  const { doAction } = useFormActionLoader();
  const form = useForm<EndpointFormState, EndpointFormField>({
    initialState: {
      [EndpointFormField.name]: '',
      [EndpointFormField.host]: '',
      [EndpointFormField.port]: 5672,
      [EndpointFormField.vhost]: '',
      [EndpointFormField.exchange]: '',
      [EndpointFormField.exchangeDurable]: false,
      [EndpointFormField.exchangeType]: 'DIRECT',
      [EndpointFormField.queue]: '',
      [EndpointFormField.login]: '',
      [EndpointFormField.password]: '',
      [EndpointFormField.passwordRepeat]: '',
      [EndpointFormField.ssl]: false,
    },
    showFields: [
      EndpointFormField.name,
      EndpointFormField.host,
      EndpointFormField.port,
      EndpointFormField.vhost,
      EndpointFormField.exchange,
      EndpointFormField.exchangeDurable,
      EndpointFormField.exchangeType,
      EndpointFormField.queue,
      EndpointFormField.login,
      EndpointFormField.password,
      EndpointFormField.passwordRepeat,
      EndpointFormField.ssl,
    ]
  });

  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    const endpoint: Endpoint = {
      id: 0,
      name: form.state[EndpointFormField.name],
      host: form.state[EndpointFormField.host],
      port: form.state[EndpointFormField.port],
      vhost: form.state[EndpointFormField.vhost],
      exchange: form.state[EndpointFormField.exchange],
      exchange_durable: form.state[EndpointFormField.exchangeDurable],
      exchange_type: form.state[EndpointFormField.exchangeType],
      queue: form.state[EndpointFormField.queue],
      login: form.state[EndpointFormField.login],
      password: form.state[EndpointFormField.password],
      ssl: form.state[EndpointFormField.ssl],
    };

    doAction({
      action: CreateEndpoint(endpoint),
      onSuccess: () => onClose(),
      onError: form.catchError,
    });
  };

  const handleTest = () => {
    if (!form.validate()) {
      return false;
    }
    const endpoint: Endpoint = {
      id: 0,
      name: form.state[EndpointFormField.name],
      host: form.state[EndpointFormField.host],
      port: form.state[EndpointFormField.port],
      vhost: form.state[EndpointFormField.vhost],
      exchange: form.state[EndpointFormField.exchange],
      exchange_durable: form.state[EndpointFormField.exchangeDurable],
      exchange_type: form.state[EndpointFormField.exchangeType],
      queue: form.state[EndpointFormField.queue],
      login: form.state[EndpointFormField.login],
      password: form.state[EndpointFormField.password],
      ssl: form.state[EndpointFormField.ssl],
    };

    doAction({
      action: TestEndpoint(endpoint),
      onError: form.catchError,
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title="Add New endpoint"
      content={ <EndpointForm { ...form }/> }
      actions={
        <Box className={ classes.actionRoot }>
          <Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={ handleTest }
            >
              Test
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={ onClose }
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={ handleSave }
              className={ classes.saveBtn }
            >
              Add
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
