import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { createZone as createZoneApi, updateZone as updateZoneApi, deleteZone as deleteZoneApi } from 'clients/device-management';
import { useFormActionNotifier } from 'hooks/form';
import { NotifyError } from 'actions/notifier';
import { fetchZones } from 'clients/device-management';
import { Position, Zone, ZoneFiltersParams } from 'models/device-management';
import { getResponseData } from 'utils/clients';
import { useGroups } from './groups';
import { sortStrings } from 'helpers';

interface ZoneQueryResult {
  isLoading: boolean;
  zone?: Zone;
}

export const useZoneForPosition = (position: Position | undefined): ZoneQueryResult => {
  const groupId = position?.group_id;
  const groupsQuery = useGroups({
    params: { groups: groupId ? [groupId] : undefined },
    enabled: Boolean(groupId),
  });

  const zoneId = groupsQuery.groups[0]?.zone_id;
  const zonesQuery = useZones({
    params: { zones: zoneId ? [zoneId] : undefined },
    enabled: Boolean(zoneId)
  });

  return {
    isLoading: groupsQuery.isLoading || zonesQuery.isLoading,
    zone: zonesQuery.zones[0],
  };
};

interface ZonesQueryResult {
  isLoading: boolean;
  zones: Zone[];
  total: number;
}

interface GetZonesHookOptions {
  params: ZoneFiltersParams;
  enabled?: boolean;
}

export function useZones({ params, enabled }: GetZonesHookOptions): ZonesQueryResult {
  const dispatch = useDispatch();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/zones', params],
    queryFn: async () => {
      const res = await fetchZones(params);
      return {
        data: getResponseData(res),
        total: res.total
      };
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      dispatch(NotifyError(`Error while fetching zones: ${ (e as Error).message }`));
    },
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    zones: queryResult.data?.data ? queryResult?.data.data.sort((a, b) => sortStrings(a.name, b.name)) : [],
    total: queryResult.data?.total || 0,
  };
}

export const useCreateZone = (onSuccess?: (zone: Zone) => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isCreateZoneFetching, setIsCreateZoneFetching] = useState(false);
  const queryClient = useQueryClient();

  const createZone = async (params: Partial<Zone>) => {
    try {
      setIsCreateZoneFetching(true);
      const res = await createZoneApi({
        ...params,
      }, true);
      setIsCreateZoneFetching(false);
      notifySuccess('Zone create successfully');
      await queryClient.refetchQueries(['deviceManagement/zones']);
      onSuccess && onSuccess(res.data);
    } catch(e) {
      setIsCreateZoneFetching(false);
      notifyError('Error while creating zone');
    }
  };

  return {
    isCreateZoneFetching,
    createZone
  };
};

type UseUpdateZoneParams = {
  onSuccess?: (zone: Partial<Zone>) => void,
  onError?: () => void
}

export const useUpdateZone = ({
  onSuccess,
  onError
}: UseUpdateZoneParams) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isUpdateZoneFetching, setIsUpdateZoneFetching] = useState(false);

  const updateZone = async (id: number, params: Partial<Zone>) => {
    try {
      setIsUpdateZoneFetching(true);
      await updateZoneApi({
        id,
        props: params,
      }, true);
      setIsUpdateZoneFetching(false);
      notifySuccess('Zone update successfully');
      onSuccess && onSuccess(params);
    } catch(e) {
      setIsUpdateZoneFetching(false);
      notifyError('Error while updating zone');
      onError && onError();
    }
  };

  return {
    isUpdateZoneFetching,
    updateZone
  };
};

export const useDeleteZone = (onSuccess?: (zone: Zone) => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isDeleteZoneFetching, setIsDeleteZoneFetching] = useState(false);

  const deleteZone = async (id: number) => {
    try {
      setIsDeleteZoneFetching(true);
      const res = await deleteZoneApi(id, true);
      setIsDeleteZoneFetching(false);
      notifySuccess('Zone delete successfully');
      onSuccess && onSuccess(res.data);
    } catch(e) {
      setIsDeleteZoneFetching(false);
      notifyError('Error while deleting zone');
    }
  };

  return {
    isDeleteZoneFetching,
    deleteZone,
  };
};