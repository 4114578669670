import { useState } from 'react';
import { createUser as createUserApi } from 'clients/user-management';
import { UserCreateParams } from 'models/user-management';
import { ReasonEnum } from 'models/apiResponse';
import { useFormActionNotifier } from 'hooks/form';

export const useCreateUser = () => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const createUser = async (params: UserCreateParams) => {
    try {
      setIsFetching(true);
      const response = await createUserApi({
        ...params,
        projects: params.allProjects ? 'all' : params.projects
      });
      if (response.reason === ReasonEnum.Ok) {
        notifySuccess('User create successfully');
        return;
      }

      let message = response.message || 'Server error';
      if (message.startsWith('Found 1 users with email')) {
        message = 'User already exists';
      }

      notifyError(`Error while creating a user: ${ message }`);
      throw new Error(message);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    createUser
  };
};