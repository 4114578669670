import { StationLocation } from 'models/base-station';

export const BASE_STATION_LOCATION_CREATE = 'BASE_STATION_LOCATION_CREATE';
export const BASE_STATION_LOCATION_UPDATE = 'BASE_STATION_LOCATION_UPDATE';
export const BASE_STATION_LOCATION_DELETE = 'BASE_STATION_LOCATION_DELETE';

export interface CreateStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_CREATE;
  location: StationLocation;
}

export interface UpdateStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_UPDATE;
  location: StationLocation;
}

export interface DeleteLocation {
  readonly type: typeof BASE_STATION_LOCATION_DELETE;
  location: StationLocation;
}

export const CreateStationLocation = (location: StationLocation): CreateStationLocation => ({
  type: BASE_STATION_LOCATION_CREATE, location
});

export const UpdateStationLocation = (location: StationLocation): UpdateStationLocation => ({
  type: BASE_STATION_LOCATION_UPDATE, location
});

export const DeleteLocation = (location: StationLocation): DeleteLocation => ({
  type: BASE_STATION_LOCATION_DELETE, location
});

export type Action =
  | CreateStationLocation
  | UpdateStationLocation
  | DeleteLocation
