import React from 'react';
import { uniq, isNil } from 'lodash';
import { Endpoint } from 'models/rabbit';
import { useRabbitRuleDictionarySelector } from 'hooks/rabbit';
import { useZones } from 'hooks/device-management';
// components
import { AllSelectControlMui, SelectOption } from 'components/Controls';
import { Box } from '@mui/material';
// styles
import useStyles from '../style';

interface Props {
  endpoint: Endpoint;
  selected?: number[];
  onChange: (value?: number[]) => void;
}

export const ZoneSelect = ({ endpoint, selected, onChange }: Props): JSX.Element => {
  const classes = useStyles();
  const { rules } = useRabbitRuleDictionarySelector();
  const { zones } = useZones({ params: {} });

  const zoneOption: SelectOption<number>[] = [];
  const uniqZone = uniq(rules.filter(r => r.endpoint_id === endpoint.id).map(r => r.zone_id));
  uniqZone.forEach(zoneId => {
    const zone = zones.find(z => z.id === zoneId);
    if (zone) {
      zoneOption.push({ label: zone.name, value: zone.id });
    }
  });

  return (
    <Box className={ classes.filterControl }>
      <AllSelectControlMui<number>
        name="zones"
        allOptionLabel="All zones"
        options={ zoneOption }
        selected={ isNil(selected) ? { type: 'all' } : { type: 'options', values: selected } }
        onChange={ value => onChange(value.type === 'all' ? undefined : value.values) }
      />
    </Box>
  );
};
