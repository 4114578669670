// components
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ZoneFormWidget } from 'components/Zones/ZoneForm/ZoneForm';

export const CreateZone = (): JSX.Element => {
  return (
    <Box p={ 2 }>
      <Helmet>
        <title>Create zone</title>
      </Helmet>
      <Paper>
        <ZoneFormWidget/>
      </Paper>
    </Box>
  );
};
