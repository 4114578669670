import clsx from 'clsx';
import React from 'react';
import { RouteConfigChildrenProps } from 'react-router-config';
import { getLocation } from 'utils/routing/query';

// components
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { renderRouteLabel } from 'utils/routing';

import MenuIcon from './MenuIcon';

// styles
import useStyles from '../styles';

const MainMenuLink: React.FC<RouteConfigChildrenProps> = ({ match, route }) => {
  const { path, pathParams, icon } = route;
  const classes = useStyles({});

  if (!path) {
    return null;
  }

  const location = getLocation(path, pathParams ?? {});

  return (
    <Link to={location} className={classes.noUnderline}>
      <ListItem
        button={true}
        className={clsx((match && match.isExact) ? classes.activeLink : '', classes.links)}
      >
        <ListItemIcon>
          <MenuIcon icon={icon} match={match} />
        </ListItemIcon>
        <ListItemText primary={renderRouteLabel({ match, route })} />
      </ListItem>
    </Link>
  );
};

export default MainMenuLink;
