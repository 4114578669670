import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_LIMIT } from 'models';
import { GetTemplateSuitesParams } from 'models/caller';
import { DoRoutesTemplatesSuitesLoad } from 'actions/caller';
import { RootState } from 'reducers';
import { TemplateRouteSuite } from 'reducers/caller/routes-templates-suites';


const filterByParams = (params: GetTemplateSuitesParams, suites: TemplateRouteSuite[]): TemplateRouteSuite[] => {
  return suites.filter(suite => {
    if (params.owner !== undefined && params.owner !== suite.owner_id) {
      return false;
    }

    if (params.name !== undefined && !suite.name.includes(params.name)) {
      return false;
    }

    return true;
  });
};


export const useTemplateSuites = (params: GetTemplateSuitesParams) => {
  const dispatch = useDispatch();
  useEffectOnce(() => {
    // TemplatesSuites not support filters, load all
    dispatch(DoRoutesTemplatesSuitesLoad({ limit: 999 }));
  });

  const { isFetched, data } = useSelector((state: RootState) => ({
    isFetched: state.caller.templatesRoutesSuites.isFetched,
    data: state.caller.templatesRoutesSuites.data
  }));
  const items = filterByParams(params, data);

  return {
    isLoading: isFetched !== true,
    data: items.slice(params.offset ?? 0, (params.offset ?? 0) + (params.limit ?? DEFAULT_LIMIT)),
    total: items.length
  };
};