import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useLocationItems } from 'hooks/location';
import { useDMSListDeviceQuery } from 'hooks/dms';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { DEFAULT_LIMIT } from 'models';
import { Device } from 'models/dms';


export interface PageQueryParam {
  owner_id?: number;
  project_id?: number[];
  limit: number;
  offset: number;
}

const initialParam: PageQueryParam = {
  owner_id: undefined,
  project_id: undefined,
  limit: DEFAULT_LIMIT,
  offset: 0,
};

const buildQueryParam = (items: UrlItems): PageQueryParam => {
  const parser = new Parser(items as PageQueryParam);
  return {
    owner_id: parser.asNumber('owner_id') ?? initialParam.owner_id,
    project_id: parser.asNumbers('project_id') ?? initialParam.project_id,
    limit: parser.asNumber('limit') ?? initialParam.limit,
    offset: parser.asNumber('offset') ?? initialParam.offset,
  };
};

interface PageData {
  queryParam: PageQueryParam;
  setQueryParam: (update?: PageQueryParam) => void;
  isLoading: boolean;
  devices: Device[];
}

export const useDeviceLedData = (): PageData => {
  const { items, setItems } = useLocationItems();
  const [queryParam, setQueryParam] = useState<PageQueryParam>(() => buildQueryParam(items));
  useDeepCompareEffect(() => setQueryParam(buildQueryParam(items)), [items]);

  const { isLoading, devices } = useDMSListDeviceQuery({
    project_id: queryParam.project_id,
    owner_id: queryParam.owner_id,
    limit: queryParam.limit,
    offset: queryParam.offset,
  });

  return {
    queryParam: queryParam,
    setQueryParam: (update?: PageQueryParam) => {
      const updated = update ?? initialParam;
      setItems({ ...updated, limit: queryParam.limit, offset: 0 });
    },
    isLoading: isLoading,
    devices: devices,
  };
};




