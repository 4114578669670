import React from 'react';

import { Project } from 'models/user-management';
import { useProjectsDictionarySelector } from 'hooks/user-managment';
import { ProjectsBag } from 'pages/UserManagement/Users/utils';

// components
import { Chip } from '@mui/material';
import { styled } from 'styles/utils';
import { Skeleton } from '@mui/lab';

const ChipGrid = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(-0.4)}px 0`,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& > *': {
    margin: theme.spacing(0.5),
  },
}));

interface ProjectsListProps extends React.ComponentPropsWithoutRef<typeof ChipGrid> {
  entries: Project['id'][];
}

const ProjectsList: React.FC<ProjectsListProps> = ({ entries, ...restProps }) => {

  const { projects: projectsData, isLoading: projectsFetching } = useProjectsDictionarySelector();
  const projects: ProjectsBag = {
    byId: Object.fromEntries(projectsData.map(p => [p.id, p])),
    loading: projectsFetching,
  };

  return (
    <ChipGrid { ...restProps }>
      { entries.map(projectId => {
        const project = projects.byId[projectId];

        if (project) {
          return <Chip label={ project.name } key={ projectId } />;
        }

        if (projects.loading) {
          return <Skeleton width={ 70 } height={ 30 } key={ projectId } />;
        }

        return <Chip label={ `#${projectId}` } variant="outlined" key={ projectId } />;
      }) }
    </ChipGrid>
  );
};

export default ProjectsList;
