import React from 'react';

import {
  createFieldFromRegularInput,
  createFieldFromSelectorWithError,
  InputProps,
} from 'components/Form/Field/field-creators';
import { getMuiErrorProps } from 'components/Form/Field/utils';

// components
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { SingleSelectControl, MultiSelectControl } from 'components/Controls/Select';
import { TimezoneSelectControl } from 'components/Controls';

// TODO: add `LabelledSwitch` component to support error state
export const Switch = createFieldFromRegularInput<MuiSwitchProps & InputProps>(
  ({ value, ...props }) => <MuiSwitch {...props} checked={value} />,
);

export const Text = createFieldFromRegularInput<TextFieldProps & InputProps>(
  ({ errors, ...props }) => <TextField {...props} {...getMuiErrorProps(errors)} />,
);

export const SelectSingle = createFieldFromSelectorWithError(SingleSelectControl);

export const SelectMulti = createFieldFromSelectorWithError(MultiSelectControl);

export const SelectTimezone = createFieldFromSelectorWithError(TimezoneSelectControl);
