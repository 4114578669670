import { StationLocation } from 'models/base-station';
import { Address } from './types';

export const formatLocationTitle = (location: StationLocation): string =>
  [location.country, location.city, location.address]
    .filter(v => !!v)
    .join(', ');


export const isEmptyLocation = (location: StationLocation): boolean => (
  location.country === ''
  && location.city === ''
  && location.address === ''
);

export const changeLocationByAddress = (location: StationLocation, address: Address): StationLocation => {
  return {
    ...location,
    lat: address.lat,
    lon: address.lon,
    country: address.country ?? location.country,
    city: address.city ?? location.city,
    address: `${ address.building ?? '' } ${ address.street ?? '' }`.trim() || location.address,
    postcode: address.postcode ?? location.postcode,
  };
};

export const getAddressByLocation = (location?: StationLocation): Address | undefined => {
  if (location?.lat === undefined || location?.lon === undefined) {
    return undefined;
  }

  return {
    id: String(location.id),
    title: [location.country, location.city, location.address].filter(Boolean).join(', '),
    lat: location.lat,
    lon: location.lon,
    country: location.country,
    city: location.city,
    street: location.address,
    postcode: location.postcode,
  };
};
