import { apiRawMessagesPath } from 'routing/paths';
import { EnumRawMessagesFields } from 'models/raw-messages';
import { Station } from 'models/base-station';

// components
import { CommonButton } from 'components/Buttons';
// styles
import { useStyles } from '../../styles';

interface Props {
  station: Station;
}

export const MessageButton = ({ station }: Props): JSX.Element => {
  const classes = useStyles();
  const messagePath = apiRawMessagesPath({
    [EnumRawMessagesFields.bsid]: station.id
  });

  return (
    <CommonButton
      type="text"
      label="MESSAGES"
      LinkTo={ messagePath }
      ButtonProps={ { className: classes.buttonMessage } }
    />
  );
};
