import { reinvite } from 'clients/user-management';
import { useFormActionNotifier } from 'hooks';

export const useUserReinvite = () => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const handleReinvite = async (userSub: string) => {
    try {
      await reinvite(userSub);
      notifySuccess('Invite sended succesfully');
    } catch(e) {
      notifyError('Send invite failed');
    }
  };

  return {
    handleReinvite
  };
};
