import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormActionNotifier } from 'hooks';
import { useLorawanMessages } from 'hooks/useLorawanMessagesApi';
// components
import { LorawanMessagesTable } from './LorawanMessagesTable';
import { LorawanMessagesFilter } from './LorawanMessagesFilter';
import { initLoRaWANRawMessagesFilters, LorawanMessage } from 'models';
import { setIsNewForSyncDataUpdate } from 'utils/data-normalizers';
import { useLorawanMessagesParams } from './useLorawanMessagesParams';

export const LorawanMessagesPage = () => {
  const { notifySuccess } = useFormActionNotifier();
  const { filters, applyFilters, limit, offset } = useLorawanMessagesParams();

  const [isSync, setIsSync] = useState(false);
  const { lorawaMessages, total, isLoading } = useLorawanMessages({ ...filters, limit, offset }, isSync);
  // sync logic
  const [prevData, setPrevData] = useState<LorawanMessage[]>([]);
  const [data, setData] = useState<LorawanMessage[]>([]);
  useEffect(() => {
    if (isSync) {
      const dataWithNew = setIsNewForSyncDataUpdate(lorawaMessages, prevData, el => el.trace_id);
      setData(dataWithNew);
      setPrevData(lorawaMessages);
    } else {
      setData(lorawaMessages);
      setPrevData(lorawaMessages);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lorawaMessages, setPrevData, isSync]);

  return (
    <>
      <Helmet>
        <title>Lorawan Messages</title>
      </Helmet>
      <LorawanMessagesFilter
        defaultFilters={filters}
        autoUpdate={isSync}
        onSave={filters => {
          applyFilters(filters);
        }}
        onReset={() => {
          applyFilters(initLoRaWANRawMessagesFilters);
        }}
      />
      <LorawanMessagesTable
        data={ data }
        total={ total }
        fetching={ isLoading }
        isSync={isSync}
        setIsSync={ () => {
          if (!isSync) {
            notifySuccess('Synchronization has been started');
          } else {
            notifySuccess('Synchronization has been stopped');
          }
          setIsSync(!isSync);
        }}
      />
    </>
  );
};
