import React from 'react';
import { WorkOff } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

interface Props {
  open: () => void;
  show: boolean;
}

export const RemoveFromShipmentButton = ({ open, show }: Props): JSX.Element => (
  show
    ? <Tooltip title="Remove box from the shipment">
      <IconButton onClick={ open } color="primary">
        <WorkOff />
      </IconButton>
    </Tooltip>
    : <></>
);
