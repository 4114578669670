import React from 'react';
// components
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const BlockLoader = ({ size = 60 }: { size?: number }): JSX.Element => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" height="100%">
      <CircularProgress size={ size } color="primary" />
    </Box>
  );
};
