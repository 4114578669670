import React from 'react';
import { Link } from 'react-router-dom';
import { TemplateSuite } from 'models/caller';
import { getCallerTemplateSuiteDetailPath, getCallerTemplateSuiteStatisticPath, getCallerTemplateSuiteTemplatesPath } from 'routing/paths';
// components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabsSkeleton } from 'components/Skeleton';

export enum NavigationTab {
  DETAIL,
  TEMPLATES,
  STATISTIC,
}

interface Props {
  templateSuite?: TemplateSuite;
  active?: NavigationTab;
}

const Component = (props: Props): JSX.Element => {
  const { templateSuite, active } = props;
  if (!templateSuite) {
    return <TabsSkeleton tabsCount={ 2 }/>;
  }

  const suiteId = String(templateSuite.template_suite_id);

  return (
    <Tabs value={ active ?? false } indicatorColor="primary" textColor="primary">
      <Tab
        component={ Link }
        to={ getCallerTemplateSuiteDetailPath(suiteId) }
        label="Suite"
        key={ NavigationTab.DETAIL }
        id={ `tab-${ NavigationTab.DETAIL }` }
        aria-controls={ `tabpanel-${ NavigationTab.DETAIL }` }
      />
      <Tab
        component={ Link }
        to={ getCallerTemplateSuiteTemplatesPath(suiteId) }
        label="Templates"
        key={ NavigationTab.TEMPLATES }
        id={ `tab-${ NavigationTab.TEMPLATES }` }
        aria-controls={ `tabpanel-${ NavigationTab.TEMPLATES }` }
      />
      <Tab
        component={ Link }
        to={ getCallerTemplateSuiteStatisticPath(suiteId) }
        label="Statistic"
        key={ NavigationTab.STATISTIC }
        id={ `tab-${ NavigationTab.STATISTIC }` }
        aria-controls={ `tabpanel-${ NavigationTab.STATISTIC }` }
      />
    </Tabs>
  );
};

export default Component;
