import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import{ getDateParams } from 'models/base-station-status';
import { LorawanStation } from 'models/base-station';
import { useFormActionNotifier } from 'hooks/form';
import { ensureRequestSucceeded } from 'utils/clients';
import { clearQueryCache } from 'utils/react-query';
import { getLorawanStationsByParams, getLorawanStationsById, updateLoraStation } from 'clients/base-stations';
import {
  getLorawanStationStatusById,
  getLorawanStationStatusHistoryById,
  getLorawanStationLastMessage
} from 'clients/base-stations-status';
import { MonitoringDateRange } from 'models/base-station-status';
import  {
  normalizeStations,
  getStatuses,
  normalizeLorawanStationStatusHistoryRes,
  mapStatusToStation
} from './normalizers';
import { ApiResponseWithTotal } from 'models';

export interface LorawanStationsState {
  stations: LorawanStation[];
  isLoading: boolean;
  total: number;
}

export function useLoraWANStationsDictionarySelector(enabled = true): LorawanStationsState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['lorawanBaseStation/stations'],
    queryFn: async (): Promise<ApiResponseWithTotal<LorawanStation[]>> => {
      const stationsRes: ApiResponseWithTotal<LorawanStation[]> = await getLorawanStationsByParams({ limit: 999 });
      const stations = normalizeStations(stationsRes.data);
      const ids = stations.map(s => s.id);
      const statusesRes = await getLorawanStationStatusById(ids);
      const resultStations =  mapStatusToStation(stations, statusesRes.data);
      return {
        ...stationsRes,
        data: resultStations,
      };
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching LoRaWAN base stations: ${ (e as Error).message }`),
    enabled,
  });

  return {
    stations: queryResult.data?.data || [],
    isLoading: queryResult.isLoading,
    total: queryResult.data?.total ?? 0,
  };
}

export const useLorawanStationStatus = (stationIds: number[]) => {
  const { notifyError } = useFormActionNotifier();
  const query = useQuery({
    queryKey: ['lorawanBaseStation/station/status', stationIds],
    queryFn: async () => {
      const result = await getLorawanStationStatusById(stationIds);
      ensureRequestSucceeded(result);
      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching Lorawan base station status by Id: ${ (e as Error).message }`),
    refetchInterval: 100000, // @TODO render problem
    enabled: stationIds.length > 0,
  });
  return {
    statuses: query.data?.data,
    isLoadingStatus: query.isLoading
  };
};

export const useLorawanStationLastMessage = (stationId: number) => {
  const { notifyError } = useFormActionNotifier();

  const query = useQuery({
    queryKey: ['lorawanBaseStation/station/last-message'],
    queryFn: async () => {
      const result = await getLorawanStationLastMessage(stationId);
      ensureRequestSucceeded(result);
      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching Lorawan base station status by Id: ${ (e as Error).message }`),
  });
  return {
    stationId,
    lastMsg: query.data?.data ? query.data?.data[0].value : '',
    isLoading: query.isLoading
  };
};

export function useLorawanStationsById(stationId: number, enabled = true) {
  const { notifyError } = useFormActionNotifier();

  const lastMsgQuery = useLorawanStationLastMessage(stationId);

  const { statuses, isLoadingStatus } = useLorawanStationStatus([stationId]);

  const queryResult = useQuery({
    queryKey: ['lorawanBaseStation/station', stationId],
    queryFn: async (): Promise<ApiResponseWithTotal<LorawanStation[]>> => {
      const result: ApiResponseWithTotal<LorawanStation[]> = await getLorawanStationsById(stationId);
      ensureRequestSucceeded(result);
      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching LoRaWAN base station by Id: ${ (e as Error).message }`),
    enabled,
  });
  const stationInfo = normalizeStations(queryResult.data?.data)[0];

  return {
    station: {
      ...stationInfo,
      status: getStatuses(statuses),
      lastMsg: lastMsgQuery.lastMsg
    },
    isLoading: queryResult.isLoading || isLoadingStatus || lastMsgQuery.isLoading,
  };
}

export const useLorawanStationUpdate = () => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isUpdating, setIsUpdating] = useState(false);
  const updateLorawanStation = useCallback(async (station: LorawanStation) => {
    setIsUpdating(true);
    try {
      await updateLoraStation(station.id, station);
      await clearQueryCache('lorawanBaseStation/station');
      notifySuccess(`Lorawan station #${ station.id } has been updated`);
    } catch(e) {
      notifyError(`Error while updating a lorawan station ${ station.id }`);
    } finally {
      setIsUpdating(false);
    }
  }, [notifySuccess, notifyError]);

  return {
    isUpdating,
    updateLorawanStation
  };
};



type LorawanStatusHistoryParams = {
  id?: number;
  dateRange: MonitoringDateRange;
};

export const useLorawanStationStatusHistory =  ({ id, dateRange }: LorawanStatusHistoryParams) => {
  const { notifyError } = useFormActionNotifier();
  const dates = getDateParams(dateRange);

  const queryResult = useQuery({
    queryKey: ['lorawanBaseStation/station/status-history', id, dateRange],
    queryFn: async () => {
      const result = await getLorawanStationStatusHistoryById({
        id,
        dateFrom: dates.dateFrom.toISOString(),
        dateTo: dates.dateTo.toISOString(),
      });
      ensureRequestSucceeded(result);
      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching Lorawan base station status history: ${ (e as Error).message }`),
    enabled: Boolean(String(id))
  });
  return {
    data: normalizeLorawanStationStatusHistoryRes(queryResult.data?.data),
    fetched: queryResult.isFetched,
    dateRange: dates
  };
};