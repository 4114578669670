import React from 'react';

import { CustomIdSelect } from 'components/Controls';
import { stringAsUUID } from 'helpers';

interface Props {
  changeHandler: (chosenNetworkId: string[]) => void;
  selected: string[];
  name?: string;
  disabled?: boolean;
}

export const NetworkIdSelect = (props: Props): JSX.Element => {
  return (
    <CustomIdSelect
      type="string"
      name={ props.name ?? 'Position Network ID' }
      disabled={ props.disabled }
      selected={ props.selected }
      changeHandler={ props.changeHandler }
      mapInputValue={ value => value.replace(/[^A-Za-z0-9]+/g, '') }
      formatValue={ stringAsUUID }
      helperText={ stringAsUUID }
    />
  );
};
