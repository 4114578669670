import React from 'react';
// components
import { Button, Typography } from '@mui/material';
import { SettingsBackupRestore } from '@mui/icons-material';

interface Props {
  onReset: () => void;
  count: number;
}

export const ResetFiltersButton = ({ onReset, count }: Props): JSX.Element => {
  if (count === 0) {
    return <></>;
  }
  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={ <SettingsBackupRestore color="action" /> }
      onClick={ onReset }
    >
      <Typography color="textSecondary">Reset</Typography>
    </Button >
  );
};
