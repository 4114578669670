import { ReactNode } from 'react';
// components
import { Button } from '@mui/material';
import { CommonDialog } from 'components/Dialogs';
// styles
import useStyles from 'styles/filters';

interface Props {
  isLoading: boolean;
  isValid?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSave: () => void;
  onReset: () => void;
  children: ReactNode;
}

export const Dialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isLoading, isValid, isOpen, onClose, onCloseEnd, onSave, onReset, children } = props;

  return (
    <CommonDialog
      testId="filters-block"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ 'Add filters' }
      content={ children }
      actions={
        <>
          <Button
            color="primary"
            disabled={ isLoading }
            onClick={ onReset }
          >Reset</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={ isLoading || !isValid }
            onClick={ onSave }
            className={ classes.buttonText }
          >Save</Button>
        </>
      }
    />
  );
};
