import { uniqWith } from 'lodash';
import React from 'react';

import { Device, SoftType } from 'models/device-management';
import { isAllowedToUpdateDevice } from 'utils/permissions';
import { nameof } from 'utils/type-checking';
import { useAuthUserSelector, useDialog } from 'hooks';
import { FormHookResult } from 'utils/form';
import { isDeviceVirtual } from 'utils/models';
import { useFirmwaresDictionarySelector, getVirtualFirmwares } from 'hooks/firmware-management';

import { DeviceFormValues as FormValues } from './DeviceBlock/utils';
import { Firmware } from 'models/firmware-management';

// components
import { Accordion, AccordionDetails, FormControlLabel, FormGroup, Grid, TextField, } from '@mui/material';

import { SingleSelectControl } from 'components';
import * as Fields from 'components/Form/Field';
import { FieldSkeleton } from 'components/Skeleton';

import DeviceOperations from './DeviceOperations';
import { MarkDeviceDamageDialogComponent } from 'components/DeviceManagement';
import { SelectOption } from 'components/Controls';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface Props {
  form: FormHookResult<FormValues>;
  device?: Device;
  idPrefix?: string;
  onDevicePositionRelationUpdate?: () => void;
}

function getFirmwareHashOptions(
  allFirmwares: Firmware[],
  currentFirmwareHash?: string,
  currentSoftType?: SoftType,
): SelectOption<string | undefined>[] {
  const options: SelectOption<string | undefined>[] = [];

  const currentFirmware = currentFirmwareHash
    ? allFirmwares.find(firmware => firmware.hash === currentFirmwareHash)
    : undefined;

  const firmwaresToShow = uniqWith([
    // if currentFirmware's name and version are equal to one of allowed firmwares
    // but currentFirmware's hash is different, we need to show currentFirmware
    // since otherwise there'll be no option matching the field value so field will be broken
    ...(currentFirmware ? [currentFirmware] : []),
    ...getVirtualFirmwares(allFirmwares),
  ], (a, b) => a.name === b.name && a.version === b.version);

  if (!currentFirmware && currentSoftType) {
    options.push({ value: undefined, label: currentSoftType });
  }

  return [
    ...firmwaresToShow.map(f => ({ value: f.hash, label: `${f.name}/${f.version}` })),
    ...options
  ];
}

const DeviceGeneralInfo: React.FC<Props> = ({ form, device, onDevicePositionRelationUpdate }) => {
  const firmwareHash = form.watch('firmware_hash');
  const { firmwares } = useFirmwaresDictionarySelector();
  const { isAdmin, data } = useAuthUserSelector();
  const damageDialog = useDialog();
  const allowedToUpdateDevice = isAllowedToUpdateDevice(data);

  const infoCss = useInfoBlockStyles();

  if (!device) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  return (
    <Accordion expanded>
      <AccordionDetails>
        <FormGroup className={ infoCss.fields }>

          <Fields.Text
            name={ nameof<FormValues>('hardware_type') }
            className={ infoCss.field }
            label="Hardware type"
            disabled={ !isAdmin }
          />

          <input type="hidden" name={ nameof<FormValues>('firmware_hash') } ref={ form.register } />

          <SingleSelectControl
            label="Firmware"
            name={ nameof<FormValues>('firmware_hash') }
            isDisabled={ !allowedToUpdateDevice || !isDeviceVirtual(device.device_id) }
            ControlProps={ { className: infoCss.field } }
            selected={ firmwareHash }
            values={ getFirmwareHashOptions(firmwares, firmwareHash, device.soft_type) }
            changeHandler={ hash => form.setValue('firmware_hash', hash) }
          />

          <TextField
            className={ infoCss.field }
            disabled
            label="High level protocol"
            value={ device.high_level_protocol_version || '' }
          />

          <Grid className={ infoCss.field } container>
            <Grid item xs={ 12 } md={ 6 }>
              <FormControlLabel
                className={ infoCss.switch }
                control={
                  <Fields.Switch
                    name={ nameof<FormValues>('activation_status') }
                    disabled={ !allowedToUpdateDevice }
                  />
                }
                label="Active"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <FormControlLabel
                className={ infoCss.switch }
                control={
                  <Fields.Switch
                    name={ nameof<FormValues>('damaged_status') }
                    disabled={ !allowedToUpdateDevice }
                    onChange={ () => !device.damaged_status ? damageDialog.open() : undefined }
                  />
                }
                label="Damaged"
                labelPlacement="start"
              />
            </Grid>
          </Grid>

          { isAdmin && (
            <div className={ infoCss.field }>
              <Fields.SelectOwner
                name={ nameof<FormValues>('owner_id') }
                isClearable={ false }
                label="Owner"
              />
            </div>
          ) }

          <DeviceOperations
            device={ device }
            onDevicePositionRelationUpdate={ onDevicePositionRelationUpdate }
          />
        </FormGroup>
      </AccordionDetails>
      { damageDialog.isMounted && <MarkDeviceDamageDialogComponent
        device={ device as Device }
        isOpen={ damageDialog.isOpen }
        onClose={ () => {
          form.setValue('damaged_status', false);
          damageDialog.close();
        } }
        onCloseEnd={ damageDialog.unmount }
      /> }
    </Accordion>
  );
};

export default DeviceGeneralInfo;
