import { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { Helmet } from 'react-helmet-async';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { IncidentsFiltersFields, IncidentsFiltersState } from 'models';
import { useFilterState } from './utils';
import {
  DoGetIncidentsStart,
  DoSetIncidentsFilters,
  DoFetchReport,
  DoStartSync,
  DoStopSync,
  DoUpdateIncidentsFromUrl
} from 'actions/http-incidents';
import { RootState } from 'reducers';
import { initIncidentsFilters } from 'reducers/incidents';
import { usePagination } from 'hooks/usePagination';

// components
import { IncidentsTable, IncidentsTableFilter } from 'components';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

const mapState = (state: RootState) => ({
  user: state.user,
  incidents: state.incidents,
  filters: state.incidents.filters,
});

const mapDispatch = (dispatch: Dispatch) => ({
  fetchIncidents: () => dispatch(DoGetIncidentsStart()),
  setFilters: (filters: IncidentsFiltersState) => dispatch(DoSetIncidentsFilters(filters, true)),
  fetchReport: (query: IncidentsFiltersState) => dispatch(DoFetchReport(query)),
  startSync: () => dispatch(DoStartSync()),
  stopSync: () => dispatch(DoStopSync()),
  initData: () => dispatch(DoUpdateIncidentsFromUrl())
});

const connector = connect(
  mapState,
  mapDispatch
);

const IncidentsDashboard = (props: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = useStyles();
  const { initData, fetchIncidents, setFilters, fetchReport, startSync, stopSync, filters } = props;
  const filterState = useFilterState(filters);
  const { limit, offset } = usePagination();

  useEffect(() => {
    initData();
    fetchIncidents();
  }, [initData, fetchIncidents, limit, offset]);

  useDeepCompareEffect(() => {
    filterState.setState({ ...filters, limit, offset });
  }, [filters, limit, offset]);

  const updateFilters = (filters: IncidentsFiltersFields) => {
    const filtersLimitOffset: IncidentsFiltersState = {
      ...filters,
      limit: limit,
      offset: 0
    };

    setFilters(filtersLimitOffset);
    fetchIncidents();
  };

  const fetchReportHandler = () => {
    fetchReport({ ...filters, limit, offset });
  };

  const onSave = () => updateFilters(filterState.state);
  const onReset = () => {
    filterState.setState(initIncidentsFilters);
    updateFilters(initIncidentsFilters);
  };

  return (
    <>
      <Helmet>
        <title>Device Incidents History</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ props.incidents.isFetching }
          isValid={ filterState.isValid }
          filter={ filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <IncidentsTableFilter { ...filterState } />
        </FilterButton>
      </div>
      <IncidentsTable
        data={ props.incidents.data }
        total={ props.incidents.total || 0 }
        isFetching={ props.incidents.isFetching }
        csvFetching={ props.incidents.csvFetching }
        fetchReport={ fetchReportHandler }
        startSync={ startSync }
        stopSync={ stopSync }
      />
    </>
  );
};

export default connector(IncidentsDashboard);
