import { useState, MouseEvent } from 'react';
import { isAllowedToUpdateUser } from 'utils/permissions';
import { useUserReinvite } from 'hooks/user-managment';

// components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions
} from '@mui/material';

type Props<TUser, TAuthUser> = {
  user?: TUser;
  authUser?: TAuthUser;
};

const ConfirmAccount = <TAuthUser, TUser extends { sub: string; confirmed: boolean, email: string }>({
  user,
  authUser
}: Props<TUser, TAuthUser>) => {
  const { handleReinvite } = useUserReinvite();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!user || user.confirmed) {
    return null;
  }

  const handleClickOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    await handleReinvite(user.sub);
    e.preventDefault();
    setIsModalOpen(false);
  };

  return (<>
    <Button
      color="inherit"
      onClick={ handleClickOpen }
      // @TODO join types UserPayload(src/actions/user-profile.ts) and User(src/models/user-management/user.ts)
      disabled={ !isAllowedToUpdateUser({ ...authUser, confirmed: '' }) }
    >
      RESEND INVITATION
    </Button>
    <Dialog
      open={ isModalOpen }
      onClose={ handleClose }
      maxWidth={ 'sm' }
    >
      <DialogTitle id="alert-dialog-title">Resend invitation to { user.email }?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirm
        </Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default ConfirmAccount;
