import { Device } from 'models/dms';
import { TableCell, TableRow, Typography } from '@mui/material';

interface Props {
  cols: number;
  device: Device;
}

export const DeviceInfo = ({ cols, device }: Props) => {
  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        <Typography variant="subtitle2">
          Comment: &nbsp;&nbsp; <Typography style={{ whiteSpace: 'pre-wrap', }} variant="caption">{ device.comment || '-' }</Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
};