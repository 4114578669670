import { Controller, FieldError, Control } from 'react-hook-form-v6';
import { Project } from 'models/user-management';

// components
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { OwnersSingleSelectControl, TimezoneSelectControl } from 'components/Controls';

interface Props {
  isAdmin: boolean;
  allowed: boolean;
  errors: { [x in keyof Partial<Project>]: FieldError | undefined };
  control: Control<Partial<Project>>;

}
export const ProjectForm = ({
  isAdmin,
  allowed,
  errors,
  control,
}: Props): JSX.Element => {
  return (<>
    <FormGroup>
      <Controller
        render={props => <TextField
          {...props}
          label="Project Name"
          error={!!errors.name}
          helperText={errors.name && 'Required'}
        />}
        name="name"
        control={control}
        rules={{ required: true }}
      />
      <br />
      <Controller
        render={props => <TimezoneSelectControl
          selected={props.value}
          onChange={v => props.onChange(v)}
          isDisabled={ !allowed }
          isSearchable
          isClearable
        />}
        name="timezone"
        control={control}
        rules={{ required: true }}
      />
      <br />
      {isAdmin && <Controller
        render={props => <Box my={-1}>
          <OwnersSingleSelectControl
            {...props}
            selected={props.value}
            changeHandler={v => props.onChange(v)}
            error={errors.owner_id && 'Required'}
            isDisabled={ !allowed }
          />
        </Box>}
        name="owner_id"
        control={control}
        rules={{ required: true }}
      />}
    </FormGroup>
  </>);
};
