import { ReactChild } from 'react';
import { useLocation } from 'react-router-dom';
import { useAmplifyAuth } from './hooks/useAmplifyAuth';

// components
import { Authenticator } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import logo from 'images/favicon.png';
import { InviteForm } from './widget/InviteForm';

// styles
import contentStyles from 'styles';
import '@aws-amplify/ui-react/styles.css';
import './style.scss';


const formFields = {
  signIn: {
    username: {
      placeholder: 'Email',
      type: 'email',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email',
    }
  },
};

interface Props {
  children: ReactChild;
}

const inviteRe = /\/invite\/.+/;
const isInvite = (inputString: string) => inviteRe.test(inputString);

const AuthContainer = ({ children }: Props): JSX.Element => {
  const classes = contentStyles();
  const location = useLocation();
  const { signedIn, handleSignIn, handleForgotPassword } = useAmplifyAuth();

  // invite scenario (user is not signed up)
  if (isInvite(location.pathname)) {
    return (
      <Box className={classes.root} justifyContent="center">
        <h1 className={classes.logo}><img src={logo} alt="Logo"/>&nbsp;&nbsp;&nbsp;Nwave Console</h1>
        <InviteForm/>
      </Box>
    );
  }

  // user signed in
  if (signedIn) {
    return (
      <>{ children }</>
    );
  }

  // user is not signed in
  return (
    <Box className={ classes.root } justifyContent="center">
      <h1 className={ classes.logo }><img src={ logo } alt="Logo"/>  Nwave Console</h1>
      <Authenticator
        services={{ handleSignIn, handleForgotPassword }}
        formFields={ formFields }
        hideSignUp={ true }
      />
    </Box>
  );
};

export default AuthContainer;
