import { Statistic } from 'models/device-management';
import { ZoneConnectivity } from 'models/connectivity/zoneConnectivity';

export const FETCH_ZONE_STATISTICS = 'FETCH_ZONE_STATISTICS';
export const FETCH_ZONE_STATISTICS_SUCCESS = 'FETCH_ZONE_STATISTICS_SUCCESS';
export const FETCH_ZONE_STATISTICS_FAILED = 'FETCH_ZONE_STATISTICS_FAILED';

export type DeleteZoneResult = void;

export interface GetZoneStatistics {
  filters: {
    zones: number[];
  };
  type: typeof FETCH_ZONE_STATISTICS;
}

export interface GetZoneStatisticsSuccess {
  type: typeof FETCH_ZONE_STATISTICS_SUCCESS;
  payload: {
    statAll: Statistic;
    connectivity: ZoneConnectivity[];
  };
}

export interface GetZoneStatisticsFailed {
  type: typeof FETCH_ZONE_STATISTICS_FAILED;
}

export const DoGetZoneStatistics = (filters: GetZoneStatistics['filters']): GetZoneStatistics => ({
  type: FETCH_ZONE_STATISTICS,
  filters
});
export const DoGetZoneStatisticsSuccess = (payload: GetZoneStatisticsSuccess['payload']): GetZoneStatisticsSuccess => ({
  type: FETCH_ZONE_STATISTICS_SUCCESS,
  payload
});
export const DoGetZoneStatisticsFailed = (): GetZoneStatisticsFailed => ({
  type: FETCH_ZONE_STATISTICS_FAILED,
});

export type Action =
  GetZoneStatistics |
  GetZoneStatisticsSuccess |
  GetZoneStatisticsFailed
