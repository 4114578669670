import React from 'react';
import { useControlledState } from 'utils/components';
import { getFirstVisibleTab } from './utils';

// components
import { Tab, Tabs } from '@mui/material';

interface TabContentDefaultProps {
  tabActive: boolean;
}

export interface TabConfig<Props> {
  hidden?: boolean;
  id: string;
  label: string;
  render: (props: Props & TabContentDefaultProps) => React.ReactNode;
}

type PageTabsProps<TabContentProps> = {
  tabs: TabConfig<TabContentProps>[];
  tabContentProps: TabContentProps;

  activeTabId?: TabConfig<TabContentProps>['id'];
  onActiveTabChange?: (activeTabId?: TabConfig<TabContentProps>['id']) => void;
};

export function PageTabs<TabContentProps>({
  tabs,
  tabContentProps,
  ...restProps
}: PageTabsProps<TabContentProps>) {
  const firstVisibleTab = getFirstVisibleTab(tabs);

  const [activeTabId, setActiveTabId] = useControlledState({
    controlled: typeof restProps.activeTabId !== 'undefined',
    state: restProps.activeTabId ?? firstVisibleTab?.id,
    onChange: restProps.onActiveTabChange,
  });

  return (
    <>
      <Tabs
        value={activeTabId}
        onChange={(_event, tabId) => setActiveTabId(tabId)}
      >
        {tabs
          .filter(tab => !tab.hidden)
          .map(tab => <Tab key={tab.label} label={tab.label} value={tab.id} />)}
      </Tabs>

      {tabs.map(tab => (
        <div key={tab.id} hidden={tab.id !== activeTabId}>
          {tab.render({
            ...tabContentProps,
            tabActive: tab.id === activeTabId,
          })}
        </div>
      ))}
    </>
  );
}
