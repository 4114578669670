import React from 'react';
import { isNil } from 'lodash';

import { Tree, TreeNode, ZoneNode } from 'hooks/device-management/useTree/types';
import { ProjectsState, useProjectsDictionarySelector } from 'hooks/user-managment';

// components
import { Box, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { InlineLoader } from 'components/Loaders';
import { ActiveStatusIcon } from 'components/Icons';
import { GroupLink, LevelLink, PositionDetailLink, ProjectLink, ZoneLink } from 'components/Links';
import { TreeTableRow } from '../types';
import * as actions from '../actions';

interface Props {
  tree: Tree;
  row: TreeTableRow<TreeNode>;
}

export const NameCell = ({ tree, row }: Props): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box paddingLeft={ row.level * 2 }>
        { renderNodeTitle(row.node) }
        { !isNil(row.childrenTotal) && <Box component="span" ml={ 0.5 }>({ row.childrenTotal })</Box> }
        { !isNil(row.expansion) && (
          <IconButton onClick={ row.expansion.toggle }>
            { row.expansion.status ? <ExpandLess/> : <ExpandMore/> }
          </IconButton>
        ) }
      </Box>
      <Box maxWidth="45px">{ renderNodeAction(tree, row.node) }</Box>
    </Box>
  );
};

function renderNodeAction(tree: Tree, node: TreeNode) {
  switch (node.type) {
    case 'zone':
      return <actions.AddLevelAction tree={ tree } node={ node }/>;
    case 'level':
      return <actions.AddGroupAction tree={ tree } node={ node }/>;
    case 'group':
      return <actions.AddPositionAction tree={ tree } node={ node }/>;
    case 'position':
      return node.device
        ? <actions.ReplaceDeviceAction node={ node }/>
        : <actions.BindDeviceAction node={ node }/>;
    default:
      return null;
  }
}

function renderNodeTitle(node: TreeNode) {
  switch (node.type) {
    case 'zone':
      return (
        <>
          <ActiveStatusIcon status={ node.zone.live_status }/>
          <ZoneNameCell node={ node }/>
        </>
      );
    case 'level': {
      if (!node.level) {
        return (
          <LevelLink
            zoneId={ node.parent.zone.id }
            levelId={ 0 }
            withReturnUrl
          >
            No level, no floor
          </LevelLink>
        );
      }

      return (
        <LevelLink
          zoneId={ node.level.zone_id }
          levelId={ node.level.id }
          withReturnUrl
        >
          {`${ node.level.name }, Floor ${ node.level.floor_number }`}
        </LevelLink>
      );
    }

    case 'group': {
      return (
        <GroupLink
          zoneId={ node.group.zone_id }
          levelId={ node.group.level_id ?? 0 }
          groupId={ node.group.id }
          withReturnUrl
        >
          { node.group.name }
        </GroupLink>
      );
    }

    case 'position': {
      return (
        <PositionDetailLink
          zoneId={ node.position.zone_id }
          levelId={ node.position.level_id ?? 0 }
          groupId={ node.position.group_id }
          positionId={ node.position.id }
          withReturnUrl
        >
          { node.position.network_id ?? `Position ID ${ node.position.id }`}
        </PositionDetailLink>
      );
    }
  }
}

function getProjectName(projectId: number | undefined, projectsState: ProjectsState) {
  if (!projectId) {
    return 'Default project';
  }

  const project = projectsState.projects.find(p => p.id === projectId);

  if (project) {
    return <ProjectLink projectId={ project.id } name={ project.name }/>;
  }

  if (projectsState.isLoading) {
    return <InlineLoader/>;
  }

  return 'Project not found';
}

const ZoneNameCell = ({ node }: { node: ZoneNode }): JSX.Element => {
  const projectsState = useProjectsDictionarySelector();
  return (
    <>
      { getProjectName(node.zone.project_id, projectsState) }
      { ' / ' }
      <ZoneLink redesign zoneId={ node.zone.id } defaultName={ node.zone.name }/>
    </>
  );
};