
import { SliceTooltipProps } from '@nivo/line';

// components
import { Box, Paper, Typography } from '@mui/material';

export const Tooltip = ({ slice }: SliceTooltipProps): JSX.Element => {
  const point = slice.points[0];

  if (!point) {
    return <></>;
  }

  return (
    <Paper data-testid="chart-tooltip" data-slice-id={ slice.id }>
      <Box p={ 2 }>
        <Typography component="p" variant="subtitle1" noWrap={ true }>
          { point.data.xFormatted }
        </Typography>
        <Box width={ 14 } height={ 14 } mt={ '5px' } display="inline-block" bgcolor={ point.serieColor } mr={ 2 }></Box>
        <Typography component="p" noWrap={ true } display="inline">
          { point.serieId }: <Typography component="span" variant="button" noWrap={ true }>{ point.data.yFormatted }</Typography>
        </Typography>
      </Box>
    </Paper>
  );
};
