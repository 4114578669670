import React from 'react';

import { Device, DeviceColdData } from 'models/device-management';
import { formatDate, formatDateTime } from 'utils/datetime';
import { Device as ProvisioningDevice } from 'models/provisioning';
import { useShipmentName } from './useShipmentName';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  InputAdornment,
  TextField,
  IconButton,
} from '@mui/material';
import { Error as ErrorIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { FieldSkeleton } from 'components/Skeleton';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

//
interface Props {
  device?: Device & DeviceColdData;
  provisioningDevices: ProvisioningDevice[];
  idPrefix?: string;
}

const DeviceBusinessInfo: React.FC<Props> = (props) => {
  const infoCss = useInfoBlockStyles();
  const { device, provisioningDevices } = props;

  const { sipmentName } = useShipmentName(provisioningDevices);

  if (!device) {
    return <FieldSkeleton className={infoCss.field} />;
  }

  const deviceCalibrated = device.calibration_status;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Device business information
      </AccordionSummary>

      <AccordionDetails>
        <FormGroup className={infoCss.fields}>
          <TextField
            className={infoCss.field}
            disabled
            label="Last calibration time"
            value={deviceCalibrated
              ? device.calibration_date_time
                ? formatDateTime(device.calibration_date_time)
                : 'Unknown'
              : 'Not calibrated'
            }
            error={!deviceCalibrated}
            InputProps={{
              endAdornment: !deviceCalibrated && (
                <InputAdornment position="end">
                  <IconButton disabled>
                    <ErrorIcon color="error" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last business info update time"
            value={device.last_update_datetime
              ? formatDateTime(device.last_update_datetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last firmware update time"
            value={device.last_fw_updatetime
              ? formatDateTime(device.last_fw_updatetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Warranty expiration date"
            value={device.expiration_datetime
              ? formatDate(device.expiration_datetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last disabling reason"
            multiline
            value={device.disabling_reason || ''}
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Shipment"
            multiline
            value={sipmentName}
          />
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default DeviceBusinessInfo;
