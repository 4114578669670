import React from 'react';

import { getRabbitEndpointListPath } from 'routing/paths';

// components
import { Box, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { CommonButton } from 'components/Buttons';

interface Props {
  endpointId: number;
}

export const EndpointNotFound = ({ endpointId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Endpoint <b>{ `#${ endpointId }` }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label="Back to list"
        LinkTo={ getRabbitEndpointListPath() }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};