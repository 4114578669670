import React from 'react';
import { WarrantyCreateBody, WarrantyFormField, Warranty } from 'models/provisioning';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
import { DoCreateWarranty } from 'actions/provisioning';

// components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { WarrantyForm } from 'components/Provisioning/WarrantyManagment';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  selectCreatedWarranty: (id: Warranty['id']) => void;
}

export const CreateWarrantyModal = ({ isOpen, onClose, onCloseEnd, selectCreatedWarranty }: Props): JSX.Element => {
  const classes = useStyles();
  const defaultValues: WarrantyCreateBody = {
    name: '',
    max_keeping_in_warehouse: 0,
    max_warranty_after_install: 0,
  };
  const form = useForm<WarrantyCreateBody, WarrantyFormField>({
    initialState: defaultValues,
    showFields: [
      WarrantyFormField.name,
      WarrantyFormField.max_keeping_in_warehouse,
      WarrantyFormField.max_warranty_after_install,
    ],
  });
  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    btnSave: 'Create',
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: DoCreateWarranty(form.state),
        onError: form.catchError,
        onSuccess: (e: Warranty) => {
          if (e.id) {
            selectCreatedWarranty(e.id);
          }
          onClose();
        },
      });
    },
    onCancel: () => {
      form.reset();
      onClose();
    },
  });

  return (
    <Dialog
      open={ isOpen }
      onClose={ () => onClose() }
      TransitionProps={ { onExited: onCloseEnd } }
      maxWidth={ 'sm' }
      fullWidth
      PaperProps={ { className: classes.formDialogPaper } }
    >
      <DialogTitle>Create Warranty</DialogTitle>
      <DialogContent className={ classes.dialogContentRoot }>
        <WarrantyForm<WarrantyCreateBody> { ...form } />
      </DialogContent>
      <DialogActions> { actions } </DialogActions>
    </Dialog>
  );
};
