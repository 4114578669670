import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { Helmet } from 'react-helmet-async';
import { initZoneFilters, ZoneFilters } from 'models/device-management';
import { usePagination } from 'hooks/usePagination';
import { DoGetZoneStatistics } from 'actions/device-management/zones';
import { ResetDMZoneData } from 'actions/device-management/zone-data';
import { RootState } from 'reducers';
import { dmZonesCreatePath } from 'routing/paths';
import { useAuthUserSelector } from 'hooks';
import { useOnSplaceMessages } from 'hooks/splace-messages';
import { isAllowedToCreateZones } from 'utils/permissions';
import { useZonesByFilters, useFilterByLocation } from './utils';

// components
import ZonesTable from 'components/DeviceManagement/Zones/ZonesTable/ZonesTableComponent';
import AddButton from 'components/Buttons/AddButton';
import { FilterDialog } from './widget/FilterDialog';

// styles
import useStyles from 'styles/page';

const Zones = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useAuthUserSelector();
  const { filter, onChange } = useFilterByLocation();
  const { limit, offset, onChangePage } = usePagination();

  // reset page count on filter change
  useDeepCompareEffect(() => {
    onChangePage(0);
  }, [filter]);

  const onFilterSave = (filter: ZoneFilters) => {
    onChange(filter);
  };

  const onFilterReset = () => {
    onChange(initZoneFilters);
  };

  // get zones data
  const { isLoading, zones, total } = useZonesByFilters({
    ...filter,
    limit,
    offset
  });

  const { zoneStatistics } = useSelector((state: RootState) => state.deviceManagement.zones);
  const allowedToCreateZone = isAllowedToCreateZones(user.data);

  const { connectivity, isFetching: statFetch } = zoneStatistics;
  const devicesByZone = zoneStatistics.statAll.by_zones;

  const { message } = useOnSplaceMessages();
  useEffect(() => {
    message && dispatch(ResetDMZoneData());
  }, [message, dispatch]);

  useDeepCompareEffect(() => {
    const zoneIds = zones.map(el => el.id);
    zoneIds.length && dispatch(DoGetZoneStatistics({ zones: zoneIds }));
  }, [dispatch, zones]);

  return (
    <>
      <Helmet><title>Zones</title></Helmet>
      <div className={ classes.toolbar }>
        <FilterDialog
          isLoading={ isLoading }
          filter={ filter }
          onSave={ onFilterSave }
          onReset={ onFilterReset }
        />
      </div>
      <div className={ classes.table }>
        <ZonesTable
          isLoading={ isLoading }
          data={ zones }
          total={ total }
          devicesByZone={ devicesByZone }
          connectivity={ connectivity }
          statFetch={ statFetch }
        />
      </div>
      { allowedToCreateZone && (
        <AddButton
          label="Add zone"
          LinkTo={ dmZonesCreatePath }
        />
      )}
    </>
  );
};

export default Zones;
