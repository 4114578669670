import moment from 'moment';
// components
import TimePicker, { TimePickerProps } from '@mui/lab/TimePicker';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';

interface Props {
  className?: string;
  label: string;
  value?: Date | string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date | undefined, textValue?: string) => void;
  onBlur?: () => void;
  error?: string;
  helperText?: string;
  timePickerProps?: Partial<TimePickerProps>;
}

export const TimeControl = (props: Props): JSX.Element => {
  const onChange = (dateValue: Date | null, textValue?: string) => {
    const date = dateValue ? moment(dateValue).set('ms', 0) : undefined;
    props.onChange(date?.toDate(), textValue ?? undefined);
  };

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <TimePicker
        renderInput={ textProps => (
          <TextField
            { ...textProps }
            fullWidth
            margin="normal"
            className={ props.className }
            error={ Boolean(props.error) }
            helperText={ props.error ?? props.helperText }
            required={ props.isRequired }
          />
        ) }
        label={ props.label }
        inputFormat="HH:mm"
        value={ props.value ? props.value : null }
        onChange={ onChange }
        minutesStep={ 1 }
        ampm={ false }
        views={ ['hours', 'minutes'] }
        disabled={ props.isDisabled }
      />
    </LocalizationProvider>
  );
};