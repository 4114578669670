import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { RootState } from 'reducers';
import { IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

let displayed: (number | undefined)[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store: RootState) => store.notifications.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number | undefined) => {
    displayed = [...displayed, id];
  };

  const action = useCallback(key => (
    <IconButton onClick={ () => closeSnackbar(key) }>
      <Clear />
    </IconButton>
  ), [closeSnackbar]);

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (key && displayed.includes(key)) { return; }

      if (options.variant === 'error') {
        // log an error to the console primarily to catch it in e2e tests
        // eslint-disable-next-line no-console
        console.error(`[notification] ${message}`);
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        // If a user-defined key is passed in the options of enqueueSnackbar,
        // then this prevents snackbars with the same key to be displayed multiple times.
        ...(!options.preventDuplicate && { key }),
        ...options,
        // preventDuplicate is ignored when messages are similar but keys is different
        preventDuplicate: true,
        onClose: (event, reason) => {
          if (options.onClose) {
            options.onClose(event, reason);
          }
        },
        ...(options.persist && { action })
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, action]);

  return null;
};

export default Notifier;
