import { Box } from '@mui/material';
import { EnsureDialog } from 'components/Dialogs';
import { ShipmentDeviceForAws } from 'models/provisioning';

type Props = {
  isOpen: boolean
  onProceed: () => void
  onClose: () => void
  provisionedDevices: ShipmentDeviceForAws[];
}

export const ProvisioningAlertationDialog = ({
  isOpen,
  onProceed,
  onClose,
  provisionedDevices,
}: Props) => {
  return (<EnsureDialog
    name="AwsProviAlertationDialog"
    testId="filters-block"
    isOpen={ isOpen }
    btnNo="Cancel"
    btnYes="Yes"
    onYes={ onProceed }
    onNo={ onClose }
    onClose={ onClose }
    label="Provide devices to AWS"
  >
    <Box>
      There are {provisionedDevices.length} already provisioned devices in the shipment.
      These devices will be skipped while the bulk provisioning process:
      <ul className="list">
        {provisionedDevices.slice(0,5).map(d => (d ? <li key={d.device_id}>{d.device_id}</li> : <></>))}
      </ul>
      {provisionedDevices.length > 5 && <>and {provisionedDevices.length - 5} more.<br/><br/></>}
      Proceed?
    </Box>
  </EnsureDialog>);
};
