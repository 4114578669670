import { Project } from 'models/user-management';
import { ProjectsFilters } from 'models/projects';
import { SaveInUrl } from 'react-router-config';

export const SET_PROJECTS_FILTERS = 'SET_PROJECTS_FILTERS';
export const UPDATE_PROJECTS_FILTERS_FROM_URL = 'UPDATE_PROJECTS_FILTERS_FROM_URL';

export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID';
export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_BY_ID_FAILED = 'GET_PROJECT_BY_ID_FAILED';

export interface SetProjectsFilters extends SaveInUrl<ProjectsFilters> {
  readonly type: typeof SET_PROJECTS_FILTERS;
}
export interface UpdateProjectsFromUrl {
  readonly type: typeof UPDATE_PROJECTS_FILTERS_FROM_URL;
}

export interface GetProjectById {
  readonly type: typeof GET_PROJECT_BY_ID;
  id: number;
}
export interface GetProjectByIdSuccess {
  readonly type: typeof GET_PROJECT_BY_ID_SUCCESS;
  byId: Project;
}
export interface GetProjectByIdFailed {
  readonly type: typeof GET_PROJECT_BY_ID_FAILED;
}

export const DoSetProjectsFilters: (
  filters: ProjectsFilters,
  toUrl: boolean
) => SetProjectsFilters = (filters, toUrl) => ({
  type: SET_PROJECTS_FILTERS,
  filters,
  pushFiltersToUrl: toUrl
});

export const DoUpdateProjectsFromUrl: () => UpdateProjectsFromUrl = () => ({
  type: UPDATE_PROJECTS_FILTERS_FROM_URL
});
export const DoGetProjectById: (id: number) => GetProjectById = id => ({
  type: GET_PROJECT_BY_ID,
  id
});
export const DoGetProjectByIdSuccess: (byId: Project) => GetProjectByIdSuccess = byId => ({
  type: GET_PROJECT_BY_ID_SUCCESS,
  byId
});
export const DoGetProjectByIdFailed: () => GetProjectByIdFailed = () => ({
  type: GET_PROJECT_BY_ID_FAILED
});

export type Action = (
  | SetProjectsFilters
  | UpdateProjectsFromUrl
  | GetProjectById
  | GetProjectByIdSuccess
  | GetProjectByIdFailed
)
