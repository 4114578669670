import React from 'react';

import { SingleValueType } from 'components/Controls/Select/types';
import { User, UserRoleEnum } from 'models/user-management';
import { mapPossibleOptionToValue } from 'utils/form';

// components
import { ReactSelect } from 'components/Controls/Select';
import { SelectProps } from 'components/Controls/Select/ReactSelect';

type Value = User['role'];

type SelectUserRoleProps = Omit<SelectProps<Option, false>, 'isMulti' | 'options' | 'value' | 'onChange' | 'searchConfig'> & {
  name: string;
  value: Value;
  onChange: (value: Value | null) => void;
  filter?: (option: { label: UserRoleEnum | string; value: UserRoleEnum; }) => boolean;
};

interface Option {
  value: Value;
}

const SelectUserRole: React.FC<SelectUserRoleProps> = (props) => {

  const filterCallback = props.filter ?? (() => (true));
  const options = Object.values(UserRoleEnum)
    .map(role => ({ label: role.charAt(0).toUpperCase() + role.slice(1), value: role }))
    .filter(filterCallback);

  return (
    <ReactSelect
      {...props}
      isMulti={false}
      options={options}
      value={options.find(o => o.value === props.value)}
      onChange={(possibleOption) => props.onChange(
        // `possibleOption as SingleValueType<Option>` is valid as long as `isMulti !== true`
        mapPossibleOptionToValue(possibleOption as SingleValueType<Option, false>) ?? null,
      )}
    />
  );
};

export default SelectUserRole;
