import React from 'react';
// components
import { Button as MuiButton, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';

interface Props {
  count: number;
  onClick: () => void;
}

export const Button = ({ count, onClick }: Props): JSX.Element => {
  return (
    <MuiButton
      data-testid="filters-block-show-button"
      variant="text"
      color="secondary"
      startIcon={ <FilterList color="action" /> }
      onClick={ onClick }
    >
      <Typography color="textSecondary">
        Filters { count ? `(${count})` : '' }
      </Typography>
    </MuiButton>
  );
};
