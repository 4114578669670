import { Project } from 'models/user-management';
import { useProjectsDictionarySelector } from 'hooks/user-managment';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

export const PROJECT_ALL_OPTION_VALUE = -1;
export const PROJECT_DEFAULT_OPTION_VALUE = 0;

const AllOption: Option<number> = {
  value: PROJECT_ALL_OPTION_VALUE,
  label: 'All',
};

// https://bsstatus.dev.api.nwave.io/

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & {
    withAllOption?: boolean;
    filter?: (project: Project) => boolean;
  }

const Component = (props: Props): JSX.Element => {

  const { projects, isLoading }  = useProjectsDictionarySelector();
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<number>[] = [
    ...(props.withAllOption ? [AllOption] : []),
    ...projects
      .filter(filterCallback)
      .map(project => ({
        value: project.id,
        label: project.name,
      }))
  ];

  return <SelectControl
    label="Project"
    name="project"
    closeMenuOnSelect={ !props.isMulti }
    { ...props }
    options={ options }
    isLoading={ isLoading }
    checkSelected={ !isLoading }
    isDisabled={ !options.length || props.isDisabled }
  />;
};

export default Component;
