import clsx from 'clsx';
import React from 'react';
// components
import FormControl from '@mui/material/FormControl';
import ReactSelect from './ReactSelect';
import { SelectOption, SingleSelectProps } from './types';
// styles
import contentStyles from 'styles';

function SingleSelectControl<TValue>(props: SingleSelectProps<TValue>): JSX.Element {
  const classes = contentStyles();

  return (
    <FormControl
      disabled={ props.isDisabled }
      required={ props.isRequired }
      error={ props.error ? true : false }
      { ...props.ControlProps }
      className={ clsx(classes.formControl, props.ControlProps?.className) }
    >
      <ReactSelect
        isMulti={ false }
        label={ props.label }
        name={ props.name }
        placeholder={ props.placeholder }
        options={ props.values }
        onChange={ (value) => {
          const option = value && props.values.find((x) => {
            // `value as SelectOption<TValue>` is valid as long as `isMulti !== true`
            return x.value === (value as SelectOption<TValue>).value;
          });
          props.changeHandler(option ? option.value : undefined);
        } }
        error={ props.error }
        help={ props.help }
        isClearable={ props.isClearable }
        isDisabled={ props.isDisabled }
        isLoading={ props.isLoading }
        isRequired={ props.isRequired }
        isPortal={ props.isPortal }
        value={ props.values.find((x) => (x.value === props.selected)) ?? null }
        onBlur={ props.onBlur }
      />
    </FormControl>
  );
}

export default SingleSelectControl;
