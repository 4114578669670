import React from 'react';

import { SingleValueType } from 'components/Controls/Select/types';
import { GroupType } from 'models/device-management';
import { mapPossibleOptionToValue } from 'utils/form';
import { formatPositionGroupType } from 'utils/models';

// components
import { ReactSelect } from 'components/Controls/Select';
import { SelectProps } from 'components/Controls/Select/ReactSelect';

type SelectPositionGroupTypeProps = Omit<SelectProps<Option, false>, 'isMulti' | 'options' | 'value' | 'onChange' | 'searchConfig'> & {
  name: string;
  value: GroupType;
  onChange: (value: GroupType | null) => void;
};

interface Option {
  value: GroupType;
}

export const SelectPositionGroupType: React.FC<SelectPositionGroupTypeProps> = (props) => {
  const options = Object.values(GroupType)
    .map(groupType => ({ label: formatPositionGroupType(groupType), value: groupType }));

  return (
    <ReactSelect
      {...props}
      isMulti={false}
      options={options}
      value={options.find(o => o.value === props.value)}
      onChange={(possibleOption) => props.onChange(
        // `possibleOption as SingleValueType<Option>` is valid as long as `isMulti !== true`
        mapPossibleOptionToValue(possibleOption as SingleValueType<Option, false>) ?? null,
      )}
    />
  );
};
