import React from 'react';

import { DEFAULT_LIMIT } from 'models';
import { commonOptions } from 'utils/tables';

// components
import { Tooltip, IconButton } from '@mui/material';
import { Update } from '@mui/icons-material';
import { MUIDataTableOptions } from 'mui-datatables';

import { PaginationFooter } from 'components/Table';
import { IncidentDetails, IncidentsMore } from './IncidentsMore';
import { SaveCSVButton } from './widgets/SaveCSVButton';

export interface MuiDatatableOptionsGetterConfig {
  total: number;
  rowDetails: IncidentDetails[];

  autoUpdate: boolean;
  onChangeAutoUpdate: () => void;

  fetchReport: () => void;
  csvFetching: boolean;

  muiDatatableOptionsOverrides?: MUIDataTableOptions;
}

export function getMuiDatatableOptions({
  total,
  rowDetails,

  autoUpdate,
  onChangeAutoUpdate,

  fetchReport,
  csvFetching,

  muiDatatableOptionsOverrides,
}: MuiDatatableOptionsGetterConfig) {
  const finalOptions: MUIDataTableOptions = {
    ...commonOptions,
    expandableRows: true,
    expandableRowsOnClick: true,
    rowsPerPageOptions: [DEFAULT_LIMIT, 50, 100, 250],
    serverSide: true,
    count: total,
    renderExpandableRow: (rowData, rowMeta) => {
      const details = rowDetails[rowMeta.dataIndex];

      if (!details) {
        return null;
      }

      return (
        <IncidentsMore cols={ rowData.length + 1 } data={ details } />
      );
    },
    customToolbar: () => {
      return (
        <>
          <SaveCSVButton total={ total } isLoading={ csvFetching } onClick={ fetchReport } />
          <Tooltip title="Auto update" onClick={ onChangeAutoUpdate }>
            <IconButton>
              <Update color={ autoUpdate ? 'primary' : undefined } />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customFooter: (count) => {
      return (
        <PaginationFooter
          count={ count }
          rowsPerPageOptions={ finalOptions.rowsPerPageOptions }
        />
      );
    },
    ...muiDatatableOptionsOverrides,
  };

  return finalOptions;
}
