import React from 'react';
import { getDMListPath } from 'routing/paths';

// components
import { Box, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { CommonButton } from 'components/Buttons';

interface Props {
  zoneId: string;
}

export const ZoneNotFound = ({ zoneId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Zone <b>{ `#${ zoneId }` }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label="Back to list"
        LinkTo={ getDMListPath() }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};