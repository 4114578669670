import React from 'react';
import { Rule } from 'models/rabbit';
import { useAuthUserSelector, useDialog } from 'hooks';
import { getRabbitHistoryLocation } from 'routing/paths';

// components
import { Box } from '@mui/material';
import { CommonButton, DeleteButton } from 'components/Buttons';
import { canDeleteRule, canEditRule } from 'utils/permissions';
import { ActivityRuleDialog, DeleteRuleDialog } from 'components/Rabbit/Dialogs';

interface Props {
  rule: Rule;
}

export const ActionsCell = ({ rule }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const activityDialog = useDialog();
  const deleteDialog = useDialog();
  const historyLocation = getRabbitHistoryLocation({
    zones: rule.zone_id ? [rule.zone_id] : [],
    endpointIds: rule.endpoint_id ? [rule.endpoint_id] : [],
  });

  return (
    <Box display="flex" justifyContent="flex-end">
      { canEditRule(user) && <CommonButton
        type="text"
        label={ rule.activity ? 'Deactivate' : 'Activate' }
        icon={ <></> }
        onClick={ activityDialog.open }
        dataTestId="rabbit-rule-activity-button"
      /> }
      <CommonButton
        type="text"
        label="History"
        icon={ <></> }
        LinkTo={ historyLocation }
        dataTestId="rabbit-rule-history-button"
      />
      { canDeleteRule(user) && <DeleteButton
        type="text"
        label="Delete"
        onClick={ deleteDialog.open }
        dataTestId="rabbit-rule-delete-button"
      /> }

      { activityDialog.isMounted && <ActivityRuleDialog
        rule={ rule }
        isOpen={ activityDialog.isOpen }
        onClose={ activityDialog.close }
        onCloseEnd={ activityDialog.unmount }
      /> }
      { deleteDialog.isMounted && <DeleteRuleDialog
        rule={ rule }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
      /> }
    </Box>
  );
};
