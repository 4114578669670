import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { ensureRequestSucceeded } from 'utils/clients';
import { LoRaWANRawMessagesQuery } from 'models';

import { getLorawanMessages, getLorawanMessagesTotal } from 'clients/raw-messages';

export function useLorawanMessages(params: LoRaWANRawMessagesQuery, isSync = false) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['lorawan-messages', params],
    queryFn: async () => {
      const result = await getLorawanMessages(params);
      const total = await getLorawanMessagesTotal(params);
      ensureRequestSucceeded(result);

      return {
        data: result.data,
        total: total.total,
      };
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: isSync ? 10000 : undefined,
    onError: e => notifyError(`Error while fetching lorawan messages: ${ (e as Error).message }`),
  });

  return {
    isLoading: queryResult.isLoading,
    lorawaMessages: useMemo(() => queryResult.data?.data || [], [queryResult.data]),
    total: queryResult.data?.total || 0,
  };
}
