import React from 'react';
import { Level } from 'models/device-management';
import { formatDateTime } from 'utils/datetime';
import { LevelNode, Tree } from 'hooks/device-management/useTree/types';
import { calculateTotal } from 'hooks/device-management/useTree/utils';

// components
import { Grid, Paper, Typography } from '@mui/material';
import { FieldSkeleton } from 'components/Skeleton';
import { ConnectivityCell, IssuesCell, LabelsCell } from 'components/DeviceManagement/TreeTable/cells';

// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  tree: Tree;
  node: LevelNode;
  level: Level;
}

export const LevelStatistic = ({ tree, node, level }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={ classes.root } data-testid="dm-level-statistic">
      <Grid container>
        <Grid item xs={ 1 }>
          <Typography>Level ID</Typography>
          <Typography className={ classes.itemValue }>
            { level.id === 0 ? '-' : level.id }
          </Typography>
        </Grid>
        <Grid item xs={ 2 }>
          <Typography>Creation time</Typography>
          <Typography className={ classes.itemValue }>
            { level.creation_date ? formatDateTime(level.creation_date) : '-' }
          </Typography>
        </Grid>
        <Grid item xs={ 1 }>
          <Typography>Devices</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.device
                ? calculateTotal(node, 'devices')
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
        <Grid item xs={ 1 }>
          <Typography>Positions</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.position
                ? calculateTotal(node, 'children')
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
        <Grid item xs={ 2 }>
          <Typography>Issues</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.device
                ? <IssuesCell tree={ tree } node={ node } />
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
        <Grid item xs={ 1 }>
          <Typography>Connectivity</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.deviceConnect
                ? <ConnectivityCell tree={ tree } node={ node } />
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
        <Grid item xs={ 4 }>
          <Typography>Labels</Typography>
          <Typography component="div" className={ classes.itemValue }>
            {
              tree.loaded.position
                ? <LabelsCell node={ node } />
                : <FieldSkeleton height={ 24 }/>
            }
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
