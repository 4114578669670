import React from 'react';

// components
import Skeleton, { SkeletonProps } from '@mui/lab/Skeleton';

export const FieldSkeleton = (props: SkeletonProps): JSX.Element => {
  return (
    <Skeleton
      height={ props.height ?? 60 }
      { ...props }
    />
  );
};

export default FieldSkeleton;
