import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';

import { getLastRawMessagesTime } from 'clients/raw-messages';

export function useRawMessagesLastMessageTimeApi(deviceId?: string) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['raw-messages-last-message-time', deviceId],
    queryFn: async () =>  await getLastRawMessagesTime(deviceId),
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching last raw message time: ${ (e as Error).message }`),
    enabled: !!deviceId
  });

  return {
    isLoading: queryResult.isLoading,
    lastRawMessagesTime: queryResult.data?.data,
  };
}

