import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import moment from 'moment';
import { Template } from 'models/caller';
import { RootState } from 'reducers';
import { LoadTemplateStatistic } from 'actions/caller';
// components
import Box from '@mui/material/Box';
import { Line, LinearAreaChart } from 'components/Charts';
import { BlockLoader } from 'components/Loaders';
// styles
import outerTheme from 'styles/themes/outer';

interface Props {
  template: Template;
}

const Component = ({ template }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const stats = useSelector((state: RootState) => template && state.caller.templateStatistic.findById(template.template_id));
  useEffectOnce(() => {
    if (!stats) {
      dispatch(LoadTemplateStatistic({
        templateId: template.template_id,
        dateFrom: moment().subtract(7, 'days').startOf('day').toDate(),
      }));
    }
  });

  const successLine: Line = { name: 'Successful', points: [], color: outerTheme.status.success };
  const faltureLine: Line = { name: 'Failures', points: [], color: outerTheme.status.error };
  for (const history of stats?.statistic ?? []) {
    successLine.points.push({ date: new Date(history.date), value: Number(history.successful) });
    faltureLine.points.push({ date: new Date(history.date), value: Number(history.failures) });
  }

  return (
    <Box pr={ 1 } height={ 400 }>
      { !stats ? <BlockLoader/> :
        <LinearAreaChart
          title="Http call template statistic"
          lines={ [faltureLine, successLine] }
        />
      }
    </Box>
  );
};

export default Component;
