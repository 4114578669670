import { useState } from 'react';
import { ShipmentDeviceForAws } from 'models/provisioning';
import { PositionedDeviceData } from 'models/device-management';
import { useDeviceProfiles, ShipmentDeviceForAwsWithAppKey } from 'hooks';
import { useGetDevicesByIdBulk } from 'hooks/device-management';

// components
import { Button } from '@mui/material';
import { SingleSelectControl } from 'components';
import { CommonDialog } from 'components/Dialogs';
// styles
import useStyles from 'styles/filters';

interface Props {
  isDevicesLoading: boolean
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => void;
  nonProvisionedDevices: ShipmentDeviceForAws[];
  bulkProvisionDeviceToAws: (devices: ShipmentDeviceForAwsWithAppKey[], profileId: string) => void;
}

const addAppKeys = (nonProvisionedDevices: ShipmentDeviceForAws[], devices: PositionedDeviceData[]) => {
  return nonProvisionedDevices.map(d => {
    const meta = devices.find(meta =>
      String(meta.device_id).toLocaleLowerCase() === String(d.device_id).toLocaleLowerCase());
    return {
      ...d,
      app_key: meta?.encryption_keys?.key16
    };
  });
};

export const ProvisioningSideMenu = ({
  isOpen,
  onClose,
  nonProvisionedDevices,
  isDevicesLoading,
  bulkProvisionDeviceToAws,
}: Props): JSX.Element => {
  const { deviceProfiles } = useDeviceProfiles();
  const { devices, isDevicesBulkLoading } = useGetDevicesByIdBulk({
    devicesIds: nonProvisionedDevices.map(d => d.device_id),
    enabled: isOpen && !isDevicesLoading
  });

  const [selectedProfile, setSelectedProfile] = useState('');
  const classes = useStyles();
  const isLoading = isDevicesLoading || isDevicesBulkLoading;

  return (
    <CommonDialog
      testId="filters-block"
      isOpen={ isOpen }
      onClose={ onClose }
      title="Provide to AWS"
      content={ <>
        <SingleSelectControl
          label="Device Profile"
          name="test"
          selected={ selectedProfile }
          values={ deviceProfiles.map(p => ({ label: p.name, value: p.id })) }
          changeHandler={ v => v && setSelectedProfile(v) }
        />
      </> }
      actions={
        <>
          <Button
            color="primary"
            disabled={ isLoading }
            onClick={ onClose }
          >Close</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={ isLoading || !selectedProfile}
            onClick={ async () => {
              onClose();
              await bulkProvisionDeviceToAws(addAppKeys(nonProvisionedDevices, devices), selectedProfile);
            }}
            className={ classes.buttonText }
          >
            Provision
          </Button>
        </>
      }
    />
  );
};
