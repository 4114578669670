import React from 'react';
import { Helmet } from 'react-helmet-async';

import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { CustomLink } from 'components/Links';
import { Typography } from '@mui/material';
import { NotInterested } from '@mui/icons-material';

interface Props {
  pageTitle?: string;
}

export const UserNotPermittedComponent = ({ pageTitle }: Props): JSX.Element => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexDirection="column" data-testid="user-not-permitted">
      <Helmet><title>Nwave Console { pageTitle ? `| ${pageTitle}` : '' }</title></Helmet>
      <NotInterested fontSize="large" color="error" />
      <Typography variant="h6">
        You don't have permissions to see this page
      </Typography>

      <Box mt={ 1 }>
        <Link color="secondary" component={ CustomLink } to={ '/' }>Return to Home</Link>
      </Box>
    </Box>
  );
};
