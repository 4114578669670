import React from 'react';
import { MarkerColor, getPositionMarkerLegend } from 'utils/map';

// components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';

// styles
import { LegendStyles } from 'styles';

export const PositionLegend = () => {
  const classes = LegendStyles();
  return (
    <Box lineHeight={ 1 } justifyContent="center" className={ classes.legendBox }>
      <div className={ classes.iconContainer }>
        <Icon><img src={ getPositionMarkerLegend(MarkerColor.green) } alt="Green marker"/></Icon>
        <Typography variant="subtitle2" align="center">device is OK</Typography>
      </div>
      <div className={ classes.iconContainer }>
        <Icon><img src={ getPositionMarkerLegend(MarkerColor.yellow) } alt="Yellow marker"/></Icon>
        <Typography variant="subtitle2" align="center">unconfirmed non-critical incident</Typography>
      </div>
      <div className={ classes.iconContainer }>
        <Icon><img src={ getPositionMarkerLegend(MarkerColor.orange) } alt="Orange marker"/></Icon>
        <Typography variant="subtitle2" align="center">unconfirmed critical incident</Typography>
      </div>
      <div className={ classes.iconContainer }>
        <Icon><img src={ getPositionMarkerLegend(MarkerColor.red) } alt="Red marker"/></Icon>
        <Typography variant="subtitle2" align="center">confirmed critical incident</Typography>
      </div>
      <div className={ classes.iconContainer }>
        <Icon><img src={ getPositionMarkerLegend(MarkerColor.transparent) } alt="Transparent marker"/></Icon>
        <Typography variant="subtitle2" align="center">empty position</Typography>
      </div>
    </Box>
  );
};
