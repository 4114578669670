import { config } from './config';
import { CreateDeviceParam, Device, DeviceProfile, ListDeviceParam, UpdateDeviceParam } from 'models/dms';
import { ApiResponse } from 'models';
import { errorHandler, formatQuery } from '../utils';
import { API } from 'aws-amplify';

const urls = {
  deviceCreate: '/device',
  deviceUpdate: (deviceId: number) => `/device/${ deviceId }`,
  deviceList: '/devices',
  deviceStatus: (deviceId: number) => `/devices/${ deviceId }/status_history`,
  deviceProfiles: '/devices/profiles',
};

export const listDeviceProfiles = (): Promise<ApiResponse<DeviceProfile[]>> => {
  return API
    .get(config.name, urls.deviceProfiles, {})
    .catch(errorHandler);
};

export const listDevice = (param: ListDeviceParam): Promise<ApiResponse<Device[]>> => {
  const options = {
    queryStringParameters: formatQuery({
      owner_id: param.owner_id,
      project_id: param.project_id,
      limit: param.limit ?? 100,
      offset: param.offset ?? 0,
    })
  };

  return API
    .get(config.name, urls.deviceList, options)
    .catch(errorHandler);
};

export const createDevice = (param: CreateDeviceParam): Promise<ApiResponse<Device>> => {
  const options = {
    body: {
      owner_id: param.owner_id,
      project_id: param.project_id,
      lorawan_id: param.lorawan_id,
      device_profile_id: param.device_profile_id,
      comment: param.comment,
    }
  };

  return API
    .post(config.name, urls.deviceCreate, options)
    .catch(errorHandler);
};

export const updateDevice = (id: number, param: UpdateDeviceParam): Promise<ApiResponse<Device>> => {
  const options = {
    body: {
      owner_id: param.owner_id,
      project_id: param.project_id,
      comment: param.comment,
    }
  };

  return API
    .patch(config.name, urls.deviceUpdate(id), options)
    .catch(errorHandler);
};
