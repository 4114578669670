import React, { useEffect } from 'react';
import { Card, CardContent, List, ListItem, ListItemText, Typography, CardHeader, ListItemIcon } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { DoFetchWarranty } from 'actions/provisioning';
import { SingleSelectProps } from 'components/Controls/Select/types';

import useStyles from './styles';
import { LoaderSvg } from 'components/Loaders';
import { Add } from '@mui/icons-material';
import { useDialog } from 'hooks';
import { CreateWarrantyModal } from 'components/Provisioning/CreateWarrantyModal/CreateWarrantyModal';

type Props = Partial<Omit<SingleSelectProps, 'changeHandler' | 'selected' | 'filter'>> & {
  selected?: string;
  selectWarranty: (id?: string) => void;
};

export const WarrantyCustomSelect = ({ selected, selectWarranty, isDisabled }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const createModal = useDialog();
  const warranties = useSelector((state: RootState) => state.provisioning.warranty);

  useEffect(() => {
    if (!warranties.isFetched) {
      dispatch(DoFetchWarranty());
    }
  }, [warranties.isFetched, dispatch]);

  if (warranties.isFetching) {
    return <Typography> Warranty: <LoaderSvg /> </Typography>;
  }

  if (isDisabled) {
    const currentWarranty = warranties.list.find(warranty => warranty.id === selected);

    if (!currentWarranty) {
      return <Typography> Shipment without warranty</Typography>;
    }

    const maxWarranty = Math.max(
      currentWarranty.max_keeping_in_warehouse ?? 0,
      currentWarranty.max_warranty_after_install ?? 0
    );
    return (
      <>
        <Typography> Warranty: { currentWarranty.name || 'No' } </Typography>
        <Typography> Maximum: { maxWarranty } Months</Typography>
        <Typography> Storage: { currentWarranty.max_keeping_in_warehouse } Months</Typography>
        <Typography> Operation: { currentWarranty.max_warranty_after_install } Months</Typography>
      </>
    );
  }

  return (
    <>
      <Card>
        <CardHeader title="Change warranty" />
        <CardContent className={ classes.root }>
          <List>
            <ListItem key="create" button onClick={ createModal.open }>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary={ 'Create new warranty' } />
            </ListItem>
            { warranties.list.map(warranty => {
              return (
                <ListItem
                  key={ warranty.id }
                  button
                  selected={ selected === warranty.id }
                  onClick={ () => selectWarranty(warranty.id) }
                >
                  <ListItemText
                    primary={ warranty.name }
                    secondary={
                      <>
                        <Typography component="span">
                          Storage: { warranty.max_keeping_in_warehouse } Months
                        </Typography>
                        <br />
                        <Typography component="span">
                          Operation: { warranty.max_warranty_after_install } Months
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              );
            }) }
          </List>
        </CardContent>
      </Card>

      { createModal.isMounted && <CreateWarrantyModal
        isOpen={ createModal.isOpen }
        onClose={ createModal.close }
        onCloseEnd={ createModal.unmount }
        selectCreatedWarranty={ selectWarranty }
      /> }
    </>
  );
};
