import { useForm } from 'react-hook-form-v6';
import moment from 'moment-timezone';
import { useCreateProject, useUpdateProject, useDeleteProject } from 'hooks/user-managment';
import { Project } from 'models/user-management';

type Params = {
  defaultValues?: Partial<Project>;
  onCreateSuccess?: (project: Partial<Project>) => void;
  onUpdateSuccess?: (project: Partial<Project>) => void;
  onDeleteSuccess?: () => void;
}

export const useProjectForm = ({
  defaultValues,
  onCreateSuccess,
  onUpdateSuccess,
  onDeleteSuccess,
}: Params) => {
  const { createProject } = useCreateProject(onCreateSuccess);
  const { updateProject } = useUpdateProject(onUpdateSuccess);
  const { deleteProject } = useDeleteProject(onDeleteSuccess);

  const _defaultValues = defaultValues || {
    name: '',
    timezone: moment.tz.guess(),
    owner_id: undefined,
  };

  const { control, getValues, formState: { errors, isDirty, isValid }, reset } = useForm({
    defaultValues: _defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return {
    defaultValues: _defaultValues,
    control,
    getValues,
    errors,
    reset,
    isDirty,
    isValid,
    createProject,
    updateProject,
    deleteProject,
  };
};
