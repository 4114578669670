import React from 'react';
import { ApiKey, UserGroup } from 'models/user-management';
import { useApiKeysParamsSelector } from 'hooks/user-managment';
import { useAuthUserSelector, useDialog, useDialogWithItem } from 'hooks';
import { canCreateApiKey, canDeleteApiKey, canEditApiKey } from 'utils/permissions';
import { formatLongText } from 'utils/format';
import { getClientApiKeyDetailPath, getProfileApiKeyDetailPath } from 'routing/paths';
// components
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import List from '@mui/material/List';
import { ListItemSkeleton } from 'components/Skeleton';
import { ApiKeyCreateDialog, ApiKeyDeleteDialog } from 'components/UserManagement';
import { AddButton, CopyToClipboardButton, DeleteButton, EditButton } from 'components/Buttons';
// styles
import { useStyles } from './styles';

interface Props {
  isProfile?: boolean;
  userGroup: UserGroup;
}

export const ApiKeyList = (props: Props): JSX.Element => {
  const { isProfile, userGroup } = props;
  const classes = useStyles();
  const user = useAuthUserSelector();
  const createDialog = useDialog();
  const deleteDialog = useDialogWithItem<ApiKey>();

  const { isLoading, apiKeys } = useApiKeysParamsSelector({ owner_id: userGroup.id });
  return (
    <Card className={ classes.cardRoot }>
      <CardHeader title="Client API Auth Tokens" action={
        canCreateApiKey(user) && <AddButton type="icon" onClick={ createDialog.open }/>
      }/>
      <CardContent>
        <List>
          { isLoading ? <ListItemSkeleton itemsCount={ 5 }/> :
            apiKeys.map(apiKey => {
              return (
                <ListItem key={ apiKey.id }>
                  <ListItemText
                    primary={ apiKey.name }
                    secondaryTypographyProps={ { component: 'div' } }
                    secondary={ formatLongText(apiKey.key, 100) }
                  />
                  <ListItemSecondaryAction>
                    <CopyToClipboardButton
                      type="icon"
                      value={ apiKey.key }
                      copied={ `Token ${ apiKey.name } was copied` }
                    />
                    { !canEditApiKey(user) ? '' :
                      <EditButton
                        type="icon"
                        LinkTo={ isProfile
                          ? getProfileApiKeyDetailPath(apiKey.id)
                          : getClientApiKeyDetailPath(apiKey.owner_id, apiKey.id)
                        }
                      />
                    }
                    { !canDeleteApiKey(user) ? '' :
                      <DeleteButton
                        type="icon"
                        onClick={ () => deleteDialog.open(apiKey) }
                      />
                    }
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }) }
        </List>
      </CardContent>
      { createDialog.isMounted && <ApiKeyCreateDialog
        ownerId={ userGroup.id }
        isOpen={ createDialog.isOpen }
        onClose={ createDialog.close }
        onCloseEnd={ createDialog.unmount }
      /> }
      { deleteDialog.isMounted && deleteDialog.item && <ApiKeyDeleteDialog
        apiKey={ deleteDialog.item }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
      /> }
    </Card>
  );
};
