import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { ResponsiveLine, Serie } from '@nivo/line';
import { OrdinalColorsInstruction } from '@nivo/colors';
import outerTheme from 'styles/themes/outer';
import { DamageLogByDate } from '../../models/device-management';
import { IncidentHistory } from '../../models/device-monitoring/incidentHistory';
import { props as LineChartsOptions } from './ChartsOptions/LineChartsOptions';
import { CustomSliceTooltip } from './CustomSliceTooltip';
import { axisFormat, formatAxisBottom } from './ChartsOptions/CommonOptions';
import { LinearScale } from '@nivo/scales';

interface Props {
  damages: DamageLogByDate[];
  silents: IncidentHistory[];
  chartData: Map<string, number | null>;
}

const transformData = (data: Map<string, number | null>, title: string): Serie => {
  const res: Serie = {
    id: title,
    data: []
  };

  // prevent gaps
  let last = 0;
  for (const [key, value] of data) {
    const currentValue = (value === null ? last : value);
    last = currentValue;
    res.data.push({ x: key, y: currentValue < 0 ? 0 : currentValue });
  }

  return res;
};

function isChartFilled(data: Serie[]): boolean {
  return data.some(line => line.data.some(({ y }) => y && y > 0));
}

export const BrokenDevicesChart = (props: Props): JSX.Element => {
  const { chartData } = props;
  const colors: OrdinalColorsInstruction = [outerTheme.palette.error.main, outerTheme.palette.secondary.main];
  const silents: Map<string, number | null> = new Map(chartData);
  const damages: Map<string, number | null> = new Map(chartData);
  const max = props.damages.concat(props.silents).some(e => e.total > 10) ? 'auto' : 10;

  for (const stat of props.damages) {
    const day = moment(stat.date, 'YYYY-MM-D').format('YYYY-MM-DD');
    if (damages.has(day)) {
      damages.set(day, stat.total >= 0 ? stat.total : null);
    }
  }

  for (const stat of props.silents) {
    const day = moment(stat.date).format('YYYY-MM-DD');
    if (silents.has(day)) {
      silents.set(day, stat.total);
    }
  }

  const data: Serie[] = [
    transformData(damages, 'Failures'),
    transformData(silents, 'Silent')
  ];

  return (
    <Box
      data-filled={ isChartFilled(data) ? 'true' : 'false' }
      data-testid="chart"
      width="100%"
      height="100%"
    >
      <ResponsiveLine
        { ...LineChartsOptions }
        data={ data }
        yScale={ {
          ...LineChartsOptions.yScale as LinearScale,
          max
        } }
        colors={ colors }
        sliceTooltip={ CustomSliceTooltip }
        axisTop={ {
          ...LineChartsOptions.axisTop,
          legend: 'Issues in the field',
        } }
        axisLeft={ { format: axisFormat } }
        axisBottom={ {
          ...LineChartsOptions.axisBottom,
          format: formatAxisBottom(data[0].data.length, 30)
        } }
      />
    </Box>
  );
};
