// components
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';
// styles
import useStyles from 'styles/filters';

export interface FilterState {
  name?: string;
  owner?: number;
}

interface Props {
  canViewOwner: boolean;
  state: FilterState;
  setState: (filters: FilterState) => void;
}

const Component = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { canViewOwner, state, setState } = props;

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      { !canViewOwner ? '' :
        <OwnersSingleSelectControl
          selected={ state.owner }
          changeHandler={ (owner?: number) => setState({ ...state, owner }) }
        />
      }
      <TextControl
        name="name"
        label="Name"
        value={ state.name ?? '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, name: event.target.value }) }
      />
    </form>
  );
};

export default Component;
