import React from 'react';

import { useAuthUserSelector, useDialogWithItem } from 'hooks';
import { Router } from 'models/caller';
import { isAllowedToCreateCaller, isAllowedToDeleteCaller } from 'utils/permissions';
import { commonOptions } from 'utils/tables';

// components
import { Box } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions, MUIDataTableOptions } from 'mui-datatables';

import { ZoneLabel } from 'components/Labels';
import { DeleteButton, PlusButton } from 'components/Buttons';
import { TableLoadingLayout } from 'components/Table';

import { DisconnectZoneDialog } from './DisconnectZoneDialog';

// styles
import { ThemeProvider } from 'styles/utils';
import { getTemplateSuitePageTableTheme } from 'pages/Caller/TemplateSuitePageNew/styles';

interface TemplateSuiteZonesTableProps {
  routes: Router[];
  zoneIds: number[];
  isLoading: boolean;
  className?: string;
  onZoneDisconnect?: () => void;
  onZoneConnect?: () => void;
}

export const TemplateSuiteZonesTable = ({
  routes,
  zoneIds,
  isLoading,
  className,
  onZoneDisconnect,
  onZoneConnect,
}: TemplateSuiteZonesTableProps) => {
  const detachZoneDialog = useDialogWithItem<number>();
  const user = useAuthUserSelector();

  const actionsColumnOptions: MUIDataTableColumnOptions = {
    customHeadLabelRender: () => {
      return !isAllowedToCreateCaller(user.data)
        ? null
        : (
          <Box display="flex" justifyContent="flex-end">
            <PlusButton
              type="icon"
              label="Connect Zone"
              compact
              onClick={ onZoneConnect }
              dataTestId="connect-zone-button"
            />
          </Box>
        );
    },
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'routeId',
      label: 'Connected Zones',
      options: {
        customBodyRenderLite: dataIndex => {
          const zoneId = zoneIds[dataIndex];
          return <ZoneLabel zoneId={ zoneId } />;
        },
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        ...actionsColumnOptions,
        customBodyRenderLite: dataIndex => {
          const zoneId = zoneIds[dataIndex];

          return !isAllowedToDeleteCaller(user.data)
            ? <span />
            : (
              <Box display="flex" justifyContent="flex-end" position="relative" right="-6px">
                <DeleteButton
                  type="text"
                  label="Disconnect"
                  onClick={ () => detachZoneDialog.open(zoneId) }
                  dataTestId="disconnect-zone-button"
                />
              </Box>
            );
        }
      },
    },
  ];

  const rowsPerPage = 5;

  const tableOptions: MUIDataTableOptions = {
    ...commonOptions,
    elevation: 0,
    rowsPerPage,
    rowsPerPageOptions: [rowsPerPage],
    textLabels: {
      body: {
        noMatch: 'No zone connected',
      },
    },
  };

  return (
    <>
      <ThemeProvider
        theme={ getTemplateSuitePageTableTheme({
          empty: !zoneIds.length,
          loading: isLoading,
        }) }
      >
        <div className={ className }>
          <TableLoadingLayout isLoading={ isLoading }>
            <MUIDataTable
              title={ null }
              options={ tableOptions }
              columns={ columns }
              data={ zoneIds.map(zoneId => ({ zoneId })) }
            />
          </TableLoadingLayout>
        </div>
      </ThemeProvider>

      { detachZoneDialog.isMounted && detachZoneDialog.item && (
        <DisconnectZoneDialog
          routes={ routes.filter(route => route.zone_id === detachZoneDialog.item) }
          isOpen={ detachZoneDialog.isOpen }
          onClose={ detachZoneDialog.close }
          onCloseEnd={ detachZoneDialog.unmount }
          onSuccess={ onZoneDisconnect }
        />
      ) }
    </>
  );
};
