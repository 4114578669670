import { Theme } from '@mui/material/styles';

export const getDatePickerPaperStyles = (theme: Theme) => ({
  '& .PrivatePickersToolbar-root': {
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(255, 255, 255, 0.54)',
    '& .MuiTypography-root': {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    '& .MuiTypography-root.Mui-selected': {
      color: 'rgba(255, 255, 255)',
    }
  },
  '& .MuiPickersDay-root': {
    fontSize: '0.75rem',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  }
});