import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { RootState } from 'reducers';
import { DoFetchShipmentById } from 'actions/provisioning';
import {
  Shipment,
  ShipmentParams,
  Device,
  ReceivedDevice,
  ShipmentStatusEnum,
  AwsDevice,
  ShipmentDeviceForAws
} from 'models/provisioning';
import {
  getDeliveredShipmentDevices,
  getNonDeliveredShipmentDevices,
  getAwsShipmentDevices,
  getDeviceProfiles,
  GetShipmentDevicesParams,
  fetchProvisioningShipments,
  fetchProvisioningReceivedShipments,
  getShipmentById,
} from 'clients/provisioning';
import { useFormActionNotifier } from 'hooks';
import { getResponseData } from 'utils/clients';

export const useShipmentById = (id?: string, enabled = true) => {
  const { notifyError } = useFormActionNotifier();
  return useQuery({
    queryKey: ['/provisioning/shipments', id],
    queryFn: async () => id && getResponseData(await getShipmentById(id)),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching shipment by id: ${ (e as Error).message }`);
    },
    enabled
  });
};

export const useProvisioningShipments = (params: Partial<ShipmentParams>, enabled = true) => {
  const { notifyError } = useFormActionNotifier();
  return useQuery({
    queryKey: ['provisioning/shipments', params],
    queryFn: async () => getResponseData(await fetchProvisioningShipments(params)),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching shipments: ${ (e as Error).message }`);
    },
    enabled
  });
};

export const useProvisioningReceivedShipments = (params: Partial<ShipmentParams>, enabled = true) => {
  const { notifyError } = useFormActionNotifier();
  return useQuery({
    queryKey: ['provisioning/received/shipments', params],
    queryFn: async () => getResponseData(await fetchProvisioningReceivedShipments(params)),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching received shipments: ${ (e as Error).message }`);
    },
    enabled
  });
};

export const useNonReceivedShipmentDevices = (params: Partial<ShipmentParams>, enabled = true) => {
  const { notifyError } = useFormActionNotifier();
  const nonDeliveredQuery = useQuery({
    queryKey: ['provisioning/shipment/devices', params],
    queryFn: async () => {
      const cursor = 0;
      const reqFn = async (offset: number): Promise<Device[]> => {
        const res = getResponseData(await getNonDeliveredShipmentDevices({
          ...params,
          limit: CHUNK_SIZE,
          offset,
        }));
        return res.length === 500  ? [ ...res, ...await reqFn(offset + CHUNK_SIZE) ] : res;
      };
      return reqFn(cursor);
    },
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching shipment non delivered devices: ${ (e as Error).message }`);
    },
    enabled
  });

  return nonDeliveredQuery;
};

export const useReceivedShipmentsDevices = (params: GetShipmentDevicesParams, enabled = true) => {
  const { notifyError } = useFormActionNotifier();
  const deliveredQuery = useQuery({
    queryKey: ['provisioning/shipment/received/devices', params],
    queryFn: async () => {
      const cursor = 0;
      const reqFn = async (offset: number): Promise<ReceivedDevice[]> => {
        const res = getResponseData(await getDeliveredShipmentDevices({
          ...params,
          limit: CHUNK_SIZE,
          offset,
        }));
        return res.length === 500  ? [ ...res, ...await reqFn(offset + CHUNK_SIZE) ] : res;
      };
      return reqFn(cursor);
    },
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching shipment devices: ${ (e as Error).message }`);
    },
    enabled
  });

  return deliveredQuery;
};


export interface ShipmentState {
  shipment?: Shipment;
  isLoading: boolean;
}

// @TODO deprecatted. Move to useProvisioningShipments
export function useProvisioningShipmentSelector(shipmentId: string, enabled = true): ShipmentState {
  const dispatch = useDispatch();
  useEffect(() => {
    enabled && dispatch(DoFetchShipmentById(shipmentId.toUpperCase()));
  }, [dispatch, enabled, shipmentId]);

  const state: ShipmentState = useSelector((state: RootState) => ({
    isLoading: state.provisioning.shipment.isFetching,
    shipment: state.provisioning.shipment.shipments.find(s => s.id.toUpperCase() === shipmentId.toUpperCase()),
  }), shallowEqual);

  return state;
}

type NormalizeDevicesData = {
  delivered?: Device[];
  nonDelivered?: Device[];
  awsDevices: AwsDevice[];
  status?: ShipmentStatusEnum
}

const isDelivered = (status: ShipmentStatusEnum) =>
  status === ShipmentStatusEnum.Received || status === ShipmentStatusEnum.InAWarehouse;

const LORA_ID_MASK = /1[a-z0-9]{5}/i;
const normalizeLoraDevices = ({
  delivered,
  nonDelivered,
  awsDevices,
  status,
}: NormalizeDevicesData): ShipmentDeviceForAws[] => {
  const devices: Device[] = (status && isDelivered(status) ? delivered : nonDelivered) || [];
  const loraDevices = devices.filter(d => d.device_id.match(LORA_ID_MASK));
  const shipmentDevice: ShipmentDeviceForAws[] = loraDevices.map(d => {
    const awsDevice = awsDevices.find(awsDevice => awsDevice.device_id === d.device_id);
    return awsDevice ?
      {
        ...d,
        dev_eui: awsDevice.dev_eui,
        aws_id: awsDevice.id
      }
      : d;
  });
  return shipmentDevice.sort((x, y) => (Boolean(x.dev_eui) === Boolean(y.dev_eui)) ? 0 : x.dev_eui ? -1 : 1);
};

const CHUNK_SIZE = 500;
export function useGetShipmentDevices(shipment?: Shipment) {
  const { notifyError } = useFormActionNotifier();

  const shipmentId = shipment?.id || '';

  const nonReceivedQuery = useNonReceivedShipmentDevices(
    { shipment_id: [shipmentId] },
    !!shipmentId && shipment?.status && !isDelivered(shipment.status)
  );

  const receivedQuery = useReceivedShipmentsDevices(
    { shipmentId },
    !!shipmentId && shipment?.status && isDelivered(shipment.status)
  );

  const awsDevices = useQuery({
    queryKey: ['provisioning/aws/devices', shipmentId],
    queryFn: async () => {
      const cursor = 0;
      const reqFn = async (offset: number): Promise<AwsDevice[]> => {
        const res = getResponseData(await getAwsShipmentDevices({
          limit: CHUNK_SIZE,
          offset,
          shipmentId: shipmentId,
        }));
        return res.length === 500 ? [ ...res, ...await reqFn(offset + CHUNK_SIZE) ] : res;
      };
      return reqFn(cursor);
    },
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching shipment aws devices: ${ (e as Error).message }`);
    },
    enabled: !!shipment && !!shipmentId
  });

  const devices = useMemo(() => normalizeLoraDevices({
    delivered: receivedQuery.data as Device[], // @TODO fix this
    nonDelivered: nonReceivedQuery.data,
    awsDevices: awsDevices.data || [],
    status: shipment?.status
  }), [receivedQuery.data, nonReceivedQuery.data, awsDevices.data, shipment]);
  const provisionedDevices = useMemo(() => devices.filter(d => d.dev_eui), [devices]);
  const nonProvisionedDevices = useMemo(() => devices.filter(d => !d.dev_eui), [devices]);

  return {
    devices,
    provisionedDevices,
    nonProvisionedDevices,
    isDevicesLoading: nonReceivedQuery.isFetching || receivedQuery.isFetching || awsDevices.isFetching
  };
}

export const useDeviceProfiles = () => {
  const { notifyError } = useFormActionNotifier();
  const { data, isLoading } = useQuery({
    queryKey: 'provisioning/aws/devices/profiles',
    queryFn: getDeviceProfiles,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching shipment aws devices: ${ (e as Error).message }`);
    },
  });

  return {
    deviceProfiles: data?.data || [],
    isDeviceProfilesLoading: isLoading,
  };
};
