import clsx from 'clsx';
import React from 'react';
import { ResolvedStatuses } from 'models';
import { Incident } from 'models/device-monitoring';
import { Zone } from 'models/device-management';
import { Defined } from 'utils/models';
import { hideColumns } from 'utils/tables';
import { createHiglightingNewRows } from 'utils/mui-datatables';

import { getMuiDatatableOptions } from './getMuiDatatableOptions';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';

import { DeviceLink } from 'components/Links';
import { IncidentErrorType, IncidentReason, ZoneLabel } from 'components';
import { PositionName } from 'components/Labels';
import { TableLoadingLayout } from 'components/Table';

// styles
import useStyles from './IncidentsTableStyle';
import { getMuiTheme } from 'styles/themes';


type IncidentsColumnName = (
  | 'device_id'
  | 'network_id'
  | 'zone'
  | 'error_type'
  | 'resolving_status'
  | 'incident_appearing_time'
  | 'reason'
  | 'details'
);

interface Props {
  data: Incident[];
  isFetching: boolean;
  csvFetching: boolean;
  fetchReport: () => void;
  startSync: () => void;
  stopSync: () => void;
  total: number;
  className?: string;
  muiDataTableOptions?: MUIDataTableOptions;
  visibleColumns?: IncidentsColumnName[];
}

const getTheme = () => {
  const theme = getMuiTheme();
  return {
    ...theme,
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            lineHeight: 'initial',
            fontSize: 14,
          }
        }
      },
      MUIDataTable: {
        styleOverrides: {
          tableRoot: {
            width: '98%',
            margin: '0 auto',
          },
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          }
        }
      },
    },
  };
};

export const IncidentsTable = (props: Props): JSX.Element => {
  const classes = useStyles();

  const [autoUpdate, changeAutoUpdateValue] = React.useState(false);
  const handleChangeAutoUpdate = () => {
    changeAutoUpdateValue(!autoUpdate);

    if (autoUpdate) {
      props.stopSync();
    } else {
      props.startSync();
    }
  };

  let columns: MUIDataTableColumn[] = [
    {
      name: 'device_id',
      label: 'Device ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id: string) => <DeviceLink deviceId={ id } />
      },
    },
    {
      name: 'network_id',
      label: 'Network ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (network: string) => <PositionName network={ network } />
      },
    },
    {
      name: 'zone',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (zoneId: Defined<Zone['id']>) => zoneId
          ? <ZoneLabel zoneId={ zoneId } />
          : 'Unknown'
      }
    },
    {
      name: 'error_type',
      label: 'Error Type',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => <IncidentErrorType errorType={ props.data[dataIndex].error_type }/>
      },
    },
    {
      name: 'resolving_status',
      label: 'Resolution Status',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'incident_appearing_time',
      label: 'Detection Time',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => <IncidentReason reason={ props.data[dataIndex].reason }/>
      },
    },
  ];
  columns = hideColumns(columns, {
    visibleColumns: props.visibleColumns
  });

  const options = getMuiDatatableOptions({
    total: props.total,
    rowDetails: props.data,

    autoUpdate,
    onChangeAutoUpdate: handleChangeAutoUpdate,

    fetchReport: props.fetchReport,
    csvFetching: props.csvFetching,
    muiDatatableOptionsOverrides: {
      ...props.muiDataTableOptions,
      setRowProps: createHiglightingNewRows(props.data)
    }
  });

  return (
    <div className={ clsx(classes.incedentTableRoot, props.className) }>
      <ThemeProvider theme={ getTheme() }>
        <TableLoadingLayout isLoading={props.isFetching}>
          <MUIDataTable
            title={ null }
            data={ props.data.map((el) => (
              {
                device_id: el.device_id.toUpperCase() || '—',
                network_id: el.network_id,
                zone: el.zone_id,
                resolving_status: el.is_resolved ? ResolvedStatuses.resolved : ResolvedStatuses.not_resolved,
                incident_appearing_time: new Date(el.created_time.toString()).toLocaleString(),
              }
            )) }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </ThemeProvider>
    </div>
  );
};
