import { useQuery } from 'react-query';
import { ApiResponse } from 'models';
import { GetStationLocationParams, StationLocation } from 'models/base-station';
import { useFormActionNotifier } from 'hooks/form';
import { getLocationsByParams } from 'clients/base-stations';
import { ensureRequestSucceeded } from 'utils/clients';
import { LOCATION_KEY } from './query';

export interface LocationsState {
  locations: StationLocation[];
  isLoading: boolean;
}

export function useStationLocationsSelector(params: GetStationLocationParams, enabled = true): LocationsState {
  const { notifyError } = useFormActionNotifier();

  const query = useQuery({
    queryKey: [`${LOCATION_KEY}/selector`, params],
    queryFn: async (): Promise<ApiResponse<StationLocation[]>> => {
      const result: ApiResponse<StationLocation[]> = await getLocationsByParams(params);
      ensureRequestSucceeded(result);

      return result;
    },
    onError: e => notifyError(`Error while fetching base station locations: ${ (e as Error).message }`),
    enabled,
    cacheTime: Infinity,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    locations: query.data?.data ?? [],
    isLoading: query.isLoading
  };
}

export function useStationLocationsDictionary(enabled = true): LocationsState {
  const { notifyError } = useFormActionNotifier();

  const query = useQuery({
    queryKey: [`${LOCATION_KEY}/dictionary`],
    queryFn: async (): Promise<ApiResponse<StationLocation[]>> => {
      const result: ApiResponse<StationLocation[]> = await getLocationsByParams({ });
      ensureRequestSucceeded(result);

      return result;
    },
    onError: e => notifyError(`Error while fetching base station locations: ${ (e as Error).message }`),
    enabled,
    cacheTime: Infinity,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    locations: query.data?.data ?? [],
    isLoading: query.isLoading
  };
}