declare global {
  interface Window {
    __CI?: boolean;
  }
}

/**
 * Only build-time constants which are not supposed to be overridden at runtime.
 */
export const staticEnv = {
  LOCAL_DEBUG: process.env.REACT_APP_STAGE === 'debug',
  IS_PRODUCTION: process.env.REACT_APP_STAGE === 'production',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  MAILGUN_PASSWORD: process.env.REACT_APP_MAILGUN_PASSWORD,
  AUTOTEST: typeof window !== 'undefined' && Boolean(window.__CI),
  IS_PARKING_ANOMALY_ON: false, // Disable PositionParkingAnomaly - https://nwaveio.atlassian.net/browse/BNIV-1981
};