import React from 'react';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDialog, useForm, useFormEditToogleActions, useAuthUserSelector, useFormActionLoader } from 'hooks';
import { useProvisioningReceivedBoxes } from 'hooks/provisioning';
import { Box, BoxFormField } from 'models/provisioning';
import { DoAddBoxesToShipment, DoDeleteBox, DoRemoveBoxFromShipment, DoFetchBoxById } from 'actions/provisioning';
import { provisioningBoxesPath } from 'routing/paths';
import { canEditProvisioning, canDeleteProvisioning } from 'utils/permissions/provisioning';
import { qetReturnUrlParam } from 'utils/routing/query';

// components
import { Card, CardHeader, CardContent } from '@mui/material';
import { DeleteDialog } from './DeleteDialog';
import { RemoveFromShipmentDialog } from './RemoveFromShipmentDialog';
import { BoxForm } from './BoxForm/BoxForm';
import DeleteButton from 'components/Buttons/DeleteButton';
import { RemoveFromShipmentButton } from './RemoveFromShipmentButton';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  box: Box;
}

export const BoxManagment = ({ box }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useAuthUserSelector();
  const receivedBoxes = useProvisioningReceivedBoxes({
    filter: { boxes: [box.id], limit: 1 },
    enabled: isNil(box.shipment_id)
  });
  const receivedBox = receivedBoxes.boxes.find(b => b.box_id === box.id);

  const deleteDialog = useDialog();
  const location = useLocation();
  const deleteFromShipmentDialog = useDialog();
  const form = useForm<Box, BoxFormField>({
    initialState: { ...box, shipment_id: box.shipment_id ?? receivedBox?.shipment_id },
    showFields: [
      BoxFormField.id,
      BoxFormField.shipment_id,
      ...(user.isAdmin ? [BoxFormField.owner_id] : []),
    ],
  });
  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: !box.shipment_id && canEditProvisioning(user.data),
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      if (form.state.shipment_id && box.shipment_id !== form.state.shipment_id) {
        doAction({
          action: DoAddBoxesToShipment([box.id], form.state.shipment_id),
          onError: error => {
            form.reset();
            form.catchError(error);
          },
          onSuccess: () => {
            dispatch(DoFetchBoxById(box.id));
            return;
          }
        });
      }
    },
    onCancel: form.reset
  });

  const { returnUrl = provisioningBoxesPath } = qetReturnUrlParam(location);

  const handleDelete = () => doAction({
    action: DoDeleteBox(box.id),
    onSuccess: () => ({ to: returnUrl })
  });

  const handleRemoveFromShipment = () => doAction({
    action: DoRemoveBoxFromShipment(box.id),
    onSuccess: () => {
      dispatch(DoFetchBoxById(box.id));
      return;
    },
  });

  return (
    <Card className={ classes.cardRoot } data-testid="box-configuration">
      <CardHeader title="Box configuration" action={
        <>
          { actions }
          { editMode ? null :
            <>
              {
                canEditProvisioning(user.data) && (
                  <RemoveFromShipmentButton
                    open={ deleteFromShipmentDialog.open }
                    show={ !!box.shipment_id }
                  />
                )
              }
              {
                canDeleteProvisioning(user.data) &&
                  <DeleteButton type="icon" onClick={ deleteDialog.open }/>
              }
            </>
          }
        </>
      }/>
      <CardContent>
        <BoxForm
          isView={ !editMode }
          { ...form }
          disabledFields={ [BoxFormField.id, BoxFormField.owner_id] }
        />
      </CardContent>
      { deleteDialog.isMounted && <DeleteDialog
        box={ box }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
        onConfirm={ handleDelete }
      /> }
      { deleteFromShipmentDialog.isMounted && <RemoveFromShipmentDialog
        box={ box }
        isOpen={ deleteFromShipmentDialog.isOpen }
        onClose={ deleteFromShipmentDialog.close }
        onCloseEnd={ deleteFromShipmentDialog.unmount }
        onConfirm={ handleRemoveFromShipment }
      /> }
    </Card>
  );
};
