import { useMutation } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { getResponseData } from 'utils/clients';
import { clearQueryCache } from 'utils/react-query';
import { CreateDeviceParam } from 'models/dms';
import { createDevice } from 'clients/dms';


export function useDMSCreateDeviceMutation() {
  const notifier = useFormActionNotifier();

  const mutation = useMutation({
    mutationFn: async (param: CreateDeviceParam) => {
      const res = await createDevice(param);
      return getResponseData(res);
    },
    onError: (e) => {
      notifier.notifyError(`Error while creating device: ${ (e as Error).message }`);
    },
    onSuccess: () => {
      notifier.notifySuccess('Device has been created');
      return clearQueryCache('dms/devices/');
    },
  });

  return {
    isLoading: mutation.isLoading,
    mutate: mutation.mutate
  };
}