import React from 'react';
import { getClientsListPath } from 'routing/paths';
// components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CommonButton from 'components/Buttons/CommonButton';

interface Props {
  clientId: number;
}

export const ClientNotFound = ({ clientId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Client #{ clientId } not found
      </Typography>

      <CommonButton type="button" label="Back to list" LinkTo={ getClientsListPath() } icon={ <ArrowBackIcon/> }/>;
    </Box>
  );
};
