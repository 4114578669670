import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Template, TemplateMethod } from 'models/caller';
import { CreateTemplate } from 'actions/caller';
import { getCallerTemplateDetailPath, getCallerTemplateSuiteTemplatesPath } from 'routing/paths/caller';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
// component
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { CallerTemplateForm, TemplateFormField } from 'components/Caller';
// styles
import { useStyles } from './styles';

interface Props {
  templateSuiteId: number;
}

const TemplateCreatePage = (props: Props): JSX.Element => {
  const { templateSuiteId } = props;
  const classes = useStyles();
  const initialTemplate = {
    template_id: 0,
    template_suite_id: templateSuiteId,
    name: '',
    message_type: 'all',
    url: '',
    headers: {},
    method: TemplateMethod.POST,
  };

  const form = useForm<Template, TemplateFormField>({
    initialState: initialTemplate,
    showFields: [
      TemplateFormField.name,
      TemplateFormField.templateSute,
      TemplateFormField.messageType,
      TemplateFormField.url,
      TemplateFormField.method,
      TemplateFormField.headers,
      TemplateFormField.body,
    ]
  });

  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    canSave: true,
    btnSave: 'Add',
    onSave: () => {
      if (form.validate()) {
        doAction({
          action: CreateTemplate(form.state),
          onSuccess: (result) => ({
            push: true,
            to: getCallerTemplateDetailPath(result.template_suite_id, result.template_id),
          }),
          onError: form.catchError
        });
      }
    },
    linkCancel: getCallerTemplateSuiteTemplatesPath(String(templateSuiteId))
  });

  return (
    <>
      <Helmet><title>{ `Http Template Create` }</title></Helmet>
      <Paper className={ classes.root }>
        <Card>
          <CardHeader title="Create Template" action={ actions }/>
          <CardContent>
            <CallerTemplateForm { ...form } />
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default TemplateCreatePage;
