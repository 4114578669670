import moment from 'moment';
import { Box } from '@mui/material';
import { ResponsiveLine, Serie, Datum } from '@nivo/line';
import { OrdinalColorsInstruction } from '@nivo/colors';
import outerTheme from 'styles/themes/outer';
import { props as LineChartsOptions } from './ChartsOptions/LineChartsOptions';
import { LinearScale } from '@nivo/scales';
import { CustomSliceTooltip } from './CustomSliceTooltip';
import { ConnectivityChartData } from 'models/connectivity';
import { formatAxisBottom } from './ChartsOptions/CommonOptions';

interface Props {
  connectivity: ConnectivityChartData[];
  chartData: Map<string, number | null>;
}

const prepare = (data: ConnectivityChartData[], connectivity: Map<string, number | null>): Datum[] => {
  const connectivityLog: Datum[] = [];

  for (const stat of data) {
    const day = moment(stat.saved_on, 'YYYY-MM-D').format('YYYY-MM-DD');

    if (connectivity.has(day)) {
      connectivity.set(day, stat.connectivity);
    }
  }

  for (const [key, value] of connectivity) {
    connectivityLog.push({ x: key, y: value || 0 });
  }

  return connectivityLog;
};

function isChartFilled(data: Serie[]): boolean {
  return data.some(line => line.data.some(({ y }) => y && y > 0));
}

export const ConnectivityChart = (props: Props): JSX.Element => {
  const { chartData, connectivity } = props;
  const colors: OrdinalColorsInstruction = outerTheme.palette.secondary.main;

  const connectivityLog: Serie[] = [
    {
      id: 'Connectivity',
      data: prepare(connectivity, chartData)
    },
  ];

  return (
    <Box
      data-filled={ isChartFilled(connectivityLog) ? 'true' : 'false' }
      data-testid="chart"
      width="100%"
      height="100%"
    >
      <ResponsiveLine
        { ...LineChartsOptions }
        data={ connectivityLog }
        sliceTooltip={ CustomSliceTooltip }
        colors={ colors }
        yScale={ {
          ...LineChartsOptions.yScale as LinearScale,
          max: 100
        } }
        axisTop={ {
          ...LineChartsOptions.axisTop,
          legend: 'Connectivity',
        } }
        axisLeft={ {
          ...LineChartsOptions.axisLeft,
          format: value => `${Number(value)}%`
        } }
        axisBottom={ {
          ...LineChartsOptions.axisBottom,
          format: formatAxisBottom(connectivityLog[0].data.length, 30)
        } }
      />
    </Box>
  );
};
