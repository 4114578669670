import React from 'react';
import { getDMGroupDetailPath } from 'routing/paths';

// components
import { Box, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { CommonButton } from 'components/Buttons';
import { PageParams } from '../utils';

export const PositionNotFound = ({ zoneId, levelId, groupId, positionId }: PageParams): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Position <b>{ `#${ positionId }` }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label={ `Back to group ${ groupId }` }
        LinkTo={ getDMGroupDetailPath(zoneId, levelId, groupId) }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};