import React from 'react';
import { useProvisioningBoxSelector, useProvisioningDeviceSelector, useProvisioningShipmentSelector } from 'hooks';
import { DeviceProvisioningState } from 'models/provisioning';
import { canEditProvisioning } from 'utils/permissions';

// components
import { Grid, Paper, Typography } from '@mui/material';
import { DialogButton, EditButton } from 'components/Buttons';
import { ProvisioningShipmentLink, ShipmentBoxLink } from 'components/Links';
import { FieldSkeleton } from 'components/Skeleton';
import { RemoveFromBoxDialog } from 'components/Provisioning/DeviceProvisioningManagement/widgets/RemoveFromBoxDialog';
import { ChangeDeviceStatusDialog } from 'components/Provisioning/Dialogs';
// styles
import useStyles from 'components/DeviceManagement/style';


interface Props {
  deviceId: string;
}

export const PositionDeviceProvision = ({ deviceId }: Props): JSX.Element => {
  const classes = useStyles();
  const { isLoading, device, reload } = useProvisioningDeviceSelector(deviceId);
  const { isLoading: isLoadingBox, box } = useProvisioningBoxSelector(device?.box_id ?? 0, Boolean(device?.box_id));
  const { isLoading: isLoadingShipment, shipment } = useProvisioningShipmentSelector(box?.shipment_id ?? '', Boolean(box?.shipment_id));

  return (
    <Paper className={ classes.root } data-testid="dm-position-device-provision">
      <Grid container alignItems="center">
        <Grid item lg={ 10 }>
          <Grid container spacing={ 3 } alignItems="center">
            <Grid item xs={ 3 }>
              <Typography>Status</Typography>
              <Typography className={ classes.itemValue }>
                { isLoading ? <FieldSkeleton height={ 24 }/> : (
                  device ? DeviceProvisioningState[device?.status] : '-'
                ) }
              </Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Box</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { isLoadingBox ? <FieldSkeleton height={ 24 }/> : (
                  box ? <ShipmentBoxLink id={ box.id }/> : 'Not in box'
                ) }
              </Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Shipment</Typography>
              <Typography className={ classes.itemValue }>
                { isLoadingShipment ? <FieldSkeleton height={ 24 }/> : (
                  !shipment ? '-' : (
                    <ProvisioningShipmentLink
                      shipmentId={ shipment.id }
                      withReturnUrl
                    >
                      { shipment.name }
                    </ProvisioningShipmentLink>
                  )
                ) }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={ 2 } className={ classes.buttonBlock }>
          { device && (
            <DialogButton
              permission={ canEditProvisioning }
              button={ open => <EditButton type="text" label="Edit" onClick={ open }/> }
              dialog={ props => (
                <ChangeDeviceStatusDialog
                  { ...props }
                  title={`Provisioning ${device?.device_id.toUpperCase()}`}
                  device={ device }
                  onSuccess={ () => reload() }
                />
              ) }
            />
          )}
          { device && (
            <DialogButton
              permission={ (user) => box !== undefined && canEditProvisioning(user) }
              button={ open => <EditButton type="text" label="Remove from box" onClick={ open }/> }
              dialog={ props => (
                <RemoveFromBoxDialog
                  { ...props }
                  device={ device }
                  onSuccess={ () => reload() }
                />
              ) }
            />
          ) }
        </Grid>
      </Grid>
    </Paper>
  );
};