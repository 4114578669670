import React from 'react';
// components
import TextField, { TextFieldProps } from '@mui/material/TextField';
// styles
import contentStyles from 'styles';
import clsx from 'clsx';

export const TextControl = (props: TextFieldProps): JSX.Element => {
  const classes = contentStyles();

  return (
    <TextField
      {...props}
      className={ clsx(classes.formControl, props.className) }
    />
  );
};
