import { History } from 'models/caller';
import { useCallerTemplateDictionary } from 'hooks/caller';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { CallerTemplateLink, CallerTemplateSuiteLink, DeviceLink, PositionLink } from 'components/Links';
import AdditionalInfo from './AdditionalInfo';

// styles
import { getMuiTheme } from 'styles/themes';

interface Props {
  isLoading: boolean;
  data: History[];
}

const Component = (props: Props): JSX.Element => {
  const {
    data,
    isLoading,
  } = props;

  const { templates } = useCallerTemplateDictionary();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'sending_time',
      label: 'Sending Time',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? new Date(value).toLocaleString() : '-')
      },
    },
    {
      name: 'position_network_id',
      label: 'Position Network ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (networkId, tableMeta) => {
          const historyRow: History = data[tableMeta.rowIndex];
          return <PositionLink positionId={ historyRow.position_id } positionName={ networkId } />;
        }
      },
    },
    {
      name: 'device_id',
      label: 'Device ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: deviceId => <DeviceLink deviceId={ deviceId } />
      },
    },
    {
      name: 'template_id',
      label: 'Suite/Template',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (templateId, tableMeta) => {
          const historyRow: History = data[tableMeta.rowIndex];
          const template = templates.find((x) => x.template_id === templateId);
          const suiteLink = <CallerTemplateSuiteLink templateSuiteId={ template?.template_suite_id } />;
          const templateLink = <CallerTemplateLink templateId={ templateId } templateName={ historyRow.template_name } />;

          return (
            <> { suiteLink } / { templateLink } </>
          );
        }
      },
    },
    {
      name: 'sending_status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'resp_code',
      label: 'Status Code',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: i => data[i].resp_code ?? '-',
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    customFooter: (rowCount) => {
      return (
        <PaginationFooter
          withoutTotal
          count={ rowCount }
        />
      );
    },

    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const history: History = data[rowMeta.dataIndex];

      if (!history) {
        return <></>;
      }

      return (
        <AdditionalInfo rows={ rowData.length + 1 } data={ history } />
      );
    }
  };

  return (
    <ThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={isLoading}>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

export default Component;
