import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUser, updateWithRelogin } from 'actions/user-management/users';
import { User, UserUpdateParams } from 'models/user-management';
import { RootState } from 'reducers';
import * as is from 'utils/form/validation/validators';
import { dispatchAsync } from 'utils/store';

// components
import { Button as MuiButton, FormGroup, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { Button, Form } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import * as Fields from 'components/Form/Field';
import { SuccessButton } from 'components/Buttons';

// styles
import { ThemeProvider } from 'styles/utils';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

type SelectedParams = 'first_name' | 'last_name' | 'user_group';

type AccountFormValues = Pick<UserUpdateParams['props'], SelectedParams> & {
  role: UserUpdateParams['props']['role'] | null;
};

interface AccountFormProps {
  user: User;
  onEditComplete: () => void;
}

const AccountForm: React.FC<AccountFormProps> = (props) => {
  const { user } = props;

  const { data: stateUser, isAdmin } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const [isMyProfileChange, changeMyProfile] = useState<boolean>(false);
  const [newUserProps, setNewUserProps] = useState<UserUpdateParams['props']>(user);

  const infoCss = useInfoBlockStyles();

  const handleSubmit = (values: AccountFormValues) => {
    // Error: "Only admins can set role", if nonAdmin user tries to change profile values https://nwaveio.atlassian.net/browse/BNIV-1941
    const role = isAdmin && values.role ? values.role : undefined;
    const userUpdateProps: UserUpdateParams['props'] = {
      ...user,
      ...values,
      role,
    };
    if(Number(stateUser.user_id) === user.id) {
      changeMyProfile(true);
    }
    setNewUserProps(userUpdateProps);

    return dispatchAsync(dispatch, updateUser({ sub: user.sub, props: userUpdateProps }));
  };

  const handleSubmitWithLogout = () => dispatch(updateWithRelogin({ sub: user.sub, props: newUserProps }));

  return (
    <>
      <Dialog
        open={ isMyProfileChange }
        onClose={ () => changeMyProfile(false) }
        fullWidth
      >
        <DialogContent>
          <Typography>You need to relogin for the changes to be applied</Typography>
        </DialogContent>
        <DialogActions>
          <SuccessButton label="Relogin now" onClick={ () => {
            handleSubmitWithLogout();
            changeMyProfile(false);
            props.onEditComplete();
          }}/>
          <MuiButton color="inherit" onClick={() => changeMyProfile(false)}>
            Skip
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Form<AccountFormValues>
        defaultValues={user}
        validators={{
          role: isAdmin ? is.required() : undefined,
          user_group: isAdmin ? is.required() : undefined,
        }}
        onSubmit={handleSubmit}
      >
        {form => (
          <Paper>
            <BlockToolbar>
              <BlockTitle>
                Edit account
              </BlockTitle>

              <MuiButton
                color="inherit"
                disabled={form.formState.isSubmitting}
                onClick={props.onEditComplete}
              >
                Cancel
              </MuiButton>

              <ThemeProvider theme={successTheme}>
                <Button
                  color="primary"
                  disabled={!form.formState.dirty}
                  pending={form.formState.isSubmitting}
                  type="submit"
                >
                Save
                </Button>
              </ThemeProvider>
            </BlockToolbar>

            <FormGroup className={infoCss.fields}>
              <Fields.Text
                className={infoCss.field}
                label="First name"
                name="first_name"
              />

              <Fields.Text
                className={infoCss.field}
                label="Last name"
                name="last_name"
              />

              {isAdmin && (
                <div className={infoCss.field}>
                  <Fields.SelectUserRole
                    name="role"
                    label="Role"
                  />
                </div>
              )}

              {isAdmin && (
                <div className={infoCss.field}>
                  <Fields.SelectOwner
                    name="user_group"
                    isClearable={false}
                    label="User Group"
                  />
                </div>
              )}
            </FormGroup>
          </Paper>
        )}
      </Form>
    </>
  );
};

export default AccountForm;
