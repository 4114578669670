import React from 'react';
import { RouteConfig, RouteLabelRendererProps } from 'react-router-config';
import { State as UserState } from 'reducers/user-profile';
import { getUserManagementClientRoutes } from './client';

import * as Pages from 'pages';
import * as paths from 'routing/paths/user-management';
import * as permission from 'utils/permissions';

import UserRouteLabel from './UserRouteLabel';
import { USER_SUB_PARAM_NAME, UserRouteParams } from './shared';

export function getUserManagementRoutes(userData: UserState): RouteConfig[] {
  const { data: user } = userData;
  return [
    {
      path: '/settings/um',
      label: 'User Management',
      icon: 'AccountCircle',
      hiddenOnMainMenu: !permission.isAllowedToReadUsers(user),
      isShowByUrl: permission.isAllowedToReadUsers(user),
      routes: [
        {
          path: paths.getUserListPath(),
          label: 'Users',
          icon: 'users',
          isShowByUrl: permission.isAllowedToReadUsers(user),
          getRouteComponent: (options) => options.match.isExact ? Pages.Users : undefined,
          routes: [
            {
              path: paths.getUserCreatePath(),
              label: 'Create',
              exact: true,
              main: Pages.CreateUserPage,
              hiddenOnMainMenu: true,
              isShowByUrl: permission.isAllowedToCreateUser(user),
            },
            {
              path: paths.getUserDetailPath(`:${ USER_SUB_PARAM_NAME }`),
              label: ({ match }: RouteLabelRendererProps<UserRouteParams>) => (
                match &&
                match.params.userSub &&
                <UserRouteLabel userSub={ match.params.userSub }/>
              ),
              exact: true,
              main: Pages.User,
              hiddenOnMainMenu: true,
              isShowByUrl: permission.isAllowedToReadUsers(user),
            },
          ]
        },
        ...getUserManagementClientRoutes(userData)
      ],
    }
  ];
}
