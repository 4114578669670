import { useState } from 'react';
import { useDialog } from 'hooks';

import { Label, PositionWithLevel } from 'models/device-management';

// components
import { Box, Checkbox } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { DmLabel } from 'components/DmLabels';
import { FieldSkeleton } from 'components/Skeleton';
import { DeleteLabelDialog } from 'components/DeviceManagement';

// styles
import { useStyles } from './styles';


interface DialogLabelsProps {
  availableLabelsLoading: boolean;
  availableLabels: Label[];
  positionLabels: Label[];
  onChangePositionLabels: (labels: Label[]) => void;
  positionOwnerId?: number;
  position: PositionWithLevel;
}

export const DialogLabels = ({
  availableLabelsLoading,
  availableLabels,
  positionLabels,
  onChangePositionLabels,
  positionOwnerId,
  position
}: DialogLabelsProps) => {
  const deleteDialog = useDialog();
  const [selectedLabel, setSelectedLabel] = useState<Label>();

  const styles = useStyles();

  if (availableLabels.length) {
    return (
      <>
        { availableLabels.map(label => (
          <Box key={ label.id } display="flex" justifyContent="space-between" alignItems="center">
            <Box key={ label.id } display="flex" alignItems="center">
              <DmLabel label={ label } />
              <Delete
                className={ styles.deleteButton }
                onClick={() => { deleteDialog.open(); setSelectedLabel(label); }}
              />
            </Box>

            <Checkbox
              checked={ positionLabels.some(l => l.id === label.id) }
              onChange={ (_event, checked) => onChangePositionLabels(
                checked
                  ? [...positionLabels, label]
                  : positionLabels.filter(l => l.id !== label.id)
              ) }
            />
          </Box>
        )) }

        { deleteDialog.isMounted && selectedLabel && <DeleteLabelDialog
          label={ selectedLabel }
          position={ position }
          isOpen={ deleteDialog.isOpen }
          onClose={ deleteDialog.close }
          onCloseEnd={ deleteDialog.unmount }
          positionOwnerId={ positionOwnerId }
        /> }
      </>
    );
  }

  if (availableLabelsLoading) {
    return <FieldSkeleton />;
  }

  return <Box mt={1} mb={1}>No labels added yet</Box>;
};
