import { bindPopper, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotifyError } from 'actions/notifier';

// components
import { Box, ClickAwayListener, DialogActions, DialogContent, Grid, Paper, Popper } from '@mui/material';
import { DayPickerRangeController, FocusedInputShape, CalendarDay } from 'react-dates';

import { CommonButton } from 'components/Buttons';
import { TimeControl } from './TimeControl';

// styles
import './styles.scss';

export interface DateTimeRange {
  dateTimeFrom?: Date;
  dateTimeTo?: Date;
}

interface DateRange {
  dateFrom?: string;
  dateTo?: string;
}

interface ReactDatesDateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

const DEFAULT_TIME_FROM = moment('1970-01-01').set({ h: 0, m: 0, s: 0, ms: 0 }).toJSON();
const DEFAULT_TIME_TO = moment('1970-01-01').set({ h: 23, m: 59, s: 59, ms: 999 }).toJSON();

const mapTimeValue = (time?: Date, textValue?: string): string => {
  if (time) {
    return time.toJSON();
  }

  if (textValue) {
    return textValue;
  }

  return '';
};

function mapReactDatesToControlValue({ startDate, endDate }: ReactDatesDateRange): DateRange {
  return {
    dateFrom: startDate?.toJSON() ?? undefined,
    dateTo: endDate?.toJSON() ?? undefined,
  };
}

interface DateRangeControlState {
  dateFrom?: string;
  dateTo?: string;
  timeFrom: string;
  timeTo: string;
}

interface DateRangeControlErrors {
  dateTo?: string;
  dateFrom?: string;
  timeFrom?: string;
  timeTo?: string;
}

function removeDate(dateType: 'start' | 'end', dateTime: string): string {
  const dateTimeMoment = moment(dateTime);

  return moment(dateType === 'start' ? DEFAULT_TIME_FROM : DEFAULT_TIME_TO)
    .set({ h: dateTimeMoment.get('h'), m: dateTimeMoment.get('m') })
    .toJSON();
}

function validateState(state: DateRangeControlState): DateRangeControlErrors {
  const errors: DateRangeControlErrors = {};

  if (!state.timeFrom) {
    errors.timeFrom = 'Required';
  } else if (!moment(state.timeFrom).isValid()) {
    errors.timeFrom = 'Invalid time';
  }

  const isSingleDayPeriod = state.dateFrom && (
    !state.dateTo ||
    moment(state.dateFrom).isSame(state.dateTo, 'day')
  );

  if (!state.timeTo) {
    errors.timeTo = 'Required';
  } else if (!moment(state.timeTo).isValid()) {
    errors.timeTo = 'Invalid time';
  } else if (
    isSingleDayPeriod &&
    moment(removeDate('start', state.timeFrom))
      .isSameOrAfter(removeDate('end', state.timeTo))
  ) {
    errors.timeTo = 'For a single-day period, "End time" must be after "Start time"';
  }

  return errors;
}

interface DateRangeControlProps {
  value?: DateTimeRange | null;
  onChange: (value: DateTimeRange) => void;
  renderTrigger: (props: ReturnType<typeof bindTrigger>) => React.ReactNode;
  timeCustomizationEnabled?: boolean;
}

/**
 * На вход принимаются `value.dateTimeFrom` и `value.dateTimeTo`.
 *
 * Внутри дата и время хранятся раздельно:
 * - `dateFrom`
 * - `dateTo`
 * - `timeFrom`
 * - `timeTo`
 *
 * В момент сохранения они склеиваются в `dateTimeFrom` и `dateTimeTo`
 * и передаются в `props.onChange`.
 *
 * Начало периода всегда в 0s 0ms, конец в 59s 999ms.
 *
 * Несмотря на то, что у всех этих переменных внутри полные даты/время, в `dateFrom` и `dateTo`
 * учитываются только даты, а в `timeFrom` и `timeTo` - только время.
 */
export const DateRangeControl = ({
  value,
  onChange,
  renderTrigger,
  timeCustomizationEnabled,
}: DateRangeControlProps) => {
  const valueDateTimeFrom = value?.dateTimeFrom?.toJSON();
  const valueDateTimeTo = value?.dateTimeTo?.toJSON();

  const [dateFrom, setDateFrom] = useState<string | undefined>(valueDateTimeFrom);
  const [dateTo, setDateTo] = useState<string | undefined>(valueDateTimeTo);
  const [timeFrom, setTimeFrom] = useState<string>(valueDateTimeFrom ?? DEFAULT_TIME_FROM);
  const [timeTo, setTimeTo] = useState<string>(valueDateTimeTo ?? DEFAULT_TIME_TO);
  const [reactDatesFocusedInput, setReactDatesFocusedInput] = useState<FocusedInputShape | null>('startDate');
  const popupState = usePopupState({ variant: 'popper', popupId: undefined });
  const dispatch = useDispatch();

  const errors = validateState({ dateFrom, dateTo, timeFrom, timeTo });

  useEffect(() => {
    if (!popupState.isOpen) {
      setDateFrom(valueDateTimeFrom);
      setDateTo(valueDateTimeTo);
      setTimeFrom(valueDateTimeFrom ?? DEFAULT_TIME_FROM);
      setTimeTo(valueDateTimeTo ?? DEFAULT_TIME_TO);
    }
  }, [popupState.isOpen, valueDateTimeFrom, valueDateTimeTo]);

  const handleReset = () => {
    onChange?.({});
    popupState.close();
  };

  const reactDatesStartDate = dateFrom ? moment(dateFrom) : null;

  return (
    <>
      {renderTrigger(bindTrigger(popupState))}

      <Popper
        style={{ zIndex: 100 }}
        placement="bottom-start"
        {...bindPopper(popupState)}
      >
        <ClickAwayListener onClickAway={popupState.close}>
          <Paper data-testid="date-range-picker">
            <DialogContent dividers>
              <DayPickerRangeController
                startDate={reactDatesStartDate}
                endDate={dateTo ? moment(dateTo) : null}
                // this seems valid until we explicitly allow a user to select the end date first
                focusedInput={dateFrom ? reactDatesFocusedInput : 'startDate'}
                numberOfMonths={2}
                minimumNights={0}
                isOutsideRange={d => d.isAfter(moment(), 'day')}
                minDate={moment('2020-01-01').startOf('month')}
                maxDate={moment().add(1, 'month').endOf('month')}
                initialVisibleMonth={() => {
                  const lastMonth = moment().subtract(1, 'month').startOf('month');

                  if (reactDatesStartDate?.isBefore(lastMonth)) {
                    return reactDatesStartDate.clone().startOf('month');
                  }
                  return lastMonth;
                }}
                renderCalendarDay={props => <CalendarDay {...props} />}
                hideKeyboardShortcutsPanel
                noBorder
                renderDayContents={ date => <div className="CalendarDay__content">{ date.format('D') }</div> }
                onFocusChange={focusedInput => {
                  // force focusedInput to always be truthy so dates are always selectable
                  setReactDatesFocusedInput(!focusedInput ? 'startDate' : focusedInput);
                }}
                onDatesChange={range => {
                  const dateRange = mapReactDatesToControlValue(range);
                  setDateFrom(dateRange.dateFrom);
                  setDateTo(dateRange.dateTo);
                }}
              />

              {timeCustomizationEnabled && (
                <Box mt="-6px">
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <TimeControl
                        label="Start time"
                        error={errors.timeFrom}
                        value={timeFrom}
                        onChange={
                          (date, textValue) =>
                            setTimeFrom(mapTimeValue(date, textValue))
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TimeControl
                        label="End time"
                        error={errors.timeTo}
                        value={timeTo}
                        onChange={(date, textValue) => setTimeTo(mapTimeValue(date, textValue))}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Box m={1} width="100%">
                <Grid container justifyContent="space-between" wrap="nowrap">
                  <Grid item xs>
                    <CommonButton
                      type="button"
                      label="Reset"
                      icon={<></>}
                      ButtonProps={{ variant: 'outlined' }}
                      onClick={handleReset}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container wrap="nowrap">
                      <Grid item>
                        <Box mr={1}>
                          <CommonButton
                            type="text"
                            label="Cancel"
                            icon={<></>}
                            onClick={popupState.close}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <CommonButton
                          type="button"
                          label="Apply"
                          icon={<></>}
                          onClick={() => {
                            if (!dateFrom) {
                              dispatch(NotifyError('Date must be selected'));
                              return;
                            }

                            if (Object.values(errors).some(Boolean)) {
                              dispatch(NotifyError(`There're validation errors in the specified custom date range`));
                              return;
                            }

                            if (!dateFrom) {
                              handleReset();
                              return;
                            }

                            const timeFromMoment = moment(timeFrom);
                            const timeToMoment = moment(timeTo);

                            onChange?.({
                              dateTimeFrom: moment(dateFrom)
                                .set({
                                  h: (timeCustomizationEnabled && timeFromMoment.isValid()) ? timeFromMoment.get('h') : 0,
                                  m: (timeCustomizationEnabled && timeFromMoment.isValid()) ? timeFromMoment.get('m') : 0,
                                  s: 0,
                                  ms: 0,
                                })
                                .toDate(),

                              dateTimeTo: moment(dateTo || dateFrom)
                                .set({
                                  h: (timeCustomizationEnabled && timeToMoment.isValid()) ? timeToMoment.get('h') : 23,
                                  m: (timeCustomizationEnabled && timeToMoment.isValid()) ? timeToMoment.get('m') : 59,
                                  s: 59,
                                  ms: 999,
                                })
                                .toDate(),
                            });
                            popupState.close();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogActions>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
