import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(() => createStyles({
  wrap: {
    width: '30rem',
    fontSize: 12,
    margin: '0 auto',
    padding: '2rem',
    boxShadow: '0 2px 6px rgba(13,26,38,0.15)',
    border: '1px solid #aeb3b7'
  },
  btn: {
    width: '100%'
  },
  fieldBlock: {
    paddingBottom: '1rem'
  },
  invalidText: {
    margin: 0,
    textAlign: 'center',
  }
}));
