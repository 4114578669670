import React from 'react';
import { Button } from '@mui/material';
import { clearLocationParams } from 'utils/routing/query';

const action = () => (
  <Button
    color="primary"
    onClick={ () => clearLocationParams(true) }
  >
    Reload
  </Button>
);

export default action;
