import { useZones, useGroups } from 'hooks/device-management';
import { Zone } from 'models/device-management';
import { isNil } from 'utils/models';

// components
import { Typography } from '@mui/material';

import Content from './Content';

// styles
import useProjectStyles from 'styles';

interface ZonePageProps {
  zoneId: Zone['id'];
}

const ZonePage: React.FC<ZonePageProps> = ({ zoneId }) => {
  const projectCss = useProjectStyles();
  const { zones, isLoading } = useZones({ params: { zones: [zoneId] }, enabled: Boolean(zoneId) });
  const zone = zones[0];
  const groupsState = useGroups({ params: { zones: [zoneId] } });
  if (isNil(zoneId) || (!zone && !isLoading)) {
    return (
      <Typography variant="h4" className={projectCss.pageNotImplemented}>
        You have requested the page of nonexistent zone
      </Typography>
    );
  }

  return (
    <div data-testid="page-dm-zone-detail">
      <Content
        zone={zone}
        zoneId={zoneId}
        groups={groupsState.groups}
        groupsFetching={groupsState.isLoading}
      />
    </div>
  );
};

export default ZonePage;