import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import {
  EnumVirtualDevicesParamsFields,
  initVirtualDevicesFilters,
  VirtualDevicesFiltersFields
} from 'models/device-management';
import { Parser } from 'utils/routing/parser';

export const useVirtualDevicesPageParams = () => {
  const { items, setItems } = useLocationItems();
  const [filters, setFilters] = useState<VirtualDevicesFiltersFields>(initVirtualDevicesFilters);
  const { limit, offset } = usePagination();

  useEffectOnce(() => {
    const parser = new Parser(items as VirtualDevicesFiltersFields);
    setFilters({
      [EnumVirtualDevicesParamsFields.owner]: parser.asNumber(EnumVirtualDevicesParamsFields.owner),
      [EnumVirtualDevicesParamsFields.isActive]: parser.asBoolean(EnumVirtualDevicesParamsFields.isActive),
      [EnumVirtualDevicesParamsFields.firmwareHash]: parser.asString(EnumVirtualDevicesParamsFields.firmwareHash),
    });
  });

  return {
    params: { ...filters, limit, offset },
    filters,
    setFilters: (filters: VirtualDevicesFiltersFields) => {
      setFilters(filters);
      setItems({ ...filters, limit, offset: 0 });
    },
    resetFilters: () => {
      setFilters(initVirtualDevicesFilters);
      setItems({ ...initVirtualDevicesFilters, limit, offset: 0 });
    }
  };
};