import React from 'react';
// components
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { styled } from 'styles/utils';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

export const PageLoader = (): JSX.Element => {
  return (
    <StyledBackdrop open={ true }>
      <CircularProgress size={ 60 } color="primary"/>
    </StyledBackdrop>
  );
};
