import { call, cancel, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import { Task as ReduxSagaTask } from '@redux-saga/types';
import { createCSVFromObject, downloadFileFromRes } from 'helpers';
import { NotifyError } from 'actions/notifier';
import * as actions from 'actions/device-management/device-download';
import { DevicesFilterFields, PositionedDeviceData, ResponsePositionedDevicesWithTotal, ResponseReason } from 'models/device-management';
import { Firmware } from 'models/firmware-management';
import { fetchDevices } from 'clients/device-management';

const COLUMNS = [
  'device_id',
  'app_key',
  'device_firmware', // mapping from firmware_hash
  'position_id',
  'position_network_id', // mapping from network_id
  'position_custom_id', // mapping from custom_id
  'group_inner_id',
  'lat',
  'lon',
  'group_id',
  'group_name',
  'group_type',
  'level_id',
  'level_name',
  'floor_number',
  'zone_id',
  'zone_name',
  'labels',
];

const getAppKey = (device: PositionedDeviceData) => {
  // exporting app_key only for devices with id mathes 1*****. BNIV-2276
  if(/(^[1]).{5}$/.test(device.device_id)) {
    return device?.encryption_keys?.key16;
  }
};

let data: string[] = [];

function* startDeviceDownload(action: actions.StartDeviceDownload) {
  try {
    const downloadTask: ReduxSagaTask = yield fork(download, action.filter, action.firmwares);
    const { complete, canceled } = yield race({
      complete: take(actions.DEVICE_DOWNLOAD_COMPLETE),
      canceled: take(actions.DEVICE_DOWNLOAD_CANCEL)
    });

    if (canceled) {
      yield cancel(downloadTask);
    } else if (complete) {
      downloadFileFromRes(data.join('\n'), 'devices-data.csv');
    }
  } finally {
    data = [];
  }
}

function* download(filter: DevicesFilterFields, firmwares: Firmware[]) {
  data.push(COLUMNS.join(','));
  let offset = 0;
  let total = 0;
  const limit = 1000;
  do {
    const response: ResponsePositionedDevicesWithTotal = yield call(fetchDevices, {
      ...filter,
      limit: limit,
      offset: offset,
      keysEnabled: true
    });
    offset += limit;
    total = response.total;
    if (response.reason === ResponseReason.Ok && response.data) {
      yield put(actions.SuccessDeviceDownload(offset > total ? total : offset, total));
      const csvRows = response.data.map(device => {
        const deviceFirmwareObj = firmwares.find(f => f.hash === device.firmware_hash);

        const data: Record<string, unknown> = {
          ...device,
          app_key: getAppKey(device),
          position_network_id: device.network_id,
          position_custom_id: device.custom_id,
          device_firmware: deviceFirmwareObj ? `${deviceFirmwareObj.name}/${deviceFirmwareObj.version}` : '',
          labels: device.labels?.map(l => l.label).join(',') ?? '',
        };

        return createCSVFromObject(data, COLUMNS);
      });
      data.push(...csvRows);
    } else {
      const message = response.message || 'Server error';
      yield put(actions.FailureDeviceDownload(message));
      yield put(NotifyError(`Error while fetching devices: ${message}`));
    }
  } while (total > offset);

  yield put(actions.CompleteDeviceDownload());
}

export default [
  takeEvery(actions.DEVICE_DOWNLOAD_START, startDeviceDownload),
];
