import { useForm } from 'react-hook-form-v6';
import { useCreateLevel, useUpdateLevel } from 'hooks/device-management';
import { Level, LevelUpdateFields } from 'models/device-management';

type Params = {
  defaultValues?: LevelUpdateFields;
  onCreateSuccess?: (project: Level) => void;
  onUpdateSuccess?: (project: Level) => void;
  onDeleteSuccess?: () => void;
}

export const useLevelForm = ({
  defaultValues,
  onCreateSuccess,
  onUpdateSuccess,
}: Params) => {
  const { createLevel, isCreateLevelFetching } = useCreateLevel(onCreateSuccess);
  const { updateLevel } = useUpdateLevel(onUpdateSuccess);

  const _defaultValues = defaultValues || {
    name: '',
    floor_number: null,
  };

  const { control, getValues, formState: { errors, isDirty, isValid, }, watch, reset } = useForm({
    defaultValues: _defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return {
    defaultValues: _defaultValues,
    control,
    getValues,
    errors,
    reset,
    watch,
    isDirty,
    isValid,
    createLevel,
    isCreateLevelFetching,
    updateLevel,
  };
};
