import React from 'react';

import { Zone } from 'models/device-management';
import { dmZonePath } from 'routing/paths';
import { Defined, formatZone } from 'utils/models';
import { useZones } from 'hooks/device-management';

// components
import { Link } from '@mui/material';
import { CustomLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

interface ZoneLabelProps {
  zoneId: Defined<Zone['id']>;
  name?: string;
  emptyName?: string;
  noLink?: boolean;
}

export const ZoneLabel: React.FC<ZoneLabelProps> = (props) => {
  const { zoneId, name, noLink, emptyName = '-' } = props;

  const { zones, isLoading } = useZones({ params: {} });
  if (isLoading) {
    return <LoaderSvg/>;
  }

  const zone = zones.find(z => z.id === zoneId);
  if (!zone) {
    return <>{ emptyName }</>;
  }

  const children = zone ? <>{ formatZone(zoneId, zone) }</> : <>{ name }</>;
  if (noLink) {
    return children;
  }

  return (
    <Link
      color="secondary"
      component={ CustomLink }
      to={ dmZonePath(zoneId) }
      withReturnUrl
    >
      { children }
    </Link>
  );
};
