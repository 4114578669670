import React from 'react';
import { useAuthUserSelector } from 'hooks';
import { PositionNode, Tree } from 'hooks/device-management/useTree/types';
import { PositionWithLevel } from 'models/device-management';
// components
import { Grid } from '@mui/material';
import { PositionDeviceInfo } from './device/PositionDeviceInfo';
import { PositionDeviceStatistic } from './device/PositionDeviceStatistic';
import { PositionDeviceProvision } from './device/PositionDeviceProvision';

interface Props {
  tree: Tree;
  node: PositionNode;
  onEdit: (position: PositionWithLevel) => void;
}

export const PositionDeviceBlock = ({ tree, node, onEdit }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();

  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 12 }>
        <PositionDeviceInfo
          node={ node }
          onEdit={ onEdit }
        />
      </Grid>
      { node.device && (
        <>
          <Grid item xs={ 12 }>
            <PositionDeviceStatistic
              tree={ tree }
              node={ node }
              device={ node.device }
            />
          </Grid>
          { isAdmin && (
            <Grid item xs={ 12 }>
              <PositionDeviceProvision
                deviceId={ node.device.device_id }
              />
            </Grid>
          )}
        </>
      ) }
    </Grid>
  );
};