import { useAuthUserSelector } from 'hooks';
import { useStationLocationsSelector, useLoraWANStationsDictionarySelector } from 'hooks/station';
import { StationEntity, StationLocation } from 'models/base-station';
import LocationOn from 'images/Map/location-on.svg';
import LocationOff from 'images/Map/location-off.svg';
import { getAttached } from 'pages/BaseStation/BaseStations/utils/helpers';

// components
import { Icon, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { ListItemSkeleton } from 'components/Skeleton';

interface Props {
  station: StationEntity;
  selected?: StationLocation;
  onSelect: (location?: StationLocation) => void;
}

export const LocationList = ({ station, selected, onSelect }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const { isLoading: isStationLoading, stations: lorawanStations } = useLoraWANStationsDictionarySelector();
  const { isLoading, locations } = useStationLocationsSelector({
    ...(user.isAdmin && { ownerId: station.owner_id }) // dont filter by owner for simple user
  });

  if (isLoading && isStationLoading) {
    return (<ListItemSkeleton itemsCount={ 5 }/>);
  }

  return (
    <List>
      { locations
        .filter(l => l.owner_id === station.owner_id)
        .map(location => {
          return (
            <ListItem
              key={ location.id }
              button
              selected={ location?.id === selected?.id }
              onClick={ () => onSelect(location) }
            >
              <ListItemText
                primary={ `${ location.city }, ${ location.address }` }
                secondaryTypographyProps={ { component: 'div' } }
                secondary={
                  <>
                    <Typography> Country: { location.country } </Typography>
                    <Typography> ZIP-Code: { location.postcode } </Typography>
                    <Typography>
                      Attached Station: { getAttached(lorawanStations, location) }
                    </Typography>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Icon>
                  <img
                    src={ location.station_id ? LocationOn : LocationOff }
                    alt={ location.station_id ? `#${ location.station_id }` : 'Free' }
                  />
                </Icon>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }) }
    </List>
  );
};
