import { useState } from 'react';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useAuthUserSelector, useLocationItems, usePagination } from 'hooks';
import { ShipmentFilters, ShipmentParams, initProvisioningShipmentFilters } from 'models/provisioning';
import {
  DoSetShipmentFilters,
  DoUpdateShipmentFromUrl,
  DoFetchShipmentByParams,
} from 'actions/provisioning';

// components
import { ProvisioningShipmentTableFilter, ProvisioningShipmentTable } from 'components';
import { AddButton } from 'components/Buttons';
import { CreateShipmentModal } from './ShipmentDetailPage/widgets/CreateShipmentModal';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

const ProvisioningShipments = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { shipment } = useSelector((state: RootState) => state.provisioning);
  const { setItems } = useLocationItems();
  const { limit, offset, onChangePage } = usePagination();

  // main filters
  const [filterState, setFilterState] = useState<ShipmentFilters>(initProvisioningShipmentFilters);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isAdmin } = useAuthUserSelector();

  const fetchShipments = (params: ShipmentParams) => dispatch(DoFetchShipmentByParams(params));
  const setFilters = (filters: ShipmentFilters) => dispatch(DoSetShipmentFilters(filters));
  const UpdateShipmentFromUrl = () => dispatch(DoUpdateShipmentFromUrl());

  // init
  useEffectOnce(() => {
    UpdateShipmentFromUrl();
  });

  // filter sync
  useDeepCompareEffect(() => {
    setFilterState(shipment.filters);
  }, [shipment.filters]);

  // fetch
  useDeepCompareEffect(() => {
    fetchShipments({ ...shipment.filters, limit, offset });
  }, [shipment.filters, limit, offset]);

  const onSave = () => {
    setFilters(filterState);
    setItems(shipment.filters); // @TODO order with onChangePage
    onChangePage(0); // reset page
  };
  const onReset = () => {
    setFilterState(initProvisioningShipmentFilters);
    setFilters(initProvisioningShipmentFilters);
    setItems(initProvisioningShipmentFilters); // @TODO order with onChangePage
    onChangePage(0); // reset page
  };

  return (
    <>
      <Helmet>
        <title>Devices provisioning | Shipments</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ shipment.isFetching }
          filter={ shipment.filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <ProvisioningShipmentTableFilter
            fetching={ shipment.isFetching }
            isAdmin={ isAdmin }
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div>
      <div className={ classes.table }>
        <ProvisioningShipmentTable
          shipments={ shipment.shipments }
          isFetching={ shipment.isFetching }
          total={ shipment.total }
          isAdmin={ isAdmin }
        />
      </div>
      { isAdmin &&
        <AddButton
          label="Create"
          onClick={ () => setDialogOpen(true) }
        />
      }
      { isDialogOpen && <CreateShipmentModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
        isAdmin={ isAdmin }
      /> }
    </>
  );
};

export default ProvisioningShipments;
