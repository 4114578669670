import React from 'react';

import { useAuthUserSelector } from 'hooks';
import { Tree } from 'hooks/device-management/useTree/types';
import { getDatatableOptions, Pagination } from 'utils/paginator';
import { isAdmin } from 'utils/permissions';
import { commonOptions } from 'utils/tables';
import { useTreeTableRows } from './utils';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout } from 'components/Table';
import * as cells from './cells';

// styles
import { ThemeProvider } from 'styles/utils';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

interface Props {
  isLoading?: boolean;
  tree: Tree;
  pagination: Pagination;
}

export const ProjectTreeTable = ({ isLoading, tree, pagination }: Props) => {
  const user = useAuthUserSelector();
  const data = useTreeTableRows(tree);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Project/Zone/Level/Group Name/Network ID',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.NameCell
            tree={ tree }
            row={ data[dataIndex] }
          />
        )
      },
    },
    {
      name: 'devices',
      label: 'Devices / Positions',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.DevicesPositionsCell
            tree={ tree }
            node={ data[dataIndex].node }
          />
        )
      },
    },
    {
      name: 'issue',
      label: 'Issues',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.IssuesCell
            tree={ tree }
            node={ data[dataIndex].node }
          />
        )
      },
    },
    {
      name: 'conn',
      label: 'Connectivity',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.ConnectivityCell
            tree={ tree }
            node={ data[dataIndex].node }
          />
        )
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: isAdmin(user) ? 'true' : 'excluded',
        customBodyRenderLite: i => (
          <cells.OwnerCell node={ data[i].node }/>
        )
      }
    },
    {
      name: 'label',
      label: 'Labels',
      options: {
        customBodyRenderLite: i => (
          <cells.LabelsCell node={ data[i].node }/>
        )
      }
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    rowsPerPageOptions: [20],
    ...getDatatableOptions({ ...pagination, data, total: tree.total }),
  };

  return (
    <ThemeProvider theme={ truncatedCellsMuiTableTheme(data.length) }>
      <TableLoadingLayout isLoading={ isLoading }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};
