import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { ResponsiveBar, BarDatum, TooltipProp } from '@nivo/bar';
import { CustomColorFunction } from '@nivo/colors';
import outerTheme from 'styles/themes/outer';
import { props as BarChartsOptions } from './ChartsOptions/BarChartsOptions';
import { HistoryStatistic } from 'models/rabbit';
import {
  dateToMonthDay,
  patternLines,
  patternLines2,
  legends,
  stackedBarTooltip,
  kFormatter,
  layers
} from './ChartsOptions/CommonOptions';

interface Props {
  history: HistoryStatistic[];
}

enum TooltipText {
  total = 'Total',
  failures = 'Failures',
  successful = 'Successful',
}

function isChartFilled(data: BarDatum[]): boolean {
  return data.some(bar => bar[TooltipText.failures] > 0 || bar[TooltipText.successful] > 0);
}

export const HTTPCallerHistoryChart = (props: Props): JSX.Element => {
  const colors = {
    [TooltipText.failures]: outerTheme.palette.error.main,
    [TooltipText.successful]: outerTheme.status.success
  };
  const getColor: CustomColorFunction = bar => colors[bar.id as keyof typeof colors];

  const chartDuration = 30; // visible days
  const momentDuration = moment().subtract(chartDuration, 'days');

  const filteredData = props.history.filter(d => {
    return moment(d.date, 'YYYY-MM-D').isAfter(momentDuration);
  });

  const dates: Map<string, { successful: number; failures: number }> = new Map();

  for (let i = chartDuration - 1; i >= 0; i--) {
    const day = moment().subtract(i, 'days').format('YYYY-MM-DD');
    dates.set(day, { successful: 0, failures: 0 });
  }

  for (const stat of filteredData) {
    const day = moment(stat.date, 'YYYY-MM-D').format('YYYY-MM-DD');
    dates.set(day, {
      failures: stat.failures || 0,
      successful: stat.successful || 0
    });
  }

  const data: BarDatum[] = [];

  for (const [key, value] of dates) {
    data.push({
      date: key,
      [TooltipText.failures]: value.failures,
      [TooltipText.successful]: value.successful
    });
  }

  const tooltip: TooltipProp = ({ id, value, color, data }) => {
    const total = Number(data[TooltipText.successful] as string) + Number(data[TooltipText.failures] as string);
    return stackedBarTooltip(total, data, color, value, TooltipText.total, id);
  };

  const fill = [
    {
      match: {
        id: TooltipText.failures
      },
      id: 'lines2'
    }
  ];

  const keys: (TooltipText.successful | TooltipText.failures)[] = [
    TooltipText.successful,
    TooltipText.failures,
  ];

  return (
    <Box
      data-filled={ isChartFilled(data) ? 'true' : 'false' }
      data-testid="chart"
      width="100%"
      height="100%"
    >
      <ResponsiveBar
        { ...BarChartsOptions }
        data={ data }
        keys={ keys }
        colors={ getColor }
        animate={ false }
        margin={ { top: 40, right: 10, bottom: 80, left: 50 } }
        defs={ [
          patternLines,
          { ...patternLines2, color: outerTheme.palette.error.light },
        ] }
        axisLeft={ {
          format: kFormatter
        } }
        fill={ fill }
        axisTop={ {
          ...BarChartsOptions.axisTop,
          legend: 'HTTP Calls',
        } }
        tooltip={ tooltip }
        axisBottom={ {
          ...BarChartsOptions.axisBottom,
          format: dateToMonthDay,
        } }
        layers={ layers }
        legends={ [
          {
            data: keys.map((id, index) => ({
              id,
              value: index,
              color: colors[id],
              label: id
            })),
            ...legends
          }
        ] }
      />
    </Box>
  );
};
