import React from 'react';
import { IncidentReasonEnum } from 'models/device-monitoring';
import { useForm, useFormActionLoader } from 'hooks/form';
import { CreateDamagedIncident } from 'actions/http-incidents';
import { Device } from 'models/device-management';
import { DamageState, FormField } from './types';

// components
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { InfoDialog, MuiDialog } from 'components/Dialogs';
import { MarkDamageForm } from './form';

interface Props {
  device: Device;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const MarkDeviceDamageDialogComponent = ({ device, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const form = useForm<DamageState, FormField>({
    initialState: {
      reason: IncidentReasonEnum.BrokenCasing,
      comment: ''
    },
    showFields: [
      FormField.reason,
      FormField.comment,
    ]
  });

  if (!device.position_id) {
    return (
      <InfoDialog
        name="mark-device-damage"
        label="Can not mark device damage"
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
      >
        The device <b>{ device.device_id }</b> can not be mark damage.<br/>
        First place the device into position
      </InfoDialog>
    );
  }

  const handleConfirm = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateDamagedIncident(device.device_id, form.state.reason, form.state.comment),
      onSuccess: onClose,
    });
  };

  return (
    <MuiDialog
      open={ isOpen }
      onClose={ onClose }
      onExited={ onCloseEnd }
      maxWidth={ 'sm' }
    >
      <DialogTitle>Please select the device damaging reason</DialogTitle>
      <DialogContent>
        <MarkDamageForm { ...form } />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={ onClose } autoFocus>
          Cancel
        </Button>
        <Button color="primary" onClick={ handleConfirm }>
          Mark as damaged
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
