import { StationGeneration } from 'models/base-station';
import { HealthyStatus, OnlineStatus } from '../BaseStationFilter/types';
import { BaseStationFilterState } from '../BaseStationFilter';
import { useLocationItems } from 'hooks/location';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { usePagination } from 'hooks/usePagination';
import { PageFilter } from '../types';

const initialState: BaseStationFilterState = { projects: [] };
export const useFilterByLocation = (): PageFilter => {
  const location = useLocationItems();
  const { limit } = usePagination();
  const filter = buildState(location.items);

  return {
    filter: filter,
    updateFilters: (filters: BaseStationFilterState) => {
      // @TODO reset pagination in one setItems
      location.setItems({ ...filters, limit, offset: 0 });
    },
    resetFilters: () => {
      // @TODO reset pagination in one setItems
      location.setItems({ ...buildState({}), limit, offset: 0 });
    }
  };
};

const buildState = (items: UrlItems): BaseStationFilterState => {
  const parser = new Parser(items as BaseStationFilterState);
  return {
    ...initialState,
    owner: parser.asNumber('owner') ?? initialState.owner,
    projects: parser.asNumbers('projects') ?? initialState.projects,
    stationId: parser.asNumber('stationId') ?? initialState.stationId,
    eui: parser.asString('eui') ?? initialState.eui,
    country: parser.asString('country') ?? initialState.country,
    city: parser.asString('city') ?? initialState.city,
    generation: parser.asEnum('generation', StationGeneration) ?? initialState.generation,
    onlineStatus: parser.asEnum('onlineStatus', OnlineStatus) ?? initialState.onlineStatus,
    healthyStatus: parser.asEnum('healthyStatus', HealthyStatus) ?? initialState.healthyStatus,
  };
};
