import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import * as actions from 'actions/projects';
import { NotifyError } from 'actions/notifier';
import { fetchProjectById } from 'clients/user-management';

function* getProjectById(action: actions.GetProjectById) {
  const response: ApiResponse = yield call(fetchProjectById, action.id);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetProjectByIdSuccess(response.data));
  } else {
    yield put(NotifyError(`Error while fetching a project: ${response.message}`));
    yield put(actions.DoGetProjectByIdFailed());
  }
}

export const projectSagas = [
  takeEvery(actions.GET_PROJECT_BY_ID, getProjectById),
];
