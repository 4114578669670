import { useState } from 'react';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks/usePagination';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { LoRaWANRawMessagesFilters, initLoRaWANRawMessagesFilters } from 'models';
import moment from 'moment';

export function getFiltersFromUrl(urlState: UrlItems): LoRaWANRawMessagesFilters {
  const parser = new Parser(urlState as LoRaWANRawMessagesFilters);
  return {
    owner: parser.asNumber('owner'),
    projects: parser.asNumbers('projects') ?? [],
    zones: parser.asNumbers('zones') ?? [],
    groups: parser.asNumbers('groups') ?? [],
    devices: parser.asStrings('devices') ?? [],
    gatewayEUI: parser.asString('gatewayEUI'),
    networkId: parser.asStrings('networkId') ?? [],
    traceid: parser.asString('traceid'),
    timeFrom: parser.asDate('timeFrom') ? moment(parser.asDate('timeFrom')) :  initLoRaWANRawMessagesFilters.timeFrom,
    timeTo: parser.asDate('timeTo') ? moment(parser.asDate('timeTo')) : initLoRaWANRawMessagesFilters.timeTo,
  };
}

export const useLorawanMessagesParams = () => {
  const { items, setItems } = useLocationItems();
  const { limit, offset } = usePagination();
  // filter url sync
  delete items['offset'];
  delete items['limit'];
  const [filters, setFilters] = useState<LoRaWANRawMessagesFilters>(getFiltersFromUrl(items));

  return {
    filters: filters,
    applyFilters: (filter: LoRaWANRawMessagesFilters) => {
      setItems({ ...filter, limit, offset: 0 });
      setFilters(filter);
    },
    limit,
    offset,
  };
};