import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { getResponseData } from 'utils/clients';
import { listDeviceProfiles } from 'clients/dms';


export function useDMSDeviceProfilesDictionary(enabled = true) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['dms/device_profiles/dictionary'],
    queryFn: async () => {
      return getResponseData(await listDeviceProfiles());
    },
    onError: e => notifyError(`Error while fetching device profiles: ${ (e as Error).message }`),
    enabled,
    cacheTime: Infinity,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    isLoading: queryResult.isLoading,
    profiles: queryResult.data ?? [],
  };
}