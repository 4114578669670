import moment from 'moment';
import { History, SendingStatus } from 'models/rabbit';
import { useExpandableRows } from 'hooks/expandable-rows';
import { commonOptions } from 'utils/tables';
import { createHiglightingNewRows } from 'utils/mui-datatables';
// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';
import { PositionName, RabbitMessageTypeName } from 'components/Labels';
import { RabbitEndpointLink } from 'components/Links';
import { DetailInfo } from './widget/DetailInfo';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';


// styles
import useStyles from './style';
import { getMuiTheme } from 'styles/themes';

interface Props {
  data: History[],
  isLoading: boolean;
  total?: number;
}

export const HistoryTable = ({ data, total, isLoading }: Props): JSX.Element => {
  const classes = useStyles();
  const expandable = useExpandableRows<History>(data);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'endpoint_name',
      label: 'Endpoint name',
      options: {
        customBodyRenderLite: dataIndex => (
          <RabbitEndpointLink
            endpointId={ Number(data[dataIndex].endpoint_id) }
            name={ data[dataIndex].endpoint_name }
          />
        ),
      },
    },
    {
      name: 'network_id',
      label: 'Network ID',
      options: {
        customBodyRenderLite: dataIndex => <PositionName network={ String(data[dataIndex].network_id) }/>,
      },
    },
    {
      name: 'source_message_type',
      label: 'Message type',
      options: {
        customBodyRenderLite: dataIndex => <RabbitMessageTypeName messageTypeId={ String(data[dataIndex].source_message_type) }/>,
      },
    },
    {
      name: 'sending_time',
      label: 'Sending time',
      options: {
        customBodyRenderLite: dataIndex => data[dataIndex].sending_time ? moment(data[dataIndex].sending_time).toLocaleString() : '-',
      },
    },
    {
      name: 'sending_status',
      label: 'Sending status',
      options: {
        customBodyRenderLite: dataIndex => {
          const history = data[dataIndex];
          if (history && !history.endpoint_id && history.sending_status === SendingStatus.FAILURE) {
            return 'NO ROUTE';
          }

          return history.sending_status;
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    expandableRows: true,
    expandableRowsOnClick: true,
    serverSide: true,
    rowsExpanded: expandable.rowsExpanded,
    onRowExpansionChange: expandable.onRowExpansionChange,
    renderExpandableRow: (_rowData, rowMeta) => {
      const history: History = data[rowMeta.dataIndex];
      if (!history) {
        return null;
      }

      return (
        <DetailInfo
          cols={ columns.length + 1 }
          data={ history }
        />
      );
    },
    setRowProps: createHiglightingNewRows(data),
    count: total,
    customFooter: count =>  <PaginationFooter count={ count } />
  };

  return (
    <div className={ classes.root }>
      <ThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={isLoading}>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </ThemeProvider>
    </div>
  );
};
