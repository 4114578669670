import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageParams, usePositionDetailPage } from './utils';

// components
import { Box, Grid } from '@mui/material';
import { PositionNotFound } from './widget/PositionNotFound';
import { PositionSkeleton } from './widget/PositionSkeleton';
import { PositionMainBlock } from './widget/PositionMainBlock';
import { PositionDeviceBlock } from './widget/PositionDeviceBlock';

// styles
import useStyles from 'styles/page';


export const PositionDetailPage = (params: PageParams) => {
  const classes = useStyles();
  const { isLoading, isValid, tree, node, back, reload } = usePositionDetailPage(params);

  return (
    <Box className={ classes.root } data-testid="page-dm-position-detail">
      <Helmet><title>Position { node?.position.network_id ?? `#${ params.positionId }` }</title></Helmet>
      { !isValid && <PositionNotFound { ...params } /> }
      { isValid && isLoading && <PositionSkeleton/> }
      { isValid && node && (
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 }>
            <PositionMainBlock
              node={ node }
              onEdit={ reload }
              onDelete={ back }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <PositionDeviceBlock
              tree={ tree }
              node={ node }
              onEdit={ reload }
            />
          </Grid>
        </Grid>
      ) }
    </Box>
  );
};
