import { combineReducers } from 'redux';

import * as users from './users';
import * as stationMonitoring from './station-monitoring';

export interface State {
  users: users.State;
  stationMonitoring: stationMonitoring.State;
}

export const reducer = combineReducers<State>({
  users: users.reducer,
  stationMonitoring: stationMonitoring.reducer
});
