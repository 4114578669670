import { ShipmentDeviceForAws } from 'models/provisioning';

// copmonents
import { Box } from '@mui/material';
import { ProvisionWidget } from './Provision';
import { DeleteWidget } from './Delete';

type Props = {
  provisionedDevices: ShipmentDeviceForAws[]
  nonProvisionedDevices: ShipmentDeviceForAws[]
  isDevicesLoading: boolean
}

export const AwsProvisioningWidget = ({
  provisionedDevices,
  nonProvisionedDevices,
  isDevicesLoading
}: Props): JSX.Element => {
  return (<>
    <Box display="flex">
      <Box mr={ 1 }>
        <ProvisionWidget
          provisionedDevices={provisionedDevices}
          nonProvisionedDevices={nonProvisionedDevices}
          isDevicesLoading={isDevicesLoading}
        />
      </Box>
      <Box mr={ 1 }>
        <DeleteWidget
          provisionedDevices={provisionedDevices}
          isDevicesLoading={isDevicesLoading}
        />
      </Box>
    </Box>
  </>);
};

