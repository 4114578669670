import React from 'react';
import { dmDevicesPath } from 'routing/paths';
// components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CommonButton from 'components/Buttons/CommonButton';

interface Props {
  deviceId?: string;
}

export const DeviceNotFoundPage = ({ deviceId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Device with ID: <b>{ deviceId ?? '0' }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label="Back to list"
        LinkTo={ dmDevicesPath() }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};