import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { DoFetchDevicesByAccumulatedPosition } from 'actions/device-management/devices';
import { useEffectOnce } from 'react-use';

// components
import { DeviceLink, SPlaceLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  id: number;
}

const PositionedDeviceName = ({ id }: Props) => {
  const dispatch = useDispatch();
  const { fetched, devices } = useSelector((state: RootState) => ({
    fetched: state.deviceManagement.devices.accumulatedDataLoaded,
    devices: state.deviceManagement.devices.data,
  }));

  useEffectOnce(() => {
    dispatch(DoFetchDevicesByAccumulatedPosition(id));
  });

  const currentDevice = devices.find(device => device.position_id === id);

  const deviceId = currentDevice?.device_id;

  if (!deviceId && fetched) {
    return <SPlaceLink positionId={ id } />;
  }

  return !deviceId && !fetched
    ? <LoaderSvg />
    : <DeviceLink deviceId={ deviceId } />;
};

export default PositionedDeviceName;
