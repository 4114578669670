import { makeStyles, createStyles, Theme } from 'styles/utils';
import { fonts } from 'styles/themes/outer';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  mapBlock: {
    width: '70vw',
    height: '100vh'
  },
  mapSearch: {
    margin: theme.spacing(4, 4, 1, 4),
    width: '90%',
  },
  mapRoot: {
    width: '100%',
    height: '89vh!important',
  },
  formBlock: {
    width: '30vw',
    height: '100vh'
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  formHeader: {
    flex: '0 0 auto',
    padding: theme.spacing(2, 3),
  },
  formTitle: {
    color: theme.palette.primary.main,
    fontFamily: fonts.textSecondary,
    fontSize: '18px',
    lineHeight: 1.44,
  },
  formContent: {
    flex: '1 1 auto',
    padding: theme.spacing(1, 3),
    overflowY: 'auto',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: alpha(theme.palette.primary.main, .38)
  },
  formActions: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  deleteBtn: {
    marginRight: 'auto',
    color: 'red',
  },
}));
