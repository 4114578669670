import React, { HTMLAttributes, useRef, useCallback, useMemo } from 'react';
import { useVirtual } from 'react-virtual';
import clsx from 'clsx';
import Select, { components as SelectComponents, ValueType, OptionsType, ActionMeta, createFilter } from 'react-select';
import { SearchConfig, SelectOption } from './types';

import { FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
// styles
import useStyles, { customTheme } from './styles';

const inputComponent = ({ inputRef, ...props }: Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>) => (
  <div ref={ inputRef } { ...props } />
);

const SelectContainer = (props: React.ComponentProps<typeof SelectComponents.SelectContainer>) => {
  return (
    <SelectComponents.SelectContainer
      {...props}
      innerProps={ {
        ...props.innerProps,
        'data-testid': 'select-container',
        'data-disabled': props.isDisabled,
      } as typeof props.innerProps }
    />
  );
};

const Control = (props: React.ComponentProps<typeof SelectComponents.Control>) => {
  const { selectProps: { classes, TextFieldProps, isMultiLine }, innerProps = {}, innerRef, children } = props;

  return (
    <TextField
      fullWidth
      InputProps={ {
        inputComponent,
        inputProps: {
          className: clsx(classes.input, {
            [classes.multiLineInput]: isMultiLine,
          }),
          ref: innerRef,
          children,
          ...innerProps,
        },
      } }
      { ...TextFieldProps }
    />
  );
};

const OPTION_TEST_ID = 'select-option';

interface OptionContentRenderProps {
  optionProps: React.ComponentProps<typeof SelectComponents.Option>;
  isMulti?: boolean;
}

function renderOptionContent({ optionProps, isMulti }: OptionContentRenderProps) {
  const { children, isSelected } = optionProps;
  const option = optionProps.data as SelectOption<unknown>;

  return (
    <Grid container wrap="nowrap" alignItems="center">
      { isMulti && (
        <Grid item>
          <Checkbox
            checked={ isSelected }
            value={ option.value }
            onChange={ () => null }
          />
        </Grid>
      ) }

      <Grid item container direction="column" justifyContent="center">
        <Grid item>
          <FormLabel>{ children }</FormLabel>
        </Grid>
        { option.helperText && (
          <Grid item>
            <FormHelperText>{ option.helperText }</FormHelperText>
          </Grid>
        ) }
      </Grid>
    </Grid>
  );
}

const OptionSingle = (props: React.ComponentProps<typeof SelectComponents.Option>) => {
  const { selectProps: { classes }, innerProps = {}, innerRef } = props;
  return (
    <MenuItem
      className={ clsx(classes.option, {
        [classes.optionFocused]: props.isFocused,
      }) }
      ref={ innerRef }
      selected={ props.isSelected }
      disabled={ props.isDisabled }
      { ...innerProps }
      data-testid={ OPTION_TEST_ID }
    >
      <FormControl>
        { renderOptionContent({ optionProps: props }) }
      </FormControl>
    </MenuItem>
  );
};

const OptionMultiplue = (props: React.ComponentProps<typeof SelectComponents.Option>) => {
  const { selectProps: { classes }, innerProps = {}, innerRef } = props;

  return (
    <MenuItem
      className={ clsx(classes.option, classes.optionMulti, {
        [classes.optionFocused]: props.isFocused,
        [classes.optionMultiWithHelperText]: (props.data as SelectOption<unknown>).helperText,
      }) }
      ref={ innerRef }
      selected={ props.isSelected }
      disabled={ props.isDisabled }
      { ...innerProps }
      data-testid={ OPTION_TEST_ID }
    >
      <FormControl>
        {renderOptionContent({ optionProps: props, isMulti: true })}
      </FormControl>
    </MenuItem>
  );
};

const NoOptionsMessage = (props: React.ComponentProps<typeof SelectComponents.NoOptionsMessage>) => {
  const { selectProps: { classes }, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={ classes.noOptionsMessage } { ...innerProps } noWrap={ true }>
      { children }
    </Typography>
  );
};

const Placeholder = (props: React.ComponentProps<typeof SelectComponents.Placeholder>) => {
  const { selectProps: { classes }, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={ classes.placeholder } { ...innerProps } noWrap={ true }>
      { children }
    </Typography>
  );
};

function ValueContainer(props: React.ComponentProps<typeof SelectComponents.ValueContainer>) {
  const { selectProps: { classes, isMultiLine } } = props;

  return (
    <div
      className={ clsx(classes.valueContainer, {
        [classes.multiLineValueContainer]: isMultiLine,
      }) }
    >
      { props.children }
    </div>
  );
}

const SingleValue = (props: React.ComponentProps<typeof SelectComponents.SingleValue>) => {
  const { selectProps: { classes }, innerProps = {}, children } = props;
  return (
    <Typography className={ classes.singleValue } { ...innerProps } noWrap={ true }>
      { children }
    </Typography>
  );
};

function MultiValue(props: React.ComponentProps<typeof SelectComponents.MultiValue>) {
  const { selectProps, children, isFocused } = props;
  return (
    <Chip
      tabIndex={ -1 }
      label={ children }
      className={ clsx({
        [selectProps.classes.chipFocused]: isFocused,
      }) }
    />
  );
}

const Menu = (props: React.ComponentProps<typeof SelectComponents.Menu>) => {
  const { selectProps: { classes }, innerProps = {}, children } = props;
  return (
    <Paper square className={ classes.paper } data-testid="select-menu" { ...innerProps }>
      { children }
    </Paper>
  );
};

function setRef<T>(ref: React.Ref<T>, value: T) {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  (ref as React.MutableRefObject<T>).current = value;
}

const MenuList = (props: React.ComponentProps<typeof SelectComponents.MenuList>) => {
  const { children, isMulti, options } = props;
  const childrenArray = useMemo(() => React.Children.toArray(children), [children]);

  const menuListRef = useRef<HTMLDivElement>(null);

  const getOptionHeight = useCallback((index: number) => {
    if (!options.length) {
      return 38;
    }

    const option = options[index];

    const optionHeight = {
      withHelperText: 50,
      withoutHelperText: isMulti ? 38 : 32,
    };

    return option.helperText ? optionHeight.withHelperText : optionHeight.withoutHelperText;
  }, [options, isMulti]);

  const optionVirtualizer = useVirtual({
    size: childrenArray.length,
    parentRef: menuListRef,
    estimateSize: getOptionHeight,
  });

  return (
    <SelectComponents.MenuList
      { ...props }
      innerRef={ node => {
        setRef(menuListRef, node);
        setRef(props.innerRef, node);
      } }
    >
      <div
        style={ {
          height: `${optionVirtualizer.totalSize}px`,
          width: '100%',
          position: 'relative',
        } }
      >
        { optionVirtualizer.virtualItems.map(virtualOption => (
          <div
            key={ virtualOption.index }
            style={ {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${virtualOption.size}px`,
              transform: `translateY(${virtualOption.start}px)`
            } }
          >
            { childrenArray[virtualOption.index] }
          </div>
        )) }
      </div>
    </SelectComponents.MenuList>
  );
};

const IndicatorsContainer = (props: React.ComponentProps<typeof SelectComponents.IndicatorsContainer>) => (
  <SelectComponents.IndicatorsContainer { ...props } className={ props.selectProps.classes.indicatorContainer }/>
);

const DropdownIndicator = (props: React.ComponentProps<typeof SelectComponents.DropdownIndicator>) => (
  <Button
    color={ props.selectProps.isDisabled ? 'inherit' : 'primary' }
    className={ props.selectProps.classes.dropDown }
  >
    <KeyboardArrowDown/>
  </Button>
);

const components = {
  SelectContainer,
  Control,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
  DropdownIndicator
};

export interface SelectProps<TValue, IsMulti extends boolean> {
  name: string;
  label?: string;
  error?: React.ReactNode;
  help?: React.ReactNode;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: IsMulti;
  isMultiLine?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  isPortal?: boolean;
  closeMenuOnSelect?: boolean;
  value?: ValueType<SelectOption<TValue>, IsMulti>;
  options: OptionsType<SelectOption<TValue>>;
  hideMenu?: boolean;
  menuIsOpen?: boolean;
  onBlur?: () => void;
  onChange: (value: ValueType<SelectOption<TValue>, IsMulti>, action?: ActionMeta<TValue>) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  searchConfig?: SearchConfig<SelectOption<TValue>>;
}

const keysToIgnore = new Set([
  'ArrowUp',
  'ArrowDown',
  'PageUp',
  'PageDown',
  'Home',
  'End'
]);

export default function ReactSelect<TValue, IsMulti extends boolean>(props: SelectProps<TValue, IsMulti>) {
  const classes = useStyles();
  const inputId = props.name + '-react-select';
  const valueFull = Boolean(
    props.isMulti
      ? (props.value as OptionsType<SelectOption> | undefined)?.length
      : props.value
  );
  const shrink = Boolean(valueFull || props.placeholder);
  const [isFocused, setFocused] = React.useState<boolean>(false);

  return (
    <Select
      className="ReactSelect-root"
      classNamePrefix="ReactSelect-root"
      classes={ classes }
      styles={ customTheme<SelectOption<TValue>, IsMulti>() }
      inputId={ inputId }
      name={ props.name }
      TextFieldProps={ {
        label: props.label,
        name: props.name,
        InputLabelProps: { htmlFor: inputId, shrink: shrink || isFocused },
        error: Boolean(props.error),
        helperText: props.error || props.help,
        required: props.isRequired,
        disabled: props.isDisabled,
      } }
      placeholder={ props.placeholder ? props.placeholder : null }
      options={ props.options }
      components={ {
        ...components,
        Option: props.isMulti ? OptionMultiplue : OptionSingle,
        Menu: props.hideMenu ? () => null : Menu,
      } }
      value={ props.value }
      isClearable={ props.isClearable }
      isSearchable={ props.isSearchable }
      isMulti={ props.isMulti }
      isMultiLine={ props.isMultiLine }
      isDisabled={ props.isDisabled }
      isLoading={ props.isLoading }
      isOptionDisabled={ o => Boolean(o.isDisabled) }
      onChange={ props.onChange }
      onBlur={ () => {
        setFocused(false);
        props.onBlur?.();
      } }
      onFocus={ () => (setFocused(true)) }
      onMenuOpen={ props.onMenuOpen }
      onMenuClose={ props.onMenuClose }
      closeMenuOnSelect={ props.closeMenuOnSelect }
      hideSelectedOptions={ false }
      blurInputOnSelect={ false }
      menuPortalTarget={ props.isPortal ? document.body : undefined }
      onKeyDown={ e => {
        if (keysToIgnore.has(e.key)) {
          e.preventDefault();
        }
      } }
      filterOption={ props.searchConfig ? createFilter(props.searchConfig) : undefined }
    />
  );
}
