import React from 'react';
import { useAuthUserSelector, useDialog } from 'hooks';
import { canCreateRule } from 'utils/permissions';
import { Endpoint } from 'models/rabbit';

// components
import { Box } from '@mui/material';
import { PlusButton } from 'components/Buttons';
import { AddRuleDialog } from 'components/Rabbit/Dialogs';

interface Props {
  endpoint: Endpoint;
}

export const ConnectZoneButton = ({ endpoint }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const connectDialog = useDialog();
  if (!canCreateRule(user)) {
    return (
      <></>
    );
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      <PlusButton
        type="icon"
        label="Connect zone"
        compact
        onClick={ connectDialog.open }
      />
      { connectDialog.isMounted && <AddRuleDialog
        endpoint={ endpoint }
        isOpen={ connectDialog.isOpen }
        onClose={ connectDialog.close }
        onCloseEnd={ connectDialog.unmount }
      /> }
    </Box>
  );
};
