import { User } from "./user";

export enum SubscriptionType {
    Weekly = 'weekly',
    Daily = 'daily',
    Station = 'station_status_instant',
}

export interface SubscriptionParams {
    projectId: number;
    ownerId?: number;
    userId: number;
    type: SubscriptionType;
    subscriptionId?: number;
}

export interface UserWithSubscriptionType extends User {
    ProjectSubscriptions: SubscriptionParams[]
}
