import { useForm } from 'hooks/form';
import { Group, PositionGroupUpdateParams, } from 'models/device-management';
import { updateGroup } from 'clients/device-management';

// components
import { Button } from '@mui/material';
import { CommonDialog } from 'components/Dialogs';
import { GroupForm, GroupFormField, GroupFormState } from 'components/DeviceManagement/Forms';

interface Props {
  group: Group;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (group: Group) => void;
}

export const EditGroupDialog = ({ group, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const form = useForm<GroupFormState, GroupFormField>({
    initialState: {
      [GroupFormField.zone]: group.zone_id,
      [GroupFormField.level]: group.level_id,
      [GroupFormField.name]: group.name,
      [GroupFormField.type]: group.type,
      [GroupFormField.customId]: group.custom_id,
    },
    showFields: [
      GroupFormField.name,
      GroupFormField.type,
      GroupFormField.zone,
      GroupFormField.level,
      GroupFormField.customId,
    ],
  });

  const handleSubmit = async (): Promise<void> => {
    if (!form.validate()) {
      return;
    }

    const groupUpdate: PositionGroupUpdateParams = {
      id: group.id,
      props: {
        zone_id: Number(form.state[GroupFormField.zone]),
        level_id: form.state[GroupFormField.level] ?? null,
        name: String(form.state[GroupFormField.name]),
        type: form.state[GroupFormField.type] ?? group.type,
        custom_id: String(form.state[GroupFormField.customId]),
      }
    };

    // @TODO check it if dm-all will be ready to prod
    // move to hooks
    try {
      await updateGroup(groupUpdate);
    } catch(e) {
      form.catchError(e as Error);
    } finally {
      onSuccess && onSuccess(group);
      onClose();
    }
  };

  return (
    <CommonDialog
      testId="dm-group-edit-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit group > ${ group.name }` }
      content={ <GroupForm { ...form } /> }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ () => handleSubmit() }>Save</Button>
        </>
      }
    />
  );
};
