import { Device } from 'models/dms';
import { commonOptions } from 'utils/tables';
import { useAuthUserSelector } from 'hooks/user';
import { useExpandableRows } from 'hooks/expandable-rows';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { ProjectName, OwnerName } from 'components/Labels';
import { EditButton } from 'components/Buttons';
import { CommonStatus } from 'components/CommonStatus';
import { DeviceInfo } from './DeviceInfo';

// styles
import { ThemeProvider } from 'styles/utils';
import { getMuiTheme } from 'styles/themes';


interface Props {
  isLoading: boolean;
  devices: Device[];
  onEdit: (device: Device) => void;
}

const mapStatusToColor = {
  unknown: 'unknown',
  online: 'ok',
  offline: 'error',
} as const;

export const PageTable = ({ isLoading, devices, onEdit }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();
  const expandable = useExpandableRows<Device>(devices);


  const columns: MUIDataTableColumn[] = [
    {
      name: 'lorawan_id',
      label: 'ID',
    },
    {
      name: 'project_id',
      label: 'Project',
      options: {
        customBodyRenderLite: dataIndex => <ProjectName id={ devices[dataIndex].project_id }/>,
      },
    },
    {
      name: 'owner_id',
      label: 'Owner',
      options: {
        filter: false,
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ devices[dataIndex].owner_id }/>,
      }
    },
    {
      name: 'uplink',
      label: 'Last Uplink Time',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          const value = devices[dataIndex].last_uplink_time ?? '';
          if (value === '') {
            return '-';
          }
          return new Date(value).toLocaleString();
        },
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          const value = devices[dataIndex].status ?? '';
          if (value === '') {
            return '-';
          }
          return <CommonStatus label={value} color={mapStatusToColor[value]} hideDuration />;
        },
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        empty: true,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
        customBodyRenderLite: dataIndex => (
          <EditButton
            type="text"
            label="Edit"
            onClick={ (_currentTarget, event) => {
              event.stopPropagation();
              onEdit(devices[dataIndex]);
            } }
          />
        ),
      },
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    customFooter: count => <PaginationFooter count={ count }/>,
    expandableRows: true,
    expandableRowsOnClick: true,
    rowsExpanded: expandable.rowsExpanded,
    onRowExpansionChange: expandable.onRowExpansionChange,
    renderExpandableRow: (rowData, rowMeta) => {
      const device = devices[rowMeta.dataIndex];
      return device ? <DeviceInfo cols={ rowData.length + 1 } device={ device }/> : null;
    },
  };

  return (
    <ThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ isLoading }>
        <MUIDataTable title={ null } data={ devices } columns={ columns } options={ options }/>
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

