import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { transformToNumber } from 'helpers';

import { UpdateDeviceEmulationSetting } from 'actions/device-emulation';
import { useDeviceEmulationSettingsSelector } from 'hooks/device-emulation';
import { useIncrementCarCounter } from '../useIncrementCarCounter';

import { Device } from 'models/device-management';
import { DeviceType, getDeviceType } from 'models/device-emulation';

import { formatAutoChangeOccupancyPeriod } from 'utils/datetime';
import { useForm } from 'utils/form';
import * as is from 'utils/form/validation/validators';
import { dispatchAsync } from 'utils/store';
import { nameof } from 'utils/type-checking';
import { useDialog } from 'hooks/dialog';

// components
import {
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Popover,
  Paper,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import {
  Block,
  Help as InfoIcon,
  HelpOutline as InfoOutlineIcon,
  ExpandMore,
  Save,
  Send,
} from '@mui/icons-material';

import { FormContext } from 'components';
import { CommonButton } from 'components/Buttons';
import * as Fields from 'components/Form/Field';
import {
  SendMessageAlerts,
  SendMessageDialog,
  SendMessageUnactiveDeviceDialog,
} from 'components/DeviceManagement/Dialogs/SendMessageDialog';
import { LoaderSvg } from 'components/Loaders';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

const AUTO_SENDING_PERIOD_OPTIONS = [1, 2, 5, 10, 15, 30, 60, 240].map(periodMinutes => ({
  value: periodMinutes,
  label: formatAutoChangeOccupancyPeriod(periodMinutes)
}));

const DEFAULT_AUTOSENDING_PERIOD_VALUE = 5;

interface VirtualDeviceSettingsFormValues {
  auto_sending: boolean;
  sending_period_min?: number;
  heartbeat_sending: boolean;
  step_from?: number;
  step_to?: number;
}

interface VirtualDeviceSettingsProps {
  device: Device;
  allowedToUpdateDevice: boolean;
  onActivateDevice: () => Promise<unknown>;
}

enum DEVICE_ACTIVATION_ACTION_STATE {
  DEFAULT,
  MANUALY,
  AUTO_SENDING
}

export const VirtualDeviceSettings = ({
  device,
  allowedToUpdateDevice,
  onActivateDevice,
}: VirtualDeviceSettingsProps) => {
  const infoCss = useInfoBlockStyles();
  const dispatch = useDispatch();
  const { deviceSettings } = useDeviceEmulationSettingsSelector(device.device_id);
  const deviceType = getDeviceType(device);
  const sendMessageDialog = useDialog();
  const { isUpdating: isIncrementUpdating, incrementCarCounter } = useIncrementCarCounter();
  const [deviceActivationStatus, setDeviceActivationStatus] = useState(DEVICE_ACTIVATION_ACTION_STATE.DEFAULT);

  const form = useForm<VirtualDeviceSettingsFormValues>({
    defaultValues: {
      ...deviceSettings,
      auto_sending: deviceSettings?.auto_sending ?? false,
      sending_period_min: deviceSettings?.sending_period_min ?? undefined,
      heartbeat_sending: deviceSettings?.heartbeat_sending ?? false,
      step_from: deviceSettings?.step_from ?? 1,
      step_to: deviceSettings?.step_to ?? 1023,
    },
    validators: {
      sending_period_min: (value, values, allValues) => {
        if (values.auto_sending) {
          return is.required()(value, values, allValues);
        }
      },
      step_from: (value, values) => {
        if (deviceType !== DeviceType.CAR_COUNTER) {
          return;
        }

        if (Number(value) > Number(values.step_to)) {
          return 'Min increasing step should be less than Max increasing step';
        }
      },
    },
  });

  const handleSubmit = form.handleSubmit(values => {
    const nextDeviceSettings = {
      ...deviceSettings,
      ...values,
    };

    const action = UpdateDeviceEmulationSetting(device.device_id, nextDeviceSettings);

    return dispatchAsync(dispatch, action);
  });

  const handleAutoSendingChange = (nextAutosendingEnabled: boolean) => {
    form.setValue(
      'sending_period_min',
      nextAutosendingEnabled ? DEFAULT_AUTOSENDING_PERIOD_VALUE : undefined,
    );
    if (nextAutosendingEnabled) {
      form.setValue('heartbeat_sending', true);
    }
  };

  const step_from = form.watch('step_from');
  const step_to = form.watch('step_to');

  useEffect(() => {
    const newStep = Number(transformToNumber(String(step_from)));
    const newValue = Math.max(1, Math.min(1023, newStep));
    if (newValue !== step_from) {
      form.setValue('step_from', newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_from]);
  useEffect(() => {
    const newStep = Number(transformToNumber(String(step_to)));
    const newValue = Math.max(1, Math.min(1023, newStep));
    if (newValue !== step_to) {
      form.setValue('step_to', newValue);
    }
    if(step_from && step_to && step_from < step_to) {
      form.clearError('step_from');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_to]);

  const autosendingEnabled = form.watch('auto_sending');
  const autosendingPeriod = form.watch('sending_period_min');
  const autosendingPeriodBiggerThanHeartbit = (
    typeof autosendingPeriod === 'number' &&
    autosendingPeriod > 180
  );
  const HeartbeatInfoIcon = autosendingPeriodBiggerThanHeartbit ? InfoIcon : InfoOutlineIcon;
  const showCounterStepBlock = autosendingEnabled && deviceType === DeviceType.CAR_COUNTER;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMore/> }>
        Send messages
      </AccordionSummary>

      <AccordionDetails>
        <div className={ infoCss.fields }>
          <FormContext form={ form }>
            <div>
              <Box ml={ 1 } mr={ 1 } mb={ 4 } display="flex" justifyContent="flex-end">
                <Box mr={ 2 }>
                  <SendMessageAlerts isDevicePositioned={ Boolean(device.position_id) }>
                    { ({ onClick }) => (
                      <CommonButton
                        type="button"
                        label="Send manually"
                        icon={ <Send/> }
                        ButtonProps={ { size: 'small' } }
                        onClick={ onClick ?? (() => {
                          if (!device.activation_status) {
                            setDeviceActivationStatus(DEVICE_ACTIVATION_ACTION_STATE.MANUALY);
                          } else {
                            sendMessageDialog.open();
                          }
                        }) }
                      />
                    ) }
                  </SendMessageAlerts>
                </Box>
                { deviceType === DeviceType.CAR_COUNTER && <Box mr={ 2 }>
                  <Tooltip title="Send incremented counter">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={ () => incrementCarCounter(device.device_id) }
                      disabled={isIncrementUpdating}
                    >
                      {isIncrementUpdating ? <LoaderSvg/> : '+1'}
                    </Button>
                  </Tooltip>
                </Box>}
                <Box mr={ 2 }>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={ <Block/> }
                    onClick={ form.reset }
                    disabled={ !allowedToUpdateDevice || !form.formState.dirty }
                  >Cancel</Button>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmit}
                  startIcon={ form.formState.isSubmitting
                    ? <CircularProgress size="1rem"/>
                    : <Save/>
                  }
                  disabled={ !allowedToUpdateDevice || !form.formState.dirty || form.formState.isSubmitting }
                >Save</Button>
              </Box>

              <Grid className={ infoCss.field } container>
                <Grid item xs={ 12 } lg={ 6 }>
                  <FormControlLabel
                    label={ deviceType === DeviceType.CAR_COUNTER ? 'Auto-increase counter' : 'Auto-change occupancy' }
                    control={
                      <Fields.Switch
                        name={ nameof<VirtualDeviceSettingsFormValues>('auto_sending') }
                        disabled={ !allowedToUpdateDevice }
                      />
                    }
                    className={ infoCss.switch }
                    labelPlacement="start"
                    onChange={ (_e, nextAutosendingEnabled) => {
                      if (!device.activation_status && nextAutosendingEnabled) {
                        setDeviceActivationStatus(DEVICE_ACTIVATION_ACTION_STATE.AUTO_SENDING);
                      } else {
                        handleAutoSendingChange(nextAutosendingEnabled);
                      }
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } lg={ 6 } hidden={ !autosendingEnabled }>
                  <Fields.SelectSingle
                    label={ deviceType === DeviceType.CAR_COUNTER ? 'Auto-increase counter period' : 'Auto-change occupancy period' }
                    isDisabled={ !allowedToUpdateDevice }
                    isRequired
                    name={ nameof<VirtualDeviceSettingsFormValues>('sending_period_min') }
                    values={ AUTO_SENDING_PERIOD_OPTIONS }
                  />
                </Grid>
              </Grid>

              { showCounterStepBlock && (
                <Grid container className={ infoCss.field }>
                  <Grid item xs={ 12 } lg={ 6 }>
                    <Fields.Text
                      label="Min increasing step"
                      disabled={ !allowedToUpdateDevice }
                      required
                      name={ nameof<VirtualDeviceSettingsFormValues>('step_from') }
                      InputLabelProps={ {
                        shrink: true,
                      } }
                    />
                  </Grid>

                  <Grid item xs={ 12 } lg={ 6 }>
                    <Fields.Text
                      label="Max increasing step"
                      required
                      fullWidth
                      disabled={ !allowedToUpdateDevice }
                      name={ nameof<VirtualDeviceSettingsFormValues>('step_to') }
                      InputLabelProps={ {
                        shrink: true,
                      } }
                    />
                  </Grid>
                </Grid>
              ) }

              <Popover
                open={ Boolean(anchorEl) }
                anchorEl={ anchorEl }
                onClose={ () => setAnchorEl(null) }
                anchorOrigin={ {
                  vertical: 'bottom',
                  horizontal: 'right',
                } }
                transformOrigin={ {
                  vertical: 'top',
                  horizontal: 'left',
                } }
              >
                <Paper>
                  <Box padding="8px 16px" maxWidth="300px">
                    <Typography>
                      Heartbeat message will be sent every 3 hours if device does not update its occupancy
                    </Typography>
                  </Box>
                </Paper>
              </Popover>

              <Box className={ infoCss.field } display="flex" alignItems="center">
                <FormControlLabel
                  label="Auto-send heartbeat"
                  control={
                    <Fields.Switch
                      name={ nameof<VirtualDeviceSettingsFormValues>('heartbeat_sending') }
                      disabled={ !allowedToUpdateDevice || autosendingEnabled }
                    />
                  }
                  onChange={ (_e, nextAutosendingHeartbeatEnabled) => {
                    if (!device.activation_status && nextAutosendingHeartbeatEnabled) {
                      setDeviceActivationStatus(DEVICE_ACTIVATION_ACTION_STATE.AUTO_SENDING);
                    }
                  } }
                  className={ infoCss.switch }
                  labelPlacement="start"
                />
                <Box display="inline-block" ml={ 1 }>
                  <CommonButton
                    type="icon"
                    label="Info"
                    icon={
                      <HeartbeatInfoIcon
                        color={ autosendingPeriodBiggerThanHeartbit ? 'primary' : 'action' }
                      />
                    }
                    onClick={ setAnchorEl }
                  />
                </Box>
              </Box>
            </div>
          </FormContext>
        </div>
      </AccordionDetails>

      { sendMessageDialog.isMounted && (
        <SendMessageDialog
          deviceId={ device.device_id }
          isOpen={ sendMessageDialog.isOpen }
          onClose={ sendMessageDialog.close }
          onCloseEnd={ sendMessageDialog.unmount }
        />
      ) }

      { deviceActivationStatus !== DEVICE_ACTIVATION_ACTION_STATE.DEFAULT ?
        <SendMessageUnactiveDeviceDialog
          deviceId={ device.device_id }
          isActiveDevice={ device.activation_status }
          onActivateDevice={ onActivateDevice }
          onUpdated={ () => {
            handleAutoSendingChange(autosendingEnabled);
            if (deviceActivationStatus === DEVICE_ACTIVATION_ACTION_STATE.MANUALY) {
              sendMessageDialog.open();
            }
            setDeviceActivationStatus(DEVICE_ACTIVATION_ACTION_STATE.DEFAULT);
          } }
          onClose={ () => {
            setDeviceActivationStatus(DEVICE_ACTIVATION_ACTION_STATE.DEFAULT);
            form.setValue('auto_sending', false);
            form.setValue('heartbeat_sending', false);
          } }
        />
        : null
      }
    </Accordion>
  );
};
