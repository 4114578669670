import React from 'react';
// components
import FileCopy from '@mui/icons-material/FileCopy';
import CommonButton, { CommonButtonProps } from './CommonButton';

type Props = Omit<CommonButtonProps, 'icon' | 'label' | 'type'> & {
  label?: CommonButtonProps['label'];
  type?: CommonButtonProps['type'];
};

const CloneButton = (props: Props): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Clone';
  const type = props.type !== undefined ? props.type : 'icon';
  return <CommonButton
    { ...props }
    type={ type }
    label={ label }
    icon={ type === 'text' ? undefined : <FileCopy /> }
  />;
};

export default CloneButton;

