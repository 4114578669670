import { useEffect } from 'react';
import { Zone, LevelUpdateFields } from 'models/device-management';

// components
import { AddButton, SuccessButton } from 'components/Buttons';
import { MuiDialog } from 'components/Dialogs';
import { Button } from '@mui/material';
import { DialogActions, DialogTitle, DialogContent } from '@mui/material';

import { AddLevelEnsureDialog } from './AddLevelEnsureDialog';
import { useDialog } from 'hooks';
import { Level } from 'models/device-management';
import { LevelForm } from './LevelForm';
import { useLevelForm } from './useLevelForm';

interface CreateLevelProps {
  mode: 'EDIT' | 'CREATE'
  level?: Level;
  zone: Zone;
  zoneLevels: Level[];
  open?: boolean;
  onClose?: () => void;
  onCreateSuccess?: (level: LevelUpdateFields & { id: number }) => void;
  onUpdateSuccess?: (level: LevelUpdateFields & { id: number }) => void;
}

export const LevelFormModal = ({
  level,
  zone,
  zoneLevels,
  mode,
  open,
  onClose,
  onCreateSuccess,
  onUpdateSuccess
}: CreateLevelProps) => {
  const isEdit = mode === 'EDIT';
  const dialog = useDialog();
  const ensureDialog = useDialog();
  useEffect(() => { open && dialog.open(); }, [open, dialog]);

  const { createLevel, updateLevel, control, errors, getValues, isValid, watch } = useLevelForm({
    defaultValues: level,
    onCreateSuccess,
    onUpdateSuccess,
  });

  const onSave = async () => {
    const v = getValues();
    if (isEdit && level?.id) {
      await updateLevel(level.id, { ...v, zone_id: zone.id });
    } else {
      await createLevel({ ...v, zone_id: zone.id });
    }
  };

  return (<>
    {!isEdit && <AddButton
      label="Add level"
      type="icon"
      onClick={ dialog.open }
    />}
    { dialog.isMounted && <MuiDialog
      fullWidth
      maxWidth="sm"
      open={ dialog.isOpen }
      onClose={ () => { dialog.close(); onClose?.(); } }
      onExited={ dialog.unmount }
    >
      <DialogTitle>
        {isEdit ? `Edit level ${zone.name}` : `Add level to zone ${zone.name}`}
      </DialogTitle>
      <DialogContent>
        <LevelForm control={control} errors={errors} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={ () => { dialog.close(); onClose?.(); } }>
          Cancel
        </Button>
        <SuccessButton
          disabled={!isValid}
          onClick={async () => {
            const v = getValues();
            const isExist = zoneLevels
              .filter(l => l.id !== level?.id)
              .some(level => level.floor_number === v.floor_number);
            if (isExist) {
              ensureDialog.open();
              return;
            }

            await onSave();
            dialog.close();
            onClose?.();
          } }
          label={!isEdit ? 'Add' : 'Save'}
        />
      </DialogActions>
    </MuiDialog>
    }
    <AddLevelEnsureDialog
      dialog={ensureDialog}
      zoneName={String(zone.name || zone.id)}
      floor={ watch('floor_number') }
      onYes={ async () =>  { await onSave(); dialog.close(); onClose?.(); }}
    />
  </>);
};
