import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { findPositionsByLocation } from 'actions/device-management/positions';
import { findPositionedDevices } from 'actions/device-management/devices';
import { Position } from 'models/device-management';
import emptyIcon from 'images/Map/green-bagel.svg';
import fillIcon from 'images/Map/green-circle.svg';

// components
import { Marker } from '@react-google-maps/api';
import { PositionMarkerInfo } from './PostionMarkerInfo';

interface Props {
  position: Position;
  openInfo?: number;
  onClick: (position?: Position) => void;
  coordinate: {
    lat: number;
    lng: number;
  };
}

export const PositionNeighborMarkers = ({ position, coordinate, openInfo, onClick }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { aroundPositions } = useSelector((state: RootState) => ({
    aroundPositions: state.deviceManagement.positions.positionsByLocation.data
      .filter(p => p.id !== position.id)
  }));

  useEffect(() => {
    dispatch(findPositionsByLocation({
      lat: coordinate.lat,
      lon: coordinate.lng,
      radius: 1000,
    }));
  }, [coordinate.lat, coordinate.lng, dispatch]);
  const aroundPositionsIds = aroundPositions.map(p => p.id);
  const { aroundDevices } = useSelector((state: RootState) => ({
    isLoading: state.deviceManagement.devices.isFetching,
    aroundDevices: state.deviceManagement.devices.data
      .filter(d => d.position_id && aroundPositionsIds.includes(d.position_id))
  }));

  useEffect(() => {
    dispatch(findPositionedDevices({
      lat: coordinate.lat,
      lon: coordinate.lng,
      radius: 1000,
      force: false
    }));
  }, [coordinate.lat, coordinate.lng, dispatch]);

  return (
    <>
      { aroundPositions.map(pos => {
        const device = aroundDevices.find(d => d.position_id === pos.id);
        return (
          <React.Fragment key={ pos.id }>
            <Marker
              key={ pos.id }
              icon={ device ? fillIcon : emptyIcon }
              position={ {
                lat: pos.lat,
                lng: pos.lon,
              } }
              onClick={ () => onClick(pos) }
            >
              { openInfo === pos.id && (
                <PositionMarkerInfo
                  position={ pos }
                  device={ device }
                  onClose={ () => onClick(undefined) }
                />
              ) }
            </Marker>
          </React.Fragment>
        );
      }) }
    </>
  );
};