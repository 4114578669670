import { DEFAULT_LIMIT, RawMessagesWithNewFlag } from 'models';
import { useExpandableRows } from 'hooks/expandable-rows';
import { commonOptions } from 'utils/tables';
import { createHiglightingNewRows } from 'utils/mui-datatables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';
import { IconButton, Tooltip } from '@mui/material';
import { Update } from '@mui/icons-material';

import { ZoneLabel } from 'components';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { PositionGroupLabel } from 'components/Labels';
import { BaseStationLink, DeviceLink } from 'components/Links';
import { MessageDetailInfo } from './widget/MessageDetailInfo';

// styles
import useStyles from './RawMessagesTableStyle';
import { getMuiTheme } from 'styles/themes';

interface Props {
  data: RawMessagesWithNewFlag[];
  total: number;
  fetching: boolean;
  isSync: boolean;
  setIsSync: () => void;
}

const RawMessagesTable = ({ data, total, fetching, isSync, setIsSync }: Props): JSX.Element => {
  const classes = useStyles();
  const expandable = useExpandableRows();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'deviceid',
      label: 'Device ID',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const deviceId = data[index].device_id;
          return deviceId ? <DeviceLink deviceId={ deviceId } /> : 'None';
        }
      },
    },
    {
      name: 'stationid',
      label: 'Station ID',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const stationId = data[index].bs_id;
          return stationId ? <BaseStationLink stationId={ stationId } /> : 'None';
        }
      },
    },
    {
      name: 'iterator',
      label: 'Iter',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const iterator = data[index].iterator;
          return Number.isInteger(iterator) ? iterator : '—';
        }
      }
    },
    {
      name: 'payload',
      label: 'Payload',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => (data[index].payload || 'None'),
      },
    },
    {
      name: 'receiptttime',
      label: 'Reception Time',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => new Date(data[index].demodulation_time || 0).toLocaleString(),
      },
    },
    {
      name: 'brokerttime',
      label: 'Broker Time',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => new Date(data[index].sent_time || 0).toLocaleString(),
      },
    },
    {
      name: 'signal',
      label: 'Signal',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => data[index].signal_strength,
      },
    },
    {
      name: 'zoneid',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const zoneId = data[index].zone_id;
          return zoneId ? <ZoneLabel zoneId={ zoneId } /> : '-';
        }
      },
    },
    {
      name: 'group',
      label: 'Group',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const groupId = data[index].group_id;
          const groupName = data[index].group_name;
          return groupId ? <PositionGroupLabel groupId={ groupId } name={ groupName } /> : '-';
        }
      },
    },
  ];
  const options: MUIDataTableOptions = {
    ...commonOptions,
    rowsPerPageOptions: [DEFAULT_LIMIT, 50, 100, 200],
    responsive: 'standard',
    expandableRows: true,
    expandableRowsOnClick: true,
    serverSide: true,
    count: total,
    setRowProps: createHiglightingNewRows(data),
    onTableChange: (action) => {
      if (fetching) {
        return false;
      }
      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
          expandable.closeRows();
          break;
      }
    },
    rowsExpanded: expandable.rowsExpanded,
    onRowExpansionChange: expandable.onRowExpansionChange,
    renderExpandableRow: (rowData, rowMeta) => {
      const message = data[rowMeta.dataIndex];
      return message ? <MessageDetailInfo cols={ rowData.length + 1 } message={ message } /> : null;
    },
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Auto update" onClick={ setIsSync }>
            <IconButton>
              <Update color={ isSync ? 'primary' : undefined } />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customFooter: (count) => {
      return (
        <PaginationFooter
          count={ count }
          rowsPerPageOptions={ options.rowsPerPageOptions }
        />
      );
    },
  };

  return (
    <div className={ classes.root }>
      <ThemeProvider theme={ getMuiTheme() } >
        <TableLoadingLayout isLoading={fetching}>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </ThemeProvider>
    </div>
  );
};

export default RawMessagesTable;
