import React from 'react';
import { isNil } from 'lodash';
import { DateRange, formatDateRange } from 'utils/datetime';

// components
import { Box } from '@mui/material';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { FilterPresetButton } from 'components/Buttons';
import { DateRangeControl } from 'components/Controls';

// styles
import useStyles from '../style';

interface Props {
  value?: DateRange;
  onChange: (value?: DateRange) => void;
  customValue?: string;
}

export const DateRangeSelect = ({ value, onChange, customValue }: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" >
      { customValue && <FilterPresetButton
        label={ customValue }
        icon={ <></> }
        selected={ isNil(value)  }
        ButtonProps={ { className: classes.filterControl } }
        onClick={ () => onChange(undefined) }
      /> }
      <DateRangeControl
        timeCustomizationEnabled
        value={ value ? { dateTimeFrom: value.dateFrom, dateTimeTo: value.dateTo } : undefined }
        renderTrigger={ triggerProps => (
          <FilterPresetButton
            { ...triggerProps }
            label={ value ? formatDateRange(value)  : 'Custom' }
            icon={
              <DateRangeIcon
                style={ {
                  marginTop: '-2px',
                  marginLeft: '-5px',
                  height: '14px'
                } }
              />
            }
            iconPlacement="end"
            selected={ !isNil(value) }
            selectedClickable
            dataTestId="date-range-control"
            ButtonProps={ {
              ...triggerProps,
              className: classes.filterControl,
              'data-date-from': value?.dateFrom.toJSON(),
              'data-date-to': value?.dateTo.toJSON(),
            } }
            onClick={ (_currentTarget, event) => triggerProps.onClick(event) }
          />
        ) }
        onChange={ (value) => onChange({
          dateFrom: value.dateTimeFrom as Date,
          dateTo: value.dateTimeTo as Date
        }) }
      />
    </Box>
  );
};
