import { useState } from 'react';
import { useAuthUserSelector, useForm, useFormActionLoader, useFormEditToogleActions } from 'hooks';
import { Shipment, ShipmentFormField, ShipmentStatusEnum } from 'models/provisioning';
import { DoUpdateShipment, DoUpdateShipmentStatus } from 'actions/provisioning';

// components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ShipmentForm } from './ShipmentForm/ShipmentForm';
import EnsureDialog from 'components/Dialogs/EnsureDialog';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  shipment: Shipment;
  haveLoraDevicesNotInAws: boolean;
}

export const ShipmentManagment = ({
  shipment,
  haveLoraDevicesNotInAws = true
}: Props): JSX.Element => {
  const [confirmMsg, showConfirmMsg] = useState('');
  const classes = useStyles();
  const user = useAuthUserSelector();

  const form = useForm<Shipment, ShipmentFormField>({
    initialState: shipment,
    showFields: [
      ShipmentFormField.name,
      ...(user.isAdmin || user.clientId === shipment.shipper_id ? [ShipmentFormField.shipper_id] : []),
      ShipmentFormField.recipient_id,
      ShipmentFormField.arrival_date,
      ShipmentFormField.sending_date,
      ShipmentFormField.status,
      ShipmentFormField.warranty,
    ],
  });
  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: true,
    canSave: form.isChange,
    onSave: () => {
      if (
        haveLoraDevicesNotInAws &&
        (form.state.status === ShipmentStatusEnum.Dispatched ||
        form.state.status === ShipmentStatusEnum.Received)
      ) {
        showConfirmMsg('There are not provisioned to AWS devices in the shipment. Are you sure that you want to dispatch them as it?');
        return false;
      }

      if (!form.validate()) {
        return false;
      }

      if (!form.state.warranty_id && form.state.status !== ShipmentStatusEnum.Created) {
        showConfirmMsg('Are you sure you want to change status of the <b>shipment</b> without <b>warranty</b>?');
        return false;
      }

      saveAction();
    },
    onCancel: form.reset
  });

  const saveAction = () => {
    if (shipment.status !== form.state.status) {
      doAction({
        action: DoUpdateShipmentStatus(shipment.id, form.state.status || ShipmentStatusEnum.Created),
        onError: form.reset,
        onSuccess: () => doAction({
          action: DoUpdateShipment(shipment.id, form.state),
          onError: form.catchError,
        })
      });

      return;
    }

    doAction({
      action: DoUpdateShipment(shipment.id, form.state),
      onError: form.catchError,
    });
  };

  return (
    <Card className={ classes.cardRoot } data-testid="shipment-configuration">
      <CardHeader title="Shipment configuration" action={ actions } />
      <CardContent>
        <ShipmentForm<Shipment> isView={ !editMode } { ...form } disabledFields={ [] } />
      </CardContent>
      <EnsureDialog
        name={ 'confirm-save' }
        isOpen={ Boolean(confirmMsg) }
        onClose={ () => showConfirmMsg('') }
        onYes={ saveAction }
      >
        { confirmMsg }
      </EnsureDialog>
    </Card >
  );
};
