import React from 'react';

// components
import { Grid, Paper } from '@mui/material';
import { FieldSkeleton, TableSkeleton } from 'components/Skeleton';

// styles
import { ThemeProvider } from 'styles/utils';
import { getMuiTheme } from 'styles/themes';

export const ZoneSkeleton = (): JSX.Element => {
  return (
    <Grid container spacing={ 1 } >
      <Grid item xs={ 12 }>
        <Paper>
          <FieldSkeleton/>
        </Paper>
      </Grid>
      <Grid item xs={ 12 }>
        <Paper>
          <FieldSkeleton/>
        </Paper>
      </Grid>
      <Grid item xs={ 12 }>
        <Paper>
          <ThemeProvider theme={ getMuiTheme({ embeddedTable: true }) }>
            <TableSkeleton
              rows={ 5 }
              cols={ 1 }
              options={ {
                elevation: 0,
                pagination: false,
              } }
            />
          </ThemeProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};