import { useStyles } from './styles';

type Props = {
  children: JSX.Element | JSX.Element[]
}

export const FieldBlock = ({ children }: Props) => {
  const classes = useStyles();
  return <div className={classes.fieldBlock}>
    {children}
  </div>;
};
