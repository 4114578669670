import React from 'react';
// components
import Skeleton, { SkeletonProps } from '@mui/lab/Skeleton';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface Props {
  itemsCount: number;
  ListProps?: Partial<ListProps>;
  SkeletonProps?: Partial<SkeletonProps>;
}

export const ListItemSkeleton = (props: Props): JSX.Element => {
  const { itemsCount, ListProps, SkeletonProps } = props;
  const items = Array.from({ length: itemsCount }, (_value, index) => (
    <ListItem key={ index }> <Skeleton variant="rectangular" width="100%"  { ...SkeletonProps }/></ListItem>
  ));

  return (
    <List { ...ListProps }>{ items }</List>
  );
};
