import React from 'react';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { StationType, BaseStation, LorawanStation } from 'models/base-station';
import { MonitoringDateRange } from 'models/base-station-status';
import { DATE_WITH_MINUTES_FORMAT } from 'utils/datetime';
import { useStationMonitoringFilters } from './utils';

// components
import { Box } from '@mui/material';
import { DateFilters } from './DateFilters';
import { BaseStationsCharts } from './widgets/AvailabilityChart/BaseStationsCharts';
import { NoiseLevelChart } from './widgets/AvailabilityChart/charts/NoiseLevelChart';
import { StatusComponent } from './StatusComponent';
import { LorawanCharts } from './widgets/AvailabilityChart/LorawanStationCharts';

// styles
import { useStyles } from './styles';

interface Props {
  station: BaseStation | LorawanStation;
}

function formatLastMessage(lastMessageValue?: string | null): React.ReactNode {
  if (!lastMessageValue) { return ''; }
  const date = moment(lastMessageValue);
  const now = moment();

  if (date.isSame(now, 'day')) {
    return date.format('HH:mm');
  }

  return date.format(DATE_WITH_MINUTES_FORMAT);
}

export const StationMonitoring = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { station } = props;
  const isLorawan = station.type === StationType.lorawan;
  const state = useSelector((state: RootState) => state.pages.stationMonitoring, shallowEqual);
  // FILTER
  const filters = useStationMonitoringFilters();
  const { dateRange } = filters.state;
  const dateChange = (dateRange: MonitoringDateRange) => {
    filters.onChange({
      ...filters.state,
      dateRange,
    });
  };

  return (
    <Box className={ classes.root } data-testid="base-station-monitoring">
      {!isLorawan &&
        <Box className={ classes.statusBlock }>
          <StatusComponent
            value={state.dongle && state.dongle.value}
            isLoading={!state.dongle}
            title="Receiver status"
            positiveLabel="Connected"
            negativeLabel="Disconnected"
          />
          <StatusComponent
            value={state.minCpp && state.minCpp.value}
            isLoading={!state.minCpp}
            title="Software status"
            positiveLabel="Running"
            negativeLabel="Stopped"
          />
          <StatusComponent
            value={state.minCpp && state.minCpp.value}
            isLoading={!state.minCpp}
            title="Time of the last received message"
            positiveLabel={formatLastMessage(state.lastMessage?.value)}
            negativeLabel="-"
            noIcons
          />
        </Box>
      }
      {isLorawan &&
        <Box display="flex" alignItems="center" justifyContent="end" paddingRight="8%" marginBottom="16px">
          <StatusComponent
            value={station.lastMsg ? 1 : 0}
            isLoading={false}
            title="Time of the last received message"
            positiveLabel={formatLastMessage(String(station.lastMsg))}
            negativeLabel="-"
            noIcons
          />
        </Box>
      }
      <Box>
        {!isLorawan && <BaseStationsCharts
          station={station}
          dateRange={dateRange}
          dateRangeControlNode={
            <DateFilters
              dateRange={ dateRange }
              onChange={ dateChange }
            />
          }
        />}
        {isLorawan && <LorawanCharts
          station={station}
          dateRange={dateRange}
          dateRangeControlNode={
            <DateFilters
              dateRange={ dateRange }
              onChange={ dateChange }
            />
          }
        />}
      </Box>
      {!isLorawan && <Box>
        <NoiseLevelChart
          noiseData={ state.noiseLevelHistory }
        />
      </Box>}
    </Box>
  );
};
