import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { getClientDetailPath } from 'routing/paths';
// components
import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface Props {
  userGroupId?: number;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

export const UserGroupLink = ({ userGroupId, innerProps }: Props): JSX.Element => {
  const { userGroups } = useUserGroupsDictionarySelector();
  const userGroup = userGroups.find(el => el.id === userGroupId);
  const TypoProps = { ...defaultProps, ...innerProps };

  if (!userGroup) {
    return <Typography { ...TypoProps }>-</Typography>;
  }

  const path = getClientDetailPath(userGroup.id);
  return <Link component={ RouterLink } color="secondary" to={ path }>{ userGroup.name }</Link>;
};
