import React, { ReactNode } from 'react';
// components
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, PaperProps, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { CommonButton } from 'components/Buttons';
import { Transition } from './widgets/Transition';
// styles
import clsx from 'clsx';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  title: ReactNode;
  content: ReactNode;
  actions?: ReactNode;
  testId?: string;
  PaperProps?: PaperProps;
  contentClassName?: string;
}

export const CommonDialog = (props: Props): JSX.Element => {
  const { isOpen, onClose, onCloseEnd, title, content, actions, PaperProps } = props;
  const styles = useStyles();

  return (
    <Dialog
      data-testid={ props.testId }
      classes={ {
        container: styles.dialogContainer,
        paper: styles.dialogPaper,
      } }
      open={ isOpen }
      onClose={ onClose }
      TransitionProps={ {
        onExited: onCloseEnd
      }}
      TransitionComponent={ Transition }
      maxWidth={ false }
      PaperProps={ {
        ...PaperProps,
        square: PaperProps?.square ?? true,
        className: clsx(styles.dialogPaper, PaperProps?.className),
      } }
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            className={ styles.dialogTitle }
          >{ title }</Typography>
          <CommonButton
            type="icon"
            icon={ <Close /> }
            label="Close"
            onClick={ onClose }
            ButtonProps={{ color: 'inherit' }}
          />
        </Box>
      </DialogTitle>
      <DialogContent className={ clsx(styles.dialogContent, props.contentClassName) }>
        { content }
      </DialogContent>
      { actions && (
        <DialogActions classes={ {
          root: styles.actionsRoot
        } }>{ actions }</DialogActions>
      ) }
    </Dialog>
  );
};
