import { ReactNode, useState } from 'react';
import { staticEnv } from 'env';
// components
import Box from '@mui/material/Box';
import { GoogleMap, GoogleMapProps, useJsApiLoader } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

const MapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ]
};

type JsApiLoaderArgs = Parameters<typeof useJsApiLoader>
const ScriptOptions: JsApiLoaderArgs[0] = {
  id: 'google-map-script',
  version: '3.exp',
  googleMapsApiKey:  staticEnv.GOOGLE_MAPS_KEY ?? '',
  language:  'en-GB',
  region:  'GB',
  libraries: ['geometry'],
};

type Google = typeof google;

export type CommonMapProps =
  Omit<GoogleMapProps, 'onLoad'>
  & {
    children?: ReactNode | ReactNode[];
    defaultCenter?: GoogleMapProps['center'];
    onLoad?: (map: google.maps.Map, google: Google) => void;
  }

/* Common map component, without any additional logic, please keep is clear */
export const CommonMap = (props: CommonMapProps): JSX.Element => {
  const { isLoaded } = useJsApiLoader(ScriptOptions);

  const [map, setMap] = useState<google.maps.Map>();
  const handleLoad = (ref: google.maps.Map): void => {
    const google = window.google;
    if (!google || !ref || ref === map) {
      return;
    }
    setMap(ref);
    props.defaultCenter !== undefined && ref.setCenter(props.defaultCenter);
    props.onLoad && props.onLoad(ref, window.google);
  };

  if (!isLoaded) {
    return (
      <Box height="100%" width="100%" data-testid="map-loading"/>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={ mapContainerStyle }
      options={ MapOptions }
      { ...props }
      onLoad={ handleLoad }
    >
      { props.children }
    </GoogleMap>
  );
};


