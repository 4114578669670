import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import { LocationUpdate, StationLocation } from 'models/base-station';
import * as Action from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createLocation, deleteLocation, updateLocation } from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import { clearQueryCache } from 'utils/react-query';
import { LOCATION_KEY, STATION_KEY } from 'hooks/station/query';

type UpdateAction =
  | Action.UpdateStationLocation
  | ActionWithPromise<Action.UpdateStationLocation, StationLocation>

function* doUpdateLocation(action: UpdateAction) {
  const { location } = action;
  const update: LocationUpdate = {
    ownerId: location.owner_id,
    projectId: location.project,
    country: location.country,
    city: location.city,
    address: location.address,
    postcode: location.postcode,
    notes: location.notes,
    lat: location.lat,
    lon: location.lon,
  };
  const response: ApiResponse = yield call(updateLocation, location.id, update);
  if (response.reason === ReasonEnum.Ok) {
    const newLocation: StationLocation = response.data as StationLocation;
    'meta' in action && action.meta.promise.resolve(newLocation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while updating base station location #${ location.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type CreateAction =
  | Action.CreateStationLocation
  | ActionWithPromise<Action.CreateStationLocation, StationLocation>

function* doCreateLocation(action: CreateAction) {
  const { location } = action;
  const create: LocationUpdate = {
    ownerId: location.owner_id,
    projectId: location.project,
    country: location.country,
    city: location.city,
    address: location.address,
    postcode: location.postcode,
    notes: location.notes,
    lat: location.lat,
    lon: location.lon,
  };
  const response: ApiResponse = yield call(createLocation, create);

  if (response.reason === ReasonEnum.Ok) {
    const newLocation: StationLocation = response.data as StationLocation;
    yield put(NotifySuccess(`Base station location #${ newLocation.id } has been created`));
    'meta' in action && action.meta.promise.resolve(newLocation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating a base station location: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DeleteAction =
  | Action.DeleteLocation
  | ActionWithPromise<Action.DeleteLocation, StationLocation>

function* doDeleteLocation(action: DeleteAction) {
  const { location } = action;

  const response: ApiResponse = yield call(deleteLocation, location.id);
  if (response.reason === ReasonEnum.Ok) {
    yield put(NotifySuccess(`Base station location #${ location.id } has been deleted`));
    'meta' in action && action.meta.promise.resolve(location);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while deleting base station location #${ location.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

function* clearCache() {
  yield clearQueryCache([
    STATION_KEY,
    LOCATION_KEY,
  ]);
}

export default [
  takeEvery(Action.BASE_STATION_LOCATION_UPDATE, doUpdateLocation),
  takeEvery(Action.BASE_STATION_LOCATION_CREATE, doCreateLocation),
  takeEvery(Action.BASE_STATION_LOCATION_DELETE, doDeleteLocation),
];
