import { Helmet } from 'react-helmet-async';
import { useDialog, useAuthUserSelector } from 'hooks';
import { useTemplateSuitesParams } from './useTemplateSuitesParams';
import { useTemplateSuites } from './useTemplateSuites';
// components
import AddButton from 'components/Buttons/AddButton';
import { RoutesTemplatesSuitesTable } from 'components/Caller';
import { CreateSuiteDialog } from 'components/Caller/CreateSuiteDialog/CreateSuiteDialogComponent';
import TemplatesSuitesFilterButton from './widgets/FilterButton';
// styles
import useStyles from 'styles/page';

const RoutesTemplatesSuitesPage = (): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const { params, setParams } = useTemplateSuitesParams();
  const { isLoading, data, total } = useTemplateSuites(params);
  const addDialog = useDialog();

  return (
    <div data-testid="routes-template-suites-page">
      <Helmet>
        <title>Template Suites</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <TemplatesSuitesFilterButton
          isLoading={ isLoading }
          filters={ params }
          applyFilters={ setParams }
        />
      </div>
      <div className={ classes.table }>
        <RoutesTemplatesSuitesTable
          isLoading={ isLoading }
          canViewOwner={ user.isAdmin }
          suites={ data }
          total={ total }
        />
      </div>
      <AddButton
        label="Add template suite"
        isLoading={ isLoading }
        onClick={ addDialog.open }
      />
      { addDialog.isMounted && (
        <CreateSuiteDialog
          isOpen={ addDialog.isOpen }
          onClose={ addDialog.close }
          onCloseEnd={ addDialog.unmount }
        />
      ) }
    </div>
  );
};

export default RoutesTemplatesSuitesPage;
