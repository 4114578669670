import { ZoneFormWidget } from 'components/Zones/ZoneForm/ZoneForm';

// components
import { CommonDialog } from 'components/Dialogs';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: () => void;
}

export const CreateZoneDialog = ({ isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  return (
    <>
      <CommonDialog
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
        title="Add zone"
        content={ <ZoneFormWidget
          asSider
          onCreateSuccess={() => { onSuccess && onSuccess(); onClose(); }}
          onCancel={onClose}
        /> }
      />
    </>
  );
};
