import { Helmet } from 'react-helmet-async';
import { useDeepCompareEffect } from 'react-use';
import { useDialog, usePagination } from 'hooks';
import { useFilterByLocation, useEndpointsByParams } from './utils';
// components
import { Box } from '@mui/material';
import { EndpointsFilter, EndpointsTable } from 'components/Rabbit';
import { CreateEndpointDialog } from 'components/Rabbit/Dialogs';
import { FilterButton, AddButton } from 'components/Buttons';
// styles
import useStyles from 'styles/page';

const Component = (): JSX.Element => {
  const classes = useStyles();
  const { filter, filterState, setFilterState, onSave, onReset } = useFilterByLocation();
  const { limit, offset, onChangePage } = usePagination();
  const { isLoading, data, total } = useEndpointsByParams({
    ...filter,
    limit,
    offset,
  });
  const createDialog = useDialog();

  useDeepCompareEffect(() => {
    onChangePage(0);
  }, [filter]);

  return (
    <div data-testid="page-rabbit-endpoint-list">
      <Helmet><title>RabbitMQ Endpoints</title></Helmet>
      <Box className={ classes.toolbar }>
        <FilterButton
          isLoading={isLoading}
          filter={filter}
          onSave={ () => onSave() }
          onReset={ () => onReset() }
        >
          <EndpointsFilter
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </Box>
      <EndpointsTable isLoading={ isLoading } data={data} total={total} />
      <AddButton label="Add endpoint" onClick={ createDialog.open } />
      { createDialog.isMounted && <CreateEndpointDialog
        isOpen={ createDialog.isOpen }
        onClose={ createDialog.close }
        onCloseEnd={ createDialog.unmount }
      /> }
    </div>
  );
};

export default Component;
