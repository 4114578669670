import { Controller, useForm } from 'react-hook-form-v6';
import { Box, Button, FormGroup , TextField } from '@mui/material';
import { DialogProps, useAuthUserSelector } from 'hooks';
import { useDMSCreateDeviceMutation } from 'hooks/dms';
import { CommonDialog } from 'components/Dialogs';
import { DeviceProfileSelect, OwnersSingleSelectControl, ProjectSelectControl } from 'components/Controls';
import { transformToHex } from 'helpers';

interface Props {
  dialog: DialogProps;
}

const defaultValues = {
  owner_id: null,
  project_id: null,
  lorawan_id: '',
  profile_id: null,
  comment: '',
};

export const CreateDialog = ({ dialog }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();
  const createDevice = useDMSCreateDeviceMutation();

  const form = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  return (
    <CommonDialog
      isOpen={ dialog.isOpen }
      onClose={ dialog.close }
      onCloseEnd={ dialog.unmount }
      title={ 'Create Digital Sign' }
      content={
        <FormGroup>
          { isAdmin && <Controller
            control={ form.control }
            name="owner_id"
            rules={ { required: true } }
            render={ props =>
              <OwnersSingleSelectControl
                { ...props }
                selected={ props.value }
                changeHandler={ async v => {
                  props.onChange(v);
                } }
                isClearable={ false }
                error={ form.errors.owner_id && 'Required' }
              />
            }
          /> }
          <Controller
            control={ form.control }
            name="project_id"
            rules={ { required: true } }
            render={ props =>
              <ProjectSelectControl
                { ...props }
                selected={ props.value }
                onChange={ v => props.onChange(v) }
                filter={ project => {
                  const owner = form.watch('owner_id');
                  return project.owner_id === owner;
                } }
                error={ !form.errors.owner_id && form.errors.project_id && 'Required' }
                isDisabled={ !!form.errors.owner_id }
              />
            }
          />
          <Controller
            control={ form.control }
            name="lorawan_id"
            rules={ {
              required: 'EUI is required',
              minLength: { value: 16, message: 'EUI length is 16 letter' },
              maxLength: { value: 16, message: 'EUI length is 16 letter' },
              pattern: { value: /[A-F0-9]/g, message: 'EUI allow only HEX letter' },
            } }
            render={ props =>
              <TextField
                { ...props }
                label="EUI"
                value={ props.value }
                onChange={ v => {
                  v.target.value = transformToHex(v.target.value);
                  props.onChange(v);
                } }
                error={ form.errors.lorawan_id !== undefined }
                helperText={ form.errors.lorawan_id?.message }
              />
            }
          />
          <Controller
            control={ form.control }
            name="profile_id"
            rules={ { required: true } }
            render={ props =>
              <DeviceProfileSelect
                { ...props }
                selected={ props.value }
                onChange={ v => props.onChange(v) }
                error={ form.errors.profile_id && 'Required' }
              />
            }
          />

          <Controller
            control={ form.control }
            name="comment"
            render={ props =>
              <Box pt={1}>
                <TextField
                  style={{ width: '100%' }}
                  label="Comment"
                  value={ props.value }
                  onChange={ v => props.onChange(v) }
                  multiline
                  maxRows={4}
                />
              </Box>
            }
          />
        </FormGroup>
      }
      actions={
        <>
          <Button
            color="primary"
            onClick={ () => form.reset(defaultValues) }
          >
            Reset
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ async () => {
              const isValid = await form.trigger();
              if (isValid) {
                const values = form.getValues();
                createDevice.mutate({
                  owner_id: Number(values.owner_id),
                  project_id: Number(values.project_id),
                  lorawan_id: String(values.lorawan_id),
                  device_profile_id: String(values.profile_id),
                  comment: String(values.comment)
                }, {
                  onSuccess: dialog.close
                });
              }
            } }
          > Add </Button>
        </>
      }
    />
  );
};