import React from 'react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Link, withRouter } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useAuthUserSelector } from 'hooks';
import { getProfileClientPath, getUserDetailPath } from 'routing/paths';
import { RoutesContext } from 'utils/routing';

// components
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Divider,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Breadcrumbs
} from '@mui/material';

import {
  AccountCircle,
  ExitToApp,
  SettingsApplications,
  Menu as MenuIcon,
  NavigateNext,
} from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';

import MainMenu from '../MainMenu';
import { renderBreadcrumbsEntries, renderBreadcrumbsEntriesMobile } from './utils';
// styles
import useStyles from './HeaderStyle';


const Component = (props: RouteProps) => {
  const user = useAuthUserSelector();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const [stateMenu, setStateMenu] = React.useState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setStateMenu(open);
  };

  const pathname = props && props.location && props.location.pathname;
  const userName = user.data.first_name && user.data.last_name ?
    `Hello, ${user.data.first_name} ${user.data.last_name}` :
    user.data.email;

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={ classes.appBar } color="primary" data-testid="app-header">
        <Toolbar>
          <IconButton
            data-testid="main-menu-icon"
            edge="start"
            className={ classes.menuButton }
            onClick={ toggleDrawer(!stateMenu) }
          >
            <MenuIcon />
          </IconButton>
          <RoutesContext.Consumer>
            { routes => pathname && routes && <>
              <Breadcrumbs
                classes={{
                  root: clsx(classes.title, classes.mobileHeader),
                  ol: classes.breadcrumbs,
                  li: classes.breadcrumb,
                  separator: classes.breadcrumbSeparator,
                }}
                separator={ <NavigateNext fontSize="small" /> }
              >
                { renderBreadcrumbsEntriesMobile({ pathname, routes }) }
              </Breadcrumbs>
              <Breadcrumbs
                classes={{
                  root: clsx(classes.title, classes.desktopHeader),
                  ol: classes.breadcrumbs,
                  li: classes.breadcrumb,
                  separator: classes.breadcrumbSeparator,
                }}
                separator={ <NavigateNext fontSize="small" /> }
              >
                { renderBreadcrumbsEntries({ pathname, routes }) }
              </Breadcrumbs>
            </> }
          </RoutesContext.Consumer>
          { user.signedIn && user.data.sub && (
            <div>
              <IconButton onClick={ handleMenu }>
                <AccountCircle className={ classes.title } />
              </IconButton>
              <Menu
                anchorEl={ anchorEl }
                anchorOrigin={ {
                  vertical: 'top',
                  horizontal: 'right',
                } }
                keepMounted={ true }
                transformOrigin={ {
                  vertical: 'top',
                  horizontal: 'right',
                } }
                open={ open }
                onClose={ handleClose }
              >
                <MenuItem>
                  <ListItemIcon>
                    <AccountCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={ userName }
                    secondary={
                      <React.Fragment>
                        <Typography variant="caption">{ user.data.role }</Typography>
                      </React.Fragment>
                    }
                  />
                </MenuItem>
                <Divider />
                <MenuItem
                  component={ Link }
                  to={ getUserDetailPath(user.data.sub) }
                  onClick={ handleClose }
                >
                  <ListItemIcon>
                    <SettingsApplications color="primary" />
                  </ListItemIcon>
                  <Typography>Profile</Typography>
                </MenuItem>
                <MenuItem component={ Link } to={ getProfileClientPath() } onClick={ handleClose }>
                  <ListItemIcon><BusinessIcon color="primary" /></ListItemIcon>
                  <Typography>Company Info</Typography>
                </MenuItem>
                <MenuItem onClick={ () => Auth.signOut() }>
                  <ListItemIcon>
                    <ExitToApp color="primary" />
                  </ListItemIcon>
                  <Typography>Sign out</Typography>
                </MenuItem>
              </Menu>
            </div>
          ) }
        </Toolbar>
      </AppBar>

      <MainMenu
        swipeableDrawerProps={ {
          open: stateMenu,
          onClose: toggleDrawer(false),
          onOpen: toggleDrawer(true),
        } }
      />
    </>
  );
};

export default withRouter(Component);
