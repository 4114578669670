import { useState } from 'react';
import { useDialog, useStationsDictionarySelector, useLoraWANStationsDictionarySelector } from 'hooks';
import { StationLocation, StationType } from 'models/base-station';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Link, Typography } from '@mui/material';
import { TableLoadingLayout } from 'components/Table';
import { BaseStationLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';
import { EditLocationDialog } from '../../../BaseStation/widgets/Dialogs'; // @TODO

// styles
import { ThemeProvider } from 'styles/utils';
import { getTableTheme } from 'styles/themes';

interface Props {
  isLoading?: boolean;
  locations: StationLocation[];
}

export const LocationsStations = ({ isLoading, locations }: Props): JSX.Element => {
  const editLocationDialog = useDialog();
  const [activeLocation, setActiveLocation] = useState<StationLocation>();

  const { stations: baseStations, isLoading: isBaseStationLoading } = useStationsDictionarySelector();
  const { stations: loraStations, isLoading: isLoraStationLoading } = useLoraWANStationsDictionarySelector();
  const stations = [...baseStations,  ...loraStations];
  const isStationLoading = isBaseStationLoading || isLoraStationLoading;

  const columns: MUIDataTableColumn[] = [
    {
      name: 'address',
      label: 'Address',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const location = locations[dataIndex];
          const address = `${ location.country }, ${ location.city }, ${ location.address }`;
          if(location.station_id) {
            return <BaseStationLink stationId={ location.station_id } label={ address } />;
          } else {
            return <Typography><Link onClick={ () => {setActiveLocation(location); editLocationDialog.open();} }>{ address }</Link></Typography>;
          }
        }
      }
    },
    {
      name: 'stationId',
      label: 'Station',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (isStationLoading) {
            return <LoaderSvg/>;
          }

          const location = locations[dataIndex];
          const station = stations.find(station => station.location?.id === location.id);
          return station ? <BaseStationLink
            stationId={ station.id }
            label={ station.type === StationType.lorawan ? `${station.eui}` : `${station.id}` }
          /> : '-';
        },
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    responsive: 'simple',
  };

  return (
    <>
      <ThemeProvider theme={ getTableTheme() }>
        <TableLoadingLayout isLoading={ isLoading }>
          <div data-testid="project-locations">
            <MUIDataTable
              title={ 'Locations and Stations' }
              data={ locations }
              columns={ columns }
              options={ options }
            />
          </div>
        </TableLoadingLayout>
      </ThemeProvider>
      { editLocationDialog.isMounted && activeLocation && <EditLocationDialog
        location={ activeLocation }
        isOpen={ editLocationDialog.isOpen }
        onClose={ editLocationDialog.close }
        onCloseEnd={ editLocationDialog.unmount }
      /> }
    </>
  );
};
