import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { StartStationMonitoringSync, StopStationMonitoringSync } from 'actions/pages/station-monitoring';
import { StationEntity } from 'models/base-station';
import { MonitoringDateRange } from 'models/base-station-status';
// components
import { Box, Typography } from '@mui/material';
import { UptimeHistoryChart } from './charts/UptimeHistoryChart';
import { InternetHistoryChart } from './charts/InternetHistoryChart';
import { OnlineHistoryChart } from './charts/OnlineHistoryChart';
// styles
import { useStyles } from '../../styles';

interface Props {
  station: Pick<StationEntity, 'id' | 'type'>;
  dateRange: MonitoringDateRange;
  dateRangeControlNode: React.ReactNode;
}

export const BaseStationsCharts = ({
  station,
  dateRange,
  dateRangeControlNode,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.pages.stationMonitoring, shallowEqual);
  useDeepCompareEffect(() => {
    if (station.id) {
      dispatch(StartStationMonitoringSync({ stationId: station.id, dateRange }));
    }
    return () => {
      dispatch(StopStationMonitoringSync());
    };
  }, [dispatch, station.id, dateRange]);
  // Первая и последняя точка для графиков формируются на backend автоматически исходя из переданных в запросе dateFrom и dateTo
  // в файле src/sagas/base-station/station-monitoring.ts
  return (
    <>
      <Box
        className={ classes.blockCaption }
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={ classes.blockCaptionText }>Availability History</Typography>
        { dateRangeControlNode }
      </Box>
      <UptimeHistoryChart uptime={state.uptimeHistory} />
      <InternetHistoryChart internet={state.internetHistory} />
      <OnlineHistoryChart online={state.onlineHistory} />
    </>
  );
};
