const baseUrl = '/settings/um';
const userSubParam = '{userSub}';
const clientIdParam = '{clientId}';
const apikeyIdParam = '{apiKeyId}';

enum Path {
  userList = '/users',
  userDetail = '/users/{userSub}',
  userCreate = '/users/create',
  clientList = '/clients',
  clientDetail = '/clients/{clientId}',
  clientApiKeyList = '/clients/{clientId}/api-tokens',
  clientApiKeyDetail = '/clients/{clientId}/api-tokens/{apiKeyId}',
}

export function getUserListPath(): string {
  return baseUrl + Path.userList;
}

export function getUserDetailPath(userSub: string) {
  return baseUrl + Path.userDetail.replace(userSubParam, userSub);
}

export function getUserCreatePath() {
  return baseUrl + Path.userCreate;
}

export function getClientsListPath(): string {
  return baseUrl + Path.clientList;
}

export function getClientDetailPath(clientId: string | number): string {
  return baseUrl + Path.clientDetail.replace(clientIdParam, String(clientId));
}

export function getClientApiKeyDetailPath(clientId: string | number, apiKeyId: string): string {
  return baseUrl + Path.clientApiKeyDetail
    .replace(clientIdParam, String(clientId))
    .replace(apikeyIdParam, apiKeyId);
}
