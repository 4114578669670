import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { GetUsersParams } from 'models/user-management';
import { DEFAULT_FILTERS } from 'reducers/pages/users';
// components
import { FiltersForm, FiltersShape } from './FiltersForm';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

interface FiltersBlockProps {
  isFetching: boolean;
  filters: GetUsersParams;
  onSubmit: (state: FiltersShape) => void;
  onReset: () => void;
}

export const FiltersBlock = (props: FiltersBlockProps): JSX.Element => {
  const { isFetching, filters, onSubmit, onReset } = props;
  const classes = useStyles();

  const [state, setState] = useState<FiltersShape>(filters);
  useDeepCompareEffect(() => {
    setState(filters);
  }, [filters]);

  return (
    <div className={ classes.toolbar }>
      <FilterButton
        isLoading={ isFetching }
        filter={ filters }
        onSave={ () => onSubmit(state) }
        onReset={ () => { onReset(); setState(DEFAULT_FILTERS); } }
      >
        <FiltersForm filters={ state } setFilters={ setState } />
      </FilterButton>
    </div>
  );
};

export default FiltersBlock;
