import { Zone } from 'models/device-management';

// components
import { CommonDialog } from 'components/Dialogs';
import { ZoneFormWidget } from 'components/Zones/ZoneForm/ZoneForm';

interface Props {
  zone: Zone;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (zone: Partial<Zone>) => void;
}

export const EditZoneDialog = ({ zone, isOpen, onClose, onSuccess, onCloseEnd }: Props): JSX.Element => {
  return (
    <CommonDialog
      testId="dm-zone-edit-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit zone > ${zone.name}` }
      content={ <ZoneFormWidget asSider zone={zone} mode="EDIT" onCancel={onClose} onEditSuccess={onSuccess} /> }
    />
  );
};
