import React from 'react';
import { default as SvgIcon, SvgIconProps } from '@mui/material/SvgIcon';

export const SwapHorizOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon { ...props }>
      {/* eslint-disable-next-line max-len */}
      <path d="M 9.066 10.972 L 6.357 13.933 L 9.066 16.894 L 9.066 14.674 L 13.826 14.674 L 13.826 13.193 L 9.066 13.193 Z M 18.579 9.491 L 15.869 6.53 L 15.869 8.752 L 11.11 8.752 L 11.11 10.232 L 15.869 10.232 L 15.869 12.452 Z"></path>
      {/* eslint-disable-next-line max-len */}
      <path d="M 19.265 2.688 L 5.265 2.688 C 4.155 2.688 3.265 3.588 3.265 4.688 L 3.265 18.688 C 3.265 19.788 4.155 20.688 5.265 20.688 L 19.265 20.688 C 20.365 20.688 21.265 19.788 21.265 18.688 L 21.265 4.688 C 21.265 3.588 20.365 2.688 19.265 2.688 Z M 19.265 18.688 L 5.265 18.688 L 5.265 4.688 L 19.265 4.688 L 19.265 18.688 Z M 11.76 12.699 Z"></path>
    </SvgIcon>
  );
};