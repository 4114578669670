import { Zone } from 'models/device-management';
import { useAuthUserSelector } from 'hooks';
import { isAllowedToDeleteZones, isAllowedToCreateZones, isAllowedToUpdateZones } from 'utils/permissions';
import { useDefaultProject } from 'hooks/user-managment';

const getDefaultValues = (
  getDefaultProject: ReturnType<typeof useDefaultProject>['getDefaultProject'],
  zone: Partial<Zone>,
) => {
  if (!zone?.owner_id) {
    return zone;
  }
  const defaultProject = getDefaultProject(zone?.owner_id);
  return {
    ...zone,
    project_id: zone?.project_id || defaultProject?.id
  };
};

export const useZoneForm = (zone?: Partial<Zone>) => {
  const { isAdmin, data: user } = useAuthUserSelector();
  const { getDefaultProject } = useDefaultProject();
  const userOwner = !isAdmin ? Number(user.user_group) : undefined;

  const defaultValues = zone || getDefaultValues(getDefaultProject, {
    name: '',
    live_status: false,
    project_id: undefined,
    owner_id: userOwner ? userOwner : undefined,
  });

  return {
    isAdmin,
    defaultValues,
    allowedToCreate: isAllowedToCreateZones(user),
    allowedToUpdate: isAllowedToUpdateZones(user),
    allowedToDelete: isAllowedToDeleteZones(user),
    getDefaultProject,
  };
};
