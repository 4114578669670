import React from 'react';
import { useFormActionLoader } from 'hooks';
import { useConnectForm } from './utils';
import { ConnectZoneAdditionalData } from '../RoutesTemplatesSuitesTable/types';
// components
import { Button } from '@mui/material';
import { CommonDialog } from 'components/Dialogs';
import { ConnectZoneForm } from './ConnectZoneForm';

interface Props {
  isOpen: boolean;
  data: ConnectZoneAdditionalData;
  onSuccess: () => void;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const ConnectZoneDialog = (props: Props): JSX.Element => {
  const { isOpen, onSuccess, onClose, onCloseEnd, data } = props;
  const { doAction } = useFormActionLoader();
  const { form, onSubmit } = useConnectForm({
    doAction,
    onSuccess: () => {
      onClose();
      onSuccess?.();
    },
    templateSuite: data.suite
  });

  return (
    <CommonDialog
      isOpen={ isOpen }
      title={ 'Connect Zone' }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      content={
        <ConnectZoneForm { ...form } templateSuite={ data.suite } connectedZones={ data.connectedZones } />
      }
      actions={
        <>
          <Button
            color="primary"
            onClick={ onClose }
          >Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ onSubmit }
          >Connect</Button>
        </>
      }
    />
  );
};
