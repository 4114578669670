import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) => createStyles({
  wrap: {
    padding: '0px 8px 16px 8px'
  },
  hide: {
    display: 'none'
  },
  allProjects: {
    margin: '8px 8px 0px 8px'
  },
  projects: {
    margin: '0px 8px',
    flexGrow: 1
  }
}));
