import { DeviceStatesV2, DeviceStateSeverityEnumModel } from 'models/device-monitoring';
import { Zone } from 'models/device-management';
import { Defined } from 'utils/models';
import { commonOptions } from 'utils/tables';
import { isNil } from 'lodash';

// components
import { SaveAlt } from '@mui/icons-material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';
import { Typography, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { PositionGroupLabel, IncidentErrorType, IncidentReason, ZoneLabel } from 'components/Labels';
import DeviceLink from 'components/Links/DeviceLink';
import { SeverityStatus } from './SeverityStatus';

// styles
import useStyles from './DeviceStatesTableComponentStyles';
import { getMuiTheme } from 'styles/themes';

interface Props {
  isLoading: boolean;
  data: DeviceStatesV2[];
  total: number;
  csvFetching: boolean;
  fetchReport: () => void;
}

enum TableFields {
  severity = 'severity',
  device_id = 'device_id',
  zone = 'zone',
  group = 'group',
  project = 'project',
  error_type = 'error_type',
  incident_date = 'incident_date',
  reason = 'reason',
  broken_days = 'broken_days'
}

const DevicesStatesTable = (props: Props): JSX.Element => {
  const classes = useStyles();

  const columns: MUIDataTableColumn[] = [
    {
      name: TableFields.severity,
      label: 'Severity',
      options: {
        customBodyRender: (status: DeviceStateSeverityEnumModel) => <SeverityStatus status={ status } />
      },
    },
    {
      name: TableFields.device_id,
      label: 'Device ID',
      options: {
        customBodyRender: (id: string) => <DeviceLink deviceId={ id } />
      },
    },
    {
      name: TableFields.zone,
      label: 'Zone',
      options: {
        customBodyRender: (zoneId?: Defined<Zone['id']>) => zoneId
          ? <ZoneLabel zoneId={ zoneId } />
          : 'Unknown'
      }
    },
    {
      name: TableFields.group,
      label: 'Group',
      options: {
        customBodyRender: ([id, name]: [number, string]) => <PositionGroupLabel groupId={ id } name={ name } />
      }
    },
    {
      name: TableFields.project,
      label: 'Project',
    },
    {
      name: TableFields.error_type,
      label: 'Error Type',
      options: {
        customBodyRenderLite: dataIndex => <IncidentErrorType errorType={data[dataIndex].error_type}/>
      }
    },
    {
      name: TableFields.incident_date,
      label: 'Incident Date',
    },
    {
      name: TableFields.reason,
      label: 'Reason',
      options: {
        customBodyRenderLite: dataIndex => <IncidentReason reason={data[dataIndex].reason}/>
      }
    },
    {
      name: TableFields.broken_days,
      label: 'Broken Days',
      options: {
        customBodyRender: (value?: number) => getCountOfBrokenDays(value)
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    count: props.total,
    customToolbar: () => {
      return (
        <>
          { props.csvFetching ? <CircularProgress size={ 16 } /> :
            <Tooltip title="Save as CSV">
              <span>
                <IconButton onClick={ props.fetchReport }>
                  <SaveAlt />
                </IconButton>
              </span>
            </Tooltip>
          }
        </>
      );
    },
    customFooter: (count) => {
      return (
        <PaginationFooter count={ count } />
      );
    },
  };

  const getCountOfBrokenDays = (days?: number): JSX.Element => {
    if (isNil(days)) {
      return <Typography>—</Typography>;
    }

    let color = classes.daysGreen;

    if (days >= 2 && days <= 14) {
      color = classes.daysYellow;
    } else if (days > 14) {
      color = classes.daysRed;
    }

    return <Typography className={ color }>{ days || '<1' }</Typography>;
  };

  const data = props.data.map(state => (
    {
      [TableFields.severity]: state.severity,
      [TableFields.device_id]: state.device_id.toUpperCase() || '—',
      [TableFields.zone]: state.zone_id,
      [TableFields.group]: [state.group_id, state.group_name],
      [TableFields.project]: state.project_name || '—',
      [TableFields.error_type]: state.incident_type,
      [TableFields.incident_date]: state.incident_date ? new Date(state.incident_date).toLocaleString() : '—',
      [TableFields.broken_days]: state.days_broken,
      [TableFields.reason]: state.reason,
    }
  ));

  return (
    <ThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ props.isLoading }>
        <MUIDataTable title={ null } data={ data } columns={ columns } options={ options } />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

export default DevicesStatesTable;
