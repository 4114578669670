import { BaseStation, LorawanStation, StationType } from 'models/base-station';
import { useAuthUserSelector, useDialog } from 'hooks';
import { canDeleteStation, canEditStation } from 'utils/permissions';
import { DetachStationLocation, DetachLorawanStationLocation } from 'actions/base-station';

// components
import { Button, Paper } from '@mui/material';
import { DetachLocationDialog, DeleteLocationDialog } from 'pages/BaseStation/widgets/Dialogs';

// styles
import useStyles from './style';

interface Props {
  readonly station: BaseStation | LorawanStation;
}

export const StationBlock = ({ station }: Props): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const detachDialog = useDialog();
  const deleteDialog = useDialog();

  return (
    <Paper className={ classes.stationLocationActions }>
      { !canEditStation(user) ? '' :
        <Button
          variant="text"
          className={ classes.buttonUnbind }
          onClick={ detachDialog.open }
        >
          Unbind location
        </Button>
      }
      { !canDeleteStation(user) ? '' :
        <Button
          variant="text"
          className={ classes.buttonDelete }
          onClick={ deleteDialog.open }
        >
          Remove location
        </Button>
      }

      { detachDialog.isMounted && <DetachLocationDialog
        station={ station }
        isOpen={ detachDialog.isOpen }
        onClose={ detachDialog.close }
        onCloseEnd={ detachDialog.unmount }
      />}
      { deleteDialog.isMounted && station.location && <DeleteLocationDialog
        location={ station.location }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
        onDelete={ station.type === StationType.lorawan ? DetachLorawanStationLocation(station) : DetachStationLocation(station) }
      /> }
    </Paper>
  );
};
