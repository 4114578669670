import React from 'react';
import { MarkerColor } from './types';
import { getMarkerIcon } from './utils';
import { PositionState } from 'models/parking';
// components
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  DeviceLink,
  PositionLink
} from 'components/Links';
import {
  formatDateTime,
  formatFullDuration
} from 'utils/datetime';
import {
  ZoneLabel,
  OwnerName,
  PositionGroupLabel,
  ProjectName
} from 'components/Labels';

interface Props {
  positionState: PositionState;
  color: MarkerColor;
  showOwner?: boolean;
  open?: string;
  handleSelect: (open: string | undefined) => void;
  clusterer: Clusterer;
}

const Component = (props: Props) => {
  const { positionState, color, showOwner, open, handleSelect } = props;
  const position = { lat: positionState.lat, lng: positionState.lon };
  return (
    <Marker
      key={ positionState.position_id }
      onClick={ () => handleSelect(open === positionState.device_id ? undefined : positionState.device_id) }
      position={ position }
      clusterer={ props.clusterer }
      icon={ getMarkerIcon(color) }
    >
      { open === positionState.device_id && (
        <InfoWindow onCloseClick={ () => handleSelect(undefined) } position={ position }>
          <Box lineHeight={ 1 }>
            <Typography variant="subtitle2">
              Network ID: <PositionLink positionId={ positionState.position_id } positionName={ positionState.network_id }/>
            </Typography>
            <Typography variant="subtitle2">
              Device ID: <DeviceLink deviceId={ positionState.device_id }/>
            </Typography>
            <Typography variant="subtitle2">
              Custom ID: { positionState.custom_id ?? '-' }
            </Typography>
            <Typography variant="subtitle2">
              State: { positionState.occupied ? 'Occupied' : 'Free' }
            </Typography>
            <Typography variant="subtitle2">
              Duration: { positionState.status_time ? formatFullDuration(positionState.status_time) : '-' }
            </Typography>
            <Typography variant="subtitle2">
              Time: { positionState.status_time ? formatDateTime(positionState.status_time) : '-' }
            </Typography>
            <Typography variant="subtitle2">
              Zone: <ZoneLabel zoneId={ positionState.zone_id }/>
            </Typography>
            <Typography variant="subtitle2">
              Group: <PositionGroupLabel groupId={ positionState.group_id }/>
            </Typography>
            <Typography variant="subtitle2">
              Project: <ProjectName id={ positionState.project_id }/>
            </Typography>
            { !showOwner ? '' :
              <Typography variant="subtitle2">
                Owner: <OwnerName ownerId={ positionState.owner_id }/>
              </Typography>
            }
          </Box>
        </InfoWindow>
      ) }
    </Marker>
  );
};

export { Component as PositionStateMarker };
