import { createStyles, makeStyles, Theme } from 'styles/utils';
import React from 'react';

import { Device } from 'models/device-management';

// components
import { Grid, Divider } from '@mui/material';

import { FieldSkeleton } from 'components/Skeleton';

import ReplaceDevice from './ReplaceDevice';
import UnbindDevice from './UnbindDevice';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface Props {
  device?: Device;
  onDevicePositionRelationUpdate?: () => void;
}

export const useOperationsStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(0, 1),
    },
  }),
);

const DeviceOperations: React.FC<Props> = (props) => {
  const infoCss = useInfoBlockStyles();
  const operationsCss = useOperationsStyles();

  if (!props.device) {
    return <FieldSkeleton className={infoCss.field} />;
  }

  // This is the constraint that's currently shared by the actions specified here,
  // so if it isn't being fit, there would be nothing to render.
  // It may change in the future, though, so for flexibility and reliability,
  // TODO: it should be changed to `actionsToRender.length === 0` where `actionsToRender`
  // is an array of React elements fillable dynamically / conditionally.
  if (!props.device.position_id) {
    return null;
  }

  return (
    <Grid alignItems="center" container>
      <UnbindDevice
        deviceId={props.device.device_id}
        positionId={props.device.position_id}
        onSuccess={props.onDevicePositionRelationUpdate}
      />

      <Divider
        className={operationsCss.divider}
        orientation="vertical"
      />

      <ReplaceDevice
        deviceId={props.device.device_id}
        onSuccess={props.onDevicePositionRelationUpdate}
      />
    </Grid>
  );
};

export default DeviceOperations;
