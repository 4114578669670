import { DEFAULT_LIMIT } from 'models';
import { useExpandableRows } from 'hooks/expandable-rows';
import { commonOptions } from 'utils/tables';
import { createHiglightingNewRows } from 'utils/mui-datatables';
import { LorawanMessageWithNewFlag } from 'models';
import { useLoraWANStationsDictionarySelector } from 'hooks/station';

// components
import { LoaderSvg } from 'components/Loaders';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ThemeProvider } from 'styles/utils';
import { IconButton, Tooltip } from '@mui/material';
import { Update } from '@mui/icons-material';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { DeviceLink, BaseStationLink } from 'components/Links';
import { ZoneLabel } from 'components';
import { PositionGroupLabel } from 'components/Labels';
import { MessageDetailInfo } from './componnents/MessageDetailInfo';

// styles
import useStyles from './RawMessagesTableStyle';
import { getMuiTheme } from 'styles/themes';

interface Props {
  data: LorawanMessageWithNewFlag[];
  total: number;
  fetching: boolean;
  isSync: boolean;
  setIsSync: () => void;
}

export const LorawanMessagesTable = ({ data, total, fetching, isSync, setIsSync }: Props): JSX.Element => {
  const classes = useStyles();
  const expandable = useExpandableRows();
  const { isLoading: loraStationsIsLoading, stations: loraStations } = useLoraWANStationsDictionarySelector();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'device_id',
      label: 'Device ID',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const deviceId = data[index].device_id;
          return deviceId ? <DeviceLink deviceId={ deviceId } /> : 'Undefined';
        }
      },
    },
    {
      name: 'gateway_eui',
      label: 'Gateway EUI',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const gatewayEUI = data[index].gateway_eui;
          const stationId = loraStations.find(s => String(s.eui).toUpperCase() === String(gatewayEUI).toUpperCase());
          if (loraStationsIsLoading) {
            return <LoaderSvg />;
          }

          if (!gatewayEUI) {
            return 'Undefined';
          }
          return stationId ? <BaseStationLink stationId={stationId.id} label={String(gatewayEUI).toUpperCase()} /> : String(gatewayEUI).toUpperCase();
        }
      },
    },
    {
      name: 'fcnt',
      label: 'FCnt',
    },
    {
      name: 'fport',
      label: 'FPort',
    },
    {
      name: 'payload',
      label: 'Payload',
    },
    {
      name: 'timestamp',
      label: 'Timestamp',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => new Date(data[index].timestamp || 0).toLocaleString(),
      },
    },
    {
      name: 'rssi',
      label: 'RSSI',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => data[index].rssi.toFixed(1) || '-',
      },
    },
    {
      name: 'snr',
      label: 'SNR',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => data[index].snr.toFixed(1) || '-',
      },
    },
    {
      name: 'zoneid',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const zoneId = data[index].zone_id;
          return zoneId ? <ZoneLabel zoneId={ zoneId } /> : '-';
        }
      },
    },
    {
      name: 'group',
      label: 'Group',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: index => {
          const groupId = data[index].group_id;
          const groupName = data[index].group_name;
          return groupId ? <PositionGroupLabel groupId={ groupId } name={ groupName } /> : '-';
        }
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    rowsPerPageOptions: [DEFAULT_LIMIT, 50, 100, 200],
    responsive: 'standard',
    expandableRows: true,
    expandableRowsOnClick: true,
    serverSide: true,
    count: total,
    onTableChange: (action) => {
      if (fetching) {
        return false;
      }
      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
          expandable.closeRows();
          break;
      }
    },
    setRowProps: createHiglightingNewRows(data),
    rowsExpanded: expandable.rowsExpanded,
    onRowExpansionChange: expandable.onRowExpansionChange,
    renderExpandableRow: (rowData, rowMeta) => {
      const message = data[rowMeta.dataIndex];
      return message ? <MessageDetailInfo cols={ rowData.length + 1 } message={message} /> :  null;
    },
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Auto update" onClick={ setIsSync }>
            <IconButton>
              <Update color={ isSync ? 'primary' : undefined } />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customFooter: (count) => {
      return (
        <PaginationFooter
          count={ count }
          rowsPerPageOptions={ options.rowsPerPageOptions }
        />
      );
    },
  };

  return (
    <div className={ classes.root }>
      <ThemeProvider theme={ getMuiTheme() } >
        <TableLoadingLayout isLoading={fetching}>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </ThemeProvider>
    </div>
  );
};
