export const createHiglightingNewRows = <T extends { new?: boolean; }>(data: T[]) => (_row: T[], dataIndex: number) => {
  const rowData = data[dataIndex];

  if (!rowData) {
    return {};
  }

  return {
    style: rowData.new ? { background: '#f5f2d3', transition: 'background 0.8s ease' } : {}
  };
};