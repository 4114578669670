import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { TemplateSuiteId } from 'models/caller';
import { useTemplateSuiteSelector } from 'hooks/caller';
import { Dialog } from 'components/Caller/TemplateSuiteDialogs/Dialog';
// component
import Navigation, { NavigationTab } from './Navigation';
import Skeleton from '@mui/lab/Skeleton';
import { TemplateSuiteDialogs, CallerTemplateSuiteManagment } from 'components/Caller';
import { Paper } from '@mui/material';
// style
import { useStyles } from './styles';

interface Props {
  templateSuiteId: TemplateSuiteId;
}

const TemplateDetailPage = ({ templateSuiteId }: Props): JSX.Element => {
  const classes = useStyles();
  const { templateSuite } = useTemplateSuiteSelector(templateSuiteId);
  const [dialog, setDialog] = useState<Dialog>(Dialog.NONE);

  return (
    <>
      <Helmet><title>{ `Http Template Suite #${ templateSuiteId } ${ templateSuite?.name } Detail` }</title></Helmet>
      <Navigation active={ NavigationTab.DETAIL } templateSuite={ templateSuite }/>
      <Paper className={ classes.root }>
        { !templateSuite
          ? <Skeleton variant="rectangular"/>
          : <CallerTemplateSuiteManagment templateSuite={ templateSuite } openDialog={ setDialog }/>
        }
      </Paper>
      <TemplateSuiteDialogs open={ dialog } onClose={ () => setDialog(Dialog.NONE) } templateSuite={ templateSuite }/>
    </>
  );
};

export default TemplateDetailPage;
