import { commonOptions } from 'utils/tables';
import { usePagination } from 'hooks';
import { IntegrationData, IntegrationsEnum, Statuses } from 'models/lorawan';
import { getStatusText, humanReadableTime } from './utils';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { OwnerName } from 'components/Labels';
import { IntegrationAction } from './IntegrationAction';
import { Typography, Box } from '@mui/material';

// styles
import { ThemeProvider } from 'styles/utils';
import { getMuiTheme } from 'styles/themes';
import useStyles from './styles';

interface Props {
  isLoading: boolean;
  canViewOwner: boolean;
  integrations: IntegrationData[];
  reload: () => void;
}

enum TableFields {
  IntegrationType = 'type',
  OwnerId = 'owner',
  Status = 'status',
  LastMessage = 'time',
  Actions = 'actions',
}

export const LorawanTable = (props: Props): JSX.Element => {
  const { integrations, isLoading, canViewOwner, reload } = props;
  const { page, limit } = usePagination();
  const classes = useStyles();

  const columns: MUIDataTableColumn[] = [
    {
      name: TableFields.IntegrationType,
      label: 'Integration Type',
      options: {
        customBodyRender: ([icon, title]: [string, IntegrationsEnum]) => (
          <Typography>
            <img src={ icon } alt={ title } title={ title } className={ classes.tableLogo } />
            { title }
          </Typography>
        ),
      }
    },
    {
      name: TableFields.OwnerId,
      label: 'Owner ID',
      options: {
        display: canViewOwner ? 'true' : 'excluded',
        customBodyRender: (ownerId: number) => <OwnerName ownerId={ ownerId } />
      }
    },
    {
      name: TableFields.Status,
      label: 'Status',
      options: {
        customBodyRender: (status: Statuses) => getStatusText(status)
      }
    },
    {
      name: TableFields.LastMessage,
      label: 'Last message',
      options: {
        customBodyRender: (date?: Date) => date ? humanReadableTime(date) : 'Waiting for messages'
      }
    },
    {
      name: TableFields.Actions,
      label: ' ',
      options: {
        setCellProps: () => ({ className: classes.actionCell }),
        customBodyRender: (integration: IntegrationData) => (
          <Box display="flex" justifyContent="flex-end" flexDirection="row-reverse">
            <IntegrationAction { ...integration } reload={ reload } />
          </Box>
        ),
      },
    },
  ];

  const data = integrations.map(integration => ({
    [TableFields.IntegrationType]: [integration.icon, integration.title],
    [TableFields.OwnerId]: integration.ownerId,
    [TableFields.Status]: integration.status,
    [TableFields.LastMessage]: integration.lastReceiveTime?.last_receive_time,
    [TableFields.Actions]: integration
  }));

  const options: MUIDataTableOptions = {
    ...commonOptions,
    page,
    rowsPerPage: limit,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  return (
    <ThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ isLoading }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};
