import React from 'react';
import { ApiKey } from 'models/user-management';
import { useAuthUserSelector } from 'hooks';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
import { CreateApiKey } from 'actions/user-management';
// components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ApiKeyForm, ApiKeyFormField } from 'components/UserManagement';
// styles
import { useStyles } from './styles';

const initialApiKey: ApiKey = {
  id: '',
  key: '',
  name: '',
  owner_id: 0,
  permission_id: 0,
  project_ids: 'all',
  creation_time: '',
};

interface Props {
  isOpen: boolean;
  ownerId?: number;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const ApiKeyCreateDialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isOpen, ownerId, onClose, onCloseEnd } = props;
  const actions = useFormEditActions({
    canEdit: true,
    canSave: true,
    btnSave: 'Add',
    onSave: () => {
      if (!form.validate()) {
        return false;
      }
      doAction({
        action: CreateApiKey(form.state),
        onSuccess: onClose,
        onError: form.catchError,
      });
    },
    onCancel: () => onClose(),
  });
  const user = useAuthUserSelector();
  const form = useForm<ApiKey, ApiKeyFormField>({
    initialState: { ...initialApiKey, owner_id: ownerId ?? Number(user.data.user_group) },
    showFields: [
      ApiKeyFormField.name,
      ApiKeyFormField.permission_id,
      ApiKeyFormField.project_ids,
    ]
  });
  const { doAction } = useFormActionLoader();

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      TransitionProps={ { onExited: onCloseEnd } }
      className={ classes.dialogRoot }
      maxWidth={ 'sm' }
      fullWidth
    >
      <DialogTitle>Add API Auth Token</DialogTitle>
      <DialogContent>
        <ApiKeyForm { ...form } />
      </DialogContent>
      <DialogActions> { actions } </DialogActions>
    </Dialog>
  );
};
