import { useAuthUserSelector } from 'hooks';
import { useGroupedFilters } from './useGroupedFilters';
// components
import { GroupSelectControl, ProjectSelectControl, ZoneSelectControl } from 'components/Controls';
import OwnersSelect from 'components/Controls/OwnersSelect/SingleSelectControl';

export interface Props {
  owner?: number;
  projects?: number[] | null;
  zones?: number[];
  groups?: number[];
  isZoneShow: boolean;
  isGroupsShow: boolean;
  isProjectShow: boolean;
  handleSelectOwner: (value?: number) => void;
  handleSelectProjects?: (value: number[]) => void;
  handleSelectZones?: (value: number[]) => void;
  handleSelectGroups?: (value: number[]) => void;
}

const GroupedFilters = (props: Props): JSX.Element => {
  const {
    isZoneShow,
    isGroupsShow,
    isProjectShow,
    owner,
    handleSelectOwner,
    projects,
    handleSelectProjects,
    zones,
    handleSelectZones,
    groups,
    handleSelectGroups
  } = props;
  const { isAdmin } = useAuthUserSelector();
  const {
    setZonesCache,
    filterZone,
    filteredProjects,
    handleSetProjectsZones,
    handleSetZonesProjects,
  } = useGroupedFilters(props);

  const defaultFuncReturn = () => [];

  return (
    <>
      { isAdmin && <OwnersSelect
        changeHandler={ handleSelectOwner }
        selected={ owner }
      /> }
      { isProjectShow && <ProjectSelectControl
        isMulti
        isClearable
        selected={ projects || [] }
        onChange={ handleSelectProjects ? (v, action) => {
          if (handleSelectZones && action?.action === 'clear') {
            handleSelectZones([]);
            setZonesCache([]);
          }
          handleSelectProjects(v);
        } : defaultFuncReturn }
        onMenuOpen={() => { setZonesCache(zones); }}
        onBlur={ handleSetProjectsZones }
        filter={ project => filteredProjects.includes(project.id) }
      /> }
      { isZoneShow && <ZoneSelectControl
        isMulti
        isClearable
        filter={ filterZone }
        selected={ zones || [] }
        onBlur={ handleSetZonesProjects }
        onChange={ handleSelectZones || defaultFuncReturn }
      /> }
      { isGroupsShow && <GroupSelectControl
        isMulti
        isClearable
        zoneIds={ zones || [] }
        selected={ groups || [] }
        onChange={ handleSelectGroups || defaultFuncReturn }
      /> }
    </>
  );
};

export default GroupedFilters;
