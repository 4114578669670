import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useAuthUserSelector } from 'hooks';
// components
import { FilterButton } from 'components/Buttons';
import TemplateSuitesFilter, { FilterState } from 'components/Caller/TemplateSuitesFilter/Component';

const resetState: FilterState = {
  name: undefined,
  owner: undefined,
};

type Props = {
  isLoading: boolean
  applyFilters: (filter: FilterState) => void
  filters: FilterState
};

const TemplatesSuitesFilterButton = ({ isLoading, filters, applyFilters }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const [state, setState] = useState<FilterState>(filters);

  useDeepCompareEffect(() => {
    setState(filters);
  }, [filters]);

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filters }
      onSave={ () => applyFilters(state) }
      onReset={ () => applyFilters(resetState) }
    >
      <TemplateSuitesFilter
        canViewOwner={ user.isAdmin }
        state={ state }
        setState={ setState }
      />
    </FilterButton>
  );
};

export default TemplatesSuitesFilterButton;