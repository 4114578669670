import React, { useState, useEffect } from 'react';
import { useFormActionLoader } from 'hooks';
import { CreateApiKey } from 'actions/user-management';
import { ApiKey, Permissions } from 'models/user-management';
import { integrationName, urls } from 'utils/lorawan';
import { createPermissions } from 'actions/user-management/permissions';
import { IntegrationsEnum } from 'models/lorawan';
import { useApiKeysParamsSelector } from 'hooks/user-managment/api-key';

// components
import { CommonDialog } from 'components/Dialogs';
import { Button, Box } from '@mui/material';
import { ReadOnlyInputCopyControl, OwnersSingleSelectControl, IntegrationSelect } from 'components/Controls';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  reload: () => void;
}

export const AddIntegrationAdminDialog = (props: Props): JSX.Element => {
  const { isOpen, onClose, onCloseEnd, reload } = props;
  const { doAction } = useFormActionLoader();
  const [generatedUrl, setGeneratedUrl] = useState<string>();
  const [generatedKey, setGeneratedKey] = useState<ApiKey['key']>();
  const [integrationType, setIntegrationType] = useState<IntegrationsEnum>();
  const [integrationOwner, setIntegrationOwner] = useState<number>();
  const [existing, setExisting] = useState<boolean>(false);
  const { apiKeys } = useApiKeysParamsSelector({});

  useEffect(() => {
    if (!apiKeys.length || !integrationType || !integrationOwner || generatedKey) {
      return;
    }

    const existing = apiKeys.some(key =>
      key.owner_id === integrationOwner &&
      key.name === integrationName(integrationType)
    );

    setExisting(existing);
  }, [integrationType, integrationOwner, apiKeys, generatedKey]);

  const handleGenerate = () => {
    if (!integrationType || !integrationOwner) {
      return;
    }

    const permission: Permissions = {
      name: integrationName(integrationType),
      owner_id: integrationOwner,
      id: 0,
    };

    doAction({
      action: createPermissions(permission),
      onSuccess: (res: Permissions) => {
        const newApiKey: ApiKey = {
          id: '',
          key: '',
          name: integrationName(integrationType),
          owner_id: integrationOwner,
          permission_id: res.id,
          project_ids: 'all',
          creation_time: '',
        };

        doAction({
          action: CreateApiKey(newApiKey),
          onSuccess: (result: ApiKey) => {
            setGeneratedKey(result.key);
            setGeneratedUrl(urls[integrationType]);
            reload();
          },
        });
      },
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ 'Create Integration' }
      content={
        <Box flexGrow={ 1 }>
          <IntegrationSelect
            onChange={ setIntegrationType }
            selected={ integrationType }
            disabled={ !!generatedKey }
            onlyAvailable
          />
          <OwnersSingleSelectControl
            selected={ integrationOwner }
            changeHandler={ setIntegrationOwner }
            isDisabled={ !!generatedKey }
            error={ existing ? `This owner already has integration ${integrationType}` : undefined }
          />
          <ReadOnlyInputCopyControl
            value={ generatedUrl }
            label="URL"
          />
          <ReadOnlyInputCopyControl
            value={ generatedKey }
            label="X-Auth-Token"
          />

          <Box mt={ 4 } display="block" mx={ -1 }>
            <Button
              onClick={ handleGenerate }
              color="primary"
              disabled={ !!generatedKey || !integrationType || !integrationOwner || existing }
            >Generate Credentials</Button>
          </Box>
        </Box>
      }
      actions={ <Button color="primary" onClick={ onClose }>close</Button> }
    />
  );
};
