import { Router as ReactRouter } from 'react-router';
import { SnackbarProvider } from 'notistack';

// components
import { Router } from './Router';
import { Notifier } from 'components';
import { ActionLoaderProvider } from 'components/Loaders';
import AuthContainer from './components/AuthContainer';

// styles
import './App.scss';

import history from 'utils/routing/history';

// AWS Amplify
import { configureAmplify } from 'clients';
import { Auth } from 'aws-amplify';
import awsconfig from 'aws-exports';


configureAmplify(awsconfig);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__testCafeGetCurrentUserSub = async (): Promise<string> => {
  return (await Auth.currentAuthenticatedUser()).attributes.sub;
};


export const App = (): JSX.Element => (
  <ReactRouter history={ history }>
    <SnackbarProvider>
      <ActionLoaderProvider>
        <AuthContainer>
          <Router/>
        </AuthContainer>
      </ActionLoaderProvider>
      <Notifier/>
    </SnackbarProvider>
  </ReactRouter>
);