import { PositionsFilterFields } from 'models/device-management/filters';
// components
import Grid from '@mui/material/Grid';
import { SingleSelectControl, LevelsFilterSelect, LabelSelectControl } from 'components/Controls';
import CustomIdSelect from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import { NetworkIdSelect } from 'components/Controls/NetworkIdSelect/NetworkIdSelectComponent';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';

// styles
import useStyles from 'styles/filters';

interface Props {
  state: PositionsFilterFields;
  setState: (filters: PositionsFilterFields | ((filters: PositionsFilterFields) => PositionsFilterFields)) => void;
}

const PositionTableFilter = (props: Props): JSX.Element => {
  const { state, setState } = props;
  const classes = useStyles();

  const bindingOptions = [{ label: 'Bound', value: 'bound' }, { label: 'Not Bound', value: 'not_bound' }];

  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner });
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const handleSelectGroups = (groupIds: number[]) => setState({ ...state, groupIds });
  const handleSelectBindingStatus = (bindingStatus: string | undefined) => setState({ ...state, bindingStatus });
  const handleSelectLevels = (levels: number[]) => setState({ ...state, levels });
  const handleSelectLabels = (labelIds: number[]) => setState({ ...state, labelIds });

  return (
    <form autoComplete="off" className={ classes.gridRoot }>
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner }
        projects={ state.projects }
        handleSelectProjects={ handleSelectProjects }
        zones={ state.zones }
        handleSelectZones={ handleSelectZones }
        groups={ state.groupIds }
        handleSelectGroups={ handleSelectGroups }
        isProjectShow
        isZoneShow
        isGroupsShow
      />
      <Grid>
        <LevelsFilterSelect
          isMulti
          isClearable
          zoneIds={ state.zones || [] }
          selected={ state.levels ?? [] }
          onChange={ handleSelectLevels }
        />
      </Grid>
      <Grid>
        <LabelSelectControl
          isMulti
          isClearable
          ownerId={ state.owner }
          selected={ state.labelIds ?? [] }
          onChange={ handleSelectLabels }
        />
      </Grid>
      <Grid>
        <CustomIdSelect
          selected={ state.groupInnerIds ?? [] }
          changeHandler={ (groupInnerIds) => setState({ ...state, groupInnerIds }) }
          type="number"
          name="Inner group"
        />
      </Grid>
      <Grid>
        <NetworkIdSelect
          selected={ state.networkIds ?? [] }
          changeHandler={ (networks: string[]) => setState({ ...state, networkIds: networks }) }
        />
      </Grid>
      <Grid>
        <CustomIdSelect
          name="Custom"
          selected={ state.customIds ?? [] }
          changeHandler={ customIds => setState({ ...state, customIds }) }
          type="string"
        />
      </Grid>
      <Grid>
        <SingleSelectControl
          selected={ state.bindingStatus }
          label="Binding Status"
          values={ bindingOptions }
          changeHandler={ handleSelectBindingStatus }
          name="bound"
          isClearable
        />
      </Grid>
    </form>
  );
};

export default PositionTableFilter;
