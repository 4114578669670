import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as dmDevicesActions from 'actions/device-management/devices';
import {
  DoGetDevicesByPositionsIdFailed,
  DoGetDevicesByPositionsIdSuccess,
  FETCH_DEVICES_BY_POSITIONS_ID,
  FetchDevicesByPositionsId,
  findDevices,
  UPDATE_DEVICE,
  UpdateDevice
} from 'actions/device-management/devices';

import * as dmModels from 'models/device-management';
import { ApiResponse, Device, PositionedDeviceData, ResponseReason } from 'models/device-management';
import * as dmClient from 'clients/device-management';
import { ActionWithPromise } from 'utils/store';

import { BindDeviceToPositionSagaAction, ReplaceDeviceSagaAction, UnbindDeviceFromPositionSagaAction } from 'models/device-management/actions';
import { NotifyError, NotifySuccess } from 'actions/notifier';

function* fetchDevicesByPositionsId(action: FetchDevicesByPositionsId) {
  const response: dmModels.ResponsePositionedDevicesWithTotal = yield call(dmClient.fetchDevices, action.filters as dmModels.DevicesFilterFields);
  if (response.reason === ResponseReason.Ok && response.data) {
    yield put(DoGetDevicesByPositionsIdSuccess(response.data));
  } else {
    yield put(DoGetDevicesByPositionsIdFailed());
    yield put(NotifyError(`Error while fetching devices: ${ response.message }`));
  }
}

const DEFAULT_LIMIT = 4000;

type UpdateDeviceSagaAction = UpdateDevice | ActionWithPromise<UpdateDevice, Device>;
function* updateDeviceSaga(action: UpdateDeviceSagaAction) {
  const response: ApiResponse = yield call(dmClient.updateDevice, action.payload);

  if (response.reason === ResponseReason.Ok) {
    yield put(NotifySuccess(`Device has been updated`));
    const response: dmModels.ResponseDevice = yield call(dmClient.getDeviceById, action.payload.id);

    // refetching the updated device to update the Redux state
    // instead of updating it in position in order to keep the client thin
    yield call(findDevicesSaga, findDevices({ deviceIds: [action.payload.id] }));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(NotifyError(`Error while updating the device: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message));
  }
}

interface DeviceIdResponsePair {
  deviceId: dmModels.Device['device_id'];
  response: dmModels.ResponseDeviceColdData;
}

function wrapFetchDeviceColdDataWithDeviceId(deviceId: dmModels.Device['device_id']): Promise<DeviceIdResponsePair> {
  return dmClient.fetchDeviceColdData(deviceId)
    .then(response => ({ deviceId, response }));
}

function* fetchDevicesColdDataSaga(action: dmDevicesActions.FetchDevicesColdData) {
  const deviceIdResponsePairs: DeviceIdResponsePair[] = yield all(
    action.deviceIds.map(deviceId => call(wrapFetchDeviceColdDataWithDeviceId, deviceId)),
  );

  // TODO: provide grouping of error state by `deviceId` too
  // since if some requests have succeeded, the reducer-wide state
  // indicates the success too (by setting `error: undefined`)
  yield all(
    deviceIdResponsePairs.flatMap(({ response }) => {
      if (response.reason === ResponseReason.Ok) {
        return [];
      }

      return [
        put(dmDevicesActions.fetchDevicesColdDataFailure(response.message)),
        put(NotifyError(`Error while fetching device's additional data: ${ response.message }`)),
      ];
    })
  );

  const requestedDevicesColdDataById = deviceIdResponsePairs.reduce(
    (devicesColdDataById, { deviceId, response }) => {
      if (response.reason === ResponseReason.Ok) {
        devicesColdDataById[deviceId] = response.data as dmModels.DeviceColdData;
      } else if (response.reason === ResponseReason.NotFound) {
        devicesColdDataById[deviceId] = null;
      }

      // We don't set `null` if `response.reason` is other than
      // `ResponseReason.NotFound` since the request is likely needed
      // to be executed again

      return devicesColdDataById;
    },
    {} as { [deviceId: string]: dmModels.DeviceColdData | null },
  );

  if (Object.keys(requestedDevicesColdDataById).length) {
    yield put(dmDevicesActions.fetchDevicesColdDataSuccess(requestedDevicesColdDataById));
  }
}

export function* findDevicesSaga(action: dmDevicesActions.FindDevices) {
  const response: dmModels.ResponseDevicesWithTotal = yield call(dmClient.findDevices, {
    deviceIds: action.params.deviceIds,
    // networkIds: action.params.networkIds, -- dont present in swagger
  });

  if (response.reason !== ResponseReason.Ok) {
    yield put(dmDevicesActions.findDevicesFailure(response.message));
    yield put(NotifyError(`Error while fetching devices: ${ response.message }`));
    return;
  }

  const foundDevices: dmModels.Device[] = response.data;
  const foundDeviceIds = foundDevices.map(d => d.device_id);

  const devicesById = [
    ...foundDeviceIds,
    ...action.params.deviceIds,
  ].reduce(
    (devicesById, deviceId) => {
      // Since we concat what we explicitly requested and what we received,
      // we may have already processed `deviceId`
      if (deviceId in devicesById) {
        return devicesById;
      }

      const device = foundDevices.find(d => d.device_id === deviceId);

      // Here we differenciate between `null` and `undefined`
      // in order to mark `deviceId` fetched but not found
      devicesById[deviceId] = device || null;

      return devicesById;
    },
    {} as { [deviceId: string]: dmModels.Device | null },
  );

  yield put(dmDevicesActions.findDevicesSuccess({
    devicesById,
    foundDevices,
    requestParams: action.params,
  }));
}

export function* findPositionedDevicesSaga(action: dmDevicesActions.FindPositionedDevices) {
  const response: dmModels.ResponsePositionedDevicesWithTotal = yield call(dmClient.fetchDevices, action.params);

  if (response.reason !== ResponseReason.Ok) {
    yield put(dmDevicesActions.findPositionedDevicesFailure(response.message));
    yield put(NotifyError(`Error while fetching positioned devices: ${ response.message }`));
    return;
  }

  const foundDevices: dmModels.PositionedDeviceData[] = response.data;
  const foundDeviceIds = foundDevices.map(d => d.device_id);

  const positionedDevicesById = [
    ...foundDeviceIds,
    ...action.params.devices || [],
  ].reduce(
    (positionedDevicesById, deviceId) => {
      // Since we concat what we explicitly requested and what we received,
      // we may have already processed `deviceId`
      if (deviceId in positionedDevicesById) {
        return positionedDevicesById;
      }

      const device = foundDevices.find(d => d.device_id === deviceId);

      // Here we differenciate between `null` and `undefined`
      // in order to mark `deviceId` fetched but not found
      positionedDevicesById[deviceId] = device || null;

      return positionedDevicesById;
    },
    {} as { [deviceId: string]: dmModels.PositionedDeviceData | null },
  );

  yield put(dmDevicesActions.findPositionedDevicesSuccess({
    foundDevices,
    positionedDevicesById,
    requestParams: action.params,
  }));
}

export function* findPositionedDevicesRecursive(action: dmDevicesActions.FindPositionedDevicesRecursive) {
  let iterations = 0;
  let result: dmModels.PositionedDeviceData[] = [];

  while (true) {
    const response: dmModels.ResponsePositionedDevicesWithTotal = yield call(dmClient.fetchDevices, {
      ...action.filters,
      limit: DEFAULT_LIMIT,
      offset: iterations * DEFAULT_LIMIT
    });

    if (response.reason !== ResponseReason.Ok) {
      yield put(NotifyError(`Error while fetching positioned devices: ${ response.message }`));
      yield put(dmDevicesActions.DoGetDevicesFailed());
      break;
    }

    const isLastFetch = response.data.length !== DEFAULT_LIMIT;

    result = result.concat(response.data);

    if (isLastFetch) {
      break;
    } else {
      iterations++;
    }
  }

  yield put(dmDevicesActions.DoFindPositionedDevicesRecursiveSuccess(result));
}

function* replaceDeviceSaga(action: ReplaceDeviceSagaAction) {
  const response: ApiResponse = yield call(dmClient.replaceDevice, action.params);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while replacing devices: ${ response.message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(response.message));
    }

    return;
  }

  yield put(dmDevicesActions.replaceDeviceSucess(action.params));
  // reload device info after replace
  yield findDevicesSaga(dmDevicesActions.findDevices({
    deviceIds: [action.params.oldDevice, action.params.newDevice],
  }));

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

function* bindDeviceToPositionSaga(action: BindDeviceToPositionSagaAction) {
  const response: ApiResponse = yield call(dmClient.bindDeviceToPosition, action.params);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while binding a device to position: ${ response.message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(response.message));
    }

    return;
  }

  yield put(dmDevicesActions.bindDeviceToPositionSuccess(action.params));

  // reload device info after bind
  yield findDevicesSaga(dmDevicesActions.findDevices({
    deviceIds: [action.params.deviceId],
  }));

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

function* unbindDeviceFromPositionSaga(action: UnbindDeviceFromPositionSagaAction) {
  const response: ApiResponse = yield call(dmClient.unbindDeviceFromPosition, action.params);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while unbinding a device from position: ${ response.message }`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(response.message));
    }

    return;
  }

  yield put(dmDevicesActions.unbindDeviceFromPositionSuccess(action.params));
  // reload device info after unbind
  yield findDevicesSaga(dmDevicesActions.findDevices({
    deviceIds: [action.params.deviceId],
  }));

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

export function* FetchDevicesByAccumulatedPosition(filters: { positions: number[] }) {
  const devicesAccumulator: PositionedDeviceData[] = [];

  for (let index = 0; index < filters.positions.length / 999; index++) {
    const response: dmModels.ResponsePositionedDevicesWithTotal = yield call(dmClient.fetchDevices, { positions: filters.positions.slice(index * 999, index * 999 + 999), force: false });
    if (response.reason === ResponseReason.Ok) {
      devicesAccumulator.push(...response.data);
    } else {
      yield put(NotifyError(`Error while fetching devices: ${ response.message }`));
      yield put(dmDevicesActions.DoGetDevicesFailed());
    }
  }
  yield put(dmDevicesActions.DoGetDevicesSuccess(devicesAccumulator, devicesAccumulator.length));
}

const positionsIdsAccumulator: Set<number> = new Set([]);

function* getPositionedDevicesWorker(action: dmDevicesActions.FetchDevicesByAccumulatedPosition) {
  positionsIdsAccumulator.add(action.positionId);
  yield delay(300);
  yield call(FetchDevicesByAccumulatedPosition, { positions: [...positionsIdsAccumulator] });

  positionsIdsAccumulator.clear();
}

export const deviceManagementDevicesSagas = [
  takeEvery(dmDevicesActions.FETCH_DEVICES_COLD_DATA, fetchDevicesColdDataSaga),
  takeEvery(dmDevicesActions.FIND_DEVICES, findDevicesSaga),
  takeEvery(dmDevicesActions.FIND_POSITIONED_DEVICES, findPositionedDevicesSaga),
  takeEvery(dmDevicesActions.REPLACE_DEVICE, replaceDeviceSaga),
  takeEvery(dmDevicesActions.BIND_DEVICE_TO_POSITION, bindDeviceToPositionSaga),
  takeEvery(dmDevicesActions.UNBIND_DEVICE_FROM_POSITION, unbindDeviceFromPositionSaga),
  takeEvery(FETCH_DEVICES_BY_POSITIONS_ID, fetchDevicesByPositionsId),
  takeEvery(UPDATE_DEVICE, updateDeviceSaga),
  takeEvery(dmDevicesActions.FIND_POSITIONED_DEVICES_RECURSIVE, findPositionedDevicesRecursive),
  takeLatest(dmDevicesActions.FETCH_DEVICES_BY_ACCUMULATED_POSITIONS_ID, getPositionedDevicesWorker),
];
