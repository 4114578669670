import { GetStatParams } from 'actions/device-statistic';
import moment from 'moment-timezone';

enum VisibleDays {
  'month' = 30
}

export enum Fields {
  zones = 'zones',
  owner = 'owner',
  devices = 'devices',
  groups = 'groups',
  timeFrom = 'timeFrom',
  timeTo = 'timeTo',
  projects = 'projects',
}

export const initStatFilters: GetStatParams = {
  [Fields.zones]: [],
  [Fields.owner]: undefined,
  [Fields.devices]: [],
  [Fields.groups]: [],
  [Fields.timeFrom]: moment().set({ hour: 0 ,minute: 0 ,second: 0, millisecond: 0 }).subtract(VisibleDays.month, 'days').utc(),
  [Fields.timeTo]: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).utc(),
  [Fields.projects]: [],
};
