/* eslint-disable @typescript-eslint/no-unused-vars */
import { Zone, LevelUpdateFields } from 'models/device-management';

// components
import { CommonDialog } from 'components/Dialogs';
import { Button as ButtonWithPending } from 'components';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { AddLevelEnsureDialog } from './AddLevelEnsureDialog';

import { LevelForm } from './LevelForm';
import { useDialog } from 'hooks';
import { Level } from 'models/device-management';
import { useLevelForm } from './useLevelForm';

interface Props {
  zone: Zone;
  zoneLevels: Level[];
  onSuccess: (level: Level) => void;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

type LevelFormSiderProps = {
  mode: 'EDIT' | 'CREATE'
  level?: Level;
  zone: Zone;
  open: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onCreateSuccess?: (level: LevelUpdateFields & { id: number }) => void;
  onUpdateSuccess?: (level: LevelUpdateFields & { id: number }) => void;
  zoneLevels: Level[];
}

export const LevelFormSider = ({
  level,
  zone,
  mode,
  open,
  onClose,
  onCloseEnd,
  onCreateSuccess,
  onUpdateSuccess,
  zoneLevels,
}: LevelFormSiderProps) => {
  const isEdit = mode === 'EDIT';

  const { createLevel, isCreateLevelFetching, updateLevel, control, errors, getValues, isValid, watch } = useLevelForm({
    defaultValues: level,
    onCreateSuccess,
    onUpdateSuccess,
  });

  const ensureDialog = useDialog();

  const onSave = async () => {
    const v = getValues();
    if (isEdit && level?.id) {
      await updateLevel(level.id, { ...v, zone_id: zone.id });
    } else {
      await createLevel({ ...v, zone_id: zone.id });
    }
  };

  return <>
    <CommonDialog
      isOpen={ open }
      title={ `Add level > ${zone.name}`}
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      content={<Box padding="8px 0px"><LevelForm control={control} errors={errors} /></Box>}
      actions={
        <>
          <Button onClick={ onClose }>Cancel</Button>
          <ButtonWithPending
            pending={isCreateLevelFetching}
            color="primary"
            variant="contained"
            disabled={!isValid}
            onClick={async () => {
              const v = getValues();
              const isExist = zoneLevels
                .filter(l => l.id !== level?.id)
                .some(level => level.floor_number === v.floor_number);
              if (isExist) {
                ensureDialog.open();
                return;
              }

              await onSave();
              onClose?.();
            } }
          >
          Add
          </ButtonWithPending>
        </>
      }
    />
    <AddLevelEnsureDialog
      dialog={ensureDialog}
      zoneName={String(zone.name || zone.id)}
      floor={ watch('floor_number') }
      onYes={ onSave }
    />
  </>;
};
