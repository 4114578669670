import React from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadCallerTemplateDataByParams } from 'actions/caller';
import { Link } from 'react-router-dom';
import { useCallerMessageTypes } from 'hooks/caller';
import { GetTemplateParams } from 'models/caller';
import { getCallerTemplateDetailLocation } from 'routing/paths';
import { commonOptions } from 'utils/tables';

// components
import Typography from '@mui/material/Typography';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { LoaderSvg } from 'components/Loaders';
import { TableLoadingLayout } from 'components/Table';
import Actions from './Actions';
// styles
import { ThemeProvider } from 'styles/utils';
import { truncatedCellsMuiTableTheme } from 'styles/themes';
import useStyles from './Style';

interface Props {
  params: GetTemplateParams;
}

const Component = ({ params }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isFetched, data, locked } = useSelector((state: RootState) => ({
    isFetched: state.caller.template.isFetched(params),
    data: state.caller.template.findByParams(params),
    locked: state.caller.template.getLocked(),
  }));
  const messageTypesState = useCallerMessageTypes();

  useEffectOnce(() => {
    dispatch(LoadCallerTemplateDataByParams(params));
  });

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRender: (name, tableMeta) => (
          <Link to={ getCallerTemplateDetailLocation(data[tableMeta.rowIndex]) }>{ name }</Link>
        )
      }
    },
    {
      name: 'message_type',
      label: 'Message type',
      options: {
        customBodyRender: (type) => {
          const messageType = messageTypesState.messageTypes?.find(m => m.id === type);

          if (messageType?.name) {
            return messageType.name;
          }

          if (messageTypesState.isLoading) {
            return <LoaderSvg />;
          }

          return type;
        },
      }
    },
    {
      name: 'method',
      label: 'Method',
    },
    {
      name: 'url',
      label: 'URL',
      options: {
        customBodyRender: (url) => (
          <span className={ classes.urlCell }>
            <Typography variant="caption">{ url }</Typography>
          </span>
        )
      }
    },
    {
      name: 'template_id',
      label: 'Actions',
      options: {
        empty: true,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
        setCellProps: () => ({ className: classes.actionCell }),
        customBodyRender: (id, tableMeta) => (
          <Actions template={ data[tableMeta.rowIndex] } isLoading={ locked.includes(id) }/>
        ),
      },
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
  };
  return (
    <ThemeProvider theme={ truncatedCellsMuiTableTheme(columns.length) }>
      <TableLoadingLayout isLoading={ !isFetched }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

export default Component;
