import { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DoSetUserData,
  DoGetUserData,
  DoDestroyUserSession,
  DoStartBrowserSession,
  DoStopBrowserSession
} from 'actions/user-profile';
import { Auth, Hub, I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { Events, hideDefaultAmplifyToast, parseError } from 'utils/amplify';
import { NotifyError } from 'actions/notifier';
import { clearLocationParams } from 'utils/routing/query';
import { useAuthUserSelector } from 'hooks/user';
import { useEffectOnce } from 'react-use';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

// Overwrite error messages
I18n.putVocabularies({
  en: {
    '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$': 'Password requirements:' +
      '\n- 1 number at least\n- 1 special character at least\n- 1 uppercase letter at least\n- 1 lowercase letter at least\n- minimum length 8',
  },
});

type TSignUpAttributes = {
  'custom:client_access_code': string;
  'custom:first_name': string;
  'custom:last_name': string;
  'email': string;
  'custom:recaptchaToken': string;
}

type TUserCredentials = {
  username: string;
  password: string;
}

type TSignUpParams = TUserCredentials & {
  attributes: TSignUpAttributes;
}

export function useAmplifyAuth() {
  const dispatch = useDispatch();
  const signedInRef = useRef(false);
  const { signedIn } = useAuthUserSelector();
  const history = useHistory();

  const handleSignIn = async (formData: TUserCredentials) => Auth.signIn({
    username: formData.username.trim().toLowerCase(),
    password: formData.password,
  });
  const handleSignUp = async (formData: TSignUpParams, recaptchaToken: string) => Auth.signUp({
    username: formData.attributes.email.toLowerCase(),
    password: formData.password,
    attributes: {
      ...formData.attributes,
      'custom:recaptchaToken': recaptchaToken,
    }
  });

  useEffectOnce(function () {
    return Hub.listen(/.*/, (data) => {
      const { payload } = data;
      switch (payload.event) {
        case Events.signIn:
          const user = payload.data.signInUserSession.idToken.payload;
          dispatch(DoSetUserData(user));
          history.replace('/');
          break;
        case Events.signOut:
          dispatch(DoStopBrowserSession());
          dispatch(DoDestroyUserSession());
          clearLocationParams();
          break;
        case Events.configured:
          hideDefaultAmplifyToast();
          break;
        case Events.authError:
          const error = parseError(payload);
          dispatch(NotifyError(error));
          break;
      }
    });
  });

  if (!signedInRef.current) {
    dispatch(DoGetUserData());
    signedInRef.current = true;
  }

  const startBrowserSession = useCallback(() => dispatch(DoStartBrowserSession()), [dispatch]);
  useEffect(() => {
    if (signedIn) {
      startBrowserSession();
    }
  }, [startBrowserSession, signedIn]);

  return {
    signedIn,
    handleSignIn,
    handleSignUp,
    handleForgotPassword: async (username: string) => Auth.forgotPassword(username.toLocaleLowerCase()),
  };
}
