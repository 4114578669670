import React, { ReactNode } from 'react';
import { getDMGroupDetailPath } from 'routing/paths';
// components
import Link from '@mui/material/Link';
import { CustomLink } from 'components/Links';

interface Props {
  zoneId: number;
  levelId: number;
  groupId: number;
  children: ReactNode;
  withReturnUrl?: boolean;
}

export const GroupLink = (props: Props): JSX.Element => {
  const { zoneId, levelId, groupId, withReturnUrl, children } = props;

  return (
    <Link
      component={ CustomLink }
      color="secondary"
      to={ getDMGroupDetailPath(zoneId, levelId, groupId) }
      withReturnUrl={ withReturnUrl }
    >
      { children }
    </Link>
  );
};
