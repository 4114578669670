import React from 'react';
import { getDeviceMarkerIcon, getStationMarkerIcon, MarkerColor } from 'utils/map';
// components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
// style
import useStyles from '../styles';
import { LegendStyles } from 'styles';

export const Legend = (): JSX.Element => {
  const classes = {
    ...useStyles(),
    ...LegendStyles()
  };
  return (
    <Box lineHeight={ 2 } justifyContent="center" className={ classes.legendBox }>
      <div className={ classes.bsIconContainer }>
        <Icon className={ classes.stationIcon }><img src={ getStationMarkerIcon(MarkerColor.green) } alt="Green station marker"/></Icon>
        <Typography variant="subtitle2" align="center">Station is online</Typography>
      </div>

      <div className={ classes.bsIconContainer }>
        <Icon className={ classes.stationIcon }><img src={ getStationMarkerIcon(MarkerColor.red) } alt="Red station marker"/></Icon>
        <Typography variant="subtitle2" align="center">Station is offline</Typography>
      </div>

      <div className={ classes.iconContainer }>
        <Icon><img src={ getDeviceMarkerIcon(MarkerColor.green) } alt="Green marker"/></Icon>
        <Typography variant="subtitle2" align="center">Excellent connectivity (98%-100%)</Typography>
      </div>

      <div className={ classes.iconContainer }>
        <Icon><img src={ getDeviceMarkerIcon(MarkerColor.yellow) } alt="Yellow marker"/></Icon>
        <Typography variant="subtitle2" align="center">Good connectivity (95%-97%)</Typography>
      </div>

      <div className={ classes.iconContainer }>
        <Icon><img src={ getDeviceMarkerIcon(MarkerColor.red) } alt="Red marker"/></Icon>
        <Typography variant="subtitle2" align="center">Poor connectivity (0%-94%)</Typography>
      </div>
    </Box>
  );
};
