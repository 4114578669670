import { useLoadingSelector } from 'hooks/useLoadingSelector';
import { Fade, LinearProgress } from '@mui/material';

export const MainLoader = (): JSX.Element => {
  const isFetching = useLoadingSelector();
  return (
    <Fade
      in={ isFetching }
      timeout={ { appear: 0, exit: 1000 } }
      mountOnEnter
      unmountOnExit
    >
      <LinearProgress
        aria-label="The website's main progress bar"
        color="primary"
        sx={ {
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999999,
          width: '100%',
        } }
      />
    </Fade>
  );
};
