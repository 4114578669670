import moment from 'moment';

export type LorawanMessage = {
  data_rate: number;
  device_eui: string;
  device_id: string;
  device_id_int: number;
  fcnt: number;
  fport: number;
  gateway_eui: string;
  integration_type: string;
  is_unique: boolean;
  payload: string;
  rssi: number;
  snr: number;
  timestamp: string;
  trace_id: string;
  group_id: number;
  group_name: string;
  zone_id: number;
}

export interface LorawanMessageWithNewFlag extends LorawanMessage {
  new?: boolean
}

export interface LoRaWANRawMessagesFilters {
  owner?: number;
  projects: number[];
  zones: number[];
  groups: number[];
  devices: string[];
  gatewayEUI?: string;
  networkId: string[];
  traceid?: string;
  timeFrom?: moment.Moment | null;
  timeTo?: moment.Moment | null;
}

export interface LoRaWANRawMessagesQuery extends LoRaWANRawMessagesFilters {
  limit: number
  offset: number
}

export const initLoRaWANRawMessagesFilters: LoRaWANRawMessagesFilters = {
  owner: undefined,
  projects: [],
  zones: [],
  groups: [],
  devices: [],
  gatewayEUI: undefined,
  networkId: [],
  traceid: undefined,
  timeFrom: moment().subtract(1, 'days'),
  timeTo: null,
};


