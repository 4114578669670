import React, { ReactNode, useState } from 'react';

// components
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

// styles
import { LegendStyles } from 'styles';

interface Props {
  children: ReactNode;
}
export const LegendPopover = ({ children }: Props): JSX.Element => {
  const classes = LegendStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton className={ classes.help } onClick={ handleClick }><HelpIcon color="primary" /></IconButton>
      <Popover
        open={ open }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        { children }
      </Popover>
    </>
  );
};
