import React from 'react';

// components
import { LocalOffer, Power, Accessible, Star } from '@mui/icons-material';

export interface Label {
  label: string;
  count?: number;
}

type IconType = React.FC<{ className?: string }>;

export function getIconForLabel(label: string): IconType {
  switch (true) {
    case isEVLabel(label):
      return Power;
    case isVipLabel(label):
      return Star;
    case isLabelAboutPeopleWithDisability(label):
      return Accessible;
    default:
      return LocalOffer;
  }
}

export function isEVLabel(label: string): boolean {
  return includesWholeWordCaseInsensitive('EV', label);
}

export function isVipLabel(label: string): boolean {
  return includesWholeWordCaseInsensitive('VIP', label);
}

export function isLabelAboutPeopleWithDisability(label: string): boolean {
  return includesWholeWordCaseInsensitive('Disabled', label);
}

function includesWholeWordCaseInsensitive(expected: string, actual: string) {
  return new RegExp(`\\b${ expected }\\b`, 'i').test(actual);
}
