import React, { useState } from 'react';
import { Box } from 'models/provisioning';
import { useCreateBoxForm } from './utils';
// components
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { InfoDialog } from 'components/Dialogs';
import { TextControl } from 'components/Controls';
import { CreateBoxForm } from './CreateBoxForm';
// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  isOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
}

export const CreateBoxModal = ({ isOpen, setDialogOpen }: Props): JSX.Element => {
  const classes = useStyles();
  const [created, setCreated] = useState<Array<Box>>([]);
  const { form, actions } = useCreateBoxForm({
    onSuccess: setCreated,
    onCancel: () => setDialogOpen(false),
  });

  if (created.length > 0) {
    return (
      <InfoDialog
        name="box-created"
        label="Boxes successfully added"
        isOpen={isOpen}
        onClose={() => setDialogOpen(false)}
        DialogProps={{ fullWidth: true, maxWidth: 'sm' }}
      >
        <TextControl
          variant="outlined"
          label="Added boxes IDs"
          multiline
          disabled
          value={ created.map(box => box.id).join(', ') }
        />
      </InfoDialog>
    );
  }

  return (
    <Dialog
      open={ isOpen }
      onClose={ () => setDialogOpen(false) }
      maxWidth={ 'sm' }
      fullWidth
      PaperProps={ { className: classes.formDialogPaper } }
    >
      <DialogTitle>Create Box</DialogTitle>
      <DialogContent className={ classes.dialogContentRoot }>
        <CreateBoxForm { ...form } />
      </DialogContent>
      <DialogActions> { actions } </DialogActions>
    </Dialog>
  );
};
