import moment from 'moment';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { initStatFilters } from './models';
import { GetStatParams } from 'actions/device-statistic';
// components
import Grid from '@mui/material/Grid';
import { DateControl, GroupedFilters } from 'components/Controls';
import { FilterButton } from 'components/Buttons';

interface Props {
  currentFilters: GetStatParams;
  isLoading: boolean;
  onSave: (filter: GetStatParams) => void;
  onReset: () => void;
}

export const DevicesHealthFilter = ({
  currentFilters,
  isLoading,
  onSave,
  onReset
}: Props): JSX.Element => {

  const [filters, setFilters] = useState<GetStatParams>(initStatFilters);

  // sync filters with params
  useDeepCompareEffect(() => {
    setFilters(currentFilters);
  }, [currentFilters]);

  const handleSelectOwner = (owner: number | undefined) => setFilters({ ...filters, owner: Number(owner) || undefined });
  const handleSelectProjects = (projects: number[]) => setFilters({ ...filters, projects });
  const handleSelectZones = (zones: number[]) => {
    setFilters({ ...filters, zones });
  };
  const handleSelectGroups = (groups: number[]) => setFilters({ ...filters, groups });

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filters }
      onSave={ () => onSave(filters) }
      onReset={ onReset }
      isValid={filters.projects.length > 0}
    >
      <form autoComplete="off">
        <GroupedFilters
          handleSelectOwner={ handleSelectOwner }
          owner={ filters.owner }
          projects={ filters.projects }
          handleSelectProjects={ handleSelectProjects }
          zones={ filters.zones }
          handleSelectZones={ handleSelectZones }
          groups={ filters.groups }
          handleSelectGroups={ handleSelectGroups }
          isProjectShow
          isZoneShow
          isGroupsShow
        />
        <Grid>
          <DateControl
            isRequired
            label="Time From"
            value={ filters.timeFrom?.toDate() }
            onChange={ (date?: Date) => setFilters({
              ...filters,
              timeFrom: date ? moment(date) : undefined
            }) }
            maxDate={ filters.timeTo?.toDate() }
          />
        </Grid>
        <Grid>
          <DateControl
            isRequired
            label="Time To"
            value={ filters.timeTo?.toDate() }
            onChange={ (date?: Date) => setFilters({
              ...filters,
              timeTo: date ? moment(date).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).utc() : undefined
            }) }
            minDate={ filters.timeFrom?.toDate() }
          />
        </Grid>
      </form>
    </FilterButton>
  );
};