import { useEffect, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiResponse, ApiResponseWithTotal } from 'models';
import { GetProjectsParams, Project } from 'models/user-management';
import { CreateProjectParams, EditProjectParams } from 'models/projects';
import { useFormActionNotifier } from 'hooks/form';
import {
  fetchProjectById,
  fetchProjects,
  createProject as createProjectApi,
  editProject as updateProjectApi,
  deleteProject as deleteProjectApi
} from 'clients/user-management';
import { ensureRequestSucceeded } from 'utils/clients';
import { sortStrings } from 'helpers';

export interface ProjectState {
  project?: Project;
  isLoading: boolean;
}

export function useProjectSelector(projectId: number, enabled = true): ProjectState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userManagement/project', projectId],
    queryFn: async (): Promise<ApiResponse<Project>> => {
      const result: ApiResponse<Project> = await fetchProjectById(projectId);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching project #${ projectId }: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    project: queryResult.data?.data,
  };
}

export interface ProjectsState {
  isLoading: boolean;
  projects: Project[];
  total: number;
}

export function useProjectsParamsSelector(params: GetProjectsParams, enabled = true): ProjectsState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userManagement/project', params],
    queryFn: async (): Promise<ApiResponseWithTotal<Project[]>> => {
      const result: ApiResponseWithTotal<Project[]> = await fetchProjects(params);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching projects: ${ (e as Error).message }`),
    enabled,
  });

  const projects: Project[] = queryResult.data?.data ?? [];

  return {
    isLoading: queryResult.isLoading,
    projects: projects.sort((a: Project, b: Project) => sortStrings(a.name, b.name)),
    total: queryResult.data?.total ?? 0,
  };
}

export function useProjectsDictionarySelector(enabled = true): ProjectsState {
  return useProjectsParamsSelector({ limit: 999 }, enabled);
}

export type TEntityWithProject = { owner_id?: number, project?: number }

export function useDefaultProject() {
  const { projects, isLoading } = useProjectsDictionarySelector();

  const getDefaultProject = useCallback((ownerId?: Project['owner_id']) => {
    if (isLoading) { return undefined; }
    // Return first project from list for current owner
    return projects.find(p => p.owner_id === ownerId);
  }, [projects, isLoading]);

  const getEntityWithDefaultProject = <Entity extends TEntityWithProject>(entity: Entity): Entity => ({
    ...entity,
    project: entity.project || getDefaultProject(entity.owner_id)?.id
  });

  return {
    isProjectFetching: isLoading,
    getDefaultProject,
    getEntityWithDefaultProject
  };
}

interface TUseSetDefaultProjectArgs<Entity> {
  state: Entity;
  setState: (values: Entity) => void;
}

export function useSetDefaultProject<Entity extends TEntityWithProject>({
  state,
  setState,
}: TUseSetDefaultProjectArgs<Entity>) {
  const { getEntityWithDefaultProject, isProjectFetching } = useDefaultProject();
  useEffect(() => {
    if (!isProjectFetching && !state.project && state.owner_id) {
      const stateWithDefaultProject = getEntityWithDefaultProject(state);
      setState(stateWithDefaultProject);
    }
  }, [state, setState, isProjectFetching, getEntityWithDefaultProject]);
}

export const useCreateProject = (onSuccess?: (project: Partial<Project>) => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const createProject = async (project: CreateProjectParams) => {
    try {
      setIsFetching(true);
      const res = await createProjectApi(project, true);
      setIsFetching(false);
      notifySuccess('Project create successfully');
      onSuccess && onSuccess(res.data);
    } catch(e) {
      setIsFetching(false);
      notifyError('Error while creating project');
    }
  };

  return {
    isCreateProjectFetching: isFetching,
    createProject
  };
};


export const useUpdateProject = (onSuccess?: (project: Partial<Project>) => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const updateProject = async (project: EditProjectParams) => {
    try {
      setIsFetching(true);
      const res = await updateProjectApi(project, true);
      setIsFetching(false);
      notifySuccess('Project was updated successfully');
      onSuccess && onSuccess(res.data);
    } catch(e) {
      setIsFetching(false);
      notifyError('Error while updating project');
    }
  };

  return {
    isUpdateProjectFetching: isFetching,
    updateProject
  };
};


export const useDeleteProject = (onSuccess?: () => void) => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isFetching, setIsFetching] = useState(false);

  const deleteProject = async (id: number) => {
    try {
      setIsFetching(true);
      await deleteProjectApi(id, true);
      setIsFetching(false);
      notifySuccess('Project was deleted successfully');
      onSuccess && onSuccess();
    } catch(e) {
      setIsFetching(false);
      notifyError('Error while deleting project');
    }
  };

  return {
    isDeleteProjectFetching: isFetching,
    deleteProject
  };
};

