import { Helmet } from 'react-helmet-async';
import { ConnectedProps, connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'reducers';
import { DoFetchShipmentById } from 'actions/provisioning';
import { useEffectOnce } from 'react-use';
import { useGetShipmentDevices } from 'hooks';

// components
import { Box, Grid } from '@mui/material';
import { PageLoader } from 'components/Loaders';
import { ShipmentDetailPageNotFound } from './ShipmentDetailPageNotFound';
import { ShipmentManagment, ShipmentBoxes } from './widgets/ShipmentManagment';
import { ShipmentDevices } from './widgets/ShipmentDevices';

// styles
import { useStyles } from './styles';

const mapState = (state: RootState, ownProps: { id: string }) => ({
  isLoading: state.provisioning.shipment.isFetching,
  shipments: state.provisioning.shipment.shipments,
  shipmentId: ownProps.id
});

const mapDispatch = (dispatch: Dispatch) => ({
  fetchShipment: (id: string) => dispatch(DoFetchShipmentById(id))
});

const connector = connect(
  mapState,
  mapDispatch
);

const ShipmentDetailPage = ({
  shipments,
  isLoading,
  shipmentId,
  fetchShipment,
}: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = useStyles();
  const current = shipments.find(shipment => shipment.id === shipmentId);
  const {
    devices,
    provisionedDevices,
    nonProvisionedDevices,
    isDevicesLoading
  } = useGetShipmentDevices(current); // @TODO ask - it is only for Lora??

  useEffectOnce(() => {
    if (!current && !isLoading) {
      fetchShipment(shipmentId);
    }
  });

  return (
    <Box pt={ 2 }>
      <Helmet><title>{ `Shipment ${current ? current.name : `#${shipmentId}` }` }</title></Helmet>
      {
        !current ? (isLoading ? <PageLoader /> : <ShipmentDetailPageNotFound />) :
          <Grid className={ classes.root } container spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
              <ShipmentManagment
                shipment={ current }
                haveLoraDevicesNotInAws={ nonProvisionedDevices.length > 0 }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
              <ShipmentBoxes shipment={ current } />
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 }>
              <ShipmentDevices
                devices={ devices }
                provisionedDevices={ provisionedDevices }
                nonProvisionedDevices={ nonProvisionedDevices }
                isLoading={ isDevicesLoading }
              />
            </Grid>
          </Grid>
      }
    </Box>
  );
};

export default connector(ShipmentDetailPage);

