import React from 'react';

import { getCallerTemplateSuiteListPath } from 'routing/paths';

// components
import { Box, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { CommonButton } from 'components/Buttons';

interface Props {
  templateSuiteId: number;
}

export const TemplateSuiteNotFound = ({ templateSuiteId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Template Suite <b>{ `#${ templateSuiteId }` }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label="Back to list"
        LinkTo={ getCallerTemplateSuiteListPath() }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};