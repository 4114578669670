import { ReplaceStationLocation } from 'actions/base-station';
import { useFormActionLoader } from 'hooks/form';
import { useLoraWANStationsDictionarySelector, useStationsDictionarySelector } from 'hooks/station';
import { LorawanStation, BaseStation, StationLocation, StationType } from 'models/base-station';
import { getAttached } from 'pages/BaseStation/BaseStations/utils/helpers';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  station: BaseStation | LorawanStation;
  location: StationLocation;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onConfirm: () => void;
}

export const ReplaceLocationDialog = ({ station, location, isOpen, onConfirm, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const { stations } = useStationsDictionarySelector();
  const { stations: loraStations } = useLoraWANStationsDictionarySelector();

  const handleConfirm = () => doAction({
    action: ReplaceStationLocation(
      [...stations, ...loraStations].find(s => s.id === location.station_id) as BaseStation,
      station,
      location
    ),
    onSuccess: () => onConfirm(),
  });

  return (
    <EnsureDialog
      name="attach-station-location"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      Are you sure that you want to replace station { getAttached(loraStations, location) } by the #{ station.type === StationType.lorawan ? station.eui : station.id }?
    </EnsureDialog>
  );
};