import React from 'react';

import { keyDown } from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import { stringAsUUID } from 'helpers';
import { stopPropagation } from 'utils/dom';

// components
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Add from '@mui/icons-material/Add';
import { ArrowDownIcon } from 'components/Controls';
// styles
import contentStyles, { MenuProps } from 'styles';
import useStyles from './NetworkIdSelectStyle';

interface Props {
  changeHandler: (chosenNetworkId?: string) => void;
  selected?: string;
}

const Component = (props: Props): JSX.Element => {
  const classes = {
    ...contentStyles(),
    ...useStyles()
  };

  const [values, setValues] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');

  if (props.selected && !values.includes(props.selected)) {
    setValues([...values, props.selected]);
  }

  const handleItemAdd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!inputValue) {
      return;
    }

    const network = stringAsUUID(inputValue);
    if (!values.includes(network)) {
      setValues([network, ...values]);
    }

    setInputValue('');
    props.changeHandler(network);
  };

  const handleItemChange = (event: React.ChangeEvent<{ value: string }>) => {
    const value = String(event.target.value);
    const clearValue = value.trim().replace(/[^A-Za-z0-9]+/g, '');
    setInputValue(clearValue);
  };

  const handleItemSelect = (event: SelectChangeEvent<string>) => {
    const value = String(event.target.value);
    props.changeHandler(value !== props.selected ? value : undefined);
  };

  const onClose = () => props.changeHandler(props.selected);
  return (
    <FormControl
      className={ classes.formControl }
      data-testid="custom-id-select"
    >
      <InputLabel htmlFor="network-label-placeholder"> Position Network ID </InputLabel>
      <Select
        placeholder="Position Network ID"
        value={ props.selected || '' }
        onChange={ handleItemSelect }
        input={ <Input name="network" id="network-label-placeholder"/> }
        name="network"
        renderValue={ (selected) => selected ? String(selected) : '' }
        MenuProps={ MenuProps }
        IconComponent={ (prop) => (<InputAdornment { ...prop } position="end">
          <ArrowDownIcon/>
        </InputAdornment>) }
        onClose={ onClose }
      >
        <div className={ classes.dropDownInput } onKeyDown={ keyDown } onClick={ stopPropagation }>
          <TextField
            value={ inputValue }
            autoFocus={ true }
            placeholder="Enter network ID"
            onKeyDown={ keyDown }
            onClick={ stopPropagation }
            className={ classes.inputFullWidth }
            onChange={ handleItemChange }
            helperText={ stringAsUUID(inputValue) }
            fullWidth
          />
          <Button
            aria-label="Add"
            disabled={ !inputValue }
            color="primary"
            variant="contained"
            className={ classes.buttonAddInSelect }
            onKeyDown={ keyDown }
            onClick={ handleItemAdd }
          >
            <Add/>
          </Button>
        </div>
        { values.map((net) => (
          <MenuItem key={ net } value={ net } className={ classes.listNetId }>
            <Checkbox checked={ props.selected === net }/>
            <ListItemText primary={ <Typography variant="caption">{ net }</Typography> }/>
          </MenuItem>
        )) }
      </Select>
    </FormControl>
  );
};

export default Component;
