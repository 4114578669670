import { StationEntity, StationType } from 'models/base-station';
import { useAuthUserSelector, useDialog } from 'hooks';
import { canCancelFlashStation, getMarkerColor } from './utils';

// components
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { BaseStationLink } from 'components/Links';
import { BaseStationFrequency } from 'components/Labels';
import { HealthyStatus, OnlineStatus, LorawanMessagesButton } from '../../widgets';
import { MapLocation } from './widgets/StationLocation/MapLocation';
import { EmptyLocation } from './widgets/StationLocation/EmptyLocation';
import { FlashLocation } from './widgets/StationLocation/FlashLocation';
import { CancelFlashDialog } from './widgets/CancelFlashDialog/CancelFlashDialogComponent';
import { MessageButton } from './widgets/MessageButton';
import { EditLocationButton } from './widgets/EditLocationButton';
// styles
import { useStyles } from './styles';

interface StationLocationComponentProps {
  station: StationEntity;
}

const StationLocationComponent = ({ station }: StationLocationComponentProps) => {
  switch (station.type) {
    case StationType.flash:
      return <FlashLocation stationFlash={ station.flash } />;
    case StationType.location:
      return <MapLocation stationLocation={ station.location } />;
    case StationType.lorawan:
    case StationType.station:
      if (station.location) {
        return <MapLocation stationLocation={ station.location } markerColor={ getMarkerColor(station.status) } />;
      }
      return <EmptyLocation station={ station } />;
    default:
      return <></>;
  }
};

interface Props {
  isLoading?: boolean;
  station: StationEntity;
}

export const StationCard = (props: Props) => {
  const { station } = props;

  const classes = useStyles();

  const user = useAuthUserSelector();
  const cancelFlashDialog = useDialog();

  return (
    <Card>
      <CardHeader
        className={ classes.cardHeader }
        disableTypography
        title={
          <Box className={ classes.stationHeader } display="flex" justifyContent="space-between">
            <Typography variant="h6">
              {  station.type === StationType.flash ? `ID ${station.id}`
                : station.type === StationType.location ? `ID -`
                  : station.type === StationType.lorawan ? <BaseStationLink stationId={ station.id } label={ `ID ${ station.eui }` }/>
                    : station.type === StationType.station ? <BaseStationLink stationId={ station.id } label={ `ID ${station.id}` } />
                      : ''}
            </Typography>
            <Typography
              className={ classes.stationFrequency }
              component="span"
              variant="subtitle1"
            >
              {
                station.type !== StationType.lorawan && station.frequency
                  ? <BaseStationFrequency stationFrequency={ station.frequency } />
                  : station.type === StationType.lorawan ? <>{ station.rf_region }</> : 'Not set'
              }
            </Typography>
          </Box>
        }
        subheader={
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" minHeight="25px">
              <Box>
                <>
                  <OnlineStatus stationStatus={ station.status } />
                  { station.type === StationType.station && <HealthyStatus stationStatus={ station.status } />}
                </>
              </Box>
              <Box>
                { station.type === StationType.station && <MessageButton station={ station } /> }
                { station.type === StationType.lorawan && <LorawanMessagesButton station={ station } type="icon" /> }
                { station.type === StationType.location && <EditLocationButton location={ station.location } /> }
              </Box>
            </Box>
            <Typography variant="subtitle1" className={ classes.stationAddress }>
              { !station.location ? 'No location' : `${station.location.country}, ${station.location.city}` }
            </Typography>
          </>
        }
      />
      <CardContent className={ classes.cardContent }>
        <StationLocationComponent station={station} />
      </CardContent>
      <CardActions className={ classes.cardAction }>
        <Box width="100%" display="flex" justifyContent="flex-end">
          { canCancelFlashStation(user, station) && station.type === StationType.flash && <>
            <Button variant="text" onClick={ cancelFlashDialog.open }>Cancel</Button>
            { cancelFlashDialog.isMounted && <CancelFlashDialog
              stationFlash={ station.flash }
              isOpen={ cancelFlashDialog.isOpen }
              onClose={ cancelFlashDialog.close }
              onCloseEnd={ cancelFlashDialog.unmount }
            /> }
          </> }
        </Box>
      </CardActions>
    </Card>
  );
};
