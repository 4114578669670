import React, { useEffect } from 'react';
import { DevicesFilterFields } from 'models/device-management';
import { useDialog } from 'hooks';
import { useDeviceDownloadCSV } from 'hooks/device-management';

// components
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { MuiDialog } from 'components/Dialogs';
import { SaveAlt } from '@mui/icons-material';

interface Props {
  filter: DevicesFilterFields;
}

export const DownloadButton = ({ filter }: Props): JSX.Element => {
  const progressDialog = useDialog();
  const progress = useDeviceDownloadCSV();

  useEffect(() => {
    !progress.inProgress && progressDialog.close();
  }, [progress.inProgress, progressDialog]);

  const handleClick = () => {
    progress.download(filter);
    progressDialog.open();
  };

  const handleCancel = () => {
    progress.cancel();
    progressDialog.close();
  };

  return (
    <>
      <Tooltip title="Save as CSV">
        <span>
          <IconButton onClick={ handleClick }>
            <SaveAlt/>
          </IconButton>
        </span>
      </Tooltip>
      { progressDialog.isMounted && (
        <MuiDialog
          open={ progressDialog.isOpen }
          onClose={ progressDialog.close }
          onExited={ progressDialog.unmount }
          disableBackdropClick
          disableEscapeKeyDown
          onEscapeKeyDown={ handleCancel }
          aria-labelledby="download-progress-label"
          aria-describedby="download-progress-description"
        >
          <DialogTitle id="download-progress-label">
            Downloading of devices information
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="download-progress-description">
              <Typography component="span" variant="body2" color="textSecondary">
                { `Downloaded ${ progress.ready } devices from ${ progress.total }` }
              </Typography>
            </DialogContentText>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={ 1 }>
                <LinearProgress variant="determinate" value={ progress.readyPercent }/>
              </Box>
              <Box minWidth={ 35 }>
                <Typography component="span" variant="body2" color="textSecondary">
                  { `${ progress.readyPercent }%` }
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={ handleCancel }
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </MuiDialog>
      ) }
    </>
  );
};
