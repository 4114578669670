import React, { memo } from 'react';
import { dmPositionGroupPath } from 'routing/paths';
import { useGroup } from 'hooks/device-management';

// components
import { Link } from '@mui/material';
import { CustomLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

// styles

interface Props {
  id: number;
}

const PositionGroupName = ({ id }: Props) => {
  const { group } = useGroup({ id });

  return !group
    ? <LoaderSvg />
    : <Link
      color="secondary"
      component={ CustomLink }
      to={ dmPositionGroupPath(id) }
    >
      { `${group?.name}` || '-' }
    </Link>;
};

export default memo(PositionGroupName);
