import React, { useState } from 'react';
import { useFormActionLoader, useAuthUserSelector } from 'hooks';
import { useForm } from 'hooks/form';
import { CreateVirtualDevice } from 'actions/device-management/virtual-devices';
import { VirtualDevice } from 'models/device-management';
import { isAdmin } from 'utils/permissions';
// components
import { Button } from '@mui/material';
import { CommonDialog, EnsureDialog, InfoDialog } from 'components/Dialogs';
import { CreateVirtualDeviceField, CreateVirtualDeviceForm, CreateVirtualDeviceState } from 'components/DeviceManagement';
import { useVirtualDevicesParamsSelector } from 'hooks/device-management';

const MAX_VIRTUAL_DEVICES_PER_OWNER = 10;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const CreateVirtualDeviceDialog = ({ isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const user = useAuthUserSelector();
  const [confirm, setConfirm] = useState<boolean>(false);
  const form = useForm<CreateVirtualDeviceState, CreateVirtualDeviceField>({
    initialState: { count: 1, active: true },
    showFields: [
      CreateVirtualDeviceField.software,
      CreateVirtualDeviceField.count,
      CreateVirtualDeviceField.active,
      ...(isAdmin(user) ? [CreateVirtualDeviceField.owner_id] : []),
    ],
  });

  const { devices } = useVirtualDevicesParamsSelector({});

  if (!user.isAdmin && devices.length >= MAX_VIRTUAL_DEVICES_PER_OWNER) {
    return (
      <InfoDialog
        name="create-virtual-device"
        label="Error while creating a virtual device"
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
      >
        Your Company can't exceed the limit of { MAX_VIRTUAL_DEVICES_PER_OWNER } virtual devices
      </InfoDialog>
    );
  }

  const handleAdd = (confirm?: boolean): void => {
    if (!form.validate()) {
      return;
    }

    if (!form.state.active && !confirm) {
      setConfirm(true);
      return;
    }

    const params: VirtualDevice = {
      firmware_hash: form.state.software,
      activation_status: form.state.active,
      owner_id: form.state.owner_id
    };
    doAction({
      action: CreateVirtualDevice(params, form.state.count),
      onSuccess: onClose,
      onError: form.catchError,
    });
  };

  return (
    <>
      <CommonDialog
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
        title="Create Virtual Devices"
        content={ <CreateVirtualDeviceForm { ...form } maxCount={ MAX_VIRTUAL_DEVICES_PER_OWNER } /> }
        actions={
          <>
            <Button color="primary" onClick={ onClose }>Cancel</Button>
            <Button color="primary" variant="contained" onClick={ () => handleAdd() }>Add</Button>
          </>
        }
      />
      { !confirm ? '' :
        <EnsureDialog
          name={ 'ensure-create-rule' }
          isOpen={ true }
          onClose={ () => setConfirm(false) }
          onYes={ () => handleAdd(true) }
        >
          Are you sure you want to add inactive virtual devices?
        </EnsureDialog>
      }
    </>
  );
};
