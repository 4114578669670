import React from 'react';
// components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CommonButton from 'components/Buttons/CommonButton';

interface Props {
  apiKeyId: string;
  clientPath: string;
}

export const NotFound = ({ apiKeyId, clientPath }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        API Auth Token <b>{ apiKeyId }</b> not found
      </Typography>

      <CommonButton type="button" label="Back to client" LinkTo={ clientPath } icon={ <ArrowBackIcon/> }/>;
    </Box>
  );
};
