import React from 'react';
import { props as LineChartsOptions } from 'components/Charts/ChartsOptions/LineChartsOptions';
import { formatChartAxisDateTime } from 'utils/datetime';
import { MonitoringLineChartProps, MonitoringSerie } from '../types';
import { formatDate, getTickValues } from '../utils';

// components
import { Grid } from '@mui/material';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import { Scrollbar } from 'components';
import { AxisTick, DateAxisTick } from 'components/Charts';
import { BlockLoader } from 'components/Loaders';
import { MonitoringChartLegend } from 'components/Charts/MonitoringChart/Legend/MonitoringChartLegend';
import { MonitoringChartTooltip } from 'components/Charts/MonitoringChart/Tooltip/MonitoringChartTooltip';

// styles
import { useStyles } from './styles';

export const MonitoringLineChart = (props: MonitoringLineChartProps): JSX.Element => {
  const classes = useStyles();
  const {
    isLoading,
    data,
    legendProps,
    tooltipProps,
    maxValue,
    durationDays,
    durationStep
  } = props;

  if (isLoading) {
    return (
      <Grid container spacing={ 1 } className={ classes.root } data-testid="monitoring-line-chart-loader">
        <Grid item xs={ 12 } sm={ 12 } className={ classes.chartBody }>
          <BlockLoader size={ 20 }/>
        </Grid>
      </Grid>
    );
  }

  const tickValues = getTickValues(maxValue || 1);
  return (
    <Grid container spacing={ 1 } className={ classes.root } data-testid="monitoring-line-chart">
      <Grid item xs={ 12 } sm={ 9 } className={ classes.chartBody }>
        <ResponsiveLine
          { ...LineChartsOptions }
          margin={ { top: 6, right: 16, bottom: 56, left: 55 } }
          xScale={ {
            type: 'time',
            format: '%Y-%m-%d %H:%M',
            useUTC: false,
          } }
          yScale={ {
            type: 'linear',
            max: Math.max(...tickValues),
            min: 0,
          } }
          axisBottom={ {
            tickSize: 0,
            tickPadding: 12,
            format: formatChartAxisDateTime,
            renderTick: (durationDays && durationDays > 7) ? AxisTick : DateAxisTick,
          } }
          axisLeft={ {
            tickSize: 0,
            tickPadding: 15,
            tickValues,
          } }
          gridYValues={ tickValues }
          lineWidth={ 1.5 }
          pointSize={ 4 }
          xFormat="time:%Y-%m-%d %H:%M"
          enableArea={ false }
          enablePoints={ false }
          colors={ data.map(serie => serie.color) }
          theme={ {
            axis: {
              ticks: {
                text: {
                  fontSize: '12px',
                },
              },
            },
          } }
          sliceTooltip={ ({ slice }: SliceTooltipProps): JSX.Element => {
            const date = (slice.points[0].data.x as Date);
            if (!date) {
              return ( <> </> );
            }

            return (
              <MonitoringChartTooltip
                title={ formatDate(date, durationStep === 'second' || durationStep === 'minute' || durationStep === 'hour') }
                entries={ [...slice.points].reverse().map(point => {
                  const serie = data.find( s => s.id === point.serieId) as MonitoringSerie;
                  return {
                    id: serie.id,
                    label: serie.label,
                    color: serie.color,
                    value: Number(point.data.y),
                  };
                }) }
                { ...tooltipProps }
              />
            );
          } }
          { ...props }
        />
      </Grid>

      <Grid item xs={ 12 } sm={ 3 } className={ classes.chartLegend }>
        <Scrollbar fullHeight>
          <MonitoringChartLegend
            entries={ data.map(serie => ({
              ...serie,
              value: serie.total,
              label: serie.label,
            })) }
            { ...legendProps }
          />
        </Scrollbar>
      </Grid>
    </Grid>
  );
};
