import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuthUserSelector } from 'hooks';
import {
  Device,
  DeviceUpdateParams,
  SoftType,
  DeviceColdData,
  ExistingPosition
} from 'models/device-management';
import { dmDevicePath } from 'routing/paths';
import { isAllowedToUpdateDevice } from 'utils/permissions';
import { combineIds } from 'utils/ids';
import { FormHookResult } from 'utils/form';
import * as is from 'utils/form/validation/validators';
import { isPositionId, isDeviceVirtual } from 'utils/models';
import { RootState } from 'reducers';
import { dispatchAsync } from 'utils/store';
import { useFirmwaresDictionarySelector } from 'hooks/firmware-management';
import { updateDevice } from 'actions/device-management/devices';
import { DoFetchProvisioningDeviceById } from 'actions/provisioning';
import { UpdateDeviceFirmware } from 'actions/firmware-management';
import { Firmware } from 'models/firmware-management';

import { DeviceFormValues as FormValues } from './utils';

// components
import { Button as MuiButton, Link, Paper, Box } from '@mui/material';

import { Button, Form } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { CustomLink } from 'components/Links';
import { FieldSkeleton } from 'components/Skeleton';

import DeviceBinding from '../DeviceBinding';
import DeviceGeneralInfo from '../DeviceGeneralInfo';
import DeviceBusinessInfo from '../DeviceBusinessInfo';
import DeviceIncidents from '../DeviceIncidents';
import DeviceMonitoring from '../DeviceMonitoring';
import DeviceProvisioningInfo from '../DeviceProvisioningInfo';
import { VirtualDeviceSettings } from '../VirtualDeviceSettings';

// styles
import { ThemeProvider } from 'styles/utils';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

export interface DeviceBlockProps {
  dependent?: boolean;
  device?: Device & DeviceColdData;
  deviceId?: Device['device_id'] | null;
  idPrefix?: string;
  positionId?: ExistingPosition['id'];
  onDevicePositionRelationUpdate?: () => void;
}

export type DeviceFormValues = DeviceUpdateParams['props'] & {
  soft_type?: SoftType;
  firmware_hash?: string;
};

const getDefaultValues = (device: Device): DeviceFormValues => ({
  ...device,
  activation_status: device?.activation_status ?? false,
  damaged_status: device?.damaged_status ?? false,
  hardware_type: device?.hardware_type || null,
});

const DeviceBlock: React.FC<DeviceBlockProps> = ({
  dependent = false,
  device,
  deviceId,
  idPrefix,
  positionId,
  onDevicePositionRelationUpdate,
}) => {
  const dispatch = useDispatch();
  const allowedToUpdateDevice = useSelector((state: RootState) => isAllowedToUpdateDevice(state.user.data));
  const { isAdmin } = useAuthUserSelector();
  const infoCss = useInfoBlockStyles();
  const { firmwares } = useFirmwaresDictionarySelector();
  const deviceVirtual = deviceId ? isDeviceVirtual(deviceId) : undefined;

  // get provisioning info
  const deviceProvisioning = useSelector((state: RootState) => state.provisioning.devices);
  const currentDevice = deviceId ?
    deviceProvisioning.devices.find(device => device.device_id.toUpperCase() === deviceId.toUpperCase())
    : null;

  useEffect(() => {
    if (!currentDevice && deviceId && !deviceVirtual) {
      dispatch(DoFetchProvisioningDeviceById(deviceId));
    }
  }, [currentDevice, dispatch, deviceId, deviceVirtual]);

  if (deviceId === null && isPositionId(positionId)) {
    return <DeviceBinding positionId={ positionId } onSuccess={ onDevicePositionRelationUpdate } />;
  }

  if (!device || !deviceId) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  const defaultValues = getDefaultValues(device);
  const handleSubmit = async (values: DeviceFormValues) => {
    const deviceUpdateProps: DeviceFormValues = {
      ...device,
      ...values,
      hardware_type: values.hardware_type || null
    };

    // we don't support resetting `firmware_hash`, so we check `values.firmware_hash` to be filled
    // for virtual devices @TODO
    if (
      values.firmware_hash &&
      values.firmware_hash !== defaultValues.firmware_hash
    ) {
      const firmware = firmwares.find(f => f.hash === values.firmware_hash);

      await dispatchAsync(
        dispatch,
        UpdateDeviceFirmware({
          deviceId: device?.device_id as string,
          firmwareHash: values.firmware_hash,
          firmware: firmware as Firmware,
        }),
      );
    }

    return dispatchAsync(dispatch, updateDevice({
      id: device?.device_id as string,
      props: deviceUpdateProps,
    }));
  };

  function renderHeader(form: FormHookResult<FormValues>) {
    return (
      <BlockToolbar>
        <BlockTitle>
          <Box padding="0px 16px">
            {/* replace with `props.renderTitle()` if more customization is needed */ }
            { dependent ? 'Bound device' : 'Device' }{ ' ' }
            { deviceId && (
              <Link
                activeAsText
                color="secondary"
                component={ CustomLink }
                to={ dmDevicePath(deviceId) }
              >
                { deviceId }
              </Link>
            )}
          </Box>
        </BlockTitle>

        { allowedToUpdateDevice && form.formState.dirty && (
          <MuiButton color="inherit" onClick={ form.reset }>
            Cancel
          </MuiButton>
        ) }

        { allowedToUpdateDevice && (
          <span>
            {/* disabled buttons don't fire events, so we need a wrapper like <span> */ }
            <ThemeProvider theme={ successTheme }>
              <Button
                color="primary"
                disabled={ !form.formState.dirty }
                pending={ form.formState.isSubmitting }
                type="submit"
              >
                Save
              </Button>
            </ThemeProvider>
          </span>
        ) }
      </BlockToolbar>
    );
  }

  return (
    <Form<FormValues>
      formProps={ { 'data-testid': 'device-block' } }
      defaultValues={ defaultValues }
      validators={ {
        owner_id: isAdmin ? is.required() : undefined,
      } }
      onSubmit={ handleSubmit }
    >
      { form => (
        <Paper className={ infoCss.root }>
          { renderHeader(form) }

          <DeviceGeneralInfo
            form={ form }
            device={ device }
            idPrefix={ combineIds(idPrefix, 'general') }
            onDevicePositionRelationUpdate={() => {
              onDevicePositionRelationUpdate && onDevicePositionRelationUpdate();
              if (defaultValues.activation_status) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleSubmit({ ...defaultValues, activation_status: false });
              }
            }}
          />

          { device?.device_id && deviceVirtual && (
            <VirtualDeviceSettings
              allowedToUpdateDevice={ allowedToUpdateDevice }
              device={ device }
              onActivateDevice={() => {
                return handleSubmit({ ...defaultValues, activation_status: true });
              }}
            />
          ) }

          <DeviceMonitoring device={ device } />

          <DeviceIncidents device={ device } />

          { (!deviceVirtual && deviceProvisioning.devices[0]) && (
            <DeviceBusinessInfo
              device={ device }
              provisioningDevices={ deviceProvisioning.devices }
              idPrefix={ combineIds(idPrefix, 'business') }
            />
          ) }

          { device?.device_id && !deviceVirtual && isAdmin && (
            <DeviceProvisioningInfo currentDevice={ currentDevice } />
          ) }
        </Paper>
      ) }
    </Form>
  );
};

export default DeviceBlock;
