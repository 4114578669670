import { Label, PositionWithLevel } from 'models/device-management';
import { deleteLabel } from 'actions/device-management/positions';
import { Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { findPositions } from 'actions/device-management/positions';

// hooks
import { useLabels } from 'hooks/device-management';
import { useAuthUserSelector } from 'hooks';
import { useFormActionLoader } from 'hooks/form';

// components
import { EnsureDialog } from 'components/Dialogs';
import { useSetLabels } from 'components/DeviceManagement/Positions/EditPositionLabelsDialog/utils';

interface Props {
  label: Label;
  position: PositionWithLevel;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  positionOwnerId?: number;
}

export const DeleteLabelDialog = ({ label, position, isOpen, onClose, onCloseEnd, positionOwnerId }: Props): JSX.Element => {
  const { label: labelName, id: labelId } = label;
  const dispatch = useDispatch();
  const { doAction } = useFormActionLoader();
  const { isAdmin } = useAuthUserSelector();
  const setLabels = useSetLabels();

  const PositionLabels = position.labels || [];
  const labelsQuery = useLabels({
    params: { owner: isAdmin ? positionOwnerId : undefined, limit: 2000, offset: 0 },
    enabled: isAdmin ? Boolean(positionOwnerId) : undefined,
  });


  const onSuccessDelete = async() => {
    await labelsQuery.refetch();
    dispatch(findPositions({ positions: [position.id] }));
  };

  const deletePositionLabels = () => {
    doAction({
      // detaching label from CURRENT position. All other positions with this label attached will be detached automatically on backend
      action: setLabels(
        position.id,
        PositionLabels.map(l => l.id),
        PositionLabels.map(l => l.id).filter(l => l !== labelId),
      ),
      onSuccess: () => {
        doAction({
          action: deleteLabel(labelId),
          onSuccess: () => {
            doAction({
              action: onSuccessDelete(),
              onSuccess: onClose,
            });
          }
        });
      },
    });
  };

  return (
    <EnsureDialog
      name="delete-label"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ deletePositionLabels }
    >
      <Typography variant="h6" display="inline">{ labelName }</Typography> will be removed and detached from all positions. Proceed?
    </EnsureDialog>
  );
};
