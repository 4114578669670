import React from 'react';

// components
import { Typography, TypographyProps } from '@mui/material';

// styles
import { styled } from 'styles/utils';

const RawBlockTitle: React.FC<TypographyProps & { component?: React.ElementType }> = ({
  component = 'h2',
  variant = 'h6',
  ...restProps
}) => {
  return <Typography component={component} variant={variant} {...restProps} />;
};

export const BlockTitle = styled(RawBlockTitle)({
  margin: 0,
  flexGrow: 1,
});

export const BlockToolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
}));
