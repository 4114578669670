import React from 'react';

// components
import {
  ButtonProps as MUIButtonProps,
  Button as MUIButton,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

// styles
import { styled } from 'styles/utils';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginRight: theme.spacing(0.8),
}));

interface ButtonProps extends MUIButtonProps {
  CircularProgressProps?: CircularProgressProps;
  // We specify `children` prop by ourselves here not to miss MUI's Button
  // supporting additional types e.g. render function in the future
  // (we'll have to provide support for this on our side)
  children: React.ReactNode;
  pending?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  CircularProgressProps,
  children,
  pending,
  ...restProps
}) => {
  return (
    <MUIButton {...restProps} disabled={pending || restProps.disabled}>
      {pending && <StyledCircularProgress size="1.2rem" {...CircularProgressProps} />}
      {children}
    </MUIButton>
  );
};
