import { GetUsersParams, User, UserUpdateParams } from 'models/user-management';

export const DELETE_USER = 'um/users/DELETE_USER';
export const DELETE_USER_SUCCESS = 'um/users/DELETE_USER_SUCCESS';

export const FETCH_USER = 'um/users/FETCH_USER';
export const FETCH_USER_SUCCESS = 'um/users/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'um/users/FETCH_USER_FAILURE';

export const UPDATE_USER = 'um/users/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'um/users/UPDATE_USER_SUCCESS';
export const UPDATE_WITH_RE_LOGIN = 'um/users/UPDATE_WITH_RE_LOGIN';
export const USER_ADD_TO_PROJECT = 'um/users/ADD_TO_PROJECT';

export const FETCH_USERS = 'um/users/FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'um/users/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'um/users/FETCH_USERS_FAILURE';

export const CHANGE_PASSWORD_FOR_CURRENT_USER = 'um/users/CHANGE_PASSWORD_FOR_CURRENT_USER';
export const CHANGE_PASSWORD_FOR_ANOTHER_USER = 'um/users/CHANGE_PASSWORD_FOR_ANOTHER_USER';

export const CONFIRM_ACCOUNT_FETCH = 'um/users/CONFIRM_USER';

export interface DeleteUser {
  readonly type: typeof DELETE_USER;
  readonly userSub: User['sub'];
}

export type DeleteUserResult = void;

export interface DeleteUserSuccess {
  readonly type: typeof DELETE_USER_SUCCESS;
  readonly userSub: User['sub'];
}

export interface FetchUser {
  readonly type: typeof FETCH_USER;
  readonly userSub: User['sub'];
}

export interface FetchUserSuccess {
  readonly type: typeof FETCH_USER_SUCCESS;
  readonly payload: {
    user: User | null;
    userSub: User['sub'];
  };
}

export interface FetchUserFailure {
  readonly type: typeof FETCH_USER_FAILURE;
  readonly error: string;
}

export interface UpdateUser {
  readonly type: typeof UPDATE_USER;
  readonly payload: UserUpdateParams;
}

export type UpdateUserResult = void;

export interface UpdateUserSuccess {
  readonly type: typeof UPDATE_USER_SUCCESS;
  readonly payload: {
    user: User;
    sub: User['sub'];
  };
}

export interface UpdateWithReLogin {
  readonly type: typeof UPDATE_WITH_RE_LOGIN;
  readonly payload: {
    props: UserUpdateParams['props'];
    sub: User['sub'];
  };
}

export interface AddUsersToProject {
  readonly type: typeof USER_ADD_TO_PROJECT;
  readonly projectId: number;
  readonly users: User[];
}

export interface FetchUsers {
  readonly type: typeof FETCH_USERS;
  readonly params: GetUsersParams;
}

export interface FetchUsersSuccess {
  readonly type: typeof FETCH_USERS_SUCCESS;
  readonly payload: {
    readonly users: User[];
    readonly params: GetUsersParams;
    readonly total: number;
  };
}

export interface FetchUsersFailure {
  readonly type: typeof FETCH_USERS_FAILURE;
  readonly error: string;
}

export interface ChangePasswordByUser {
  readonly type: typeof CHANGE_PASSWORD_FOR_CURRENT_USER;
  readonly newPassword: string;
  readonly oldPassword: string;
}
export interface ChangePasswordForAnotherUser {
  readonly type: typeof CHANGE_PASSWORD_FOR_ANOTHER_USER;
  readonly newPassword: string;
  readonly permanent: boolean;
  readonly userSub: User['sub'];
}

export const deleteUser = (userSub: User['sub']): DeleteUser => ({
  type: DELETE_USER,
  userSub,
});

export const deleteUserSuccess = (userSub: User['sub']): DeleteUserSuccess => ({
  type: DELETE_USER_SUCCESS,
  userSub,
});

export const fetchUser = (userSub: User['sub']): FetchUser => ({
  type: FETCH_USER,
  userSub,
});

export const fetchUserSuccess = (payload: FetchUserSuccess['payload']): FetchUserSuccess => ({
  type: FETCH_USER_SUCCESS,
  payload,
});

export const fetchUserFailure = (error: string): FetchUserFailure => ({
  type: FETCH_USER_FAILURE,
  error,
});

export const updateUser = (params: UpdateUser['payload']): UpdateUser => ({
  type: UPDATE_USER,
  payload: params,
});

export const updateUserSuccess = (payload: UpdateUserSuccess['payload']): UpdateUserSuccess => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateWithRelogin = (payload: UpdateWithReLogin['payload']): UpdateWithReLogin => ({
  type: UPDATE_WITH_RE_LOGIN,
  payload
});

export const addUsersToProject = (projectId: number, users: User[]): AddUsersToProject => ({
  type: USER_ADD_TO_PROJECT, projectId, users
});

export const fetchUsers = (filters: GetUsersParams): FetchUsers => ({
  type: FETCH_USERS,
  params: filters,
});

export const fetchUsersSuccess: (payload: FetchUsersSuccess['payload']) => FetchUsersSuccess =
  (payload) => ({
    type: FETCH_USERS_SUCCESS,
    payload,
  });

export const fetchUsersFailure: (error: string) => FetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  error,
});

export const DoChangePasswordByUser: (
  newPassword: string,
  oldPassword: string,
) => ChangePasswordByUser = (newPassword, oldPassword) => ({
  type: CHANGE_PASSWORD_FOR_CURRENT_USER,
  newPassword,
  oldPassword,
});

export const DoChangePasswordForAnotherUser: (
  newPassword: string,
  permanent: boolean,
  userSub: User['sub']
) => ChangePasswordForAnotherUser = (newPassword, permanent, userSub) => ({
  type: CHANGE_PASSWORD_FOR_ANOTHER_USER,
  newPassword,
  permanent,
  userSub,
});

export type Action = (
  | DeleteUser
  | DeleteUserSuccess
  | FetchUser
  | FetchUserSuccess
  | FetchUserFailure
  | UpdateUser
  | UpdateUserSuccess
  | FetchUsers
  | FetchUsersSuccess
  | FetchUsersFailure
  | UpdateWithReLogin
  | ChangePasswordByUser
  | ChangePasswordForAnotherUser
);
