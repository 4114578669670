import React from 'react';
// components
import { EditOutlined }  from '@mui/icons-material';

import CommonButton, { CommonButtonProps } from './CommonButton';

export type EditButtonProps =
  Omit<CommonButtonProps, 'icon' | 'label' | 'type'>
  & {
    label?: CommonButtonProps['label'];
    type?: CommonButtonProps['type'];
  };

export const EditButton = (props: EditButtonProps): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Edit';
  const type = props.type !== undefined ? props.type : 'icon';
  return (
    <CommonButton
      { ...props }
      type={ type }
      label={ label }
      icon={ type === 'text' ? undefined : <EditOutlined /> }
    />
  );
};
