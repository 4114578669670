import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLevelsSelector } from 'hooks/device-management/levels';
import { Zone, Level, Group } from 'models/device-management';
import { nameof } from 'utils/type-checking';
import { commonOptions } from 'utils/tables';
import { DoChangeLevel } from 'actions/device-management/levels';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Typography } from '@mui/material';
import { TableLoadingLayout } from 'components/Table';
import { DeleteLevel } from './DeleteLevel';
import { LevelFormModal } from './LevelForm/LevelFormModal';

// styles
import { ThemeProvider } from 'styles/utils';
import { makeTableStyles } from 'styles';
import { getMuiTheme } from 'styles/themes';

import { useStyles } from './styles';

const useTableStyles = makeTableStyles();

interface LevelsTableProps {
  zone: Zone;
  groups: Group[];
  allowedToUpdateLevel: boolean;
  allowedToCreateLevel: boolean;
  allowedToDeleteLevel: boolean
}

const ROWS_PER_PAGE = 10;

export const LevelsTable = ({ zone, groups, allowedToUpdateLevel, allowedToCreateLevel, allowedToDeleteLevel }: LevelsTableProps) => {
  const levelFilters = { limit: 2_000 };
  const dispatch = useDispatch();
  const { levels: allLevels, isLoading } = useLevelsSelector({ params: levelFilters });
  const visibleLevels = allLevels.filter(l => l.zone_id === zone.id);

  const [activeLevel, setActiveLevel] = useState<Level | undefined>();
  const styles = useStyles();
  const tableStyles = useTableStyles();

  const columns: MUIDataTableColumn[] = [
    {
      name: nameof<Level>('floor_number'),
      label: 'Floor',
    },
    {
      name: nameof<Level>('name'),
      label: 'Name',
      options: {
        customBodyRenderLite: dataIndex => (
          <Typography
            className={ allowedToUpdateLevel ? styles.inlineActiveButton : styles.inlineButton }
            color="secondary"
            component="span"
            onClick={ allowedToUpdateLevel ? () => { setActiveLevel(visibleLevels[dataIndex]); } : undefined }
          >
            { visibleLevels[dataIndex].name }
          </Typography>
        ),
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        setCellProps: () => ({ className: tableStyles.shrunkCell }),
        customBodyRenderLite: dataIndex => (
          <div className={tableStyles.actionsCellBody}>
            {allowedToDeleteLevel && (
              <DeleteLevel
                level={ visibleLevels[dataIndex] }
                groups={ groups }
              />
            )}
          </div>
        ),
      },
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    elevation: 0,
    rowsPerPage: ROWS_PER_PAGE,
    rowsPerPageOptions: [ROWS_PER_PAGE],
    sortOrder: {
      name: 'floor_number',
      direction: 'asc',
    },
    customToolbar: allowedToCreateLevel ? (() => (
      <LevelFormModal
        mode="CREATE"
        zoneLevels={ visibleLevels }
        zone={zone}
        onCreateSuccess={level => {
          dispatch(DoChangeLevel(level, { create: true }));
        }}
      />
    )) : undefined
  };

  const title = 'Levels';

  return (
    <>
      <ThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={ isLoading && !visibleLevels.length }>
          <MUIDataTable
            title={ title }
            data={ visibleLevels }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </ThemeProvider>
      {!!activeLevel && <LevelFormModal
        level={activeLevel}
        zoneLevels={ visibleLevels }
        mode="EDIT"
        zone={zone}
        open={!!activeLevel}
        onClose={() => { setActiveLevel(undefined); }}
        onCreateSuccess={level => {
          dispatch(DoChangeLevel(level, { create: true }));
        }}
        onUpdateSuccess={level => {
          dispatch(DoChangeLevel(level, { create: true }));
        }}
      />}
    </>
  );
};
