import React from 'react';
import { provisioningPersonalBoxPath } from 'routing/paths';
import { Link as RouterLink } from 'react-router-dom';

// components
import { Link } from '@mui/material';

const ShipmentBoxLink = ({ id }: { id: number }): JSX.Element => {
  return (
    <Link
      component={ RouterLink }
      color="secondary"
      to={ provisioningPersonalBoxPath(String(id)) }
    >{ `Box ID ${id}` }</Link>
  );
};

export default ShipmentBoxLink;
