import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { DevicesFilterFields, initDeviceFilters } from 'models/device-management/filters';

// components
import { ManageDeviceTableFilter } from 'components/DeviceManagement';
import { FilterButton } from 'components/Buttons';

type Props = {
  isFetching: boolean
  filters: DevicesFilterFields
  onApply: (filters: DevicesFilterFields) => void
}

export const DevicesFilter = ({
  isFetching,
  filters,
  onApply,
}: Props) => {
  const [filterState, setFilterState] = useState<DevicesFilterFields>(filters);
  useDeepCompareEffect(() => { setFilterState(filters); }, [filters]);

  return (<FilterButton
    isLoading={ isFetching }
    filter={ filters }
    onSave={ () => { onApply(filterState); }}
    onReset={ () => { onApply(initDeviceFilters); setFilterState(filters); }}
  >
    <ManageDeviceTableFilter
      state={ filterState }
      setState={ setFilterState }
    />
  </FilterButton>);
};