import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTemplateSelector } from './useTemplateSelector';
// components
import Navigation, { NavigationTab } from './Navigation';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/lab/Skeleton';
import { TemplateStatistic } from 'components/Caller';

interface Props {
  templateId: number;
}

const HttpCallerTemplatePageStatisticTab = ({ templateId }: Props) => {
  const { template } = useTemplateSelector(templateId);
  return (
    <>
      <Helmet><title>{ `Http Template #${ templateId } ${ template && template.name } Statistic` }</title></Helmet>
      <Navigation active={ NavigationTab.STATISTIC } template={ template }/>
      <Paper>
        { !template
          ? <Skeleton variant="rectangular"/>
          : <TemplateStatistic template={ template }/>
        }
      </Paper>
    </>
  );
};

export default HttpCallerTemplatePageStatisticTab;
