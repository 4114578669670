import { CSSProperties } from 'react';
import { alpha } from '@mui/material/styles';
import { createTheme, Theme } from '@mui/material/styles';
import { green, orange, red } from '@mui/material/colors';
import { Palette, PaletteOptions } from '@mui/material/styles/createPalette';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    danger: true;
    success: true;
    warning: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    danger: Palette['primary'];
    success: Palette['primary'];
    warning: Palette['primary'];
  }

  interface PaletteOptions {
    danger?: PaletteOptions['primary'];
    success?: PaletteOptions['primary'];
    warning?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    drawerWidth: number;
    palette: Palette;
    status: {
      ok: string;
      danger: string;
      error: string;
      success: string;
      unknown: string;
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    drawerWidth?: number;
    palette?: PaletteOptions;
    status?: {
      ok: CSSProperties['color'];
      danger: CSSProperties['color'];
      error: CSSProperties['color'];
      success: CSSProperties['color'];
      unknown: CSSProperties['color'];
    };
  }
}

export const colors = {
  textPrimary: '#4a4a4a',
  textSecondary: '#292a2c',
  // for some reason, #bbbbbb leads to invisible text in <input> in Safari, so we reverted it
  textDisabled: 'rgba(0, 0, 0, 0.38)',

  backgroundPaper: '#ffffff',
  backgroundDefault: '#fafafa',

  mainPrimary: '#2887cc',
  lightPrimary: '#69b7ff',
  darkPrimary: '#005b9b',

  errordark: '#d20127',
  errorMain: '#f44336',
  errorLight: '#e57373',

  darkgrey: '#2e2e2e',
  lightGrey: '#bbbbbb',

  mainOrange: '#f5a623',
  lightOrange: '#f7b74f',
  darkOrange: '#ab7418',

  lightRed: '#d20127',
  lightBlue: '#2887cc',
};

const originalTheme = createTheme();

const outerTheme = createTheme({
  spacing: 8,
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '12px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textDecoration: 'none',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: originalTheme.spacing(.75, 1.5),
          minWidth: originalTheme.spacing(20),
          '&.Mui-selected': {
            opacity: 1,
            color: colors.textPrimary,
          }
        },
        selected: {
          opacity: 1,
          color: colors.textPrimary,
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          top: 'calc(50% - 12px)',
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '2px',
        },
        track: {
          borderWidth: 0,
        },
        thumb: {
          width: '12px',
          height: '12px',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: originalTheme.spacing(0.1),
          backgroundColor: '#e0e0e0',
          borderRadius: 3,
          height: 'auto',
          '& $label': {
            padding: 4,
          }
        },
        label: {
          padding: 4,
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          marginRight: 8
        },
        actions: {
          marginLeft: 8
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [originalTheme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides:{
        'grid-xs-12': {
          maxWidth: '100vw'
        }
      }
    }
  },
  typography: {
    fontSize: 12,
  },
  palette: {
    background: {
      default: colors.backgroundDefault,
      paper: colors.backgroundPaper,
    },
    primary: {
      main: colors.mainPrimary,
      light: colors.lightPrimary,
      dark: colors.darkPrimary,
    },
    secondary: {
      main: colors.mainPrimary,
      light: colors.lightPrimary,
      dark: colors.darkPrimary,
    },
    error: {
      dark: colors.errordark,
      main: colors.errorMain,
      light: colors.errorLight,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
    },
    danger: originalTheme.palette.augmentColor({
      color: {
        light: red[200],
        main: red[500],
        dark: red[700],
        contrastText: '#fff',
      },
      name: 'danger',
    }),
    success: originalTheme.palette.augmentColor({
      color: {
        light: green[200],
        main: green[500],
        dark: green[700],
        contrastText: '#fff',
      },
      name: 'success',
    }),
    warning: originalTheme.palette.augmentColor({
      color: {
        light: orange[200],
        main: orange[500],
        dark: orange[700],
        contrastText: '#fff',
      },
      name: 'warning',
    }),
  },
  status: {
    ok: colors.mainPrimary,
    danger: orange[500],
    error: colors.errordark,
    success: colors.mainPrimary,
    unknown: alpha(colors.textPrimary, 0.8),
  },
});

export const fontFamilySecondary = `"Roboto-Regular", ${ outerTheme.typography.fontFamily }`;

export const fonts = {
  textPrimary: `"Roboto-Medium", ${ outerTheme.typography.fontFamily }`,
  textSecondary: `"Roboto-Regular", ${ outerTheme.typography.fontFamily }`,
};

export default outerTheme;
