import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isAllowedToCreateGroup } from 'utils/permissions';
import { useLevelDetailPage } from './utils';

// components
import { Box, Grid } from '@mui/material';
import { AddButton, DialogButton } from 'components/Buttons';
import { AddLevelGroupDialog } from 'components/DeviceManagement';
import { NodeTreeTable } from 'components/DeviceManagement/TreeTable/NodeTreeTable';
import { LevelNotFound } from './widget/LevelNotFound';
import { LevelSkeleton } from './widget/LevelSkeleton';
import { LevelManagement } from './widget/LevelManagement';
import { LevelStatistic } from './widget/LevelStatistic';
// styles
import useStyles from 'styles/page';

interface Params {
  zoneId: string;
  levelId: string;
}

export const LevelDetailPage = ({ zoneId, levelId }: Params) => {
  const classes = useStyles();
  const { isLoading, isValid, level, tree, node, reload, back } = useLevelDetailPage(zoneId, levelId);

  return (
    <Box className={ classes.root } data-testid="page-dm-level-detail">
      <Helmet><title>Level { level?.name ?? `#${ levelId }` }</title></Helmet>
      { !isValid && <LevelNotFound zoneId={ zoneId } levelId={ levelId }/> }
      { isValid && isLoading && <LevelSkeleton/> }
      { isValid && node && level && (
        <>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 12 }>
              <LevelManagement
                level={ level }
                node={ node }
                onEdit={ reload }
                onDelete={ back }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <LevelStatistic
                tree={ tree }
                node={ node }
                level={ level }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <NodeTreeTable
                embedded
                tree={ tree }
                node={ node }
                loaded={ tree.loaded.group }
              />
            </Grid>
          </Grid>
          <DialogButton
            permission={ isAllowedToCreateGroup }
            button={ handler => <AddButton label="Add group" onClick={ handler }/> }
            dialog={ props =>
              <AddLevelGroupDialog
                { ...props }
                zone={ node.parent.zone }
                level={ level }
                onSuccess={ reload }
              />
            }
          />
        </>
      ) }
    </Box>
  );
};
