import React from 'react';
import { useLocation } from 'react-router';
import { RouteConfig, matchRoutes } from 'react-router-config';

import { routesRendererBaseProps } from './utils';

import logo from 'images/nwave_logo_bk.png';

// components
import {
  Divider,
  Typography,
  List,
  SwipeableDrawer,
} from '@mui/material';
import { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';

import { RoutesContext, isImplementedRoute, renderRoutes, renderRouteLabel } from 'utils/routing';
import MainMenuLink from './parts/MainMenuLink';
import MenuCategory from './parts/MenuCategory';

// styles
import useStyles from './styles';

function createGetImplementedRouteWithLabel(pathname: string) {
  function getImplementedRouteWithLabel(route: RouteConfig): RouteConfig[] {
    // FIXME: should we necessarily hide its child routes too?
    if (route.hiddenOnMainMenu) {
      return [];
    }

    const matchedRoutes = matchRoutes([route], pathname);
    const labelRendererProps = {
      match: matchedRoutes.length ? matchedRoutes[0].match : null,
      route,
    };

    if (!renderRouteLabel(labelRendererProps)) {
      return [];
    }

    if (
      !isImplementedRoute(route) &&
      (!route.routes || !route.routes.length)
    ) {
      return [];
    }

    const childRoutes = (route.routes || []).flatMap(getImplementedRouteWithLabel);

    if (isImplementedRoute(route)) {
      return [{ ...route, routes: childRoutes }];
    }

    if (!childRoutes.length) {
      return [];
    }

    return [{ ...route, routes: childRoutes }];
  }

  return getImplementedRouteWithLabel;
}

interface Props {
  swipeableDrawerProps: SwipeableDrawerProps;
}

const MainMenu: React.FC<Props> = ({ swipeableDrawerProps }) => {
  const classes = useStyles({});
  const location = useLocation();

  return (
    <SwipeableDrawer { ...swipeableDrawerProps } data-testid="main-menu">
      <div className={ classes.toolbar }>
        <img src={ logo } alt="Logo" className={ classes.logo } />
        <Typography variant="caption">Console</Typography>
      </div>
      <Divider />
      <List
        className={ classes.noPadding }
        onClick={ swipeableDrawerProps.onClose }
      >
        <RoutesContext.Consumer>
          { routes => routes && renderRoutes({
            ...routesRendererBaseProps,
            renderRouteChildren: (props) => {
              if (!props.route.routes || !props.route.routes.length) {
                return <MainMenuLink { ...props } />;
              }

              return <MenuCategory { ...props } />;
            },
            routes: routes.flatMap(createGetImplementedRouteWithLabel(location.pathname)),
          }) }
        </RoutesContext.Consumer>
      </List>
      <Divider />
    </SwipeableDrawer>
  );
};

export default MainMenu;
