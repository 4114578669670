import { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { checkTimeFromToRelation, DATE_WITH_MINUTES_FORMAT } from 'utils/datetime';
// components
import { MobileDateTimePicker } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { InputAdornment, IconButton, TextField } from '@mui/material';
import { DateRange } from '@mui/icons-material';
// styles
import contentStyles from 'styles';
import { getDatePickerPaperStyles } from 'styles/themes';

interface Props {
  label: string;
  value?: Date | null;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange: (date?: Date) => void;
  error?: string;
  helperText?: string;
  hideErrorsFromHelperText?: boolean;
  isClearable?: boolean;
}

export const DateTimeControl = (props: Props): JSX.Element => {
  const classes = contentStyles();
  const [open, setOpen] = useState<boolean>(false);
  const error = props.error
    || (props.maxDate && props.value && checkTimeFromToRelation(props.maxDate, props.value))
    || (props.minDate && props.value && checkTimeFromToRelation(props.value, props.minDate));

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <MobileDateTimePicker
        renderInput={ textProps => (
          <TextField
            { ...textProps }
            fullWidth
            margin="normal"
            label={ props.label }
            className={ clsx(classes.formControl, props.className) }
            error={ !!error }
            helperText={ props.hideErrorsFromHelperText ? props.helperText : props.error || error || props.helperText }
            disabled={ props.isDisabled }
            required={ props.isRequired }
            value={ props.value }
            onClick={ () => setOpen(true) }
            InputProps={ {
              endAdornment: !props.isDisabled && (
                <InputAdornment position="end">
                  <IconButton onClick={ () => setOpen(true) }>
                    <DateRange/>
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        ) }
        open={ open }
        onClose={ () => setOpen(false) }
        minutesStep={ 1 }
        maxDateTime={ props.maxDate ? moment(props.maxDate) : undefined }
        minDateTime={ props.minDate ? moment(props.minDate) : undefined }
        disableFuture={ true }
        hideTabs={ false }
        showToolbar={ true }
        ampm={ false }
        disabled={ props.isDisabled }
        value={ props.value ? props.value : null }
        inputFormat={ DATE_WITH_MINUTES_FORMAT }
        onChange={ () => true }
        onAccept={ date => props.onChange(date ? date.toDate() : undefined) }
        clearable={ props.isClearable }
        DialogProps={ {
          PaperProps: { sx: theme => getDatePickerPaperStyles(theme)  }
        } }
      />
    </LocalizationProvider>
  );
};