import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { GroupsFilterFields, initGroupsFilters } from 'models/device-management/filters';
import { groupTypes as types } from 'models/device-management/dm';

// components
import Grid from '@mui/material/Grid';
import { SingleSelectControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/filters';

type Props = {
  applyFilters: (filter: GroupsFilterFields) => void
  filters: GroupsFilterFields
  isFetching: boolean
}

const GroupsFilter = ({ applyFilters, filters, isFetching }: Props): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState<GroupsFilterFields>(initGroupsFilters);

  useDeepCompareEffect(() => {
    setState(filters);
    setState(filters);
  }, [filters]);

  const onSave = () => applyFilters(state);
  const onReset = () => {
    setState(initGroupsFilters);
    applyFilters(initGroupsFilters);
  };

  const handleSelectOwner = (owner: number|  undefined) => setState({ ...state, owner });
  const handleSelectProjects = (projects: number[]) => { setState(state => ({ ...state, projects })); };
  const handleSelectType = (groupType: string | undefined) => setState({ ...state, groupType });
  const handleSelectZones = (zones: number[]) => { setState(state => ({ ...state, zones })); };

  return (
    <FilterButton
      isLoading={ isFetching }
      filter={ filters }
      onSave={ onSave }
      onReset={ onReset }
    >
      <form autoComplete="off" className={ classes.gridRoot }>
        <GroupedFilters
          handleSelectOwner={ handleSelectOwner }
          owner={ state.owner }
          projects={ state.projects }
          handleSelectProjects={ handleSelectProjects }
          zones={ state.zones || [] }
          handleSelectZones={ handleSelectZones }
          isProjectShow
          isZoneShow
          isGroupsShow={ false }
        />
        <Grid>
          <SingleSelectControl
            selected={ state.groupType }
            label="Group Type"
            values={ Object.entries(types)
              .map(el => ({
                label: el[1],
                value: el[0]
              })
              ) }
            changeHandler={ handleSelectType }
            name="groupType"
            isClearable
          />
        </Grid>
      </form>
    </FilterButton>
  );
};

export default GroupsFilter;
