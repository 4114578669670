import React, { useState } from 'react';
import { BaseStation } from 'models/base-station';
import { DeviceWithConnectivity } from 'models/device-management/dm';

import { BSConnectivityMapFilters } from 'pages/BaseStation/ConnectivityMap/types';
import { useMapByFilters } from './utils';

// components
import { Box } from '@mui/material';
import { CommonMap } from 'components/Map';
import { LegendPopover } from 'components/Popover';
import { Legend } from './widgets/Legend';
import { StationMarker } from './widgets/StationMarker';
import { DeviceMarker } from './widgets/DeviceMarker';

// styles
import useStyles from './styles';

interface Props {
  isLoading: boolean;
  filter: BSConnectivityMapFilters;
  stations: BaseStation[];
  devices: DeviceWithConnectivity[];
}

export const StationConnectivityMap = (props: Props) => {
  const { isLoading, filter, stations, devices } = props;
  const classes = useStyles();

  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  const [stationId, setStationId] = useState<number | undefined>(undefined);
  const mapState = useMapByFilters(isLoading, filter, stations, devices);

  return (
    <Box className={ classes.root }>
      <LegendPopover><Legend/></LegendPopover>
      <CommonMap { ...mapState }>
        { mapState.stations.map(station => {
          return (
            <div data-testid="map-marker" key={ station.id }>
              <StationMarker
                station={ station }
                isSelected={ stationId === station.id }
                onSelect={ () => setStationId(stationId === station.id ? undefined : station.id) }
                onClose={ () => setStationId(undefined) }
              />
            </div>
          );
        }) }
        { mapState.devices.map(device => {
          return (
            <div data-testid="map-marker" key={ device.device_id }>
              <DeviceMarker
                device={ device }
                isSelected={ deviceId === device.device_id }
                onSelect={ () => setDeviceId(deviceId === device.device_id ? undefined : device.device_id) }
                onClose={ () => setDeviceId(undefined) }
              />
            </div>
          );
        }) }
      </CommonMap>
    </Box>
  );
};

