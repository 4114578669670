import React from 'react';
// components
import LensIcon from '@mui/icons-material/Lens';
// styles
import { styled } from 'styles/utils';

const StyledIcon = styled(LensIcon)(({ theme }) => ({
  fontSize: '0.5rem',
  marginRight: theme.spacing(.5),
}));

interface Props {
  status: boolean;
}

export const ActiveStatusIcon = ({ status }: Props) => {
  return (
    <StyledIcon color={ status ? 'secondary' : 'error' }/>
  );
};