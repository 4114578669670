import React, { useState } from 'react';

// components
import { Button } from '@mui/material';
import { ChangeUserPasswordModal } from './ChangeUserPasswordModal';
import { ChangePasswordByUserModal } from './ChangePasswordByUserModal';

// styles
import { User } from 'models/user-management';

interface Props {
  isCurrentUser: boolean;
  userSub: User['sub'];
  isAdmin: boolean;
}

export const ChangeUserPasswordControl = ({ isCurrentUser, userSub, isAdmin }: Props): JSX.Element => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const showModal = () => setDialogOpen(true);

  return (
    <>
      <Button
        color="inherit"
        onClick={ showModal }
        disabled={ !isAdmin && !isCurrentUser }
      >Change Password</Button>

      { !isCurrentUser && isAdmin && isDialogOpen && <ChangeUserPasswordModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
        userSub={ userSub }
      /> }

      { isCurrentUser && isDialogOpen && <ChangePasswordByUserModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
      /> }
    </>
  );
};
