import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getCallerTemplateSuiteDetailPath } from 'routing/paths';
import { useTemplateSuitesDictionary } from 'utils/dictionary';
// components
import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface Props {
  templateSuiteId?: number;
  templateSuiteName?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

const CallerTemplateSuiteLink = ({ templateSuiteId, templateSuiteName, innerProps }: Props): JSX.Element => {
  const templateSuites = useTemplateSuitesDictionary();
  const templateSuite = templateSuites.find((el) => el.template_suite_id === templateSuiteId);
  const TypoProps = { ...defaultProps, ...innerProps };

  if (!templateSuite) {
    return <Typography { ...TypoProps }>-</Typography>;
  }

  const label = templateSuiteName ?? templateSuite.name;
  const path = getCallerTemplateSuiteDetailPath(String(templateSuite.template_suite_id));
  return <Link component={ RouterLink } color="secondary" to={ path }>{ label }</Link>;
};

export default CallerTemplateSuiteLink;
