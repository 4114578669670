import React from 'react';
import { useSelector } from 'react-redux';
import { TemplateSuite } from 'models/caller';
import { RootState } from 'reducers';
import { UpdateTemplateSuite } from 'actions/caller';
import { getCallerTemplateSuiteClonePath } from 'routing/paths/caller';
import { useAuthUserSelector, useForm, useFormActionLoader, useFormEditToogleActions } from 'hooks';
// components
import { Dialog } from 'components/Caller/TemplateSuiteDialogs/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DeleteButton from 'components/Buttons/DeleteButton';
import CloneButton from 'components/Buttons/CloneButton';
import { CallerTemplateSuiteForm, TemplateSuiteFormField } from 'components/Caller';

interface Props {
  templateSuite: TemplateSuite;
  openDialog: (dialog: Dialog) => void;
}

export const CallerTemplateSuiteManagment = ({ templateSuite, openDialog }: Props): JSX.Element => {
  const isLocked = useSelector((state: RootState) => state.caller.templateSuite.isLocked(templateSuite.template_suite_id));

  const user = useAuthUserSelector();
  const form = useForm<TemplateSuite, TemplateSuiteFormField>({
    initialState: templateSuite,
    showFields: [
      ...(user.isAdmin ? [TemplateSuiteFormField.owner_id] : []),
      TemplateSuiteFormField.name
    ]
  });

  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: !isLocked,
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: UpdateTemplateSuite(form.state),
        onError: form.catchError
      });
    },
    onCancel: form.reset
  });

  return (
    <Card>
      <CardHeader title={ templateSuite.name } action={
        editMode
          ? actions
          : (
            <>
              <DeleteButton
                type="icon"
                isLoading={ isLocked }
                onClick={ () => openDialog(Dialog.DELETE) }
              />
              <CloneButton
                type="icon"
                isLoading={ isLocked }
                LinkTo={ getCallerTemplateSuiteClonePath(String(templateSuite.template_suite_id)) }
              />
              { actions }
            </>
          )
      }/>
      <CardContent>
        <CallerTemplateSuiteForm isView={ !editMode } { ...form } />
      </CardContent>
    </Card>
  );
};
