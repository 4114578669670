import React from 'react';
import { useSelector } from 'react-redux';
import { TemplateSuite } from 'models/caller';
import { RootState } from 'reducers';
import { CloneTemplateSuite, UpdateTemplateSuite } from 'actions/caller';
import { useAuthUserSelector, useDialog, useForm, useFormActionLoader, useFormEditActions } from 'hooks';
import { RoutesState, TemplatesState } from 'hooks/caller';
import { getCallerTemplateSuiteListPath } from 'routing/paths/caller';
// compomnents
import { Box, Grid, Paper } from '@mui/material';
import DeleteButton from 'components/Buttons/DeleteButton';
import CloneButton from 'components/Buttons/CloneButton';
import { CreateSuiteDialog, CallerTemplateSuiteForm, TemplateSuiteFormField } from 'components/Caller';
import { DeleteDialog } from 'components/Caller/RoutesTemplatesSuitesTable/DeleteDialog';
// styles
import { makeStyles, createStyles } from 'styles/utils';

const useStyles = makeStyles(() => createStyles({
  root: {
    padding: '17px 14px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flex: 1,
  },
}));

interface Props {
  templateSuite: TemplateSuite;
  routesState: RoutesState;
  templatesState: TemplatesState;
}

export const CallerTemplateSuiteManagmentNew = ({
  templateSuite,
  routesState,
  templatesState,
}: Props): JSX.Element => {
  const classes = useStyles();

  const isLocked = useSelector((state: RootState) => state.caller.templateSuite.isLocked(templateSuite.template_suite_id));

  const user = useAuthUserSelector();
  const form = useForm<TemplateSuite, TemplateSuiteFormField>({
    initialState: templateSuite,
    showFields: [
      ...(user.isAdmin ? [TemplateSuiteFormField.owner_id] : []),
      TemplateSuiteFormField.name
    ]
  });

  const cloneSuiteDialog = useDialog();
  const deleteSuiteDialog = useDialog();

  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: !isLocked,
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: UpdateTemplateSuite(form.state),
        onError: form.catchError
      });
    },
    onCancel: form.reset,
  });

  return (
    <Paper className={ classes.root } data-testid="template-suite-management">
      <CallerTemplateSuiteForm
        className={ classes.form }
        renderFields={ children => <Grid container spacing={ 3 }>{ children }</Grid> }
        renderField={ children => <Grid item xs={ 3 }>{ children }</Grid> }
        { ...form }
      />

      <Box>
        { form.isChange
          ? actions
          : (
            <>
              <CloneButton
                type="text"
                isLoading={ isLocked || templatesState.isLoading }
                onClick={ cloneSuiteDialog.open }
              />
              <DeleteButton
                type="text"
                isLoading={ isLocked || templatesState.isLoading || routesState.isLoading }
                onClick={ deleteSuiteDialog.open }
              />
            </>
          )
        }
      </Box>

      { deleteSuiteDialog.isMounted && (
        <DeleteDialog
          isOpen={ deleteSuiteDialog.isOpen }
          data={ { suite: templateSuite, routes: routesState.routes } }
          onClose={ deleteSuiteDialog.close }
          onCloseEnd={ deleteSuiteDialog.unmount }
          onSuccess={ () => ({
            push: true,
            to: getCallerTemplateSuiteListPath(),
          }) }
        />
      ) }

      { cloneSuiteDialog.isMounted && (
        <CreateSuiteDialog
          isOpen={ cloneSuiteDialog.isOpen }
          templateSuite={ {
            ...templateSuite,
            template_suite_id: 0,
            name: `CLONE ${ templateSuite.name }`
          } }
          text={ {
            title: 'Clone Template Suite',
            submitButton: 'Clone',
          } }
          getSubmitAction={ suite => CloneTemplateSuite(suite, templatesState.templates) }
          onClose={ cloneSuiteDialog.close }
          onCloseEnd={ cloneSuiteDialog.unmount }
          onSuccess={ cloneSuiteDialog.close }
        />
      ) }
    </Paper>
  );
};
