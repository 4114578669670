import { Helmet } from 'react-helmet-async';
import { useAuthUserSelector, useDialog, useDialogWithItem } from 'hooks';
import { Device } from 'models/dms';
import { useDeviceLedData } from './utils';
// components

import { AddButton } from 'components/Buttons';
import { PageFilter } from './widgets/Filter';
import { PageTable } from './widgets/Table';
import { CreateDialog } from './widgets/CreateDialog';
import { EditDialog } from './widgets/EditDialog';
// styles
import useStyles from 'styles/page';

const DeviceLed = (): JSX.Element => {
  const classes = useStyles();
  const { isAdmin } = useAuthUserSelector();
  const { isLoading, devices, queryParam, setQueryParam } = useDeviceLedData();
  const addDialog = useDialog();
  const editDialog = useDialogWithItem<Device>();

  return (
    <>
      <Helmet><title>Devices LED-display</title></Helmet>
      <div className={ classes.toolbar }>
        <PageFilter
          isLoading={ isLoading }
          param={ queryParam }
          setParam={ setQueryParam }
        />
      </div>
      <div className={ classes.table }>
        <PageTable
          isLoading={ isLoading }
          devices={ devices }
          onEdit={ editDialog.open }
        />
      </div>
      { isAdmin && <AddButton label="Add device" onClick={ addDialog.open }/> }
      { addDialog.isMounted && <CreateDialog dialog={ addDialog } /> }
      { editDialog.isMounted && editDialog.item && <EditDialog
        device={ editDialog.item }
        dialog={ editDialog }
      /> }
    </>
  );
};

export default DeviceLed;
