import { Position, PositionedDeviceData } from 'models/device-management';

import emptyIcon from 'images/Map/green-bagel2.svg';
import fillIcon from 'images/Map/green-circle2.svg';

// components
import { Marker } from '@react-google-maps/api';

// styles
import { PositionMarkerInfo } from './PostionMarkerInfo';

interface Props {
  position: Position;
  device?: PositionedDeviceData;
  openInfo?: number;
  onClick: (position?: Position) => void;
  onDragEnd: (lat: number, lon: number) => void;
  coordinate: {
    lat: number;
    lng: number;
  };
}

export const PositionMarker = ({ position, device, openInfo, onClick, onDragEnd, coordinate }: Props): JSX.Element => {

  return (
    <Marker
      draggable
      key={ position.id }
      icon={ device ? fillIcon : emptyIcon }
      zIndex={ 200 }
      position={ coordinate }
      onClick={ () => onClick(position) }
      onDragEnd={ (e) => onDragEnd(e.latLng?.lat() ?? 0, e.latLng?.lng() ?? 0) }
    >
      { openInfo === position.id && (
        <PositionMarkerInfo
          position={ position }
          device={ device }
          onClose={ () => onClick(undefined) }
        />
      ) }
    </Marker>
  );
};