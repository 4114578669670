import React from 'react';

import { CallerTemplatePresetsState } from 'hooks/caller';

// compomnents
import { ButtonBase, FormControl, Checkbox, Link } from '@mui/material';

import { FieldSkeleton } from 'components/Skeleton';

// styles
import { useTemplatePresetControlStyles } from './styles';

interface TemplatePresetControlProps {
  value: number[];
  onChange: (value: number[]) => void;
  presetsState: CallerTemplatePresetsState;
}

export const TemplatePresetControl = ({
  value,
  onChange,
  presetsState,
}: TemplatePresetControlProps) => {
  const classes = useTemplatePresetControlStyles();

  return (
    <FormControl className={ classes.root } data-testid="template-preset-control">
      { presetsState.isLoading
        ? <FieldSkeleton />
        : presetsState.presets?.map((preset, i) => (
          <ButtonBase
            component="label"
            key={ i }
            className={ classes.preset }
            data-testid="preset-control-option"
          >
            <div>
              <div className={ classes.presetName } data-testid="preset-name">
                { preset.name }
              </div>
              <div className={ classes.presetDescription }>
                <span className={ classes.presetDescriptionText } data-testid="preset-description">
                  { preset.description }
                </span>
                { preset.doc_url && (
                  <>
                    &ensp;
                    <Link
                      className={ classes.presetDocsLink }
                      href={ preset.doc_url }
                      target="_blank"
                      rel="noopener"
                      data-testid="preset-link"
                    >
                      More...
                    </Link>
                  </>
                ) }
              </div>
            </div>

            <Checkbox
              data-testid="preset-checkbox"
              checked={ value.includes(preset.template_id as number) }
              onChange={ (_event, checked) => onChange(
                checked
                  ? [...new Set([...value, preset.template_id as number])]
                  : value.filter(templateId => templateId !== preset.template_id)
              ) }
            />
          </ButtonBase>
        )) }
    </FormControl>
  );
};
