import { Level, Group } from 'models/device-management';
import { isAllowedToDeleteLevel, isAllowedToUpdateLevel } from 'utils/permissions';
import { LevelNode } from 'hooks/device-management/useTree/types';
import { useZoneProjectName } from 'components/DeviceManagement/utils';
import { useAuthUserSelector } from 'hooks';

// components
import { Grid, Paper, Typography } from '@mui/material';
import { DialogButton, EditButton } from 'components/Buttons';
import { FieldSkeleton } from 'components/Skeleton';
import { LevelFormSider } from 'components/Levels/LevelForm';
import { DeleteLevel } from 'components/Levels/DeleteLevel';

// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  level: Level;
  node: LevelNode;
  onEdit: () => void;
  onDelete: (level: Level) => void;
}

export const LevelManagement = ({ level, node, onEdit }: Props): JSX.Element => {
  const authUser = useAuthUserSelector();
  const classes = useStyles();
  const zoneName = node.parent.zone.name;
  const projectName = useZoneProjectName(node.parent.zone);

  const levels = Object.values(node.parent.children.items)
    .reduce((result, levelNode) => {
      levelNode.level && result.push(levelNode.level);
      return result;
    }, [] as Level[]);

  const groups = Object.values(node.children.items)
    .reduce((result, groupNode) => {
      groupNode.group && result.push(groupNode.group);
      return result;
    }, [] as Group[]);

  return (
    <Paper className={ classes.root } data-testid="dm-level-management">
      <Grid container alignItems="center">
        <Grid item lg={ 9 }>
          <Grid container spacing={ 3 } alignItems="center">
            <Grid item xs={ 3 }>
              <Typography>Level Name</Typography>
              <Typography className={ classes.itemValue }>{ level.name }</Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Floor</Typography>
              <Typography className={ classes.itemValue }>
                { level.id === 0 ? 'No floor' : level.floor_number }
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Zone Name</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { zoneName ?? <FieldSkeleton height={ 24 }/> }
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Project Name</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { projectName ?? <FieldSkeleton height={ 24 }/> }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={ 3 } className={ classes.buttonBlock }>
          <DialogButton
            permission={ user => level.id !== 0 && isAllowedToUpdateLevel(user) }
            button={ open => <EditButton type="text" label="Edit" onClick={ open }/> }
            dialog={ props =>
              <LevelFormSider
                mode="EDIT"
                { ...props }
                onCloseEnd={ props.onCloseEnd }
                zone={node.parent.zone}
                zoneLevels={ levels }
                open={props.isOpen}
                level={ level }
                onUpdateSuccess={ onEdit }
              />
            }
          />
          {level.id !== 0 && isAllowedToDeleteLevel(authUser.data) && <DeleteLevel
            groups={ groups }
            level={ level }
          />}
        </Grid>
      </Grid>
    </Paper>
  );
};
