import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { getResponseData } from 'utils/clients';
import { ListDeviceParam } from 'models/dms';
import { listDevice } from 'clients/dms';


export function useDMSListDeviceQuery(param: ListDeviceParam) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['dms/devices/list', param],
    queryFn: async () => {
      const res = await listDevice(param);
      return {
        data: getResponseData(res),
      };
    },
    cacheTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching devices: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: queryResult.isLoading,
    devices: queryResult.data?.data || [],
  };
}